import { TextInput } from "components/inputs/text-input/TextInput";
import { TextArea } from "components/inputs/textarea/Textarea";
import { ThumbnailUploader } from "components/video-upload/components/thumbnail-uploader";
import { t } from "i18next";
import styles from "./index.module.scss";
import { CategoriesAssignment } from "components/inputs/categories-assignment";
import {
    BroadcastStatus,
    BroadcastWebLink,
    Category,
    CategoryType
} from "@switcherstudio/switcher-api-client";
import { DisplaySelectedButton } from "components/buttons/DisplaySelectedButton";
import { PlayerSelectModal } from "components/modal/PlayerSelectModal";
import { WebLinkSelectModal } from "components/modal/WebLinkSelectModal";
import { ScheduleVideoVisibilityBar } from "components/inputs/schedule-video-visibility-bar";
import { useEffect, useState } from "react";
import { exists } from "helpers/booleans";
import { AspectRatio } from "views/page-content/cloud/upload/types";
import { useClaimCheck } from "hooks/useClaimCheck";
import { Toggle } from "components/inputs/toggle/Toggle";
import { Trans } from "react-i18next";
import { Link } from "react-navi";
import { closeCurrentModal } from "store/modal/slice";
import { useDispatch } from "react-redux";

export interface BroadcastDetailsFormProps {
    broadcastId?: string;
    title: string;
    setTitle: (title: string) => void;
    description: string;
    setDescription: (description: string) => void;
    thumbnailFile: File;
    setThumbnailFile: (thumbnailFile: File) => void;
    thumbnailImageURL: string;
    setThumbnailImageURL: (thumbnailImageURL: string) => void;
    selectedCategories?: Category[];
    setSelectedCategories?: (selectedCategories: Category[]) => void;
    onRemoveThumbnail?: () => void;
    showThumbnailUploader?: boolean;
    showCategories?: boolean;
    players?: string[];
    links?: BroadcastWebLink[];
    onLinkSave?: (links: BroadcastWebLink[]) => void;
    setAddToPlayers?: (players: string[]) => void;
    showCategoryDropdownWhenEmpty?: boolean;
    broadcastStatus?: BroadcastStatus;
    setBroadcastStatus?: (status: BroadcastStatus) => void;
    startsAt?: string;
    setStartsAt?: (date: string) => void;
    showInCatalog: boolean;
    setShowInCatalog: (showInCatalog: boolean) => void;
    liveBroadcast?: boolean;
    isUpload?: boolean;
    /** Provides the player's orientation for additional context.Currently indicates the recommend resolution for the thumbnail */
    orientation?: AspectRatio | undefined;
    disablePlayerSelect?: boolean;
    filename?: string;
    filesize?: string;
}

export const BroadcastDetailsForm = ({
    broadcastId,
    title,
    setTitle,
    description,
    setDescription,
    thumbnailFile,
    setThumbnailFile,
    thumbnailImageURL,
    setThumbnailImageURL,
    selectedCategories,
    setSelectedCategories,
    onRemoveThumbnail,
    showThumbnailUploader = false,
    showCategories = false,
    players,
    links,
    onLinkSave,
    setAddToPlayers = () => {},
    showCategoryDropdownWhenEmpty,
    broadcastStatus,
    setBroadcastStatus,
    liveBroadcast,
    startsAt,
    setStartsAt,
    isUpload = true,
    orientation,
    showInCatalog,
    setShowInCatalog,
    disablePlayerSelect = false,
    filename,
    filesize
}: BroadcastDetailsFormProps) => {
    const [playlistSelectModalIsOpen, setPlaylistSelectModalIsOpen] =
        useState<boolean>(false);
    const [linkSelectModalIsOpen, setLinkSelectModalIsOpen] =
        useState<boolean>(false);
    const hasCatalogClaim = useClaimCheck("catalog");
    const dispatch = useDispatch();

    useEffect(() => {
        if (broadcastStatus === BroadcastStatus._5) {
            setShowInCatalog(false);
        } else if (
            broadcastStatus === BroadcastStatus._3 &&
            showInCatalog != false
        ) {
            setShowInCatalog(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [broadcastStatus, showInCatalog]);

    const emptyState = (
        <Trans
            i18nKey={t("players-select-modal:event-creation-empty")}
            components={{
                link1: (
                    <Link
                        href={"/catalog"}
                        title="Video Catalog"
                        className={styles["link"]}
                        onClick={() => dispatch(closeCurrentModal())}
                    />
                )
            }}
        />
    );

    return (
        <div className={`${styles["form-container"]}`}>
            {exists(players) && (
                <PlayerSelectModal
                    isOpen={playlistSelectModalIsOpen}
                    setIsOpen={setPlaylistSelectModalIsOpen}
                    handleSelect={setAddToPlayers}
                    previouslySelectedIds={players}
                    allowUnselect
                    allowNoSelection
                    emptyStateMessage={hasCatalogClaim && emptyState}
                />
            )}
            <WebLinkSelectModal
                links={links}
                isOpen={linkSelectModalIsOpen}
                broadcastId={broadcastId}
                setIsOpen={setLinkSelectModalIsOpen}
                onSuccess={onLinkSave}
            />
            {filename && (
                <div className={`${styles["selected-file"]}`}>
                    <p className={`${styles["file-name"]}`}>
                        <strong>{t("video-upload:file")}:</strong>
                        {` ${filename}`}
                    </p>
                    <p className={`${styles["file-size"]} `}>{filesize}</p>
                </div>
            )}
            <div className={`${styles["broadcast-info"]}`}>
                <TextInput
                    id="title"
                    className="title"
                    label={t("video-upload:title")}
                    type="text"
                    value={title}
                    onChange={(event) => {
                        setTitle(event.target.value);
                    }}
                ></TextInput>
                <TextArea
                    id="description"
                    label={t("video-upload:description")}
                    value={description}
                    onChange={(event) => {
                        setDescription(event.target.value);
                    }}
                ></TextArea>
            </div>
            {showThumbnailUploader && (
                <div className={`${styles["thumbnail-container"]}`}>
                    <h6>{t("video-upload:thumbnail")}</h6>
                    <ThumbnailUploader
                        thumbnailFile={thumbnailFile}
                        setThumbnailFile={setThumbnailFile}
                        thumbnailImageURL={thumbnailImageURL}
                        setThumbnailImageURL={setThumbnailImageURL}
                        onRemove={onRemoveThumbnail}
                        orientation={orientation}
                    />
                </div>
            )}
            <ScheduleVideoVisibilityBar
                broadcastStatus={broadcastStatus}
                setBroadcastStatus={setBroadcastStatus}
                startsAt={startsAt}
                setStartsAt={setStartsAt}
                liveBroadcast={liveBroadcast}
                isUpload={isUpload}
            />
            {hasCatalogClaim && (
                <div className={`${styles["toggle-container"]}`}>
                    <Toggle
                        on={showInCatalog}
                        onToggle={() => setShowInCatalog(!showInCatalog)}
                        label={t("video-upload:show-in-catalog")}
                        reverseLayout
                        disabled={broadcastStatus === BroadcastStatus._5}
                    />
                </div>
            )}
            {showCategories && (
                <CategoriesAssignment
                    categoryType={CategoryType._0}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    showCategoryDropdownWhenEmpty={
                        showCategoryDropdownWhenEmpty
                    }
                />
            )}
            {exists(players) && !disablePlayerSelect && (
                <DisplaySelectedButton
                    selected={players}
                    onClick={() => setPlaylistSelectModalIsOpen(true)}
                    label={
                        hasCatalogClaim
                            ? t("video-upload:collections")
                            : t("video-upload:players")
                    }
                    buttonText={
                        hasCatalogClaim
                            ? t("video-upload:add-to-collections")
                            : t("video-upload:add-to-playlists")
                    }
                    //Singular key here for dynamic pluralization of text
                    itemText={
                        hasCatalogClaim
                            ? t("video-upload:collection")
                            : t("video-upload:player")
                    }
                />
            )}
            {exists(links) && (
                <DisplaySelectedButton
                    selected={links}
                    onClick={() => setLinkSelectModalIsOpen(true)}
                    label={t("video-upload:links")}
                    buttonText={t("video-upload:add-links")}
                    //Singular key here for dynamic pluralization of text
                    itemText={t("video-upload:link")}
                />
            )}
        </div>
    );
};

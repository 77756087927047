import { useMemo } from "react";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { UploadedFile } from "store/videoUploadSession/types";

/** Returns some values regarding current cloud usage in relation to added files */
export const useGetCloudUsageForUploadSessions = () => {
    const { uploadSessions } = useSelector(
        (s: RootState) => s.videoUploadSession
    );

    // all of the added files from all of the upload sessions that haven't started uploading yet
    const aggregatedSessionFiles: UploadedFile[] = useMemo(() => {
        return Object.values(uploadSessions)
            .reduce(
                (aggregatedFiles, currentSession) => [
                    ...aggregatedFiles,
                    ...Object.values(currentSession.files ?? {})
                ],
                [] as UploadedFile[]
            )
            .filter((file) => file.isAwaitingUpload || file.isUploading);
    }, [uploadSessions]);

    const {
        data: cloudUsage,
        dispatchApiRequest: updateUsage,
        loading: cloudUsageLoading
    } = useSwitcherClient((client) => client.cloudRecordings_GetUsage, {
        requestImmediately: true,
        hideLoading: true
    });

    const libraryFull = useMemo(() => {
        if (!cloudUsage || cloudUsageLoading) return undefined;
        return cloudUsage.TotalRecordings >= cloudUsage.MaxRecordings;
    }, [cloudUsage, cloudUsageLoading]);
    const cloudTotal = cloudUsage?.TotalRecordings;
    const cloudMax = cloudUsage?.MaxRecordings;

    const addedVideosExceedCloudUsage = useMemo(() => {
        if (libraryFull) return false;
        if (!aggregatedSessionFiles || !cloudUsage) return false;
        if (
            aggregatedSessionFiles.length + cloudUsage.TotalRecordings >
            cloudUsage.MaxRecordings
        ) {
            return true;
        }
        return false;
    }, [libraryFull, aggregatedSessionFiles, cloudUsage]);

    return {
        cloudUsageLoading,
        libraryFull,
        addedVideosExceedCloudUsage,
        cloudTotal,
        cloudMax,
        updateUsage
    };
};

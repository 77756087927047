import {
    ExternalAuthorizationBindingModel,
    ShopifyExternalAuthorizationBindingModel
} from "@switcherstudio/switcher-api-client";
import { client } from "api/client";
import React, { useCallback, useEffect, useState } from "react";
import { AuthorizationState, AuthorizePageProps, ProviderId } from "./types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";

export const AuthorizePage: React.FC<AuthorizePageProps> = ({
    credentials,
    code,
    error,
    shop
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [authState, setAuthState] = useState<AuthorizationState>({
        state: "pending"
    });

    const postCredentials = useCallback(
        async (fn: string, payload: ExternalAuthorizationBindingModel) => {
            try {
                await client[fn](payload);
                setAuthState({
                    state: "success"
                });
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: "platforms:authorization-success",
                        fadeMilliseconds: 10000
                    })
                );
            } catch (e) {
                setAuthState({
                    state: "error"
                });
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "platforms:authorization-error"
                    })
                );
            }
        },
        [dispatch]
    );

    const handleStripeSuccess = useCallback(() => {
        setAuthState({
            state: "success"
        });
        dispatch(
            addNotification({
                type: NotificationType.Success,
                message: "platforms:authorization-success",
                fadeMilliseconds: 10000
            })
        );
    }, [dispatch]);

    useEffect(() => {
        async function authorize() {
            // TODO: replace location.origin with dashboardUrl env var
            // as there is an allowed origin restriction.
            const redirectUri = window.location.origin + "/authorize";

            if (error || !code) {
                setAuthState({
                    state: "error"
                });
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "platforms:authorization-error"
                    })
                );
                return;
            }

            const payload:
                | ExternalAuthorizationBindingModel
                | ShopifyExternalAuthorizationBindingModel = {
                AuthCode: code,
                UserId: credentials.userId,
                UserToken: credentials.userToken,
                RedirectUri: redirectUri,
                Shop: shop
            };

            switch (credentials.providerId) {
                case ProviderId.Facebook:
                    await postCredentials("facebook_PostCredentials", payload);
                    return;
                case ProviderId.Twitch:
                    await postCredentials("twitch_PostCredentials", payload);
                    return;
                case ProviderId.Youtube:
                    await postCredentials("youTube_PostCredentials", payload);
                    return;
                case ProviderId.Shopify:
                    await postCredentials("shopify_PostCredentials", payload);
                    return;
                case ProviderId.StripeConnect:
                    handleStripeSuccess();
                    return;
                default:
                    setAuthState({
                        state: "error"
                    });
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "authorization-error"
                        })
                    );
            }
        }

        authorize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credentials, code, error, shop, dispatch]);

    return (
        <>
            {authState.state === "pending" && <></>}
            {(authState.state === "error" || authState.state === "success") && (
                <a
                    className="m-auto d-flex justify-content-center btn btn-primary btn-block btn-lg"
                    href={credentials.finalUrl}
                >
                    Continue
                </a>
            )}
        </>
    );
};

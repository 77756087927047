import { useSwitcherClient } from "hooks/useSwitcherClient";
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import { ModalBase } from "../ModalBase";
import styles from "../CreateOrUpdateSwitcherPlayerModal/index.module.scss";
import mpstyles from "./index.module.scss";
import CloseIcon from "assets/icons/close.svg?react";
import { useTranslation } from "react-i18next";
import ErrorIcon from "assets/icons/error-filled.svg?react";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import rollbar from "helpers/rollbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { v4 as uuidv4 } from "uuid";
import {
    CreatorProduct,
    CreatorProductEntitlementsBindingModelDiscriminator,
    CreatorProductPricesBindingModel,
    VideoPlayerResponse
} from "@switcherstudio/switcher-api-client";
import { useGetStripeAccounts } from "hooks/useGetStripeAccounts";
import { OneTimePassField } from "./OneTimePassField";
import { RadioCard, RadioCardsBlockContainer } from "components/radio-cards";
import { useClaimCheck } from "hooks/useClaimCheck";
import { RecurringPassField } from "./RecurringPassField";
import { useForm, Errors } from "hooks/useForm";
import { AttentionModal } from "../AttentionModal";
import { PlayerSelectModal } from "../PlayerSelectModal";
import { displayAmount } from "helpers/stripe";
import { buildPriceCreateObj, buildPricesArray } from "./helpers";
import { exists } from "helpers/booleans";
import { AppDispatch } from "store/store";
import { postEvents } from "store/events/thunks";
import { AttentionModalTable } from "components/modal/AttentionModal/AttentionModalTable";
import { Button } from "components/buttons/Button";

export interface GatedContentPassFormProps {
    name: string;
    description: string;
    selectedPassType: "one-time" | "recurring";
    recurringMonthlyPriceSelected: boolean;
    recurringAnnualPriceSelected: boolean;
    oneTimePrice: string;
    recurringMonthlyPrice: string;
    recurringAnnualPrice: string;
    recurringApplySubscriptionUpdatesNewOnly: boolean;
}

export interface GatedContentModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    type: PricingModalTypes;
    onSubmit: () => void;
    oldProduct?: CreatorProduct; //for update and comparing old values
    updateModal?: (
        isModal: boolean,
        modalType: PricingModalTypes,
        newProduct?: CreatorProduct
    ) => void;
}

export enum PricingModalTypes {
    Create,
    Update
}

export const GatedContentModal = ({
    isOpen,
    setIsOpen,
    type,
    onSubmit,
    oldProduct,
    updateModal
}: GatedContentModalProps) => {
    const { accounts } = useGetStripeAccounts();
    const hasCatalogClaim = useClaimCheck("catalog");
    const dispatch = useDispatch<AppDispatch>();
    const [pendingAddToPlayers, setPendingAddToPlayers] = useState<string[]>(
        []
    );
    const [attnIsOpen, setAttnIsOpen] = useState<boolean>(false);
    const [playerSelectModalIsOpen, setPlayerSelectModalIsOpen] =
        useState<boolean>(false);
    const [playersWithPasses, setPlayersWithPasses] = useState<
        VideoPlayerResponse[]
    >([]);

    const oldOneTimePrice = useMemo(
        () => oldProduct?.Prices?.find((p) => p.IsRecurring === false),
        [oldProduct]
    );

    const userInfo = useSelector((s: RootState) => s.user?.userInfo);

    const { transformedData: existingAddToPlayers } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_GetVideoPlayers,
        {
            requestImmediately:
                exists(oldProduct?.Id) && type === PricingModalTypes.Update,
            args: [userInfo?.ProjectId, true],
            transformResponseData: ({ originalResponse: players }) => {
                if (players?.constructor !== Array) return [];
                const existingPlayers = players.filter((p) =>
                    p.VideoPlayerEntitlements?.ProductEntitlements?.some(
                        (pe) => pe?.ProductId === oldProduct?.Id
                    )
                );
                setPendingAddToPlayers(
                    existingPlayers.map((p) => p.VideoPlayer?.Id)
                );
                return existingPlayers;
            }
        }
    );

    const oldRecurringMonthlyPrice = useMemo(
        () =>
            oldProduct?.Prices?.find(
                (p) => p.IsRecurring === true && p.RecurringInterval === "month"
            ),
        [oldProduct]
    );

    const oldRecurringAnnualPrice = useMemo(
        () =>
            oldProduct?.Prices?.find(
                (p) => p.IsRecurring === true && p.RecurringInterval === "year"
            ),
        [oldProduct]
    );

    const existingAddToPlayersIds = useMemo(
        () => existingAddToPlayers?.map((p) => p.VideoPlayer?.Id),
        [existingAddToPlayers]
    );

    const { loading: postLoading, dispatchApiRequest: postProduct } =
        useSwitcherClient((client) => client.creatorProducts_Create);

    const { loading: productsUpdateLoading, dispatchApiRequest: putProduct } =
        useSwitcherClient((client) => client.creatorProducts_Update, {
            hideLoading: true
        });

    const {
        loading: pricesCreateLoading,
        dispatchApiRequest: postProductPrices
    } = useSwitcherClient((client) => client.creatorProductPrices_Create, {
        hideLoading: true
    });

    const {
        loading: pricesUpdateLoading,
        dispatchApiRequest: putProductPrices
    } = useSwitcherClient((client) => client.creatorProductPrices_Update, {
        hideLoading: true
    });

    const {
        loading: entitlementsCreateLoading,
        dispatchApiRequest: postEntitlement
    } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_Create,
        {
            hideLoading: true
        }
    );

    const {
        loading: entitlementsDeleteLoading,
        dispatchApiRequest: deleteEntitlement
    } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_Delete,
        {
            hideLoading: true
        }
    );

    const { dispatchApiRequest: getSubscriberCount } = useSwitcherClient(
        (client) => client.creatorProducts_GetSubscriberCount,
        {
            hideLoading: true
        }
    );

    const {
        dispatchApiRequest: getVideoPlayers,
        data: players,
        loading: videoPlayersLoading
    } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_GetVideoPlayers,
        {
            requestImmediately: false,
            args: [userInfo?.ProjectId, true],
            hideLoading: true,
            onError: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:player-retrieval-error")
                    })
                );
            }
        }
    );

    const processing = useMemo(
        () =>
            postLoading ||
            productsUpdateLoading ||
            pricesCreateLoading ||
            pricesUpdateLoading ||
            entitlementsCreateLoading ||
            entitlementsDeleteLoading,
        [
            entitlementsCreateLoading,
            entitlementsDeleteLoading,
            postLoading,
            pricesCreateLoading,
            pricesUpdateLoading,
            productsUpdateLoading
        ]
    );

    const { t } = useTranslation();

    useEffect(() => {
        if (players) return;
        getVideoPlayers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [players, playerSelectModalIsOpen]);

    //Get the complete list of players that have a product that has subscribers.
    const activeProductsWithPlayers = useMemo(async (): Promise<any[]> => {
        if (!players) return [];

        const playersWithActiveSubs: VideoPlayerResponse[] = [];
        for (const player of players) {
            if (
                player.VideoPlayerEntitlements?.ProductEntitlements?.length ===
                0
            )
                continue;
            const subscriberCount = await getSubscriberCount([
                accounts[0]?.Id,
                player.VideoPlayerEntitlements.ProductEntitlements[0].ProductId
            ]);
            if (subscriberCount > 0) playersWithActiveSubs.push(player);
        }

        //Group the players with active products by their associated product.
        const activeProductsWithPlayers: any[] = [];
        playersWithActiveSubs?.forEach((player) => {
            const productId =
                player.VideoPlayerEntitlements.ProductEntitlements[0].ProductId;
            if (!activeProductsWithPlayers[productId]) {
                activeProductsWithPlayers[productId] = [];
            }
            activeProductsWithPlayers[productId].push(player);
        });
        return activeProductsWithPlayers;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [players, accounts]);

    //Takes in the list of players and the players to add along with the current dictionary of activeProductsWithplayers and returns a boolean to determine if the transaction is allowed.
    const determineAllowedToOverwrite = async (
        players: VideoPlayerResponse[],
        playersToAdd: string[],
        activeProductsWithPlayers: Promise<any[]>
    ): Promise<boolean> => {
        //Get the  list of players to add to this pass that have a product that has subscribers.
        const playersToAddWithActiveSubs: VideoPlayerResponse[] = [];
        for (const player of players) {
            if (
                player.VideoPlayerEntitlements.ProductEntitlements.length ===
                    0 ||
                !playersToAdd.includes(player.VideoPlayer.Id)
            )
                continue;
            const subscriberCount = await getSubscriberCount([
                accounts[0]?.Id,
                player.VideoPlayerEntitlements.ProductEntitlements[0].ProductId
            ]);
            if (subscriberCount > 0) playersToAddWithActiveSubs.push(player);
        }

        //Group the players to add to this pass that have active products by their associated product.
        const activeProductsWithPlayersToBeAdded: any = {};
        playersToAddWithActiveSubs.forEach((player) => {
            const productId =
                player.VideoPlayerEntitlements.ProductEntitlements[0].ProductId;
            if (!activeProductsWithPlayersToBeAdded[productId]) {
                activeProductsWithPlayersToBeAdded[productId] = [];
            }
            activeProductsWithPlayersToBeAdded[productId].push(player);
        });

        //Compare the players to be added grouped by their product to the players that had passes grouped by their product to determine if selection is valid.
        let AllowedToOverWrite = true;
        for (const productId of Object.keys(
            activeProductsWithPlayersToBeAdded
        )) {
            const toAddCount =
                activeProductsWithPlayersToBeAdded[productId].length;
            const existingCount =
                activeProductsWithPlayers[productId]?.length ?? 0;
            if (toAddCount >= existingCount) {
                AllowedToOverWrite = false;
            }
        }

        return AllowedToOverWrite;
    };
    const handleUpdateModal = useCallback(
        (
            isModal: boolean,
            modalType: PricingModalTypes,
            newProduct: CreatorProduct
        ) => {
            // Cleanup state and close modals in prep for opening the edit of the price
            setPlayersWithPasses([]);
            setAttnIsOpen(false);
            setPlayerSelectModalIsOpen(false);
            setIsOpen(false);
            updateModal(isModal, modalType, newProduct);
        },
        [updateModal, setIsOpen]
    );

    const handleSetAddToPlayers = useCallback(
        async (ids: string[]) => {
            const playersAlreadyWithPasses = players.filter((player) => {
                return (
                    ids.includes(player.VideoPlayer.Id) &&
                    player.VideoPlayerEntitlements.ProductEntitlements.length >
                        0 &&
                    player.VideoPlayerEntitlements.ProductEntitlements.some(
                        (entitlement) =>
                            entitlement.ProductId !== oldProduct?.Id
                    )
                );
            });
            if (playersAlreadyWithPasses.length > 0) {
                setAttnIsOpen(true);
            } else {
                setPlayerSelectModalIsOpen(false);
            }
            setPlayersWithPasses(playersAlreadyWithPasses);
            setPendingAddToPlayers(ids);
        },
        [players, setAttnIsOpen, setPlayerSelectModalIsOpen, oldProduct]
    );

    const handleCancelAttentionModal = useCallback(async () => {
        setPlayersWithPasses([]);
        setAttnIsOpen(false);
        setPlayerSelectModalIsOpen(true);
    }, [setPlayersWithPasses, setAttnIsOpen, setPlayerSelectModalIsOpen]);

    const handleContinueAttentionModal = useCallback(async () => {
        setAttnIsOpen(false);
        setPlayerSelectModalIsOpen(false);
    }, [setAttnIsOpen, setPlayerSelectModalIsOpen]);

    const handleSubmit = useCallback(
        async ({
            name,
            description,
            selectedPassType,
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            oneTimePrice,
            recurringMonthlyPrice,
            recurringAnnualPrice,
            recurringApplySubscriptionUpdatesNewOnly
        }: GatedContentPassFormProps) => {
            //check if values have been changed
            const oneTimePassHasNoChanges =
                !oldOneTimePrice?.IsRecurring &&
                selectedPassType === "one-time" &&
                name === oldProduct?.Name &&
                description === oldProduct?.Description &&
                parseFloat(oneTimePrice) === oldOneTimePrice?.Amount;

            const recurringPassHasNoChanges =
                oldProduct?.Prices?.[0]?.IsRecurring &&
                selectedPassType === "recurring" &&
                name === oldProduct?.Name &&
                description === oldProduct?.Description &&
                ((!oldRecurringMonthlyPrice &&
                    !recurringMonthlyPriceSelected) ||
                    oldRecurringMonthlyPrice?.Amount ===
                        parseFloat(recurringMonthlyPrice)) &&
                ((!oldRecurringAnnualPrice && !recurringAnnualPriceSelected) ||
                    oldRecurringAnnualPrice?.Amount ===
                        parseFloat(recurringAnnualPrice));

            const playersToRemove =
                existingAddToPlayers?.filter(
                    (p) => !pendingAddToPlayers?.includes(p.VideoPlayer?.Id)
                ) || [];
            const playersToAdd =
                pendingAddToPlayers?.filter(
                    (p) => !existingAddToPlayersIds?.includes(p)
                ) || [];

            const allowedToOverWrite = await determineAllowedToOverwrite(
                players,
                playersToAdd,
                activeProductsWithPlayers
            );

            if (!allowedToOverWrite) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t(
                            "gated-content-modal:errors:pass-with-subscribers-error"
                        )
                    })
                );
                onSubmit();
                return;
            }

            if (type === PricingModalTypes.Update) {
                const subscriberCount = await getSubscriberCount([
                    accounts[0]?.Id,
                    oldProduct?.Id
                ]);
                if (
                    subscriberCount !== null &&
                    subscriberCount > 0 &&
                    playersToAdd.length < 1 &&
                    playersToRemove.length === existingAddToPlayers?.length
                ) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t(
                                "gated-content-modal:errors:pass-with-subscribers-error"
                            )
                        })
                    );
                    onSubmit();
                    return;
                }

                for (const player of playersToRemove) {
                    await deleteEntitlement([
                        player.VideoPlayerEntitlements?.ProductEntitlements[0]
                            .Id
                    ]);
                }
                if (oneTimePassHasNoChanges || recurringPassHasNoChanges) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Info,
                            message: t(
                                "gated-content-modal:messages:pricing-update-success"
                            )
                        })
                    );
                    setIsOpen(false);
                    onSubmit();
                    return;
                }

                if (playersToAdd.length > 0) {
                    await postEntitlement([
                        {
                            ProductEntitlements: playersToAdd.map(
                                (VideoPlayerId) => {
                                    return {
                                        ProductId: oldProduct.Id,
                                        Discriminator:
                                            CreatorProductEntitlementsBindingModelDiscriminator._0,
                                        VideoPlayerId
                                    };
                                }
                            )
                        }
                    ]);
                }
            }
            if (type === PricingModalTypes.Create) {
                const getPrices = (
                    productId: string
                ): CreatorProductPricesBindingModel[] => {
                    const prices = [];
                    if (selectedPassType === "one-time") {
                        return [
                            buildPriceCreateObj(
                                name,
                                productId,
                                oneTimePrice,
                                false
                            )
                        ];
                    } else {
                        if (recurringMonthlyPriceSelected) {
                            prices.push(
                                buildPriceCreateObj(
                                    name,
                                    productId,
                                    recurringMonthlyPrice,
                                    true,
                                    "month"
                                )
                            );
                        }

                        if (recurringAnnualPriceSelected) {
                            prices.push(
                                buildPriceCreateObj(
                                    name,
                                    productId,
                                    recurringAnnualPrice,
                                    true,
                                    "year"
                                )
                            );
                        }
                    }

                    return prices;
                };

                try {
                    const res = await postProduct([
                        accounts[0]?.Id,
                        {
                            Products: [
                                {
                                    Id: uuidv4(),
                                    Name: name,
                                    Description: description,
                                    StripeAccountId: accounts[0]?.Id,
                                    IsActive: true
                                }
                            ]
                        }
                    ]);

                    await postProductPrices([
                        accounts?.[0]?.Id,
                        res?.Products?.[0]?.Id,
                        {
                            Prices: getPrices(res?.Products?.[0]?.Id)
                        }
                    ]);

                    if (playersToAdd.length > 0) {
                        await postEntitlement([
                            {
                                ProductEntitlements: playersToAdd.map(
                                    (VideoPlayerId) => {
                                        return {
                                            ProductId: res?.Products[0]?.Id,
                                            Discriminator:
                                                CreatorProductEntitlementsBindingModelDiscriminator._0,
                                            VideoPlayerId
                                        };
                                    }
                                )
                            }
                        ]);
                    }

                    dispatch(postEvents({ "created-pass": true }));
                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: t(
                                "gated-content-modal:messages:pricing-create-success"
                            )
                        })
                    );
                } catch (e) {
                    rollbar.error("Error creating pass", e);
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t(
                                "gated-content-modal:errors:pricing-create-error"
                            )
                        })
                    );
                } finally {
                    setIsOpen(false);
                    onSubmit();
                }
            } else {
                const pricesArray = buildPricesArray({
                    recurringMonthlyPriceSelected,
                    recurringAnnualPriceSelected,
                    recurringMonthlyPrice,
                    recurringAnnualPrice,
                    oneTimePrice,
                    oldProduct,
                    oldRecurringMonthlyPrice,
                    oldRecurringAnnualPrice,
                    oldOneTimePrice,
                    name,
                    selectedPassType
                });

                try {
                    if (
                        name !== oldProduct?.Name ||
                        description !== oldProduct.Description
                    ) {
                        await putProduct([
                            accounts[0]?.Id,
                            {
                                Products: [
                                    {
                                        Id: oldProduct?.Id,
                                        Name: name,
                                        Description: description,
                                        StripeAccountId: accounts[0]?.Id,
                                        IsActive: true
                                    }
                                ]
                            }
                        ]);
                    }
                    await putProductPrices([
                        accounts[0]?.Id,
                        oldProduct?.Id,
                        {
                            Prices: pricesArray,
                            UpdateExistingCustomersToPrice:
                                !recurringApplySubscriptionUpdatesNewOnly
                        }
                    ]);

                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: t(
                                "gated-content-modal:messages:pricing-update-success"
                            )
                        })
                    );
                } catch (e) {
                    rollbar.error("Error updating pass", e);
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t(
                                "gated-content-modal:errors:pricing-update-error"
                            )
                        })
                    );
                } finally {
                    setIsOpen(false);
                    onSubmit();
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            oldOneTimePrice,
            oldProduct,
            oldRecurringMonthlyPrice,
            oldRecurringAnnualPrice,
            type,
            dispatch,
            t,
            setIsOpen,
            onSubmit,
            postProduct,
            accounts,
            postProductPrices,
            postEntitlement,
            putProductPrices,
            putProduct,
            deleteEntitlement,
            getSubscriberCount,
            existingAddToPlayers,
            existingAddToPlayersIds,
            pendingAddToPlayers,
            activeProductsWithPlayers,
            players
        ]
    );

    const validateFields = useCallback(
        ({
            name,
            description,
            selectedPassType,
            oneTimePrice,
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringAnnualPrice,
            recurringMonthlyPrice
        }: GatedContentPassFormProps) => {
            let errors: Errors = {};
            const validatePrice = (id: string, price: string) => {
                const baseError = t(
                    "gated-content-modal:errors:price-min-error"
                );
                if (!price) {
                    errors[id] = t("gated-content-modal:errors:price-error");
                }

                const minPrices = {
                    recurringAnnualPrice: import.meta.env
                        .VITE_MINIMUM_ANNUAL_PRICE,
                    recurringMonthlyPrice: import.meta.env
                        .VITE_MINIMUM_MONTHLY_PRICE,
                    oneTimePrice: import.meta.env.VITE_MINIMUM_ONE_TIME_PRICE
                };

                if (parseFloat(price) < parseFloat(minPrices[id])) {
                    errors[id] = `${baseError} $${minPrices[id]}`;
                }
            };

            if (!name?.length) {
                errors.name = t("gated-content-modal:errors:name-error");
            }

            if (name.length > 25) {
                errors.name = t("gated-content-modal:errors:name-max-error");
            }

            if (description?.length > 500) {
                errors.description = t(
                    "gated-content-modal:errors:description-max-error"
                );
            }

            if (selectedPassType === "one-time") {
                validatePrice("oneTimePrice", oneTimePrice);
            } else {
                if (recurringMonthlyPriceSelected) {
                    validatePrice(
                        "recurringMonthlyPrice",
                        recurringMonthlyPrice
                    );
                }
                if (recurringAnnualPriceSelected) {
                    validatePrice("recurringAnnualPrice", recurringAnnualPrice);
                }
            }
            return errors;
        },
        [t]
    );

    const hasRecurringPaymentsClaim = useClaimCheck(
        "gatedcontent.recurringpayments"
    );

    const {
        values: {
            name,
            description,
            selectedPassType,
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            oneTimePrice
        },
        values,
        errors,
        customHandleChange,
        handleSubmit: finalHandleSubmit
    } = useForm<GatedContentPassFormProps>(
        {
            name: "",
            description: "",
            selectedPassType: hasRecurringPaymentsClaim
                ? "recurring"
                : "one-time",
            recurringMonthlyPriceSelected: true,
            recurringAnnualPriceSelected: true,
            oneTimePrice: "2.00",
            recurringMonthlyPrice: "10.00",
            recurringAnnualPrice: "100.00",
            recurringApplySubscriptionUpdatesNewOnly: true
        },
        handleSubmit,
        validateFields
    );

    useEffect(() => {
        if (type === PricingModalTypes.Update) {
            customHandleChange({
                ...values,
                selectedPassType: oldProduct?.Prices?.[0]?.IsRecurring
                    ? "recurring"
                    : "one-time",
                name: oldProduct?.Name,
                description: oldProduct?.Description,
                ...(oldOneTimePrice && {
                    oneTimePrice: displayAmount(oldOneTimePrice?.Amount, {
                        signed: false,
                        compact: false,
                        roundUp: false,
                        useGrouping: false
                    })
                }),
                ...(oldRecurringMonthlyPrice && {
                    recurringMonthlyPriceSelected: true,
                    recurringMonthlyPrice: displayAmount(
                        oldRecurringMonthlyPrice?.Amount,
                        {
                            signed: false,
                            compact: false,
                            roundUp: false,
                            useGrouping: false
                        }
                    )
                }),
                ...(oldRecurringAnnualPrice && {
                    recurringAnnualPriceSelected: true,
                    recurringAnnualPrice: displayAmount(
                        oldRecurringAnnualPrice?.Amount,
                        {
                            signed: false,
                            compact: false,
                            roundUp: false,
                            useGrouping: false
                        }
                    )
                }),
                ...(!oldRecurringMonthlyPrice && {
                    recurringMonthlyPriceSelected: false
                }),
                ...(!oldRecurringAnnualPrice && {
                    recurringAnnualPriceSelected: false
                })
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oldProduct, type]);

    const submitDisabled = useMemo(
        () =>
            hasRecurringPaymentsClaim &&
            selectedPassType === "recurring" &&
            !recurringMonthlyPriceSelected &&
            !recurringAnnualPriceSelected,
        [
            hasRecurringPaymentsClaim,
            recurringAnnualPriceSelected,
            recurringMonthlyPriceSelected,
            selectedPassType
        ]
    );

    const handleDismiss = useCallback(() => {
        setPendingAddToPlayers(existingAddToPlayersIds);
    }, [existingAddToPlayersIds]);

    const buttonText = useMemo(() => {
        if (pendingAddToPlayers.length === 0) {
            return hasCatalogClaim
                ? t("platforms:multiple-collection-select")
                : t("platforms:multiple-player-select");
        }
        if (pendingAddToPlayers.length === 1) {
            return `1 ${
                hasCatalogClaim
                    ? t("video-library:badges:collection")
                    : t("video-library:badges:playlist")
            }`;
        }
        return `${pendingAddToPlayers.length} ${
            hasCatalogClaim
                ? t("video-library:badges:collections")
                : t("video-library:badges:playlists")
        }`;
    }, [pendingAddToPlayers.length, hasCatalogClaim, t]);

    return (
        <>
            <ModalBase
                preventDismiss={
                    processing || attnIsOpen || playerSelectModalIsOpen
                }
                onDismiss={handleDismiss}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                stylesOverride={{
                    padding: "0",
                    height: "auto",
                    maxWidth: "700px"
                }}
            >
                <>
                    <div className={styles["header"]}>
                        {type === PricingModalTypes.Create
                            ? t("gated-content-modal:create-title")
                            : t("gated-content-modal:edit-title")}
                        <button
                            className={styles["close-btn"]}
                            onClick={() => setIsOpen(false)}
                        >
                            <CloseIcon />
                        </button>
                    </div>

                    <div className={styles["content"]}>
                        <div className={styles["label-row"]}>
                            <label htmlFor="name">
                                {t("switcher-cloud:name")}
                            </label>

                            <small>{`${name?.length ?? 0} / 25`}</small>
                        </div>
                        <input
                            id="name"
                            type="text"
                            className={`${styles["name-input"]} form-control`}
                            value={name}
                            maxLength={25}
                            placeholder={t(
                                "gated-content-modal:name-placeholder"
                            )}
                            onChange={(e) => {
                                customHandleChange({
                                    ...values,
                                    name: e?.currentTarget?.value
                                });
                            }}
                        />
                        {errors.name && (
                            <div className={mpstyles["sub-error"]}>
                                <ErrorIcon /> {errors.name}
                            </div>
                        )}
                        <div className={styles["label-row"]}>
                            <label htmlFor="description">
                                {t("gated-content-modal:description")}
                            </label>

                            <small>{`${description?.length ?? 0} / 500`}</small>
                        </div>
                        <textarea
                            id="description"
                            className={`${styles["description-input"]} form-control`}
                            value={description}
                            maxLength={500}
                            placeholder={t(
                                "gated-content-modal:description-placeholder"
                            )}
                            onChange={(e) => {
                                customHandleChange({
                                    ...values,
                                    description: e?.currentTarget?.value
                                });
                            }}
                        />
                        <div className={styles["label-row"]}>
                            <label htmlFor="pass-type">
                                {t("gated-content-modal:pricing-type")}
                            </label>
                        </div>
                        {hasRecurringPaymentsClaim ? (
                            <RadioCardsBlockContainer>
                                <RadioCard
                                    id="recurring"
                                    content={
                                        <div style={{ textAlign: "left" }}>
                                            {t("misc:recurring")}
                                        </div>
                                    }
                                    onSelect={() => {
                                        values.selectedPassType !==
                                            "recurring" &&
                                            customHandleChange({
                                                ...values,
                                                selectedPassType: "recurring"
                                            });
                                    }}
                                    selectedCardId={selectedPassType}
                                    name="Recurring"
                                    expandedContent={
                                        <RecurringPassField
                                            values={values}
                                            errors={errors}
                                            onChange={customHandleChange}
                                            setPlayerSelectModalIsOpen={
                                                setPlayerSelectModalIsOpen
                                            }
                                            buttonText={buttonText}
                                            loading={videoPlayersLoading}
                                            buttonActive={
                                                pendingAddToPlayers.length > 0
                                            }
                                            type={type}
                                        />
                                    }
                                    checkboxPosition="start"
                                    selectionLocked={
                                        type === PricingModalTypes.Update
                                    }
                                />
                                <RadioCard
                                    id="one-time"
                                    content={
                                        <div style={{ textAlign: "left" }}>
                                            {t("misc:one-time")}
                                        </div>
                                    }
                                    onSelect={() => {
                                        values.selectedPassType !==
                                            "one-time" &&
                                            customHandleChange({
                                                ...values,
                                                selectedPassType: "one-time"
                                            });
                                    }}
                                    selectedCardId={selectedPassType}
                                    name="One Time"
                                    expandedContent={
                                        <OneTimePassField
                                            values={values}
                                            errors={errors}
                                            onChange={customHandleChange}
                                        />
                                    }
                                    checkboxPosition="start"
                                    selectionLocked={
                                        type === PricingModalTypes.Update
                                    }
                                />
                            </RadioCardsBlockContainer>
                        ) : (
                            <>
                                <div
                                    id="pricingType"
                                    className={`${mpstyles["pricing-upper"]}`}
                                >
                                    <div>
                                        <span
                                            className={mpstyles["pricing-type"]}
                                        >
                                            {t(
                                                "gated-content-modal:pay-per-view"
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="pricingType-lower"
                                    className={`${mpstyles["pricing-lower"]}`}
                                >
                                    <div className={styles["label-row"]}>
                                        <label htmlFor="name">{"Price"}</label>
                                    </div>
                                    <div className={mpstyles["price-row"]}>
                                        <span
                                            className={
                                                mpstyles["price-currency"]
                                            }
                                        >
                                            US$
                                        </span>
                                        <input
                                            id="price"
                                            type="number"
                                            min="2.00"
                                            className={`form-control ${mpstyles["price-input"]}`}
                                            value={oneTimePrice}
                                            placeholder={t(
                                                "gated-content-modal:price-placeholder"
                                            )}
                                            onChange={(e) => {
                                                customHandleChange({
                                                    ...values,
                                                    oneTimePrice:
                                                        e?.currentTarget?.value
                                                });
                                            }}
                                        />
                                    </div>
                                    {errors.oneTimePrice && (
                                        <div className={mpstyles["sub-error"]}>
                                            <ErrorIcon /> {errors.oneTimePrice}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>

                    <div className={styles["footer"]}>
                        <button
                            className="btn btn-text"
                            onClick={() => setIsOpen(false)}
                        >
                            {t("buttons:cancel")}
                        </button>

                        <Button
                            type="primary"
                            onClick={() => finalHandleSubmit()}
                            disabled={processing || submitDisabled}
                        >
                            {type === PricingModalTypes.Create
                                ? t("buttons:create")
                                : t("buttons:save")}
                        </Button>
                    </div>
                </>
            </ModalBase>
            <PlayerSelectModal
                isOpen={playerSelectModalIsOpen}
                setIsOpen={setPlayerSelectModalIsOpen}
                previouslySelectedIds={pendingAddToPlayers}
                handleSelect={handleSetAddToPlayers}
                allowUnselect={true}
                allowNoSelection={true}
                isDuringPassCreation={type === PricingModalTypes.Create}
                players={players}
                preventDismiss={attnIsOpen}
            />
            <AttentionModal
                isOpen={attnIsOpen}
                setIsOpen={setAttnIsOpen}
                updateModal={handleUpdateModal}
                handleCancel={handleCancelAttentionModal}
                handleContinue={handleContinueAttentionModal}
            >
                <div>
                    <p
                        className={`${mpstyles["undo-mb"]} ${mpstyles["padding"]}`}
                    >
                        {t("gated-content-page:attention-body")}
                    </p>
                </div>
                <AttentionModalTable
                    players={playersWithPasses}
                    updateModal={handleUpdateModal}
                />

                <div className={`${mpstyles["undo-mb"]}`}>
                    <p
                        className={`${mpstyles["undo-mb"]} ${mpstyles["padding-top"]}`}
                    >
                        {t("gated-content-page:attention-action")}
                    </p>
                </div>
            </AttentionModal>
        </>
    );
};

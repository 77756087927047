import React, { useMemo } from "react";
import styles from "./index.module.scss";
import { exists } from "helpers/booleans";

export interface ProgressBarProps {
    currentValue: number;
    maxValue: number;
    hideCount?: boolean;
    variant?:
        | undefined
        | "cloud-assets"
        | "thick"
        | "video-storage-medium"
        | "video-storage-max"
        | "thin-video-storage"
        | "thin-video-storage-medium"
        | "thin-video-storage-max";
    /** Bar completion displays the maxValue plus 1. Effectively, bar never displays full, even at X/X. */
    isStepped?: boolean;
    cloudUsageLoading?: boolean;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
    currentValue,
    maxValue,
    hideCount,
    variant,
    isStepped = false,
    cloudUsageLoading = false
}: ProgressBarProps) => {
    const _maxValue = isStepped ? maxValue + 1 : maxValue;
    const _currentvalue = isStepped ? currentValue + 1 : currentValue;

    const percentage = useMemo(() => {
        return _currentvalue <= _maxValue
            ? (_currentvalue / _maxValue) * 100
            : 100;
    }, [_currentvalue, _maxValue]);

    return (
        <div
            className={`${styles["progress-container"]} ${
                exists(variant) ? styles[variant] : ""
            }`}
        >
            <div className={styles["progress-bar"]}>
                {!cloudUsageLoading && (
                    <>
                        <div className={`${styles["progress-background"]}`} />
                        <div
                            className={`${styles["progress-foreground"]}`}
                            style={{ width: percentage + "%" }}
                        />
                    </>
                )}
            </div>
            {!hideCount && (
                <div className={styles["progress-text"]}>
                    {_currentvalue || 0} / {maxValue || 0}
                </div>
            )}
        </div>
    );
};

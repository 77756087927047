import React, { useCallback } from "react";
import { useForm, Errors } from "hooks/useForm";
import styles from "./LoginPage/index.module.scss";
import { Link } from "react-navi";
import { LoginInput } from "components/inputs/text-input/LoginInput";
import { client } from "api/client";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { switcherSdk } from "utils/switcher-sdk";
import { AppDispatch } from "store/store";

export const ForgotPasswordPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const onSubmit = useCallback(
        async (values) => {
            await client.account_ForgotPassword({ Email: values.email });
            dispatch(
                addNotification({
                    type: NotificationType.Info,
                    message: "messages:check-inbox"
                })
            );
        },
        [dispatch]
    );

    const { values, errors, isSubmitting, handleChange, handleSubmit } =
        useForm<{
            email: string;
        }>(
            {
                email: ""
            },
            onSubmit,
            (values) => {
                let errors: Errors = {};
                if (!values.email) {
                    errors.email = t("forgot-password:enter-email");
                } else if (!/\S+@\S+\.\S+/.test(values.email)) {
                    errors.email = t("errors:invalid-email");
                }

                return errors;
            }
        );

    return (
        <>
            <form onSubmit={handleSubmit}>
                <LoginInput
                    label={t("login:email-address")}
                    type="email"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                />
                {errors.api && (
                    <div className="alert alert-danger" role="alert">
                        {errors.api}
                    </div>
                )}
                <button
                    type="submit"
                    className="btn btn-white md-btn btn-block mb-4"
                    disabled={isSubmitting}
                >
                    {t("page-titles:reset-password").toUpperCase()}
                </button>
            </form>
            {switcherSdk.clientId === "Generic" && (
                <div className="text-center">
                    <Link
                        className={`text-center ${styles["login-links"]}`}
                        href="/login"
                    >
                        {t("misc:login-return").toUpperCase()}
                    </Link>
                </div>
            )}
        </>
    );
};

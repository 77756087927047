import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit";
import { UserState, ITicket, IUserInfo, GetUserOrgsPayload } from "./types";
import {
    getToken,
    getUserInfo,
    getUserOrgs,
    refreshToken,
    getDefaultUserOrg,
    exchangeCode
} from "./thunks";
import { OrganizationUserDefault } from "@switcherstudio/switcher-api-client";
import braze from "helpers/braze";

// TODO: configure these somewhere (store?)

const initialState = {
    ticket: undefined,
    userInfo: undefined,
    userStreamDestinations: [],
    orgStreamDestinations: [],
    orgMaxStreamDestinations: undefined,
    orgMaxUsers: undefined,
    defaultStreamDestination: undefined,
    orgName: undefined,
    orgs: [],
    userFeatures: [],
    defaultOrg: undefined,
    projectId: undefined
} as UserState;

export const user = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        logout: () => initialState,
        identify: (state, { payload }: PayloadAction<object>) => {
            analytics.identify(state.userInfo?.UserId || "", {
                email: state.userInfo?.Email || "",
                username: state.userInfo?.Email || "",
                // avatar: getGravatarImageUrl(),
                plan: state.ticket?.activePlan || "",
                enterprise: state.userInfo?.OrganizationId ? true : false,
                ...payload
            });

            braze.identify(state.userInfo?.UserId);
            braze.open();

            try {
                profitwell("start", { user_email: state.userInfo?.Email });
            } catch (e) {
                // could not start profitwell
            }
        },
        setTicket: (state, { payload }: PayloadAction<ITicket>) => {
            state.ticket = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            getToken.fulfilled,
            (state, { payload }: PayloadAction<ITicket>) => {
                state.ticket = payload;
            }
        );

        builder.addCase(
            exchangeCode.fulfilled,
            (state, { payload }: PayloadAction<ITicket>) => {
                state.ticket = payload;
            }
        );

        builder.addCase(
            getUserInfo.fulfilled,
            (state, { payload }: PayloadAction<IUserInfo>) => {
                state.userInfo = payload;
            }
        );

        builder.addCase(
            getUserOrgs.fulfilled,
            (state, { payload }: PayloadAction<GetUserOrgsPayload>) => {
                const { orgs, maximumDestinations, maximumUsers, name } =
                    payload;
                state.orgs = orgs;
                state.orgMaxStreamDestinations = maximumDestinations;
                state.orgMaxUsers = maximumUsers;
                state.orgName = name;
            }
        );

        builder.addCase(
            refreshToken.fulfilled,
            (state, { payload }: PayloadAction<ITicket>) => {
                state.ticket = payload;
            }
        );

        builder.addCase(
            getDefaultUserOrg.fulfilled,
            (state, { payload }: PayloadAction<OrganizationUserDefault>) => {
                state.defaultOrg = payload;
            }
        );

        builder.addCase(getToken.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(exchangeCode.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(getUserInfo.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(getUserOrgs.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(refreshToken.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(getDefaultUserOrg.rejected, (_, { error }) => {
            throw error;
        });
    }
});

export const { logout, identify, setTicket } = user.actions;

export default user.reducer as Reducer<UserState>;

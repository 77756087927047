import { combineReducers } from "@reduxjs/toolkit";
import app from "./app/slice";
import user from "./user/slice";
import events from "./events/slice";
import cloud from "./cloud/slice";
import confirmation from "./confirmation/slice";
import subscription from "./subscription/slice";
import loading from "./loading/slice";
import notification from "./notification/slice";
import platforms from "./platforms/slice";
import preferences from "./preferences/slice";
import rollbar from "./rollbar/slice";
import promotions from "./promotions/slice";
import header from "./header/slice";
import page from "./page/slice";
import api from "./api/slice";
import modal from "./modal/slice";
import videoUploadSession from "./videoUploadSession/slice";
import downloads from "./downloads/slice";
import view from "./view/slice";
import supportSystem from "./supportSystem/slice";

const rootReducer = combineReducers({
    app,
    user,
    events,
    cloud,
    confirmation,
    subscription,
    loading,
    notification,
    platforms,
    preferences,
    rollbar,
    promotions,
    header,
    page,
    api,
    modal,
    videoUploadSession,
    downloads,
    view,
    supportSystem
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    AnalyticsDateRangeOptions,
    AnalyticsRevenuesCards,
    AnalyticsTabs,
    AnalyticsViewsCards,
    CustomizationTabs,
    GatedContentPageTabs,
    SidebarDropdownSelection,
    ViewInitialState
} from "./types";
import { getUTCDate, setToEndOfDay, setToStartOfDay } from "helpers/time";

const initialState = {
    analyticsActiveTab: AnalyticsTabs.Views,
    analyticsViewsCardOrder: [
        AnalyticsViewsCards.Views,
        AnalyticsViewsCards.WatchTime,
        AnalyticsViewsCards.UniqueViewers
    ],
    analyticsRevenueCardOrder: [
        AnalyticsRevenuesCards.Revenue,
        AnalyticsRevenuesCards.Sales,
        AnalyticsRevenuesCards.Customers
    ],
    customizationActiveTab: CustomizationTabs.Display,
    gatedContentActiveTab: GatedContentPageTabs.Overview,
    sidebarDropdownSelection: {
        videoCMS: false,
        creationTools: false,
        accountSettings: false,
        help: false
    },
    analyticsDateRangeSelection: AnalyticsDateRangeOptions.SevenDays,
    analyticsCustomDateRangeSelection: { start: undefined, end: undefined }
} as ViewInitialState;

/**
 * This store contains any persistent UI changes across the dashboard, except for the sidebar. Saves to local storage.
 */
export const view = createSlice({
    name: "view",
    initialState: initialState,
    reducers: {
        updateAnalyticsActiveTab: (
            state,
            { payload }: PayloadAction<AnalyticsTabs>
        ): ViewInitialState => {
            return {
                ...state,
                analyticsActiveTab: payload
            };
        },
        reorderAnalyticsViewsCardOrder: (
            state,
            { payload }: PayloadAction<AnalyticsViewsCards[]>
        ): ViewInitialState => {
            return {
                ...state,
                analyticsViewsCardOrder: payload
            };
        },
        reorderAnalyticsRevenueCardOrder: (
            state,
            { payload }: PayloadAction<AnalyticsRevenuesCards[]>
        ): ViewInitialState => {
            return {
                ...state,
                analyticsRevenueCardOrder: payload
            };
        },
        updateGatedContentPageActiveTab: (
            state,
            { payload }: PayloadAction<GatedContentPageTabs>
        ): ViewInitialState => {
            return {
                ...state,
                gatedContentActiveTab: payload
            };
        },
        toggleSidebarDropdownSelection: (
            state,
            { payload }: PayloadAction<keyof SidebarDropdownSelection>
        ) => {
            return {
                ...state,
                sidebarDropdownSelection: {
                    ...state.sidebarDropdownSelection,
                    [payload]: !(
                        state.sidebarDropdownSelection?.[payload] ?? false
                    )
                }
            };
        },
        setSidebarDropdownSelection: (
            state,
            {
                payload
            }: PayloadAction<{
                key: keyof SidebarDropdownSelection;
                value: boolean;
            }>
        ) => {
            return {
                ...state,
                sidebarDropdownSelection: {
                    ...state.sidebarDropdownSelection,
                    [payload.key]: payload.value
                }
            };
        },
        updateAnalyticsDateRangeSelection: (
            state,
            { payload }: PayloadAction<AnalyticsDateRangeOptions>
        ): ViewInitialState => {
            return {
                ...state,
                analyticsDateRangeSelection: payload,
                analyticsCustomDateRangeSelection: {
                    start: null,
                    end: null
                }
            };
        },
        updateAnalyticsCustomStartDate: (
            state,
            { payload }: PayloadAction<Date>
        ): ViewInitialState => {
            return {
                ...state,
                analyticsCustomDateRangeSelection: {
                    ...state.analyticsCustomDateRangeSelection,
                    start: setToStartOfDay(getUTCDate(payload)).toISOString()
                }
            };
        },
        updateAnalyticsCustomEndDate: (
            state,
            { payload }: PayloadAction<Date>
        ): ViewInitialState => {
            return {
                ...state,
                analyticsCustomDateRangeSelection: {
                    ...state.analyticsCustomDateRangeSelection,
                    end: setToEndOfDay(getUTCDate(payload)).toISOString()
                }
            };
        },
        updateCustomizationActiveTab: (
            state,
            { payload }: PayloadAction<CustomizationTabs>
        ): ViewInitialState => {
            return {
                ...state,
                customizationActiveTab: payload
            };
        }
    }
});

export const {
    updateAnalyticsActiveTab,
    reorderAnalyticsViewsCardOrder,
    reorderAnalyticsRevenueCardOrder,
    updateGatedContentPageActiveTab,
    toggleSidebarDropdownSelection,
    setSidebarDropdownSelection,
    updateAnalyticsDateRangeSelection,
    updateAnalyticsCustomStartDate,
    updateAnalyticsCustomEndDate,
    updateCustomizationActiveTab
} = view.actions;

export default view.reducer;

import React, { useEffect, useState } from "react";
import { useNavigate } from "hooks/useNavigate";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { PlatformId } from "views/page-content/platforms/types";
import { DestinationDescriptor } from "../../types";
import { updateGeneralForm } from "store/platforms/slice";
import { client } from "api/client";

interface SimulcastGenericDestationSelectorProps {
    platformId: PlatformId;
}

export const SimulcastGenericDestinationSelector: React.FC<
    SimulcastGenericDestationSelectorProps
> = ({ platformId }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { navigate } = useNavigate();

    const [destination, setDestination] = useState<DestinationDescriptor>();
    const {
        generalForm: { selectedSimulcastDestinations }
    } = useSelector((state: RootState) => state.platforms.general);

    useEffect(() => {
        const getDestination = async () => {
            const provider =
                await client.streamingProviders_GetStreamingProvider(
                    platformId
                );
            const destinationDescriptor: DestinationDescriptor = {
                name: provider.Name,
                id: provider.ChannelSetting["switcher-stream-setting-id"],
                platform: provider.Id as PlatformId | "custom",
                logoUrl: provider.LogoUrl
            };
            setDestination(destinationDescriptor);
        };
        if (!destination) getDestination();
    }, [destination, platformId]);

    useEffect(() => {
        if (
            !selectedSimulcastDestinations.some(
                (d) => d.platform === platformId
            )
        ) {
            if (destination) {
                dispatch(
                    updateGeneralForm({
                        selectedSimulcastDestinations: [
                            ...selectedSimulcastDestinations,
                            destination
                        ]
                    })
                );

                navigate(`/platforms/simulcast/destinations`);
            }
        }
    }, [
        dispatch,
        navigate,
        selectedSimulcastDestinations,
        destination,
        platformId
    ]);

    return <></>;
};

import React from "react";
import styles from "./DownloadsPage.module.scss";
import Apple from "assets/icons/apple.svg?react";
import Windows from "assets/icons/windows.svg?react";
import { useTranslation } from "react-i18next";

// Import the app icons and set based on the environment variable
import OldAppIcon from "assets/AppIcon-1024x1024_old.png";
import NewAppIcon from "assets/AppIcon-1024x1024_new.png";
import OldSwitcherCast from "assets/SwitcherCast_old.png";
import NewSwitcherCast from "assets/SwitcherCast_new.png";

const useNewLogo = import.meta.env.VITE_USE_NEW_LOGO === "true";
const AppIcon = useNewLogo ? NewAppIcon : OldAppIcon;
const SwitcherCast = useNewLogo ? NewSwitcherCast : OldSwitcherCast;

export const DownloadsPage: React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className={`${styles["apps-container"]}`}>
                <a
                    className={`${styles["dl-container"]}`}
                    href="https://itunes.apple.com/us/app/switcher-studio-pro/id908386221"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className={`${styles["icon-container"]}`}>
                        <img
                            className={`img-fluid ${styles["smaller-icon"]}`}
                            src={AppIcon}
                            alt={`Switcher app ${styles["icon"]}`}
                        />
                    </div>
                    <div className={`${styles["title-container"]}`}>
                        <p className={`${styles["dl-title-cast"]}`}>
                            <Apple className={`${styles["icon"]}`} />{" "}
                            SwitcherStudio iOS App
                        </p>
                    </div>
                    <div className={styles["description-container"]}>
                        <small>{t("downloads:ios-description")}</small>
                    </div>
                </a>
                <div className={`${styles["dl-container"]}`}>
                    <div className={`${styles["icon-container"]}`}>
                        <img
                            className={`img-fluid ${useNewLogo ? styles["smaller-icon"] : styles["app-icon"]}`}
                            src={SwitcherCast}
                            alt={`SwitcherCast ${styles["icon"]}`}
                        />
                    </div>
                    <div className={`${styles["title-container"]}`}>
                        <a
                            className={`${styles["dl-title-cast"]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://itunes.apple.com/us/app/switcher-cast/id1238109306?mt=12"
                        >
                            <Apple className={`${styles["icon"]}`} /> Switcher
                            Cast {t("misc:for_para")} MacOS
                        </a>
                        <a
                            className={`${styles["dl-title-cast"]}`}
                            href="https://switcherstudio.blob.core.windows.net/staticcontent/software/SwitcherCastWindows.zip"
                        >
                            <Windows className={`${styles["icon"]}`} /> Switcher
                            Cast {t("misc:for_para")} Windows
                        </a>
                        <div className={styles["description-container"]}>
                            <small>
                                {t("downloads:switchercast-description")}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

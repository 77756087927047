import styles from "./index.module.scss";
import CountdownIcon from "assets/icons/countdown.svg?react";
import PlayButtonIcon from "assets/icons/play.svg?react";
import PremierIcon from "assets/icons/premierecountdown.svg?react";
import { BaseThumbnail } from "../BaseThumbnail";
import { EmptyThumbnail } from "components/thumbnails/EmptyThumbnail";
import { useMemo } from "react";
import { DisabledVariant } from "components/entity-details/BroadcastDetails";

interface BroadcastThumbnailProps {
    thumbnailImageURL: string;
    allowVideoPlaybackOnThumbnailClick: boolean;
    handlePlayVideo: (...args: any) => any;
    isScheduledUpload: boolean;
    isScheduledLive: boolean;
    disabled: boolean;
    disabledVariant: DisabledVariant;
}

export const BroadcastThumbnail = ({
    thumbnailImageURL,
    allowVideoPlaybackOnThumbnailClick,
    handlePlayVideo,
    isScheduledUpload,
    isScheduledLive,
    disabled,
    disabledVariant
}: BroadcastThumbnailProps) => {
    const countdownOrThumbnail = useMemo(
        () =>
            thumbnailImageURL && !isScheduledUpload && !isScheduledLive ? (
                <img
                    className={`video-thumbnail-image`}
                    src={thumbnailImageURL}
                    alt="video-thumbnail-image"
                />
            ) : (
                <>
                    {isScheduledLive && <CountdownIcon />}
                    {isScheduledUpload && <PremierIcon />}
                </>
            ),
        [thumbnailImageURL, isScheduledUpload, isScheduledLive]
    );

    const displayedThumbnail = useMemo(() => {
        if (disabled && disabledVariant === DisabledVariant.Disabled) {
            return (
                <BaseThumbnail>
                    <EmptyThumbnail />
                </BaseThumbnail>
            );
        }

        if (allowVideoPlaybackOnThumbnailClick && !isScheduledLive) {
            return (
                <button
                    className={`video-preview ${styles["video-preview"]}`}
                    onClick={handlePlayVideo}
                >
                    <BaseThumbnail>
                        {countdownOrThumbnail}
                        <div className={styles["play-button-wrapper"]}>
                            <PlayButtonIcon
                                className={styles["play-button-icon"]}
                            />
                        </div>
                    </BaseThumbnail>
                </button>
            );
        }

        return <BaseThumbnail>{countdownOrThumbnail}</BaseThumbnail>;
    }, [
        disabled,
        disabledVariant,
        allowVideoPlaybackOnThumbnailClick,
        isScheduledLive,
        countdownOrThumbnail,
        handlePlayVideo
    ]);

    return displayedThumbnail;
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import { useNavigate } from "hooks/useNavigate";
import { DestinationSelector } from "components/destination-selector/DestinationSelector";
import { youtube } from "api/youtube/youtube-client";
import { setLoading } from "store/loading/slice";
import rollbar from "helpers/rollbar";
import { usePlatformActions } from "hooks/usePlatformActions";
import { PlatformId } from "views/page-content/platforms/types";
import { updateGeneralForm } from "store/platforms/slice";

interface SimulcastYoutubeDestinationSelectorProps {
    disableAll?: boolean;
}

export const SimulcastYoutubeDestinationSelector: React.FC<
    SimulcastYoutubeDestinationSelectorProps
> = ({ disableAll }) => {
    const { navigate } = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const { generalForm } = useSelector(
        (state: RootState) => state.platforms.general
    );
    const [destinations, setDestinations] = useState([]);
    const { unlink } = usePlatformActions(PlatformId.Youtube);
    const onClick = async (destination: { id: string; snippet?: any }) => {
        if (
            generalForm.selectedSimulcastDestinations.some(
                (d) => d.id === destination.id
            )
        ) {
            return;
        }

        const descrip = {
            name: destination?.snippet?.title,
            id: destination.id,
            platform: PlatformId.Youtube
        };
        dispatch(
            updateGeneralForm({
                selectedSimulcastDestinations: [
                    ...generalForm.selectedSimulcastDestinations,
                    descrip
                ]
            })
        );
        // appendParamArray("destination[]", JSON.stringify(descrip));
        const path = `/platforms/simulcast/destinations`;
        navigate(path);
    };

    useEffect(() => {
        async function getChannelInfo() {
            try {
                dispatch(setLoading(1));
                const { items } = await youtube.channels.list({
                    mine: true,
                    part: "id,snippet,contentDetails"
                });
                setDestinations(items);
                dispatch(setLoading(-1));
            } catch (e) {
                // no unlinking here.
                dispatch(setLoading(-1));
                rollbar.error(
                    "Error getting and setting simulcast youtube destinations",
                    e
                );
            }
        }

        getChannelInfo();
    }, [dispatch, navigate, unlink]);

    return (
        <DestinationSelector
            destinations={destinations}
            imgSrcKey={"snippet.thumbnails.default.url"}
            destinationNameKey="snippet.title"
            onDestinationClick={onClick}
            isSimulcast={true}
            disableAll={disableAll}
        />
    );
};

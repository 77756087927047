import React, {
    useRef,
    SetStateAction,
    Dispatch,
    PropsWithChildren,
    useCallback,
    useMemo
} from "react";
import useClickOutside from "hooks/useClickOutside";
import useKeyPress from "hooks/useKeyPress";
import styles from "./index.module.scss";
import Close from "assets/icons/close.svg?react";
import { Break } from "components/breaks/Break";

export interface ModalButtonProps {
    text: string;
    onClick: (...args: any) => any;
    disabled?: boolean;
    shouldCloseOnClick?: boolean;
}

export interface ModalBaseProps extends PropsWithChildren {
    isOpen: boolean;
    setIsOpen?: Dispatch<SetStateAction<boolean>>;
    header?: string;
    /** Props for the primary button --- used for more complicated modals */
    primaryButton?: ModalButtonProps;
    /** Props for the secondary button --- used for more complicated modals */
    secondaryButton?: ModalButtonProps;
    successButton?: string;
    dismissButton?: string;
    onSuccess?: (...args: any) => any;
    shouldNotCloseOnSuccess?: boolean;
    successDisabled?: boolean;
    onDismiss?: (...args: any) => any;
    shouldHideButtons?: boolean;
    preventDismiss?: boolean;
    stylesOverride?: React.CSSProperties;
    childrenStylesOverride?: React.CSSProperties;
    /**Defines relative size of modal */
    size?: "small" | "large";
}

/** Base modal implementation used for building new modal instances.
 * For simple modals you can utilize successButton, dismissButton, onSuccess, shouldNotCloseOnSuccess, successDisabled, onDismiss props
 * to handle the two main button actions
 *
 * For more complex, multi-step modals you may not want the "secondary" button to have a "dismiss" functionality. In this case you can customize
 * button behavior using the primaryButton and secondaryButton props objects instead of the successButton and dismissButton props. (onDismiss is still needed in this case)
 */
export const ModalBase: React.FC<ModalBaseProps> = ({
    isOpen,
    setIsOpen,
    header,
    primaryButton,
    secondaryButton,
    onDismiss,
    onSuccess,
    successButton,
    dismissButton,
    shouldNotCloseOnSuccess,
    successDisabled,
    shouldHideButtons,
    children,
    preventDismiss,
    stylesOverride = {},
    childrenStylesOverride = {},
    size
}) => {
    const modalRef = useRef(null);
    /** Returns either the default success button attributes OR attributes from custom props */
    const primaryButtonObject: ModalButtonProps = useMemo(() => {
        if (successButton) {
            return {
                text: successButton,
                onClick: onSuccess,
                disabled: successDisabled,
                shouldCloseOnClick: !shouldNotCloseOnSuccess
            };
        } else {
            return primaryButton;
        }
    }, [
        onSuccess,
        primaryButton,
        shouldNotCloseOnSuccess,
        successButton,
        successDisabled
    ]);

    /** Returns either the default dismiss button attributes OR attributes from custom props */
    const secondaryButtonObject: ModalButtonProps = useMemo(() => {
        if (dismissButton) {
            return {
                text: dismissButton,
                onClick: null,
                shouldCloseOnClick: true,
                disabled: false
            };
        } else {
            return secondaryButton;
        }
    }, [dismissButton, secondaryButton]);

    const close = useCallback(() => {
        if (!preventDismiss) {
            setIsOpen && setIsOpen(false);
            onDismiss && onDismiss();
        }
    }, [onDismiss, preventDismiss, setIsOpen]);

    const handleButtonClick = useCallback(
        (btnProps: ModalButtonProps) => {
            btnProps?.onClick && btnProps.onClick();
            if (btnProps?.shouldCloseOnClick) {
                close();
            }
        },
        [close]
    );

    useClickOutside(modalRef, close);
    useKeyPress("Escape", close);

    return (
        <>
            {!isOpen ? null : (
                <div className={`background ${styles["background"]}`}>
                    <div
                        className={`body ${styles["body"]} ${styles[size]}`}
                        ref={modalRef}
                        style={stylesOverride}
                    >
                        {header && (
                            <>
                                <div className={styles["header"]}>
                                    <span>
                                        <h5>{header}</h5>
                                        {!preventDismiss && (
                                            <Close
                                                onClick={() => onDismiss()}
                                            />
                                        )}
                                    </span>
                                </div>
                                <Break customClass={styles["break"]} />
                            </>
                        )}
                        <div
                            className={`children ${styles["children"]}`}
                            style={childrenStylesOverride}
                        >
                            {children}
                        </div>
                        {!shouldHideButtons &&
                            (primaryButtonObject || secondaryButtonObject) && (
                                <>
                                    <Break customClass={styles["break"]} />
                                    <div className={styles["buttons"]}>
                                        {secondaryButtonObject?.text && (
                                            <button
                                                className="btn"
                                                onClick={() =>
                                                    handleButtonClick(
                                                        secondaryButtonObject
                                                    )
                                                }
                                            >
                                                {secondaryButtonObject?.text}
                                            </button>
                                        )}
                                        {primaryButtonObject?.text && (
                                            <button
                                                className={`btn btn-primary ${styles["success-button"]}`}
                                                onClick={() =>
                                                    handleButtonClick(
                                                        primaryButtonObject
                                                    )
                                                }
                                                disabled={
                                                    primaryButtonObject?.disabled
                                                }
                                            >
                                                {primaryButtonObject?.text}
                                            </button>
                                        )}
                                    </div>
                                </>
                            )}
                    </div>
                </div>
            )}
        </>
    );
};

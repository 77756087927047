import { useCallback, useMemo, useState, useEffect } from "react";
import styles from "./index.module.scss";
import { StyledCheckboxOrRadio } from "components/inputs/checkbox/StyledCheckboxOrRadio";
import classnames from "classnames/bind";
import { Button, ButtonVariant } from "components/buttons/Button";
import {
    BroadcastsSearchSortBar,
    BroadcastsSearchSortBarProps
} from "components/inputs/search-sort-bar/BroadcastsSearchSortBar";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "hooks/useIsMobile";
import React from "react";
import { Spinner } from "components/spinners/Spinner";
const cx = classnames.bind(styles);

export interface ComponentItem<T> {
    id: string;
    component: JSX.Element;
    /**
     * The original data object from which the rendered component is dependent upon
     * e.g. the <BroadcastDetails /> component might be paired with a base object of type "VideoPlayerCloudflareResponse"
     */
    baseObject?: T;
}

export interface HashItem<T> {
    item: ComponentItem<T>;
    selected: boolean;
    disabled: boolean;
}

/** key-value pairing where the key is the id of the component item */
export interface ItemsHash<T> {
    [key: string]: HashItem<T>;
}

export interface SearchSortOptions {
    showSearchSort: boolean;
    /** The specific implementation of <SearchSortBar /> to be used. If you need a different implementation, you can make it and add it to this component */
    implementationType?: "broadcast-search-sort";
    location?: BroadcastsSearchSortBarProps["location"];
}

export type MultiSelectActionOnClick<T> = (listItemsProps: T[]) => Promise<any>;

interface MultiSelectActionProps<T> {
    onClick: MultiSelectActionOnClick<T>;
    text: string;
    buttonType: ButtonVariant;
    shouldDeselectOnClick?: boolean;
    MobileIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export interface ActionsBarOptions<T> {
    showActions: boolean;
    actions?: MultiSelectActionProps<T>[];
}

export interface GenericMultiSelectProps<T, Y> {
    itemsHash: ItemsHash<T>;
    selectedId: string;
    handleSelect: (id: string) => void;
    handleSelectAll: (visibleItems?: string[]) => void;
    showNone?: boolean;
    allowUnselect?: boolean;
    isMultiple?: boolean;
    allowItemClick?: boolean;
    checkBoxLocation?: "left" | "right";
    actionsBarOptions?: ActionsBarOptions<Y>;
    searchSortOptions?: SearchSortOptions;
    loading?: boolean;
    setVisibleBroadcasts?: (broadcasts: string[]) => void;
}

export function GenericMultiSelect<T, Y>({
    itemsHash,
    selectedId,
    handleSelect,
    handleSelectAll,
    showNone = false,
    allowUnselect = true,
    isMultiple = false,
    allowItemClick = true,
    checkBoxLocation = "right",
    searchSortOptions = { showSearchSort: false },
    actionsBarOptions = { showActions: false },
    loading = false,
    setVisibleBroadcasts
}: GenericMultiSelectProps<T, Y>) {
    const [sortedAndFilteredItems, setSortedAndFilteredItems] = useState<
        HashItem<T>[]
    >([]);

    /** Set default data if it changes */
    useEffect(() => {
        // if we don't leverage the search sort, set the value to the incoming data, otherwise, the search sort will handle setting this when it loads
        if (itemsHash && !searchSortOptions.showSearchSort) {
            setSortedAndFilteredItems(Object.values(itemsHash));
        }
    }, [itemsHash, searchSortOptions.showSearchSort]);

    const handleBroadcastSort = useCallback(
        (broadcasts: HashItem<T>[]) => {
            setSortedAndFilteredItems(broadcasts);
            setVisibleBroadcasts(broadcasts.map(({ item }) => item.id));
        },
        [setSortedAndFilteredItems, setVisibleBroadcasts]
    );

    const handleSelectLocal = useCallback(
        (id: string, currentlySelected: boolean) => {
            if (!isMultiple && currentlySelected) return;
            handleSelect && handleSelect(id);
        },
        [handleSelect, isMultiple]
    );

    const { isMobile } = useIsMobile();

    const selectedItems = useMemo(
        () =>
            sortedAndFilteredItems?.filter(
                (item) => !item.disabled && item.selected
            ),
        [sortedAndFilteredItems]
    );

    const buttonsDisabled = useMemo(
        () => selectedItems?.length === 0,
        [selectedItems.length]
    );

    const handleAction = useCallback(
        async (onClick: MultiSelectActionOnClick<Y>, shouldDeselectOnClick) => {
            const listItemsPropsArray: Y[] = selectedItems.map(
                (item: HashItem<T>) => item.item.component.props
            );

            await onClick(listItemsPropsArray);
            // Clears selection
            if (shouldDeselectOnClick || shouldDeselectOnClick === undefined) {
                handleSelectAll();
            }
        },
        [selectedItems, handleSelectAll]
    );

    const { t } = useTranslation();

    const searchSortComponent = useMemo(() => {
        if (!searchSortOptions.showSearchSort) return <></>;

        switch (searchSortOptions.implementationType) {
            case "broadcast-search-sort":
                return (
                    <BroadcastsSearchSortBar
                        location={searchSortOptions.location}
                        broadcasts={Object.values(itemsHash)}
                        handleSort={(broadcasts) =>
                            handleBroadcastSort(broadcasts as HashItem<T>[])
                        }
                    />
                );
            // TODO: add support for generic <SearchSortBar /> component here in future!
            default:
                return <></>;
        }
    }, [
        itemsHash,
        searchSortOptions?.implementationType,
        searchSortOptions?.location,
        searchSortOptions?.showSearchSort,
        handleBroadcastSort
    ]);

    const visibleItemIds = useMemo(
        () => sortedAndFilteredItems.map((item) => item.item.id),
        [sortedAndFilteredItems]
    );

    if (loading) {
        return (
            <div className={styles["loading"]}>
                <Spinner />
            </div>
        );
    }

    return (
        <div className={styles["ms-container"]}>
            {searchSortOptions.showSearchSort && searchSortComponent}

            {actionsBarOptions.showActions && (
                <div
                    className={`${styles["actions-bar"]} select-many-list-display-actions-bar`}
                >
                    <Button
                        onClick={() => handleSelectAll(visibleItemIds)}
                        type="text"
                    >
                        {selectedItems.length
                            ? t("buttons:deselect-all")
                            : t("buttons:select-all")}
                    </Button>
                    <div className={styles["action-buttons"]}>
                        {actionsBarOptions.actions.map(
                            ({
                                onClick,
                                text,
                                buttonType,
                                shouldDeselectOnClick,
                                MobileIcon
                            }) => {
                                return (
                                    <Button
                                        onClick={() =>
                                            handleAction(
                                                onClick,
                                                shouldDeselectOnClick
                                            )
                                        }
                                        type={isMobile ? "icon" : buttonType}
                                        disabled={buttonsDisabled}
                                        key={text}
                                    >
                                        {isMobile ? <MobileIcon /> : text}
                                    </Button>
                                );
                            }
                        )}
                    </div>
                </div>
            )}

            {showNone && !isMultiple && (
                <div
                    className={cx("ms-card", {
                        "checkbox-left": checkBoxLocation === "left",
                        "allow-item-click": allowItemClick
                    })}
                    onClick={() =>
                        allowItemClick &&
                        handleSelectLocal(undefined, selectedId === undefined)
                    }
                >
                    <div className={cx("ms-card-component")}>
                        <h6 style={{ marginBottom: "0" }}>{`None`}</h6>
                    </div>
                    <StyledCheckboxOrRadio
                        type="radio"
                        checked={selectedId === undefined}
                        onChange={() =>
                            handleSelectLocal(
                                undefined,
                                selectedId === undefined
                            )
                        }
                    />
                </div>
            )}
            {sortedAndFilteredItems.length
                ? sortedAndFilteredItems.map(
                      ({ item, selected, disabled }, i) => {
                          const previouslySelected =
                              !allowUnselect && selected && disabled;
                          const isSelected = !isMultiple
                              ? selectedId === item?.id
                              : itemsHash?.[item?.id]?.selected;

                          return (
                              <div
                                  className={cx("ms-card", {
                                      "previously-selected": previouslySelected,
                                      "checkbox-left":
                                          checkBoxLocation === "left",
                                      "allow-item-click": allowItemClick
                                  })}
                                  key={i}
                                  onClick={() =>
                                      allowItemClick &&
                                      handleSelectLocal(item?.id, isSelected)
                                  }
                              >
                                  <div className={cx("ms-card-component")}>
                                      {item.component}
                                  </div>
                                  <StyledCheckboxOrRadio
                                      type={isMultiple ? "checkbox" : "radio"}
                                      checked={isSelected}
                                      onChange={() =>
                                          handleSelectLocal(
                                              item?.id,
                                              isSelected
                                          )
                                      }
                                      disabled={itemsHash?.[item?.id]?.disabled}
                                  />
                              </div>
                          );
                      }
                  )
                : searchSortOptions.implementationType ===
                      "broadcast-search-sort" && (
                      <p className="multi-select-empty-state">
                          {t("video-library:no-videos-match")}
                      </p>
                  )}
        </div>
    );
}

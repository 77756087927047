import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

export interface CouponPromptProps {
    onClick: () => void;
}

export const CouponPrompt: React.FC<CouponPromptProps> = ({ onClick }) => {
    const { t } = useTranslation("coupon-form");

    return (
        <span className={styles["prompt"]}>
            <p>{t("prompt")}</p>
            <p onClick={onClick}>{t("cta")}</p>
        </span>
    );
};

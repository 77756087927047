import { VideoPlayerResponse } from "@switcherstudio/switcher-api-client";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { Link } from "react-navi";
import { PlayerBadges } from "components/badges/PlayerBadges";
import Arrow from "assets/icons/share.svg?react";
import {
    ContextMenuItem,
    ContextMenuWrapper
} from "components/inputs/context-menu-button";
import { Button } from "components/buttons/Button";
import { useClipboard } from "hooks/useClipboard";
import { VideoPlayerThumbnail } from "../../../components/thumbnails/VideoPlayerThumbnail";
import { ComponentMap } from "components/utility/ComponentMap";
import { displayAspectRatio } from "helpers/display";

export const VideoPlayerCard = ({
    VideoPlayer,
    VideoPlayerEntitlements,
    EmbedCode,
    MicrositeUrl,
    Thumbnail,
    VideoMetricsSummary
}: VideoPlayerResponse) => {
    const { t } = useTranslation();

    const { copy } = useClipboard();

    const videosLength = useMemo(() => {
        const count =
            VideoPlayer?.VideoPlayerPlaylists?.[0]
                ?.VideoPlayerPlaylistBroadcasts?.length ?? 0;
        return count === 1
            ? t("players:video")
            : t("players:video_plural", { count });
    }, [VideoPlayer?.VideoPlayerPlaylists, t]);

    const viewCount = useMemo((): string | undefined => {
        if (!VideoMetricsSummary || VideoMetricsSummary?.TotalViews == 0) {
            return undefined;
        }

        return VideoMetricsSummary.TotalViews > 1
            ? `${VideoMetricsSummary.TotalViews} ${t("players:lifetime-views")}`
            : `${VideoMetricsSummary.TotalViews} ${t("players:lifetime-view")}`;
    }, [VideoMetricsSummary, t]);

    const infoItems = useMemo(() => {
        return viewCount
            ? [
                  displayAspectRatio(VideoPlayer.AspectRatio),
                  videosLength,
                  viewCount
              ]
            : [displayAspectRatio(VideoPlayer.AspectRatio), videosLength];
    }, [VideoPlayer.AspectRatio, videosLength, viewCount]);

    const shareOptions = useMemo<ContextMenuItem[]>(() => {
        return [
            {
                key: "copy-code",
                text: t("buttons:copy-embed-code"),
                onClick: () => copy(EmbedCode)
            },
            {
                key: "copy-link",
                text: t("buttons:copy-share-link"),
                onClick: () => copy(MicrositeUrl)
            }
        ];
    }, [EmbedCode, MicrositeUrl, copy, t]);

    return (
        <Link
            className={styles["player-card"]}
            href={`/switcher-player/${VideoPlayer?.Id}`}
        >
            <VideoPlayerThumbnail {...Thumbnail} />
            <div className={styles["player-details"]}>
                <div className={styles["player-title-and-menu"]}>
                    <h6>
                        {VideoPlayer?.Name !== "" && VideoPlayer?.Name !== null
                            ? VideoPlayer.Name
                            : t("players:untitled-player")}
                    </h6>
                    <div className={styles["meta"]}>
                        <ComponentMap
                            items={infoItems}
                            element={(i) => <small>{i}</small>}
                            separator={<small>{" • "}</small>}
                        />
                    </div>
                    <PlayerBadges
                        entitlement={
                            VideoPlayerEntitlements?.ProductEntitlements?.[0]
                        }
                    />
                </div>
                <ContextMenuWrapper items={shareOptions}>
                    <Button type="icon">
                        <Arrow />
                    </Button>
                </ContextMenuWrapper>
            </div>
        </Link>
    );
};

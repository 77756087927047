import { SilverSunnStripeCreditCard } from "@switcherstudio/switcher-api-client";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import {} from "helpers/creditCardHelpers";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import rollbar from "helpers/rollbar";

export const PaymentMethodCard = ({
    card,
    onSubmit
}: {
    card: SilverSunnStripeCreditCard;
    onSubmit?: (newDefaultCardId?: string) => void;
}) => {
    const { t } = useTranslation();
    const {
        userInfo: { UserId }
    } = useSelector((s: RootState) => s.user);
    const dispatch = useDispatch();
    const { dispatchApiRequest: removeCard, loading: removeCardLoading } =
        useSwitcherClient(
            (client) => client.userPaymentMethods_DeletePaymentMethod,
            {
                onSuccess: () => {
                    onSubmit();
                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: "messages:payment-remove-success"
                        })
                    );
                },
                onError: (e) => {
                    rollbar.error("Error removing payment method", e);
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "errors:payment-remove-error"
                        })
                    );
                }
            }
        );

    const {
        dispatchApiRequest: makeCardDefault,
        loading: makeCardDefaultLoading
    } = useSwitcherClient(
        (client) => client.userPaymentMethods_SetPrimaryPaymentMethod,
        {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            transformResponseData: ({ originalArgs: [_, paymentMethodId] }) =>
                paymentMethodId,
            onSuccess: (_, paymentMethodId) => {
                onSubmit(paymentMethodId);
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t("messages:primary-payment-updated")
                    })
                );
            },
            onError: (e) => {
                rollbar.error("Error setting primary payment method", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:primary-card-error"
                    })
                );
            }
        }
    );

    return (
        <div
            className={`${styles["payment-method-card"]} ${
                card?.Default ? styles["primary"] : ""
            }`}
        >
            <div className={styles["card-details"]}>
                {card?.Default && (
                    <div className={styles["primary-card-tag"]}>
                        {t("subscription:primary-payment")}
                    </div>
                )}
                <h5>
                    {t("subscription:card-number")}
                    {` **** **** **** ${card.LastFour}`}
                </h5>
                <h5>
                    {t("subscription:expiration")}
                    {": "}
                    {card.ExpirationMonth} / {card.ExpirationYear}
                </h5>
            </div>
            {!card.Default && (
                <div className={styles["action-buttons"]}>
                    <button
                        className="btn btn-outline-primary"
                        onClick={() =>
                            makeCardDefault([
                                UserId,
                                card.SilverSunnStripeCreditCardId
                            ])
                        }
                        disabled={makeCardDefaultLoading}
                    >
                        {t("buttons:make-primary")}
                    </button>
                    <button
                        className="btn btn-link"
                        onClick={() =>
                            removeCard([
                                UserId,
                                card.SilverSunnStripeCreditCardId
                            ])
                        }
                        disabled={removeCardLoading}
                    >
                        {t("buttons:remove")}
                    </button>
                </div>
            )}
        </div>
    );
};

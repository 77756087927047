import { SilverSunnStripeCreditCard } from "@switcherstudio/switcher-api-client";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import { ModalBase } from "../ModalBase";
import { AddNewPaymentMethod } from "./AddNewPaymentMethod";
import styles from "./index.module.scss";
import { PaymentMethodCard } from "./PaymentMethodCard";

interface PaymentMethodsModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const PaymentMethodsModal = ({
    isOpen,
    setIsOpen
}: PaymentMethodsModalProps) => {
    const [newDefaultCardId, setNewDefaultCardId] = useState<string>();
    const {
        data,
        loading,
        dispatchApiRequest: refetchCustomer
    } = useSwitcherClient((client) => client.stripe_GetCustomer, {
        requestImmediately: true,
        onSuccess: (data) => setOptimisticData(data.StripeCreditCards)
    });
    const [optimisticData, setOptimisticData] =
        useState<SilverSunnStripeCreditCard[]>();
    const stripeCreditCards = useMemo(() => optimisticData, [optimisticData]);
    const primaryCard = useMemo(
        () => stripeCreditCards?.find((card) => card.Default),
        [stripeCreditCards]
    );
    const otherCards = useMemo(
        () =>
            stripeCreditCards?.filter(
                (card) =>
                    card.SilverSunnStripeCreditCardId !==
                    primaryCard?.SilverSunnStripeCreditCardId
            ),
        [stripeCreditCards, primaryCard]
    );

    const optimisticallyRefreshCreditCardsWithNewDefault = useCallback(
        (data: SilverSunnStripeCreditCard[]) => {
            setOptimisticData(
                data.map((c) => ({
                    ...c,
                    Default: c.SilverSunnStripeCreditCardId === newDefaultCardId
                }))
            );
        },
        [newDefaultCardId]
    );

    useEffect(() => {
        if (!!data && !loading && !!newDefaultCardId) {
            optimisticallyRefreshCreditCardsWithNewDefault(
                data.StripeCreditCards
            );
        }
    }, [
        data,
        newDefaultCardId,
        loading,
        optimisticallyRefreshCreditCardsWithNewDefault
    ]);

    const prepareOptimisticResponse = (cardId: string) => {
        if (!!cardId) {
            setNewDefaultCardId(cardId);
        }
        refetchCustomer();
    };

    return (
        <ModalBase
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onSuccess={() => setIsOpen(false)}
            shouldHideButtons
            stylesOverride={{ maxHeight: "unset" }}
            childrenStylesOverride={{ height: "100%" }}
        >
            <div className={styles["modal-content"]}>
                <div className={styles["header"]}>
                    <h4>Payment Methods</h4>
                </div>

                <div className={styles["cards-container"]}>
                    {!!primaryCard && <PaymentMethodCard card={primaryCard} />}

                    {otherCards?.map((card, index) => (
                        <PaymentMethodCard
                            key={index}
                            card={card}
                            onSubmit={prepareOptimisticResponse}
                        />
                    ))}
                </div>

                <div className={styles["footer"]}>
                    <AddNewPaymentMethod onSubmit={prepareOptimisticResponse} />
                </div>
            </div>
        </ModalBase>
    );
};

import React, { useState } from "react";
import styles from "./LoginInput.module.scss";
import ProfileIcon from "assets/icons/username.svg?react";
import LockIcon from "assets/icons/password.svg?react";

interface LoginInputProps {
    label?: string;
    type: string;
    id: string;
    value: string | number | string[] | undefined;
    error?: string;
    horizontal?: boolean;
    readonly?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const LoginInput: React.FC<LoginInputProps> = ({
    label,
    type,
    id,
    value,
    error,
    horizontal,
    readonly,
    onChange
}: LoginInputProps) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
        <>
            <div className={`form-group ${horizontal ? "row" : ""}`}>
                <div className={`${horizontal ? "col-xl-10" : ""}`}>
                    <div className={styles["input-container"]}>
                        {type === "password" ? (
                            <LockIcon className={styles["lock-icon"]} />
                        ) : (
                            <ProfileIcon className={styles["profile-icon"]} />
                        )}
                        <input
                            type={type}
                            className={`${styles["login-input"]}`}
                            id={id}
                            value={value || ""}
                            onChange={onChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            readOnly={readonly}
                            placeholder=" "
                        />
                        <label
                            className={`${
                                horizontal ? "col-xl-2 col-form-label" : ""
                            } ${styles["floating-label"]} ${
                                !!value || isFocused ? styles["has-focus"] : ""
                            }`}
                            htmlFor={id}
                            placeholder=""
                        >
                            {label}
                        </label>
                    </div>
                </div>
                <small
                    id={`${id}-help`}
                    aria-describedby={id}
                    className="form-text"
                >
                    {error && (
                        <div className="alert alert-danger alert-info mb-0 p-2">
                            {error}
                        </div>
                    )}
                </small>
            </div>
        </>
    );
};

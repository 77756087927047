import React, { useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import {
    VideoPlayer,
    VideoPlayerResponse
} from "@switcherstudio/switcher-api-client";
import { useTranslation } from "react-i18next";
import { VideoPlayerThumbnail } from "components/thumbnails/VideoPlayerThumbnail";
import { Button } from "components/buttons/Button";
import { ContextMenu, ContextMenuItem } from "components/inputs/context-menu";
import { ContextMenuWrapper } from "components/inputs/context-menu-button";
import { useIsMobile } from "hooks/useIsMobile";
import { useClipboard } from "hooks/useClipboard";
import PencilIcon from "assets/icons/pencil-simple.svg?react";
import TrashIcon from "assets/icons/trash-sm.svg?react";
import ShareIcon from "assets/icons/share.svg?react";
import { useNavigate } from "hooks/useNavigate";
import { BaseDetails } from "../BaseDetails";
import { displayAspectRatio } from "helpers/display";
import { ComponentMap } from "components/utility/ComponentMap";
import { PlayerBadges } from "components/badges/PlayerBadges";
import { DetailRow } from "../BaseDetails/DetailRow";
import { Toggle } from "components/inputs/toggle/Toggle";
import { useSettableMemo } from "hooks/useSettableMemo";
import { useDebounce } from "hooks/useDebounce";

interface PlayerDetailsProps {
    item: VideoPlayerResponse;
    showEdit?: boolean;
    showDelete?: boolean;
    showShare?: boolean;
    showPrivacyToggle?: boolean;
    isDraggable?: boolean;
    handleDelete?: (videoPlayerId: string) => void;
    onPrivacyToggle?: (player: VideoPlayer, value: boolean) => void;
}

export const PlayerDetails = ({
    item,
    showEdit = false,
    showDelete = false,
    showShare = false,
    showPrivacyToggle = false,
    isDraggable = false,
    handleDelete,
    onPrivacyToggle
}: PlayerDetailsProps) => {
    const { t } = useTranslation();
    const { copy } = useClipboard();
    const mobileCheck = useIsMobile();
    const { navigate } = useNavigate();

    const title = useMemo(
        () =>
            item?.VideoPlayer?.Name != null
                ? item?.VideoPlayer?.Name
                : t("players-select-modal:untitled"),
        [t, item]
    );

    const videosLength = useMemo(() => {
        const count =
            item?.VideoPlayer?.VideoPlayerPlaylists?.[0]
                ?.VideoPlayerPlaylistBroadcasts?.length ?? 0;
        return count === 1
            ? t("players:video")
            : t("players:video_plural", { count });
    }, [item?.VideoPlayer?.VideoPlayerPlaylists, t]);

    const viewCount = useMemo((): string | undefined => {
        if (
            !item?.VideoMetricsSummary ||
            item?.VideoMetricsSummary?.TotalViews == 0
        ) {
            return undefined;
        }

        return item?.VideoMetricsSummary.TotalViews > 1
            ? `${item?.VideoMetricsSummary.TotalViews} ${t(
                  "players:lifetime-views"
              )}`
            : `${item?.VideoMetricsSummary.TotalViews} ${t(
                  "players:lifetime-view"
              )}`;
    }, [item?.VideoMetricsSummary, t]);

    const infoItems = useMemo(() => {
        return viewCount
            ? [
                  displayAspectRatio(item.VideoPlayer.AspectRatio),
                  videosLength,
                  viewCount
              ]
            : [displayAspectRatio(item.VideoPlayer.AspectRatio), videosLength];
    }, [item.VideoPlayer, videosLength, viewCount]);

    const shareOptions = useMemo<ContextMenuItem[]>(() => {
        if (!showShare) return [];

        return [
            {
                key: "copy-code",
                text: t("buttons:copy-embed-code"),
                onClick: () => copy(item.EmbedCode)
            },
            {
                key: "copy-link",
                text: t("buttons:copy-share-link"),
                onClick: () => copy(item.MicrositeUrl)
            }
        ];
    }, [item, copy, t, showShare]);

    const handleEdit = useCallback(() => {
        navigate(`/catalog/collections/${item.VideoPlayer.Id}`);
    }, [item, navigate]);

    const handleDeleteLocal = useCallback(() => {
        handleDelete && handleDelete(item.VideoPlayer.Id);
    }, [handleDelete, item]);

    const mobileButtonsMenuItems = useMemo(() => {
        let options = [...shareOptions];

        if (showEdit) {
            options = [
                {
                    key: "Edit",
                    text: t("buttons:edit"),
                    onClick: handleEdit
                },
                ...options
            ];
        }

        if (showDelete) {
            options = [
                ...options,
                {
                    key: "Delete",
                    text: t("buttons:delete"),
                    onClick: handleDeleteLocal
                }
            ];
        }

        return options;
    }, [t, handleEdit, handleDeleteLocal, shareOptions, showEdit, showDelete]);

    const [privacyToggleOn, setPrivacyToggleOn] = useSettableMemo(
        () => item?.VideoPlayer?.ShowInCatalog,
        [item?.VideoPlayer?.ShowInCatalog]
    );

    const handleUpdatePrivacyToggle = useCallback(
        (value: boolean) => {
            if (item.VideoPlayer.ShowInCatalog !== value) {
                onPrivacyToggle?.(item.VideoPlayer, value);
            }
        },
        [item.VideoPlayer, onPrivacyToggle]
    );

    useDebounce(privacyToggleOn, 500, {
        onUpdate: handleUpdatePrivacyToggle
    });

    return (
        <>
            {!!item && (
                <div className={styles["player-details"]}>
                    <BaseDetails
                        isDraggable={isDraggable}
                        thumbnail={<VideoPlayerThumbnail {...item.Thumbnail} />}
                        title={title}
                        hasToggleRow={true}
                        detailRows={
                            <>
                                <DetailRow variant="info-row">
                                    <ComponentMap
                                        items={infoItems}
                                        element={(i) => <span>{i}</span>}
                                        separator={<span>{"•"}</span>}
                                    />
                                </DetailRow>
                                {item?.VideoPlayerEntitlements
                                    ?.ProductEntitlements?.[0] && (
                                    <DetailRow variant="badge-row">
                                        <PlayerBadges
                                            entitlement={
                                                item?.VideoPlayerEntitlements
                                                    ?.ProductEntitlements?.[0]
                                            }
                                        />
                                    </DetailRow>
                                )}
                                {showPrivacyToggle && (
                                    <DetailRow variant="toggle-row">
                                        <Toggle
                                            on={privacyToggleOn}
                                            onToggle={() =>
                                                setPrivacyToggleOn(
                                                    !privacyToggleOn
                                                )
                                            }
                                            label={t(
                                                "collections-page:show-in-catalog"
                                            )}
                                            className={
                                                styles["visibility-toggle"]
                                            }
                                            reverseLayout
                                        />
                                    </DetailRow>
                                )}
                            </>
                        }
                        actionItems={
                            <>
                                {(showEdit || showDelete || showShare) && (
                                    <>
                                        {!mobileCheck.isMobile ? (
                                            <>
                                                {showEdit && (
                                                    <Button
                                                        type="icon"
                                                        onClick={handleEdit}
                                                        title={t(
                                                            "buttons:edit"
                                                        )}
                                                    >
                                                        <PencilIcon
                                                            className={
                                                                styles[
                                                                    "btn-edit"
                                                                ]
                                                            }
                                                        />
                                                    </Button>
                                                )}
                                                {showShare && (
                                                    <ContextMenuWrapper
                                                        items={shareOptions}
                                                    >
                                                        <Button
                                                            type="icon"
                                                            title={t(
                                                                "buttons:share"
                                                            )}
                                                        >
                                                            <ShareIcon />
                                                        </Button>
                                                    </ContextMenuWrapper>
                                                )}
                                                {showDelete && (
                                                    <Button
                                                        type="icon"
                                                        onClick={
                                                            handleDeleteLocal
                                                        }
                                                        title={t(
                                                            "buttons:delete"
                                                        )}
                                                    >
                                                        <TrashIcon
                                                            className={
                                                                styles[
                                                                    "trash-icon"
                                                                ]
                                                            }
                                                        />
                                                    </Button>
                                                )}
                                            </>
                                        ) : (
                                            <ContextMenu
                                                dropDirection="up"
                                                items={mobileButtonsMenuItems}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        }
                    ></BaseDetails>
                </div>
            )}
        </>
    );
};

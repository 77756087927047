import React, { useEffect, useState, useCallback } from "react";
import { client } from "api/client";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useNavigate } from "hooks/useNavigate";
import { SwitchboardSwitcherStreamSetting } from "@switcherstudio/switcher-api-client";
import { QualitySelector } from "components/quality-selector/QualitySelector";
import style from "./Switchboard.module.scss";
import { useDispatch } from "react-redux";
import { setLoading } from "store/loading/slice";
import { availableQualities, updateGeneralForm } from "store/platforms/slice";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { AppDispatch } from "store/store";

export const Switchboard: React.FC = () => {
    const { general } = useSelector((state: RootState) => state.platforms);
    const { generalForm } = general;
    const dispatch = useDispatch<AppDispatch>();
    const { selectedQuality } = generalForm;
    const { navigate } = useNavigate();

    const [frameUrl, setFrameUrl] = useState<string>();
    const frameBaseUrl =
        "https://accounts.switchboard.live/account/AuthenticatePartner?token=";

    const createSwitcherSettings = useCallback(
        async (eventData) => {
            try {
                const existingSetting =
                    await client.switchboard_GetSwitcherStudioSetting();
                if (existingSetting) {
                    const prevSelectedQuality = availableQualities.find(
                        (x) =>
                            x.name ===
                            existingSetting["video-frame-height"] + "p"
                    );
                    dispatch(
                        updateGeneralForm({
                            selectedQuality: prevSelectedQuality
                        })
                    );
                }
                const setting: SwitchboardSwitcherStreamSetting =
                    selectedQuality.setting;
                setting.url = eventData.streamUrl;
                setting["rtmp-stream"] = eventData.streamKey;
                setting["channel-name"] = "switchboard multi";
                await client.switchboard_PutSwitchboardSwitcherStreamSetting(
                    setting
                );
            } catch (e) {
                // TODO: better error message
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "Something went wrong please try again later."
                    })
                );
            }
        },
        [selectedQuality, dispatch]
    );

    const handleIFrameEvent = useCallback(
        (event) => {
            const allowedRegex = new RegExp(
                "^https://([^.]+.)*switchboard.live"
            );
            if (!allowedRegex.test(event.origin)) return;

            if (event.data.messageType === "setIngestRTMP") {
                createSwitcherSettings(event.data.messageData);
                dispatch(setLoading(-1));
            }
        },
        [createSwitcherSettings, dispatch]
    );

    const getToken = async () => {
        try {
            return await client.switchboard_GetExistingJWT();
        } catch (err) {
            return await client.switchboard_GenerateJWT();
        }
    };

    useEffect(() => {
        window.addEventListener("message", handleIFrameEvent, false);
        return () => window.removeEventListener("message", handleIFrameEvent);
    }, [handleIFrameEvent]);

    useEffect(() => {
        const getFrameUrl = async () => {
            dispatch(setLoading(1));
            const { AccessToken } = await getToken();
            const url = frameBaseUrl + AccessToken;
            setFrameUrl(url);
        };

        if (!frameUrl) {
            getFrameUrl();
        }
    }, [frameUrl, dispatch]);

    const updateQuality = useCallback(async () => {
        const existingSetting =
            await client.switchboard_GetSwitcherStudioSetting();
        await createSwitcherSettings({
            streamUrl: existingSetting.url,
            streamKey: existingSetting["rtmp-stream"]
        });

        navigate("/platforms");
    }, [createSwitcherSettings, navigate]);

    return (
        <>
            <div className={style["frame-container"]}>
                {frameUrl && (
                    <iframe
                        className={style["frame"]}
                        title="Switchboard window"
                        src={frameUrl}
                    />
                )}
            </div>
            <QualitySelector />
            <button
                className={`btn btn-primary ${style["save-btn"]}`}
                onClick={updateQuality}
            >
                Save
            </button>
        </>
    );
};

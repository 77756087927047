import {
    Category,
    CategoryType,
    CategoryType2
} from "@switcherstudio/switcher-api-client";
import { CategoryBadge } from "components/badges/CategoryBadge";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import { Select } from "../select/Select";
import { TextInput } from "../text-input/TextInput";
import styles from "./index.module.scss";
import rollbar from "helpers/rollbar";
import { slugify } from "helpers/slugify";
import { existsWithValues } from "helpers/booleans";
import { useClaimCheck } from "hooks/useClaimCheck";

interface CategoriesAssignmentProps {
    categoryType: CategoryType;
    selectedCategories: Category[];
    setSelectedCategories: (categories: Category[]) => void;
    showCategoryDropdownWhenEmpty?: boolean;
}

export const CategoriesAssignment = ({
    categoryType,
    selectedCategories,
    setSelectedCategories,
    showCategoryDropdownWhenEmpty
}: CategoriesAssignmentProps) => {
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");
    const [categoryInput, setCategoryInput] = useState<string>("");
    const trimmedCat = categoryInput.trim();
    const userInfo = useSelector((s: RootState) => s.user?.userInfo);
    const dispatch = useDispatch<AppDispatch>();

    const { data: userCategories, dispatchApiRequest: refetchCategories } =
        useSwitcherClient((client) => client.categories_GetCategories, {
            requestImmediately: true,
            args: [userInfo?.ProjectId, categoryType],
            hideLoading: true,
            onError: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: `${hasCatalogClaim ? t("video-upload:errors:tag-retrieval-error") : t("video-upload:errors:category-retrieval-error")}`
                    })
                );
            }
        });

    const { dispatchApiRequest: addUserCategory } = useSwitcherClient(
        (client) => client.categories_CreateCategories,
        {
            requestImmediately: false,
            onSuccess: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: `${hasCatalogClaim ? t("video-upload:errors:tag-add-success") : t("video-upload:errors:category-add-success")}`
                    })
                );
            },
            onError: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: `${hasCatalogClaim ? t("video-upload:errors:tag-add-error") : t("video-upload:errors:category-add-error")}`
                    })
                );
            }
        }
    );

    const handleRemoveCategory = (categoryName: string) => {
        const uc = selectedCategories.filter((c) => c.Name !== categoryName);
        setSelectedCategories(uc);
    };

    const setSelectedCategoriesFromDropdown = useCallback(
        (categoryName: string) => {
            !selectedCategories.find((c) => c.Name === categoryName) &&
                setSelectedCategories([
                    ...selectedCategories,
                    userCategories.Categories.find(
                        (uc) => uc?.Name === categoryName
                    )
                ]);
        },
        [userCategories?.Categories, selectedCategories, setSelectedCategories]
    );

    const setSelectedCategoriesFromUserInput = useCallback(
        (category: Category) => {
            setSelectedCategories([...selectedCategories, category]);
        },
        [selectedCategories, setSelectedCategories]
    );

    const handleAddCategory = useCallback(async () => {
        const existingCat = userCategories?.Categories?.find(
            (c) => slugify(c.Name) === slugify(trimmedCat)
        );
        if (trimmedCat !== "" && existingCat) {
            setSelectedCategoriesFromDropdown(existingCat.Name);
            setCategoryInput("");
        } else if (trimmedCat !== "") {
            try {
                const categoriesResponse = await addUserCategory([
                    {
                        Categories: [
                            {
                                Name: trimmedCat,
                                ProjectId: userInfo?.ProjectId,
                                CategoryType:
                                    categoryType as unknown as CategoryType2
                            }
                        ]
                    }
                ]);
                setCategoryInput("");
                refetchCategories();
                const addedCat = categoriesResponse?.Categories?.find(
                    (c) => c.Name === trimmedCat
                );
                if (addedCat) {
                    setSelectedCategoriesFromUserInput(addedCat);
                }
            } catch (e) {
                rollbar.error("Error adding user category", e);
            }
        }
    }, [
        trimmedCat,
        addUserCategory,
        userInfo?.ProjectId,
        userCategories?.Categories,
        categoryType,
        refetchCategories,
        setSelectedCategoriesFromDropdown,
        setSelectedCategoriesFromUserInput
    ]);

    const dropdownCats = useMemo(() => {
        const categorySelectOptions = userCategories?.Categories?.filter(
            (c) => !selectedCategories?.find((sc) => sc?.Name === c?.Name)
        ).map((c) => c.Name);

        return categorySelectOptions;
    }, [selectedCategories, userCategories]);

    return (
        <div>
            <div className={`${styles["category-badges"]}`}>
                <CategoryBadge
                    categories={selectedCategories}
                    handleRemoveCategory={handleRemoveCategory}
                />
            </div>
            <div className={`${styles["category-container"]}`}>
                {(showCategoryDropdownWhenEmpty ||
                    existsWithValues(dropdownCats)) && (
                    <Select
                        id="category-dropdown"
                        onChange={(c) =>
                            setSelectedCategoriesFromDropdown(c.target.value)
                        }
                        options={dropdownCats}
                        placeholder={t("broadcasts-multi-select:select")}
                        type="categories-dropdown"
                        label={
                            hasCatalogClaim
                                ? t("video-upload:select-tag")
                                : t("video-upload:select-category")
                        }
                        disabled={!dropdownCats?.length}
                    />
                )}
                <TextInput
                    id="category-input"
                    type="text"
                    value={categoryInput}
                    className={"category-input-label"}
                    onChange={(c) => setCategoryInput(c.target.value)}
                    label={
                        hasCatalogClaim
                            ? t("video-upload:new-tag")
                            : t("video-upload:new-category")
                    }
                />
                <span
                    className={`${styles["add-category-button"]}`}
                    onClick={() => handleAddCategory()}
                    aria-disabled={trimmedCat === ""}
                >
                    {t("video-upload:add")}
                </span>
            </div>
        </div>
    );
};

import { useEffect, useState } from "react";
import { client } from "api/client";
import rollbar from "helpers/rollbar";

export function useEntitlements(userId: string) {
    const [entitlements, setEntitlements] = useState([]);

    useEffect(() => {
        const getEntitlements = async () => {
            try {
                const allEntitlements =
                    await client.userEntitlements_getActiveUserEntitlements(
                        userId
                    );
                setEntitlements(allEntitlements);
            } catch (e) {
                rollbar.error("Failed to fetch user entitlements", e);
            }
        };
        getEntitlements();
    }, [userId]);

    return { entitlements };
}

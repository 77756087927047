import React from "react";
import styles from "./SubscriptionPage.module.scss";
import { DateTimePicker } from "components/inputs/datepicker/DateTimePicker";
import { useTranslation } from "react-i18next";
import CloseIcon from "assets/icons/close.svg?react";
import { Select } from "components/inputs/select/Select";

interface TimeFrameFilterDropdownProps {
    selectedTimeFrame: number;
    timeFrameOptions: string[];
    setSelectedTimeFrame: (timeFrame: string) => void;
    startDate: Date;
    setStartDate: (date: Date) => void;
    endDate: Date;
    setEndDate: (date: Date) => void;
    closeDropdown: () => void;
}

export const TimeFrameFilterDropdown: React.FC<
    TimeFrameFilterDropdownProps
> = ({
    selectedTimeFrame,
    timeFrameOptions,
    setSelectedTimeFrame,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    closeDropdown
}) => {
    const { t, i18n } = useTranslation();

    return (
        <div
            className={`${styles["filter-dropdown"]} ${styles["time-frame-filter-dropdown"]}`}
        >
            <CloseIcon
                onClick={closeDropdown}
                className={`${styles["close-icon"]}`}
            />
            <div className={`${styles["date-picker"]} }`}>
                <span>
                    <p>{t("subscription:from")}</p>
                    <DateTimePicker
                        id="start-date"
                        datePickerProps={{
                            selected: startDate,
                            minDate: null,
                            maxDate: endDate,
                            locale: i18n.language
                        }}
                        error={""}
                        onChange={setStartDate}
                        dateOnly
                    />
                </span>
                <p>{` - `}</p>
                <span>
                    <p>{t("subscription:to")}</p>
                    <DateTimePicker
                        id="end-date"
                        datePickerProps={{
                            selected: endDate,
                            minDate: startDate,
                            locale: i18n.language
                        }}
                        error={""}
                        onChange={setEndDate}
                        dateOnly
                    />
                </span>
            </div>
            <Select
                label={t("buttons:select")}
                id="date"
                options={timeFrameOptions}
                selected={timeFrameOptions[selectedTimeFrame]}
                onChange={(e) => setSelectedTimeFrame(e.target.value)}
            />
        </div>
    );
};

import { t } from "i18next";
import { MatcherGenerator } from "navi";
import { Link } from "react-navi";
import styles from "./index.module.scss";
import UnlockedIcon from "assets/icons/unlocked.svg?react";
import { useGetPromotionalClaim } from "hooks/useGetPromotionalClaim";
import { ChevronIcon } from "components/icons/ChevronIcon";
import { PropsWithChildren } from "react";

interface NavDropdownProps extends PropsWithChildren {
    label: string;
    routes: { [k: string]: () => MatcherGenerator<any> };
    excludedRoutes?: string[];
    activeRoute: string;
    navTitles: { [k: string]: string };
    navIcons: {
        [k: string]: React.FunctionComponent<
            React.SVGProps<SVGSVGElement> & { title?: string | undefined }
        >;
    };
    isOpen: boolean;
    toggleOpen: () => void;
    onToggle: () => void;
}

export const NavDropdown: React.FC<NavDropdownProps> = ({
    label,
    routes,
    excludedRoutes,
    activeRoute,
    navTitles,
    navIcons,
    isOpen,
    children,
    toggleOpen,
    onToggle
}) => {
    const filteredItems = Object.keys(routes)
        .filter((route) =>
            excludedRoutes ? !excludedRoutes.includes(route) : true
        )
        .reduce((obj, key) => {
            obj[key] = routes[key];
            return obj;
        }, {});

    const { hasPromotionalClaim: hasSwitcherPlayerStudioAccessPromo } =
        useGetPromotionalClaim("SwitcherPlayerStudioAccess");

    return (
        <div className={styles["sidebar-dropdown"]}>
            <div
                className={styles["sidebar-dropdown-title-container"]}
                onClick={toggleOpen}
            >
                <div className={styles["sidebar-dropdown-title"]}>{label}</div>
                <span>
                    <ChevronIcon direction={isOpen ? "down" : "up"} />
                </span>{" "}
            </div>
            <div
                className={`${styles["sidebar-dropdown-menu"]} ${
                    isOpen ? styles["open"] : ""
                }`}
            >
                {Object.entries(filteredItems).map(([key]) => {
                    const parentKey = key.split("/")[1];
                    const keyName = key.replace(/\//, "");
                    const isActive = parentKey === activeRoute;
                    const displayName = navTitles[keyName];
                    const Icon = navIcons[keyName];

                    return (
                        <div key={key}>
                            <Link
                                href={key}
                                prefetch={false}
                                onClick={onToggle}
                                className={`
                                    ${isActive ? styles["active-route"] : ""}
                                    ${parentKey !== keyName ? styles["sub-route"] : ""}
                                `}
                            >
                                {Icon && <Icon />}
                                {t(displayName).toUpperCase()}
                                {keyName === "switcher-player" &&
                                    hasSwitcherPlayerStudioAccessPromo && (
                                        <UnlockedIcon
                                            className={
                                                styles["promo-unlocked-icon"]
                                            }
                                        />
                                    )}
                            </Link>
                        </div>
                    );
                })}
                {children}
            </div>
        </div>
    );
};

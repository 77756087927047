import { Link } from "react-navi";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

export interface CreateAccountStateProps {
    link: string;
    target: string;
    description: string;
}

export const CreateAccountState: React.FC<CreateAccountStateProps> = ({
    link,
    target,
    description
}) => {
    const { t } = useTranslation();

    return (
        <div className="catalog-stripe-card">
            <h5 className={styles["stripe-header"]}>
                {t("subscription-page:start-getting-rich")}
            </h5>
            <p className={styles["stripe-description"]}>{description}</p>
            <Link
                href={link}
                target={target}
                className={`btn btn-primary ${styles["stripe-btn"]}`}
            >
                {t("subscription-page:connect-stripe")}
            </Link>
        </div>
    );
};

import { ProgressTrackerBanner, Step } from "../ProgressTrackerBanner";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "hooks/useNavigate";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { exists } from "helpers/booleans";
import { getEvents, postEvents } from "store/events/thunks";
import { AppDispatch } from "store/store";
import { useCombineLoading } from "hooks/useCombineLoading";
import { isEmptyOrUndefined } from "helpers/objects";

interface PlayerActivationTrackerBannerProps {
    hasCatalogClaim: boolean;
    catalogEmbedCode: string;
    videoLibraryDataLoading: boolean;
}

export const PlayerActivationTrackerBanner = ({
    hasCatalogClaim,
    catalogEmbedCode,
    videoLibraryDataLoading
}: PlayerActivationTrackerBannerProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { navigate } = useNavigate();
    const {
        userInfo: { ProjectId, UserId }
    } = useSelector((state: RootState) => state.user);
    const { events } = useSelector((state: RootState) => state.events);
    const results = events?.surveyResults;

    /* Data Fetching */

    // Video players
    const { data: videoPlayersResponse, loading: videoPlayersDataLoading } =
        useSwitcherClient(
            (client) => client.projectsVideoPlayer_GetVideoPlayers,
            {
                requestImmediately: true,
                args: [ProjectId],
                onSuccess: (data) => {
                    // Filter out default players
                    const filteredData = data?.filter((player) => {
                        const isDefaultPlayer = !player?.VideoPlayer.Name;
                        const isLibraryPlayer =
                            player?.VideoPlayer.IsLibraryPlayer;

                        return !isDefaultPlayer && !isLibraryPlayer;
                    });

                    dispatch(
                        postEvents({
                            ["created-player"]: filteredData.length > 0
                        })
                    );
                }
            }
        );

    // Products
    const {
        loading: creatorProductsDataLoading,
        dispatchApiRequest: getProducts
    } = useSwitcherClient((client) => client.creatorProducts_Get, {
        requestImmediately: false,
        onSuccess: (data) => {
            dispatch(
                postEvents({ ["created-pass"]: exists(data?.Products?.length) })
            );
        }
    });

    // Stripe account data
    const { loading: stripeAccountDataLoading } = useSwitcherClient(
        (client) => client.stripeConnect_GetStripeAccount,
        {
            args: [UserId],
            requestImmediately: true,
            fetchPolicy: "cache-first",
            onSuccess: (stripeRes) => {
                getProducts([stripeRes?.accounts?.[0]?.Id]);
            }
        }
    );

    const loading = useCombineLoading(
        stripeAccountDataLoading,
        creatorProductsDataLoading,
        videoLibraryDataLoading,
        videoPlayersDataLoading
    );

    /* Embed Code */

    // Sort players by date created and get embed code for most recent
    const videoPlayerEmbedCode = useMemo<string>(() => {
        return videoPlayersResponse?.sort(
            (a, b) =>
                new Date(b.VideoPlayer.CreatedAt).getTime() -
                new Date(a.VideoPlayer.CreatedAt).getTime()
        )[0]?.EmbedCode;
    }, [videoPlayersResponse]);

    const embedCode = useMemo<string>(
        // Choose which embed code to use based on the user's catalog claim
        () => (hasCatalogClaim ? catalogEmbedCode : videoPlayerEmbedCode),
        [catalogEmbedCode, hasCatalogClaim, videoPlayerEmbedCode]
    );

    const copy = useCallback(async () => {
        if (exists(navigator.clipboard)) {
            await navigator.clipboard.writeText(embedCode);
            dispatch(postEvents({ "copied-code": true }));
            dispatch(
                addNotification({
                    type: NotificationType.Info,
                    message: "messages:copy-embed-code"
                })
            );
        } else {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: "errors:copy-embed-code"
                })
            );
        }
    }, [dispatch, embedCode]);

    /* Steps */
    const steps: Step[] = useMemo(() => {
        let header = undefined;
        if (results && results["options.monetize"])
            header = t("activation-tracker:custom-subheader:monetize");
        if (results && results["options.embed"])
            header = t("activation-tracker:custom-subheader:embed");

        const catalogClaimTextKeyModifier = hasCatalogClaim ? "-new" : "";

        let initialSteps: Step[] = [
            {
                stepNumber: 0,
                id: "add-content",
                header: header,
                subheader: t(
                    `activation-tracker:add-content-step:subheader${catalogClaimTextKeyModifier}`
                ),
                action: t(
                    `activation-tracker:add-content-step:action${catalogClaimTextKeyModifier}`
                ),
                onAction: () => navigate("/video-library")
                // video: {
                //     id: "",
                //     name: "Switcher Player Overview",
                //     url: "https://customer-5g1vs7rwra6vdqdo.cloudflarestream.com/dbc948670a478ae700028825c777bc31/manifest/video.m3u8",
                //     thumbnailUrl:
                //         "https://switcherstudio.blob.core.windows.net/staticcontent/images/tutorial-thumbnails/Business-02-Switcher-Player-Overview.png"
                // }
            },
            {
                stepNumber: 1,
                id: "create-player",
                header: header,
                subheader: t(
                    `activation-tracker:create-player-step:subheader${catalogClaimTextKeyModifier}`
                ),
                action: t(
                    `activation-tracker:create-player-step:action${catalogClaimTextKeyModifier}`
                ),
                onAction: () =>
                    hasCatalogClaim
                        ? navigate("/catalog")
                        : navigate("/switcher-players")
                // video: {
                //     id: "",
                //     name: "Switcher Player Overview",
                //     url: "https://customer-5g1vs7rwra6vdqdo.cloudflarestream.com/dbc948670a478ae700028825c777bc31/manifest/video.m3u8",
                //     thumbnailUrl:
                //         "https://switcherstudio.blob.core.windows.net/staticcontent/images/tutorial-thumbnails/Business-02-Switcher-Player-Overview.png"
                // }
            },
            {
                stepNumber: 2,
                id: "copy-code",
                header: header,
                subheader: t(
                    `activation-tracker:embed-code-step:subheader${catalogClaimTextKeyModifier}`
                ),
                action: t(
                    `activation-tracker:embed-code-step:action${catalogClaimTextKeyModifier}`
                ),
                onAction: () => copy()
                // video: {
                //     id: "",
                //     name: "Embedding Switcher Player on Your Website",
                //     url: "https://customer-5g1vs7rwra6vdqdo.cloudflarestream.com/7154389f9f6839865016b6b7b1ce6ce7/manifest/video.m3u8",
                //     thumbnailUrl:
                //         "https://switcherstudio.blob.core.windows.net/staticcontent/images/tutorial-thumbnails/Business-04-Embedding-Switcher-Player-on-Your-Website.png"
                // }
            }
        ];

        // If the user has a catalog claim, swap the order of the second and third steps
        if (hasCatalogClaim) {
            initialSteps[1].stepNumber = 2;
            initialSteps[2].stepNumber = 1;
        }

        if (
            results &&
            (results["options.monetize"] || results["options.stream"])
        ) {
            initialSteps.push({
                stepNumber: 3,
                id: "add-pass",
                header: header,
                subheader: t(
                    `activation-tracker:add-pass-step:subheader${catalogClaimTextKeyModifier}`
                ),
                action: t(
                    `activation-tracker:add-pass-step:action${catalogClaimTextKeyModifier}`
                ),
                onAction: () =>
                    navigate("/gated-content", { fragment: "passes" })
                // video: {
                //     id: "",
                //     name: "Embedding Switcher Player on Your Website",
                //     url: "https://customer-5g1vs7rwra6vdqdo.cloudflarestream.com/7154389f9f6839865016b6b7b1ce6ce7/manifest/video.m3u8",
                //     thumbnailUrl:
                //         "https://switcherstudio.blob.core.windows.net/staticcontent/images/tutorial-thumbnails/Business-04-Embedding-Switcher-Player-on-Your-Website.png"
                // }
            });
        }

        return initialSteps.sort((a, b) => a.stepNumber - b.stepNumber);
    }, [copy, navigate, t, hasCatalogClaim, results]);

    /* Event Tracking */
    const getEventSummary = useCallback(() => {
        if (isEmptyOrUndefined(events))
            return { currentStep: 0, bannerWasDismissed: false };

        // Activation steps to track
        let trackedActivationEvents = [
            "added-video-to-library",
            "created-player",
            "copied-code",
            ...(results &&
            (results["options.monetize"] || results["options.stream"])
                ? ["created-pass"]
                : [])
        ];

        // If the user has a catalog claim, swap the order of the second and third steps
        if (hasCatalogClaim) {
            trackedActivationEvents[1] = "copied-code";
            trackedActivationEvents[2] = "created-player";
        }

        // Check if all steps have been completed
        let completedSteps = trackedActivationEvents.map(
            (event) => events?.[event]
        );
        const allStepsCompleted =
            completedSteps.length > 0 &&
            completedSteps.every((step) => step === true);

        // If all steps are completed and the banner hasn't been dismissed, dismiss the banner
        let bannerWasDismissed = events["player-activation-tracker-dismissed"];
        if (!bannerWasDismissed && allStepsCompleted) {
            // Dispatch event to dismiss the onboarding banner
            dispatch(
                postEvents({ ["player-activation-tracker-dismissed"]: true })
            );
            bannerWasDismissed = true;
        }

        // Find the first step that hasn't been completed
        const currentStepNumber = completedSteps.findIndex((v) => !v); // incomplete steps can be false or undefined

        // Return event summary
        return { currentStepNumber, bannerWasDismissed };
    }, [results, events, dispatch, hasCatalogClaim]);

    let { bannerWasDismissed, currentStepNumber } = getEventSummary();

    // After the dust settles, make sure we have the most updated events
    useEffect(() => {
        dispatch(getEvents());
    }, [dispatch]);

    if (!events || bannerWasDismissed || loading || !steps[currentStepNumber])
        return <></>;

    return (
        <ProgressTrackerBanner
            header={t("activation-tracker:header")}
            currentStep={steps[currentStepNumber]}
            progressFraction={{
                current: currentStepNumber,
                total: steps.length
            }}
            hide={bannerWasDismissed}
            onClose={() =>
                dispatch(
                    postEvents({
                        ["player-activation-tracker-dismissed"]: true
                    })
                )
            }
            hasCatalogClaim={hasCatalogClaim}
        />
    );
};

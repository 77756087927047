import React, {
    useEffect,
    useState,
    useCallback,
    useMemo,
    Dispatch,
    SetStateAction
} from "react";
import { ModalBase } from "components/modal/ModalBase";
import styles from "./index.module.scss";
import CloseIcon from "assets/icons/close.svg?react";
import { BroadcastsMultiSelect } from "views/page-content/video-player/video-player-settings/BroadcastsMultiSelect";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "store/store";
import { exists } from "helpers/booleans";
import { VideoUploadModalEntryPoint } from "components/video-upload/VideoUploadModalEntryPoint";
import { sortByDate } from "helpers/time";
import { v4 as uuidv4 } from "uuid";
import { useClaimCheck } from "hooks/useClaimCheck";
import {
    Broadcast,
    CloudflareVideo,
    CreatorProductVideoPlayerEntitlement,
    CreatorProductVideoPlayerPlaylistBroadcastEntitlement,
    EntitySummaryResponse,
    VideoPlayer,
    VideoPlayerCloudflareResponse
} from "@switcherstudio/switcher-api-client";

export interface VideoMultiSelectModalProps {
    isMultiple: boolean;
    allowAdditional?: boolean;
    onSelect: (broadcastIds: string[]) => void;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    previouslySelectedBroadcastIds: string[];
    buttonText?: string;
    videosFromProps?: VideoPlayerCloudflareResponse[];
}

export interface BroadcastsHashItem {
    broadcast: Broadcast;
    players?: VideoPlayer[];
    playlistBroadcastEntitlements?: CreatorProductVideoPlayerPlaylistBroadcastEntitlement[];
    playerEntitlements?: CreatorProductVideoPlayerEntitlement[];
    videoResponse: CloudflareVideo;
    subsequentVideoResponses: CloudflareVideo[];
    metrics: EntitySummaryResponse;
    selected: boolean;
    disabled: boolean;
}
export interface BroadcastsHash {
    [key: string]: BroadcastsHashItem;
}

export interface AddToPlaylistModalProps extends VideoMultiSelectModalProps {
    playerId: string;
    onClose?: () => void;
}

export const AddToPlaylistModal = ({
    playerId,
    isMultiple,
    onSelect,
    isOpen,
    setIsOpen,
    previouslySelectedBroadcastIds,
    buttonText,
    videosFromProps,
    onClose
}: AddToPlaylistModalProps) => {
    const [videoUploadSessionId, setVideoUploadSessionId] = useState<string>(
        uuidv4()
    );
    const [broadcastsHash, setBroadcastsHash] = useState<BroadcastsHash>();
    const [selectedBroadcastId, setSelectedBroadcastId] = useState<string>();
    const [numberSelected, setNumberSelected] = useState<number>(0);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");

    const transformAndSetVideoData = useCallback(
        (data: VideoPlayerCloudflareResponse[]) => {
            const hashedBroadcasts = data?.reduce((hash, videoResponse) => {
                return {
                    ...hash,
                    [videoResponse.broadcast?.Id]: {
                        broadcast: videoResponse.broadcast,
                        videoResponse: videoResponse?.videos[0],
                        metrics: videoResponse?.entitySummary,
                        subsequentVideoResponses:
                            videoResponse?.videos.slice(1),
                        selected: previouslySelectedBroadcastIds.includes(
                            videoResponse?.broadcast?.Id
                        ),
                        disabled: previouslySelectedBroadcastIds.includes(
                            videoResponse?.broadcast?.Id
                        )
                    }
                };
            }, {} as BroadcastsHash);

            setBroadcastsHash(hashedBroadcasts);
        },
        [setBroadcastsHash, previouslySelectedBroadcastIds]
    );

    useSwitcherClient((client) => client.cloudRecordings_GetVideosForUserV2, {
        // if videos passed in as props, avoid fetching a second time
        requestImmediately: !videosFromProps,
        fetchPolicy: "cache-and-network",
        onSuccess: (data) => {
            transformAndSetVideoData(data);
        },
        onError: () => {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("errors:recording-retrieval-error")
                })
            );
        }
    });

    useEffect(() => {
        if (!!videosFromProps) {
            transformAndSetVideoData(videosFromProps);
        }
    }, [videosFromProps, transformAndSetVideoData]);

    const handleSelect = useCallback(
        (broadcastId: string) => {
            if (isMultiple) {
                if (broadcastsHash[broadcastId]?.selected) {
                    setNumberSelected(numberSelected - 1);
                } else {
                    setNumberSelected(numberSelected + 1);
                }

                setBroadcastsHash({
                    ...broadcastsHash,
                    [broadcastId]: {
                        ...broadcastsHash[broadcastId],
                        selected: !broadcastsHash[broadcastId]?.selected
                    }
                });
                return;
            } else {
                if (numberSelected < 1) setNumberSelected(1);
                setSelectedBroadcastId(broadcastId);
            }
        },
        [
            setBroadcastsHash,
            broadcastsHash,
            isMultiple,
            setSelectedBroadcastId,
            numberSelected,
            setNumberSelected
        ]
    );

    const handleSelectAll = useCallback(
        (visibleElements: string[] = []) => {
            if (visibleElements.length === 0) {
                visibleElements = Object.keys(broadcastsHash);
            }

            const broadcastsHashEntries = Object.entries(broadcastsHash);

            // If there are selected items that are not visible, they should not be considered in the count
            const anyVisibleSelected = broadcastsHashEntries.some(
                ([id, item]) =>
                    item.selected &&
                    !item.disabled &&
                    visibleElements.includes(id)
            );

            const updatedBroadcastHash = Object.entries(broadcastsHash).reduce(
                (hash, [broadcastId]) => {
                    // If the item is not visible, it should not be selected
                    if (!visibleElements.includes(broadcastId)) {
                        return {
                            ...hash,
                            [broadcastId]: {
                                ...broadcastsHash[broadcastId],
                                selected: false
                            }
                        };
                    }

                    return {
                        ...hash,
                        [broadcastId]: {
                            ...broadcastsHash[broadcastId],
                            selected: !anyVisibleSelected
                        }
                    };
                },
                {} as BroadcastsHash
            );

            setBroadcastsHash(updatedBroadcastHash);
            setNumberSelected(
                anyVisibleSelected
                    ? 0
                    : Object.values(updatedBroadcastHash).filter(
                          (b) => b.selected && !b.disabled
                      ).length
            );
        },
        [setBroadcastsHash, broadcastsHash]
    );

    const handleSubmit = () => {
        if (numberSelected === 0) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("errors:no-broadcasts-selected")
                })
            );
            return;
        }

        if (isMultiple) {
            onSelect(
                Object.entries(broadcastsHash)
                    .filter(
                        ([id, video]) =>
                            video?.selected &&
                            !video?.disabled &&
                            visibleBroadcastIds.includes(id)
                    )
                    .map(([broadcastId]) => broadcastId)
            );
        } else {
            onSelect([selectedBroadcastId]);
        }

        setIsOpen(false);
    };

    const sortedBroadcasts = useMemo(
        () =>
            !!broadcastsHash
                ? Object.values(broadcastsHash)
                      .sort((a, b) =>
                          sortByDate(
                              a.broadcast?.CreatedAt,
                              b.broadcast?.CreatedAt,
                              { descending: true }
                          )
                      )
                      .filter((b) => !b.disabled)
                : [],
        [broadcastsHash]
    );

    const [visibleBroadcastIds, setVisibleBroadcastIds] = useState<string[]>(
        sortedBroadcasts?.map((b) => b.broadcast?.Id)
    );

    const [anyVisibleSelected, setAnyVisibleSelected] = useState<boolean>(
        sortedBroadcasts.some((entry) => {
            return (
                entry.selected &&
                !entry.disabled &&
                visibleBroadcastIds.includes(entry.broadcast?.Id)
            );
        })
    );

    return (
        exists(broadcastsHash) && (
            <ModalBase
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                stylesOverride={{
                    padding: "0",
                    maxHeight: "unset",
                    maxWidth: "700px"
                }}
            >
                <div className={styles["multi-select-container"]}>
                    <header className={styles["header"]}>
                        <h5>{t("video-player-page:add-videos")}</h5>
                        <button
                            type="button"
                            className="btn"
                            onClick={() => setIsOpen(false)}
                        >
                            <CloseIcon />
                        </button>
                    </header>
                    <hr />
                    <div className={styles["content"]}>
                        <div className={styles["upload-container"]}>
                            <VideoUploadModalEntryPoint
                                sessionId={videoUploadSessionId}
                                setSessionId={setVideoUploadSessionId}
                                lockedToPlayer={playerId}
                                allowMultipleUploads
                                onUploadComplete={onClose}
                            />
                        </div>

                        {sortedBroadcasts?.length > 0 && (
                            <>
                                <div className={styles["separator"]}>
                                    <hr />
                                    <small>
                                        {t(
                                            "video-player-page:add-videos-prompt"
                                        )}
                                    </small>
                                    <hr />
                                </div>

                                <div
                                    className={styles["video-select-container"]}
                                >
                                    <BroadcastsMultiSelect
                                        allowMultiple
                                        broadcasts={sortedBroadcasts}
                                        selectedBroadcastId={
                                            selectedBroadcastId
                                        }
                                        handleSelect={handleSelect}
                                        setVisibleBroadcastIds={
                                            setVisibleBroadcastIds
                                        }
                                        setAnyVisibleSelected={
                                            setAnyVisibleSelected
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <hr />

                    <div className={styles["footer"]}>
                        <button
                            type="button"
                            className="btn"
                            onClick={() => setIsOpen(false)}
                        >
                            {t("buttons:cancel")}
                        </button>
                        {sortedBroadcasts?.length > 0 && isMultiple && (
                            <button
                                type="button"
                                className="btn"
                                onClick={() =>
                                    handleSelectAll(visibleBroadcastIds)
                                }
                            >
                                {anyVisibleSelected
                                    ? t("buttons:deselect-all")
                                    : t("buttons:select-all")}
                            </button>
                        )}
                        {sortedBroadcasts?.length > 0 && (
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                                disabled={!anyVisibleSelected}
                            >
                                {!!buttonText
                                    ? t(buttonText)
                                    : hasCatalogClaim
                                    ? t("video-player-page:add-to-collection")
                                    : t("video-player-page:add-to-playlist")}
                            </button>
                        )}
                    </div>
                </div>
            </ModalBase>
        )
    );
};

import { useMemo } from "react";
import { differenceInCalendarDays } from "helpers/time";
import { StripeStatus } from "views/page-content/platforms/types";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { MessageContentState } from "../../../types";
import { useSupportSystem } from "hooks/useSupportSystem";

export const useSubscriptionStatusBannerMessageProps = (
    onLinkClick: () => void
) => {
    const { isSupportAvailable, supportSystemCommand } = useSupportSystem();
    const { userInfo, orgs, defaultOrg } = useSelector(
        (s: RootState) => s.user
    );

    const isShopifyPlan = useMemo<boolean>(
        () => userInfo?.ActiveProduct?.discriminator?.includes("Shopify"),
        [userInfo]
    );

    const pausedBannerProps = useMemo<MessageContentState>(() => {
        return {
            leftMessageProps: {
                key: "pause-copy:paused-subscription"
            },
            linkProps: {
                href: "#",
                text: isSupportAvailable && "pause-copy:contact-support",
                onClick: () =>
                    isSupportAvailable && supportSystemCommand("show")
            },
            rightMessage: ""
        };
    }, [isSupportAvailable, supportSystemCommand]);

    const trialBannerProps = useMemo<MessageContentState>(() => {
        const count = differenceInCalendarDays(
            new Date(userInfo.ActiveUntil),
            new Date(),
            true
        );

        if (userInfo.PlanName === "Trial") {
            return {
                leftMessageProps: {
                    key: `status-banner:days-remaining${
                        count > 1 ? "_plural" : ""
                    }`,
                    params: {
                        count
                    }
                },
                linkProps: isShopifyPlan
                    ? undefined
                    : {
                          href: "/subscription/subscribe/",
                          text: "status-banner:no-active-link",
                          onClick: onLinkClick
                      }
            };
        } else {
            return {
                leftMessageProps: {
                    key: `status-banner:days-remaining${
                        count > 1 ? "_plural" : ""
                    }`,
                    params: {
                        count
                    }
                },
                linkProps: {
                    href: `/subscription/subscribe/${userInfo.PlanId}`,
                    text: "status-banner:start-now-link",
                    end: true,
                    onClick: onLinkClick
                },
                rightMessage: "status-banner:start-now-right"
            };
        }
    }, [
        isShopifyPlan,
        onLinkClick,
        userInfo.ActiveUntil,
        userInfo.PlanId,
        userInfo.PlanName
    ]);

    const incompleteBannerProps = useMemo<MessageContentState>(() => {
        return {
            leftMessageProps: {
                key: "status-banner:could-not-charge"
            },
            linkProps: {
                href: `/subscription/subscribe/${userInfo.PlanId}`,
                text: "status-banner:try-again",
                onClick: onLinkClick
            },
            rightMessage: ""
        };
    }, [onLinkClick, userInfo.PlanId]);

    const endedBannerProps = useMemo<MessageContentState>(() => {
        if (orgs.length && defaultOrg === null) {
            return {
                leftMessageProps: {
                    key: "status-banner:did-you-mean-org"
                },
                linkProps: {
                    href: "/workspaces",
                    text: "status-banner:change-workspace-link",
                    onClick: onLinkClick
                }
            };
        } else {
            return {
                leftMessageProps: {
                    key: "status-banner:no-active-left"
                },
                linkProps: {
                    href: "/subscription/subscribe/",
                    text: "status-banner:no-active-link",
                    onClick: onLinkClick
                }
            };
        }
    }, [defaultOrg, onLinkClick, orgs.length]);

    const messageContentState = useMemo<MessageContentState>(() => {
        if (userInfo.Roles?.includes("Paused")) {
            return pausedBannerProps;
        } else {
            switch (userInfo.Status) {
                case StripeStatus.Trialing:
                    return trialBannerProps;

                case StripeStatus.Incomplete:
                case StripeStatus.PastDue:
                    return incompleteBannerProps;

                case StripeStatus.Ended:
                    return endedBannerProps;
                default:
                    if (userInfo.PlanName === "Trial") return trialBannerProps;
                    if (userInfo.PlanName === "No Subscription")
                        return endedBannerProps;
            }
        }
    }, [
        endedBannerProps,
        incompleteBannerProps,
        pausedBannerProps,
        trialBannerProps,
        userInfo.PlanName,
        userInfo.Roles,
        userInfo.Status
    ]);

    return messageContentState;
};

import { useCallback, useMemo } from "react";
import styles from "../OnboardingPage.module.scss";
import { StripePriceAnonymous } from "@switcherstudio/switcher-api-client";
import { Sidebar } from "../Sidebar";
import { OnboardingProgressBar } from "components/widgets/progress-bar/OnboardingProgressBar";
import { SidebarContent } from "../Sidebar/SidebarContent";
import { StepContent } from "../stepContent";
import SubscribeFlow from "../stepContent/SubscribeFlow";
import NoSubscribeFlow from "../stepContent/NoSubscribeFlow";

const skipDownloadStep = import.meta.env.VITE_SKIP_DOWNLOAD_STEP === "true";

const OnboardingContent = ({
    couponHook,
    stepsHook,
    tag,
    defaultToSubscribeFlow,
    setSelectedPlan,
    selectedPlan,
    isTrialing,
    disableSelectionStep,
    clientId,
    redirectStatus
}) => {
    const { resellerInventoryItem, removeCoupon } = couponHook;

    const { step, nextStep, previousStep, isLastStep, firstStep } = stepsHook;

    const onSelectPlanButtonClicked = useCallback(
        (plan: StripePriceAnonymous) => {
            setSelectedPlan(plan);
            nextStep();
        },
        [nextStep, setSelectedPlan]
    );

    const changePlan = useCallback(() => {
        setSelectedPlan(undefined);
        if (resellerInventoryItem?.ResellerInventory?.Price) {
            removeCoupon();
        }
        previousStep();
    }, [previousStep, removeCoupon, resellerInventoryItem, setSelectedPlan]);

    const _isTrialing = useMemo<boolean>(() => {
        if (!selectedPlan?.IsRecurring) return false;
        return resellerInventoryItem
            ? resellerInventoryItem?.ResellerInventory?.TrialDays !== 0
            : isTrialing;
    }, [isTrialing, resellerInventoryItem, selectedPlan]);

    return (
        <span className={styles["content-container"]}>
            <Sidebar isLastStep={isLastStep} isTrialing={_isTrialing}>
                {!skipDownloadStep && (
                    <OnboardingProgressBar
                        minValue={firstStep - 1}
                        maxValue={defaultToSubscribeFlow ? 5 : 4}
                        currentValue={step}
                    />
                )}
                <SidebarContent
                    step={step}
                    defaultToSubscribeFlow={defaultToSubscribeFlow}
                    disableSelectionStep={disableSelectionStep}
                    plan={selectedPlan}
                    resellerInventoryItem={resellerInventoryItem}
                    isTrialing={_isTrialing}
                    changePlan={changePlan}
                />
            </Sidebar>
            <StepContent>
                {defaultToSubscribeFlow ? (
                    <SubscribeFlow
                        couponHook={couponHook}
                        stepsHook={stepsHook}
                        onSelectPlanButtonClicked={onSelectPlanButtonClicked}
                        selectedPlan={selectedPlan}
                        clientId={clientId}
                        redirectStatus={redirectStatus}
                        defaultToSubscribeFlow={defaultToSubscribeFlow}
                        isTrialing={_isTrialing}
                        tag={tag}
                    />
                ) : (
                    <NoSubscribeFlow
                        couponHook={couponHook}
                        stepsHook={stepsHook}
                        clientId={clientId}
                        redirectStatus={redirectStatus}
                        tag={tag}
                        defaultToSubscribeFlow={defaultToSubscribeFlow}
                    />
                )}
            </StepContent>
        </span>
    );
};

export default OnboardingContent;

import React, { useCallback, useEffect, useState } from "react";
import styles from "./PlatformPage.module.scss";
import { client } from "api/client";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { usePlatformActions } from "hooks/usePlatformActions";
import { PlatformId } from "../types";
import { useNavigate } from "hooks/useNavigate";
import { FacebookContainer } from "./facebook/FacebookContainer";
import { YouTubeContainer } from "./youtube/YoutubeContainer";
import { TwitchContainer } from "./twitch/TwitchContainer";
import { useTranslation } from "react-i18next";
import { openConfirmation } from "store/confirmation/slice";
import { useDispatch } from "react-redux";
import { ConfigureExternalChannelSettingsContainer } from "./external-channel-settings/ConfigureExternalChannelSettingsContainer";
import { Switchboard } from "./switchboard/Switchboard";
import { AppDispatch } from "store/store";

interface PlatformPageProps {
    platformId: PlatformId;
    destinationId?: number | string;
    destinationType?: string;
}

export const PlatformPage: React.FC<PlatformPageProps> = ({
    platformId,
    destinationType
}: PlatformPageProps) => {
    const { t } = useTranslation();
    const isMountedRef = useIsMountedRef();
    const { navigate } = useNavigate();

    const [platform, setPlatform] = useState<StreamingProvider>();
    const { unlink } = usePlatformActions(platformId);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        async function run() {
            try {
                const response =
                    await client.streamingProviders_GetStreamingProvider(
                        platformId
                    );
                if (isMountedRef.current) {
                    setPlatform(response);
                }
            } catch (e) {
                navigate(`/platforms`, {
                    replace: true
                });
            }
        }

        if (isMountedRef.current) {
            if (!platform) {
                run();
            } else if (!platform.IsLinked) {
                navigate(`/platforms/connect/${platformId}`, {
                    replace: true
                });
                return;
            }
        }
    }, [isMountedRef, navigate, platform, platformId]);

    const disconnect = useCallback(async () => {
        dispatch(
            openConfirmation({
                message: "platforms:confirm-account-disconnect",
                onSuccess: async () => {
                    await unlink();
                    setPlatform((p) => {
                        return { ...p, IsLinked: false };
                    });
                }
            })
        );
    }, [unlink, dispatch]);

    return (
        <>
            {platform !== undefined && (
                <div className="row">
                    <div className="col-lg-12">
                        <div className={styles["header"]}>
                            <h4 className={styles["title"]}>
                                {platform?.Name}
                            </h4>
                            <div className={styles["buttons"]}>
                                <button
                                    className="btn btn-outline-danger"
                                    onClick={async () => await disconnect()}
                                >
                                    {t("platforms:disconnect-account-button")}
                                </button>
                            </div>
                        </div>
                        {(function () {
                            if (platform.IsLinked) {
                                switch (platformId) {
                                    case PlatformId.Facebook:
                                        return (
                                            <FacebookContainer
                                                platform={platform}
                                                destinationType={
                                                    destinationType
                                                }
                                            />
                                        );
                                    case PlatformId.Youtube:
                                        return (
                                            <YouTubeContainer
                                                platform={platform}
                                            />
                                        );
                                    case PlatformId.Twitch:
                                        return (
                                            <TwitchContainer
                                                platform={platform}
                                            />
                                        );
                                    case PlatformId.BoxCast:
                                        return;
                                    case PlatformId.SwitchboardLive:
                                        return <Switchboard />;
                                    // url-based RTMP integrations
                                    case PlatformId.MsStream:
                                    case PlatformId.AmazonLive:
                                    case PlatformId.AzureMediaServices:
                                    case PlatformId.Caast:
                                    case PlatformId.Instafeed:
                                        return (
                                            <ConfigureExternalChannelSettingsContainer
                                                platform={platform}
                                            />
                                        );
                                    default:
                                        return (
                                            <div
                                                className="alert alert-info"
                                                role="alert"
                                            >
                                                {t(
                                                    "platforms:unsupported-platform"
                                                )}
                                            </div>
                                        );
                                }
                            }
                        })()}
                    </div>
                </div>
            )}
        </>
    );
};

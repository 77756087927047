import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PricingDetails } from "./PricingDetails";
import {
    GatedContentStatus,
    useStripeAccountInfo
} from "hooks/useStripeAccountInfo";
import { useGetCreatorProducts } from "hooks/useGetCreatorProducts";

import PassEmptyState from "components/empty-state/PassEmptyState";
import { usePageHeader } from "hooks/usePageHeader";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useGetStripeConnectLink } from "hooks/useGetStripeConnectLink";

export const GatedContentPage: React.FC = () => {
    const { t } = useTranslation();

    const {
        account,
        details,
        gatedContentStatus,
        loading: stripeLoading
    } = useStripeAccountInfo({ expandDetails: true });

    const stripeConnected = gatedContentStatus === GatedContentStatus.READY;

    const {
        products,
        getProducts,
        productSubscribers,
        loading: productsLoading
    } = useGetCreatorProducts();

    const nonCatalogProducts = useMemo(
        () =>
            products?.filter(
                (p) =>
                    p.CreatorProductEntitlements.length === 0 ||
                    // @ts-expect-error - Handle multiple types
                    p.CreatorProductEntitlements.some((cpe) => !cpe.CatalogId)
            ),
        [products]
    );

    const hasCatalogClaim = useClaimCheck("catalog");

    usePageHeader({
        title: t("page-titles:gated-content"),
        subTitle: hasCatalogClaim
            ? t("page-titles:subheaders:gated-content-catalog")
            : t("page-titles:subheaders:gated-content")
    });

    const { link: stripeLink } = useGetStripeConnectLink();

    return (
        <div>
            {!stripeLoading &&
                (!stripeConnected ? (
                    <PassEmptyState
                        title={t(
                            "players:gated-content:connect-stripe-account-title"
                        )}
                        message={t(
                            "players:gated-content:connect-stripe-account-description"
                        )}
                        linkText={t(
                            "players:gated-content:connect-stripe-account-link-text"
                        )}
                        linkHref={stripeLink}
                    />
                ) : (
                    <PricingDetails
                        account={account}
                        loading={productsLoading}
                        products={nonCatalogProducts}
                        productSubscribers={productSubscribers}
                        getProducts={getProducts}
                        details={details}
                    />
                ))}
        </div>
    );
};

import React, { useState, useCallback, useMemo, useEffect } from "react";
import styles from "./index.module.scss";
import { useDispatch } from "react-redux";
import { BroadcastDetails } from "../../../../../components/entity-details/BroadcastDetails";
import {
    VideoPlayerPlaylistBroadcast,
    Broadcast,
    CloudflareVideo,
    CreatorProductEntitlement,
    PlaylistItemResponse,
    BroadcastResponse,
    OrdinalRank,
    BroadcastStatus
} from "@switcherstudio/switcher-api-client";
import { sortCloudflareVideosAsc } from "helpers/cloudVideosHelpers";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import rollbar from "helpers/rollbar";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from "react-beautiful-dnd";
import { AppDispatch } from "store/store";
import { tryGetProp } from "utils/js-utils";
import { openConfirmation } from "store/confirmation/slice";
import { useStripeAccountInfo } from "hooks/useStripeAccountInfo";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { AddToPlaylistModal } from "components/modal/AddToPlaylistModal";
import { usePageHeader } from "hooks/usePageHeader";
import { closeCurrentModal, setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { useTranslation } from "react-i18next";
import { useClaimCheck } from "hooks/useClaimCheck";

interface OrderedBroadcastMeta extends VideoPlayerPlaylistBroadcast {
    ProductEntitlements?: CreatorProductEntitlement[];
}

export interface OrderedBroadcast {
    broadcast: BroadcastResponse;
    playlistBroadcast: PlaylistItemResponse;
    videos: CloudflareVideo[];
    meta: OrderedBroadcastMeta;
}

export const PlaylistPage = (props) => {
    const { t } = useTranslation();
    const { gatedContentStatus } = useStripeAccountInfo();
    const [selectedBroadcasts, setSelectedBroadcasts] = useState<
        OrderedBroadcast[]
    >([]);
    const dispatch = useDispatch<AppDispatch>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isAfterInitialLoad, setIsAfterInitialLoad] =
        useState<boolean>(false);
    const hasCatalogClaim = useClaimCheck("catalog");

    // prefetch and cache cloud recordings for modals
    useSwitcherClient((client) => client.cloudRecordings_GetVideosForUserV2, {
        requestImmediately: true,
        fetchPolicy: "network-only",
        hideLoading: true
    });

    const { dispatchApiRequest: postVideoPlayerPlaylist } = useSwitcherClient(
        (client) => client.videoPlayerPlaylist_PostVideoPlayerPlaylist,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const {
        dispatchApiRequest: postVideoPlayerPlaylistBroadcastsByIds,
        loading: loadingPostVideoPlayerPlaylistBroadcastsByIds
    } = useSwitcherClient(
        (client) =>
            client.videoPlayerPlaylist_PostVideoPlayerPlaylistBroadcastsByIds,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const {
        dispatchApiRequest: deleteVideoPlayerPlaylistBroadcasts,
        loading: loadingDeleteVideoPlayerPlaylistBroadcasts
    } = useSwitcherClient(
        (client) =>
            client.videoPlayerPlaylist_DeleteVideoPlayerPlaylistBroadcasts,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const {
        dispatchApiRequest: putVideoPlayerPlaylistBroadcasts2,
        loading: loadingPutVideoPlayerPlaylistBroadcasts2
    } = useSwitcherClient(
        (client) =>
            client.videoPlayerPlaylist_PutVideoPlayerPlaylistBroadcasts2,
        {
            requestImmediately: false,
            hideLoading: true
        }
    );

    const { dispatchApiRequest: getCreatorProductEntitlements } =
        useSwitcherClient(
            (client) =>
                client.creatorProductEntitlements_GetByVideoPlayerPlaylistBroadcastId,
            {
                requestImmediately: false,
                hideLoading: true
            }
        );

    const videoPlayerId = useMemo(
        () => tryGetProp(props, "videoPlayerId"),
        [props]
    );

    const {
        data: videoPlayerEntitlement,
        dispatchApiRequest: fetchVideoPlayerEntitlements
    } = useSwitcherClient((client) => client.videoPlayersV2_GetVideoPlayers, {
        args: [[videoPlayerId.toString()]],
        requestImmediately: true,
        hideLoading: isAfterInitialLoad,
        onSuccess: (data) => {
            setIsAfterInitialLoad(true);
            const broadcastResponseMap =
                data?.Players?.[0]?.Playlists?.[0]?.Items?.reduce(
                    (memo, currentBroadcast) => ({
                        ...memo,
                        [currentBroadcast?.Details?.BroadcastId]:
                            currentBroadcast
                    }),
                    {} as PlaylistItemResponse
                );

            const orderedBroadcasts: OrderedBroadcast[] =
                data?.Players?.[0]?.Playlists?.[0]?.Items?.filter(
                    (broadcastWithVideos) =>
                        !!broadcastResponseMap[
                            broadcastWithVideos.Broadcast?.Details?.Id
                        ]
                )
                    ?.map((broadcastWithVideos) => {
                        const sortedVideos = sortCloudflareVideosAsc(
                            broadcastWithVideos.Broadcast?.Videos?.result
                        ) as CloudflareVideo[];

                        return {
                            broadcast: broadcastWithVideos.Broadcast,
                            playlistBroadcast: broadcastWithVideos,
                            videos: sortedVideos,
                            meta: {
                                ProductEntitlements:
                                    broadcastResponseMap[
                                        broadcastWithVideos?.Broadcast?.Details
                                            ?.Id
                                    ].EntitlementProducts
                            } as OrderedBroadcastMeta
                        };
                    })
                    .reduce((memo, currentBroadcast) => {
                        return memo.find(
                            (currentMemoBroadcast) =>
                                currentMemoBroadcast?.broadcast?.Details?.Id ===
                                currentBroadcast?.broadcast?.Details?.Id
                        )
                            ? memo
                            : [...memo, currentBroadcast];
                    }, [] as OrderedBroadcast[]);

            setSelectedBroadcasts(orderedBroadcasts);
        }
    });

    useEffect(() => {
        setIsSaving(
            loadingPostVideoPlayerPlaylistBroadcastsByIds ||
                loadingPutVideoPlayerPlaylistBroadcasts2 ||
                loadingDeleteVideoPlayerPlaylistBroadcasts
        );
    }, [
        loadingPostVideoPlayerPlaylistBroadcastsByIds,
        loadingPutVideoPlayerPlaylistBroadcasts2,
        loadingDeleteVideoPlayerPlaylistBroadcasts
    ]);

    const playerName = useMemo(() => {
        if (!!videoPlayerEntitlement) {
            return videoPlayerEntitlement?.Players?.[0]?.Details?.Name
                ? `"${videoPlayerEntitlement?.Players?.[0]?.Details.Name}" ${t(
                      "video-player-page:playlist"
                  )}`
                : `"${t("players:untitled-player")}" ${t(
                      "video-player-page:playlist"
                  )}`;
        }
    }, [t, videoPlayerEntitlement]);

    const { updatePageHeader } = usePageHeader({
        title: hasCatalogClaim
            ? t("video-player-page:playlist")
            : playerName ?? "",
        autoSave: isSaving
    });

    const handleAddVideos = useCallback(
        async (broadcastIds: string[]) => {
            try {
                updatePageHeader({
                    title: `"${
                        videoPlayerEntitlement?.Players?.[0]?.Details?.Name
                    }" ${t("video-player-page:playlist")}`
                });
                let playlistId =
                    videoPlayerEntitlement?.Players?.[0]?.Playlists?.[0]
                        ?.Details?.Id;
                //create a default playlist if there isn't one already
                if (!playlistId) {
                    const res = await postVideoPlayerPlaylist([
                        {
                            VideoPlayerId: videoPlayerId,
                            IsDefault: true,
                            Title: "Default Playlist"
                        }
                    ]);
                    playlistId = res.Id;
                }

                await postVideoPlayerPlaylistBroadcastsByIds([
                    playlistId,
                    broadcastIds
                ]);

                await fetchVideoPlayerEntitlements();
            } catch (e) {
                rollbar.error("Error adding videos to playlist", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:playlist-add-videos-error")
                    })
                );
            }
        },
        [
            updatePageHeader,
            videoPlayerEntitlement,
            t,
            postVideoPlayerPlaylistBroadcastsByIds,
            fetchVideoPlayerEntitlements,
            dispatch,
            postVideoPlayerPlaylist,
            videoPlayerId
        ]
    );

    const onAddToPlaylistModalClosed = useCallback(() => {
        fetchVideoPlayerEntitlements(null, { hideLoading: true });
    }, [fetchVideoPlayerEntitlements]);

    const closeModal = useCallback(() => {
        dispatch(closeCurrentModal());
    }, [dispatch]);

    const handleOpenModal = useCallback(() => {
        dispatch(
            setActiveModal({
                id: Modals.AddToPlaylistModal,
                type: Modals.AddToPlaylistModal,
                component: (
                    <AddToPlaylistModal
                        playerId={tryGetProp(props, "videoPlayerId")}
                        isMultiple={true}
                        allowAdditional={true}
                        isOpen
                        setIsOpen={closeModal}
                        onSelect={handleAddVideos}
                        previouslySelectedBroadcastIds={
                            !!selectedBroadcasts?.length
                                ? selectedBroadcasts.map(
                                      (sb) => sb.broadcast?.Details?.Id
                                  )
                                : []
                        }
                        onClose={onAddToPlaylistModalClosed}
                    />
                )
            })
        );
    }, [
        closeModal,
        dispatch,
        handleAddVideos,
        onAddToPlaylistModalClosed,
        props,
        selectedBroadcasts
    ]);

    const handleDeleteVideo = useCallback(
        async (video: OrderedBroadcast) => {
            const deleteVideo = async (video: OrderedBroadcast) => {
                try {
                    await deleteVideoPlayerPlaylistBroadcasts([
                        video?.playlistBroadcast?.Details
                            ?.VideoPlayerPlaylistId,
                        [video].map((btd) => {
                            return {
                                Id: btd?.playlistBroadcast?.Details?.Id,
                                VideoPlayerPlaylistId:
                                    btd?.playlistBroadcast?.Details
                                        ?.VideoPlayerPlaylistId,
                                BroadcastId:
                                    btd?.playlistBroadcast?.Details
                                        ?.BroadcastId,
                                OrdinalRank: {
                                    Ordinal:
                                        btd?.playlistBroadcast?.Details
                                            ?.Ordinal,
                                    Rank: btd?.playlistBroadcast?.Details?.Rank
                                } as OrdinalRank,
                                CreatedAt:
                                    btd?.playlistBroadcast?.Details?.CreatedAt
                            };
                        })
                    ]);

                    await fetchVideoPlayerEntitlements();
                } catch (e) {
                    rollbar.error("Error removing video from playlist", e);
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t("errors:playlist-delete-video-error")
                        })
                    );
                }
            };

            const withEntitlement = await Promise.all(
                [video].map(
                    async (b) =>
                        (
                            await getCreatorProductEntitlements([
                                b?.broadcast?.Details?.Id
                            ])
                        ).ProductEntitlements.length > 0
                )
            );

            if (withEntitlement.some((b) => b)) {
                dispatch(
                    openConfirmation({
                        message: t(
                            "messages:confirm-broadcast-with-pass-delete-message"
                        ),
                        confirmText: t(
                            "messages:confirm-broadcast-with-pass-delete-cta"
                        ),
                        onSuccess: deleteVideo
                    })
                );
            } else {
                deleteVideo(video);
            }
        },
        [
            deleteVideoPlayerPlaylistBroadcasts,
            fetchVideoPlayerEntitlements,
            dispatch,
            t,
            getCreatorProductEntitlements
        ]
    );

    const handleDragEnd = useCallback(
        async (result: DropResult) => {
            if (!result?.destination) return;
            if (result.reason === "DROP") {
                const targetBroadcast = selectedBroadcasts.find(
                    (b) =>
                        b.playlistBroadcast?.Details?.BroadcastId ===
                        result?.draggableId
                );
                const newSelectedBroadcasts = [...selectedBroadcasts];
                newSelectedBroadcasts.splice(result.source.index, 1);
                newSelectedBroadcasts.splice(
                    result.destination.index,
                    0,
                    targetBroadcast
                );

                const destinationPreviousBroadcastRank =
                    newSelectedBroadcasts[result.destination.index - 1]
                        ?.playlistBroadcast?.Details?.Rank;
                const destinationNextBroadcastRank =
                    newSelectedBroadcasts[result.destination.index + 1]
                        ?.playlistBroadcast?.Details?.Rank;

                setSelectedBroadcasts(newSelectedBroadcasts);

                try {
                    await putVideoPlayerPlaylistBroadcasts2([
                        targetBroadcast?.playlistBroadcast?.Details
                            ?.VideoPlayerPlaylistId,
                        {
                            PlaylistBroadcast: {
                                Id: targetBroadcast?.playlistBroadcast?.Details
                                    ?.Id,
                                VideoPlayerPlaylistId:
                                    targetBroadcast?.playlistBroadcast?.Details
                                        ?.VideoPlayerPlaylistId,
                                BroadcastId:
                                    targetBroadcast?.playlistBroadcast?.Details
                                        ?.BroadcastId,
                                OrdinalRank: {
                                    Ordinal:
                                        targetBroadcast?.playlistBroadcast
                                            ?.Details?.Ordinal,
                                    Rank: targetBroadcast?.playlistBroadcast
                                        ?.Details?.Ordinal
                                } as OrdinalRank,
                                CreatedAt:
                                    targetBroadcast?.playlistBroadcast?.Details
                                        ?.CreatedAt
                            },
                            PreviousOrdinal: destinationPreviousBroadcastRank,
                            NextOrdinal: destinationNextBroadcastRank
                        }
                    ]);

                    await fetchVideoPlayerEntitlements();
                } catch (e) {
                    rollbar.error("Error reordering playlist", e);
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t("messages:failed-to-reorder-playlist")
                        })
                    );
                }
            }
        },
        [
            selectedBroadcasts,
            putVideoPlayerPlaylistBroadcasts2,
            fetchVideoPlayerEntitlements,
            dispatch,
            t
        ]
    );

    // update page header with the correct modal button callback
    useEffect(() => {
        updatePageHeader({
            customButtons: (
                <div>
                    <button
                        className={`btn btn-primary ${styles["header-button"]}`}
                        type="button"
                        onClick={handleOpenModal}
                    >
                        {t("playlist-page:buttons:add-videos")}
                    </button>
                </div>
            )
        });
    }, [handleOpenModal, t, updatePageHeader]);

    return (
        <>
            <div className={styles["playlist-container"]}>
                {!!selectedBroadcasts?.length && (
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="broadcasts">
                            {(provided) => (
                                <div
                                    className={
                                        styles["broadcasts-droppable-container"]
                                    }
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {selectedBroadcasts?.map((video, index) => (
                                        <Draggable
                                            draggableId={
                                                video?.playlistBroadcast
                                                    ?.Details?.BroadcastId
                                            }
                                            key={
                                                video?.playlistBroadcast
                                                    ?.Details?.BroadcastId
                                            }
                                            index={index}
                                        >
                                            {(provided) => (
                                                <div
                                                    className={`${styles["broadcast-item"]}`}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <BroadcastDetails
                                                        video={
                                                            video
                                                                ?.playlistBroadcast
                                                                ?.Broadcast
                                                                ?.Videos
                                                                ?.result?.[0]
                                                        }
                                                        broadcast={
                                                            {
                                                                ...video
                                                                    ?.playlistBroadcast
                                                                    ?.Broadcast
                                                                    ?.Details,
                                                                BroadcastStatus:
                                                                    video
                                                                        ?.playlistBroadcast
                                                                        ?.Broadcast
                                                                        ?.Details
                                                                        ?.BroadcastStatus as unknown as BroadcastStatus
                                                            } as Broadcast
                                                        }
                                                        metrics={
                                                            video
                                                                ?.playlistBroadcast
                                                                ?.Broadcast
                                                                ?.MetricsSummary
                                                        }
                                                        playlistBroadcast={
                                                            video
                                                                ?.playlistBroadcast
                                                                ?.Details
                                                        }
                                                        badges
                                                        gatedContentStatus={
                                                            gatedContentStatus
                                                        }
                                                        showEdit
                                                        onBroadcastUpdate={
                                                            fetchVideoPlayerEntitlements
                                                        }
                                                        handleDeleteBroadcast={() =>
                                                            handleDeleteVideo(
                                                                video
                                                            )
                                                        }
                                                        isDraggable={true}
                                                        location={
                                                            "player-playlist"
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder as React.ReactNode}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </div>
        </>
    );
};

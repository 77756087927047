import { useTranslation } from "react-i18next";
import { Link } from "react-navi";
import styles from "./index.module.scss";

const pricingUrl = "https://www.switcherstudio.com/pricing";

export const ComparePlansLink: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Link
            className={styles["compare-plans-opener"]}
            href={pricingUrl}
            target="_blank"
        >
            {t("subscription:compare-plans")}
        </Link>
    );
};

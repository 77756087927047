import React, { useState, useMemo, useEffect, useCallback } from "react";
import { NotFoundBoundary } from "react-navi";
import styles from "./OnboardingPage.module.scss";
import { useTranslation } from "react-i18next";
import { StripePriceAnonymous } from "@switcherstudio/switcher-api-client";
import { useGetStripePrice } from "hooks/useGetStripePrice";
import { NotificationContainer } from "components/notification/NotificationContainer";
import { useSteps } from "hooks/useSteps";
import Loading from "components/loading/Loading";
import { useRedirectIfDisallowed } from "hooks/useRedirectIfDisallowed";
import { useOnboardingSetupIntent } from "hooks/useOnboardingSetupIntent";
import { useCouponAnonymous } from "hooks/useCouponAnonymous";
import { OnboardingPageProps } from "./types";
import { exists } from "helpers/booleans";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { useNavigate } from "hooks/useNavigate";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { ModalManager } from "components/modal/ModalManager";
import { useLandingPageRedirectABTest } from "hooks/useLandingPageRedirectABTest";
import OnboardingContent from "./OnboardingContent";

const fallbackToDefaultToSubscribe =
    import.meta.env.VITE_FALLBACK_TO_DEFAULT_TO_SUBSCRIBE === "true";

const skipDownloadStep = import.meta.env.VITE_SKIP_DOWNLOAD_STEP === "true";

export const OnboardingPage: React.FC<OnboardingPageProps> = ({
    planId,
    defaultToSubscribe,
    isTrialing,
    couponCode,
    resellerInventoryItemId,
    clientId,
    tag,
    setupIntent,
    redirectStatus,
    registerBody
}) => {
    const { navigate } = useNavigate();
    const { t } = useTranslation("getting-started");
    const { ticket } = useSelector((state: RootState) => state.user);
    const [selectedPlan, setSelectedPlan] = useState<
        StripePriceAnonymous | undefined
    >();
    const isMountedRef = useIsMountedRef();
    const { redirectToLandingPageWithABTest } = useLandingPageRedirectABTest();

    const defaultToSubscribeFlow = useMemo(() => {
        if (defaultToSubscribe !== undefined) {
            return defaultToSubscribe;
        } else {
            return fallbackToDefaultToSubscribe;
        }
    }, [defaultToSubscribe]);

    const { plan: planFromQuery } = useGetStripePrice(
        planId,
        "Invalid stripe price passed from marketing site"
    );

    const couponHook = useCouponAnonymous(couponCode, selectedPlan);

    const { resellerInventoryItem } = couponHook;

    const disableSelectionStep = useMemo<boolean>(() => {
        return (
            exists(planId) ||
            exists(
                couponCode && resellerInventoryItem?.ResellerInventory?.Price
            )
        );
    }, [couponCode, planId, resellerInventoryItem?.ResellerInventory?.Price]);

    const stepsHook = useSteps(
        defaultToSubscribeFlow ? (disableSelectionStep ? 2 : 1) : 1,
        defaultToSubscribeFlow ? 5 : 4,
        disableSelectionStep ? 2 : 1
    );
    const { step, setStep, isLastStep } = stepsHook;

    useRedirectIfDisallowed(
        (user) =>
            (!defaultToSubscribeFlow && !!user?.userInfo) ||
            redirectStatus === "succeeded" ||
            !user?.userInfo ||
            step === 3,
        null,
        isMountedRef.current
    );

    useEffect(() => {
        if (planFromQuery) {
            setSelectedPlan(planFromQuery);
        }
    }, [planFromQuery, setStep]);

    useEffect(() => {
        if (resellerInventoryItem?.ResellerInventory?.Price) {
            setSelectedPlan(resellerInventoryItem.ResellerInventory.Price);
            setStep(2);
        }
    }, [resellerInventoryItem, setStep]);

    // If redirect was required perfor all necessary registration steps!
    useOnboardingSetupIntent({
        setupIntent,
        redirectStatus,
        clientId,
        plan: registerBody?.Plan,
        resellerInventoryItemId,
        isTrialing,
        body: registerBody,
        onSuccess: useCallback(() => setStep(3), [setStep])
    });

    useEffect(() => {
        if (
            skipDownloadStep &&
            isLastStep &&
            ticket.activePlan !== "NoSubscription"
        ) {
            redirectToLandingPageWithABTest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLastStep, navigate, ticket]);

    return (
        <section className={styles["app-container"]}>
            <NotificationContainer fixed />
            <NotFoundBoundary render={() => <h1>{t("errors:not-found")}</h1>}>
                <Loading />
                <ModalManager />
                <OnboardingContent
                    couponHook={couponHook}
                    stepsHook={stepsHook}
                    tag={tag}
                    defaultToSubscribeFlow={defaultToSubscribeFlow}
                    setSelectedPlan={setSelectedPlan}
                    selectedPlan={selectedPlan}
                    isTrialing={isTrialing}
                    disableSelectionStep={disableSelectionStep}
                    clientId={clientId}
                    redirectStatus={redirectStatus}
                />
            </NotFoundBoundary>
        </section>
    );
};

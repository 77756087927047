import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { Select } from "components/inputs/select/Select";
import { TextInput } from "components/inputs/text-input/TextInput";
import { useClaimCheck } from "hooks/useClaimCheck";

export enum FilterOptions {
    All = "all-filter",
    Gated = "gated",
    NotGated = "not-gated",
    InPlayer = "in-player",
    NotInPlayer = "not-in-player",
    Shoppable = "shoppable",
    NotShoppable = "not-shoppable",
    Published = "published",
    Unpublished = "unpublished",
    Scheduled = "scheduled"
}
export enum SortOptions {
    NewestFirst = "newest-first",
    OldestFirst = "oldest-first",
    TitleAZ = "title-a-z",
    TitleZA = "title-z-a",
    MostViews = "most-views",
    LeastViews = "least-views"
}
export interface SearchSortBarProps {
    /** Sort Dropdown. Defaulted to true. */
    showSort?: boolean;
    /** An array of options objects by which to sort. Has default array of objects if none passed in. */
    sortOptions?: {
        key: string;
        label: string;
    }[];
    /** The user's selected sort option. */
    selectedSortOption?: string;
    /** Take user's input and set the selectedSortOption. */
    setSelectedSortOption?: React.Dispatch<React.SetStateAction<string>>;
    /** An array of options objects by which to categorize. */
    categoryOptions?: {
        value: string;
        text: string;
    }[];
    /** The user's selected category option. */
    selectedCategoryOption?: string;
    /** Take user's input and set the selectedCategoryOption. */
    setSelectedCategoryOption?: React.Dispatch<React.SetStateAction<string>>;
    /** Search Bar. Defaulted to true. */
    showSearch?: boolean;
    /** The user's search query. */
    query?: string;
    /** Take user's input and set the query. */
    setQuery?: React.Dispatch<React.SetStateAction<string>>;

    showFilters?: boolean;
    gatedOptions?: FilterOptions[];
    setSelectedFilterOption?: React.Dispatch<React.SetStateAction<string>>;
    selectedFilterOption?: string;
}

export const SearchSortBar = ({
    showSort = true,
    sortOptions = null,
    selectedSortOption = SortOptions.NewestFirst,
    setSelectedSortOption,
    categoryOptions = [],
    selectedCategoryOption = "all",
    setSelectedCategoryOption,
    showSearch = true,
    query,
    setQuery,
    showFilters = false,
    selectedFilterOption = FilterOptions.All,
    setSelectedFilterOption
}: SearchSortBarProps) => {
    const { t } = useTranslation("broadcasts-multi-select");
    const hasCatalogClaim = useClaimCheck("catalog");

    return (
        <div className={`${styles["sort-search-container"]} search-sort-bar`}>
            <div className={styles["dropdowns-container"]}>
                {showSort && (
                    <Select
                        id="sort"
                        onChange={(e) => {
                            setSelectedSortOption(e.target.value);
                        }}
                        options={
                            sortOptions
                                ? sortOptions
                                : Object.keys(SortOptions).map((option) => {
                                      return {
                                          key: SortOptions[option],
                                          label: t(SortOptions[option])
                                      };
                                  })
                        }
                        label="Sort"
                        optionKey="key"
                        optionLabel="label"
                        selected={selectedSortOption}
                        parentClassName={styles["sort-dropdown"]}
                    />
                )}
                <Select
                    id="category"
                    onChange={(e) => {
                        setSelectedCategoryOption(e.target.value);
                    }}
                    options={categoryOptions}
                    selected={selectedCategoryOption}
                    label={`${
                        hasCatalogClaim
                            ? t("broadcasts-multi-select:tag")
                            : t("broadcasts-multi-select:category")
                    }`}
                    parentClassName={styles["category-dropdown"]}
                />
            </div>
            {showFilters && (
                <div className={styles["filter-container"]}>
                    <Select
                        id="filter"
                        onChange={(e) => {
                            setSelectedFilterOption(e.target.value);
                        }}
                        options={Object.keys(FilterOptions).map((option) => {
                            let filterOption = FilterOptions[option];
                            let label = filterOption;

                            switch (true) {
                                case filterOption === FilterOptions.InPlayer &&
                                    hasCatalogClaim:
                                    label = "in-collection";
                                    break;
                                case filterOption ===
                                    FilterOptions.NotInPlayer &&
                                    hasCatalogClaim:
                                    label = "not-in-collection";
                                    break;
                                default:
                                    label = filterOption;
                                    break;
                            }

                            return {
                                key: filterOption,
                                label: t(label)
                            };
                        })}
                        selected={selectedFilterOption}
                        label="Filter"
                        optionKey="key"
                        optionLabel="label"
                        parentClassName={styles["category-dropdown"]}
                    />
                </div>
            )}
            {showSearch && (
                <div className={styles["search-bar-container"]}>
                    <TextInput
                        id={"search"}
                        label={"Search"}
                        type={"text"}
                        onChange={(q) => {
                            setQuery(q.target.value);
                        }}
                        value={query}
                        className="broadcasts-search-label"
                    />
                </div>
            )}
        </div>
    );
};

import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "store/notification/slice";
import { AppDispatch } from "store/store";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { NotificationType } from "store/notification/types";
import { Recording } from "components/recording-card/RecordingCard/types";
import { usePagination } from "hooks/usePagination";
import { PagintationNav } from "components/navs/PaginationNav";
import { CardGrid } from "components/card-grid/CardGrid";
import { displayDate } from "helpers/time";
import { useTranslation } from "react-i18next";
import rollbar from "helpers/rollbar";
import { useNavigate } from "hooks/useNavigate";
import LinkIcon from "assets/icons/link.svg?react";
import styles from "./MyLivestreamsPage.module.scss";
import DownIcon from "assets/icons/down-arrow.svg?react";
import UpIcon from "assets/icons/up-arrow.svg?react";
import natsort from "natsort";
import { RootState } from "store/reducers";
import { setLoading } from "store/loading/slice";
import { client } from "api/client";
import { DisabledFeature } from "components/disabled-feature/DisabledFeature";
import { useHasAccess } from "hooks/useHasAccess";
import { RecordingCard } from "components/recording-card/RecordingCard";

type SortKeyType = "created" | "title" | "provider";

function getUrl(source?: string, permanentUrl?: string) {
    let url = "";
    switch (source) {
        case "Facebook":
            url =
                "https://www.facebook.com/plugins/video.php?href=" +
                encodeURIComponent(
                    "https://www." + source + ".com" + permanentUrl
                );
            break;
        case "YouTube":
            url = "https://www." + source + ".com/embed" + permanentUrl;
            break;
    }
    return url;
}

function generateVideoUrl(source: string, permanentUrl: string) {
    let url = "";
    switch (source) {
        case "YouTube":
            url =
                "https://www.youtube.com/watch?v=" +
                permanentUrl.replace(/\//, "");
            break;
        case "Facebook":
            url = "https://www.facebook.com" + permanentUrl;
            break;
    }
    return url;
}

export const MyLivestreamsPage: React.FC = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { userInfo } = useSelector((state: RootState) => state.user);
    const isMountedRef = useIsMountedRef();
    const {
        next,
        prev,
        jump,
        currentData,
        setData,
        currentPage,
        maxPage,
        data
    } = usePagination<Recording>(10);
    const { navigate } = useNavigate();

    const link = () => {
        navigate("/platforms");
    };

    const [sortOpts] = useState([
        { name: t("video-library:date"), value: "created" },
        { name: t("video-library:title"), value: "title" },
        { name: t("video-library:source"), value: "provider" }
    ]);
    const [sortKey, setSortKey] = useState<SortKeyType>("created");
    const [sortByDesc, setSortByDesc] = useState<boolean>(true);
    const hasAccess = useHasAccess();

    useEffect(() => {
        async function run() {
            try {
                dispatch(setLoading(1));
                const videos = await client.videos_GetAllUserVideos();
                if (isMountedRef.current) {
                    // initially sort by descending CreatedAt date
                    videos.sort((a, b) =>
                        dayjs(a.CreatedAt).isBefore(dayjs(b.CreatedAt)) ? 1 : -1
                    );

                    const recordings = videos.map<Recording>((v) => {
                        return {
                            id: v.id || "",
                            title: v.Title || "",
                            provider: v.Provider || "",
                            embedUrl: getUrl(v.Provider, v.PermanentUrl),
                            shareUrl: generateVideoUrl(
                                v.Provider || "",
                                v.PermanentUrl || ""
                            ),
                            created: displayDate(
                                v.CreatedAt || "",
                                i18n.language
                            )
                        };
                    });
                    sortFunc(recordings, true, "created");
                    setData(recordings);
                }
                dispatch(setLoading(-1));
            } catch (e) {
                rollbar.error("Error retrieving recording", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:recording-retrieval-error"
                    })
                );
                dispatch(setLoading(-1));
            }
        }

        if (hasAccess) run();
    }, [dispatch, setData, isMountedRef, t, i18n, userInfo, hasAccess]);

    function sortFunc(list, isSortDesc, sortKey) {
        const sorter = natsort({ desc: isSortDesc, insensitive: true });
        if (sortKey === "created") {
            return list.sort((a, b) =>
                sorter(a[sortKey] || "", b[sortKey] || "")
            );
        }

        return list.sort((a, b) => {
            let sortKeyA = !!a[sortKey] ? String(a[sortKey]) : "";
            let sortKeyB = !!b[sortKey] ? String(b[sortKey]) : "";
            return isSortDesc
                ? sortKeyB.localeCompare(sortKeyA)
                : sortKeyA.localeCompare(sortKeyB);
        });
    }

    const toggleSortDirection = useCallback(() => {
        setSortByDesc(!sortByDesc);
    }, [sortByDesc]);

    const onSortChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            setSortKey(e.target.value as SortKeyType);
            setData(sortFunc(data, sortByDesc, e.target.value));
        },
        [sortByDesc, data, setData]
    );

    const sort = useCallback(() => {
        setSortByDesc((prev) => !prev);
        setData(sortFunc(data, !sortByDesc, sortKey));
    }, [sortKey, sortByDesc, data, setData]);

    const share = useCallback(
        async (url: string) => {
            await navigator.clipboard.writeText(url);

            dispatch(
                addNotification({
                    type: NotificationType.Info,
                    message: "messages:copy",
                    messageOptions: { url }
                })
            );
        },
        [dispatch]
    );

    /** TODO: Sort by func: date, title (and maybe source?), asc and desc */

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col-6 col-lg-3">
                            <div className={`${styles["control-bar"]}`}>
                                <div
                                    className={`${styles["control-bar-controls"]} mb-2`}
                                >
                                    <div
                                        className={`${styles["control-selects"]} mb-2`}
                                    >
                                        <div
                                            className={`input-group mr-1 ${styles["select-group"]}`}
                                        >
                                            <div
                                                className="input-group-prepend"
                                                onClick={() =>
                                                    toggleSortDirection()
                                                }
                                            >
                                                <span
                                                    className={
                                                        styles[
                                                            "direction-button"
                                                        ]
                                                    }
                                                    id="icon"
                                                    onClick={sort}
                                                >
                                                    {/* TODO: Change this icon */}
                                                    {sortByDesc ? (
                                                        <DownIcon />
                                                    ) : (
                                                        <UpIcon />
                                                    )}
                                                </span>
                                            </div>
                                            <select
                                                id="sort-select"
                                                className={styles.select}
                                                value={sortKey}
                                                onChange={onSortChange}
                                            >
                                                {sortOpts.map(
                                                    ({ name, value }, idx) => {
                                                        return (
                                                            <option
                                                                key={idx}
                                                                value={value}
                                                            >
                                                                {name}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-2 order-2 order-lg-3">
                            <PagintationNav
                                currentPage={currentPage}
                                maxPage={maxPage}
                                next={next}
                                prev={prev}
                                jump={jump}
                            />
                        </div>
                    </div>
                    {!hasAccess ? (
                        <DisabledFeature />
                    ) : currentData().length === 0 ? (
                        <>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div
                                        className="alert alert-warning"
                                        role="alert"
                                    >
                                        <h5>{t("messages:no-livestreams")}</h5>
                                        {t("messages:no-livestreams-cont")}
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                            onClick={link}
                                        >
                                            <LinkIcon /> {t("buttons:link")}{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <CardGrid>
                            {currentData()?.map((r) => {
                                return (
                                    <RecordingCard key={r.id} recording={r}>
                                        <button
                                            type="button"
                                            className={`btn btn-primary mb-2`}
                                            onClick={() => share(r.shareUrl)}
                                        >
                                            {t("buttons:share")}
                                        </button>
                                    </RecordingCard>
                                );
                            })}
                        </CardGrid>
                    )}
                </div>
            </div>
        </>
    );
};

import {
    Broadcast,
    CreatorProductEntitlement,
    VideoPlayerCloudflareResponse
} from "@switcherstudio/switcher-api-client";
import { useTranslation } from "react-i18next";
import { VideoUploadModalEntryPoint } from "components/video-upload/VideoUploadModalEntryPoint";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useCallback, useMemo, useState } from "react";
import {
    BroadcastDetails,
    BroadcastDetailsProps
} from "../../../components/entity-details/BroadcastDetails";
import DownloadIcon from "assets/icons/download.svg?react";
import TrashIcon from "assets/icons/trash-sm.svg?react";
import styles from "./index.module.scss";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { AppDispatch } from "store/store";
import { AttentionModal } from "components/modal/AttentionModal";
import { closeCurrentModal, setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { getResultsFromPromiseAllSettled } from "helpers/arrays";
import { DownloadProps, useVideoDownload } from "hooks/useVideoDownload";
import { useGenericMultiSelect } from "components/generic-multiselect/useGenericMultiSelect";
import { ComponentItem } from "components/generic-multiselect";
import { v4 as uuidv4 } from "uuid";
import { Link } from "components/buttons/Link";
import { openExternalUrlInNewTab } from "helpers/navigation";
import { useUserStatus } from "hooks/useUserStatus";

export const VideoLibraryPage = () => {
    const [videoUploadSessionId, setVideoUploadSessionId] = useState<string>(
        uuidv4()
    );
    const [allBroadcasts, setAllBroadcasts] = useState<
        VideoPlayerCloudflareResponse[]
    >([]);

    const hasVideos = useMemo<boolean>(
        () => allBroadcasts.length !== 0,
        [allBroadcasts]
    );

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { handleDownloads } = useVideoDownload();
    const { userHasNoSubscription } = useUserStatus();
    const [uploadModalKey, setUploadModalKey] = useState(0);

    const { dispatchApiRequest: refetchVideos, loading } = useSwitcherClient(
        (client) => client.cloudRecordings_GetVideosForUserV2,
        {
            requestImmediately: true,
            onSuccess: (data) => {
                setAllBroadcasts(data);
                setUploadModalKey((prevKey) => prevKey + 1);
            }
        }
    );

    const { dispatchApiRequest: deleteBroadcast } = useSwitcherClient(
        (client) => client.broadcasts_DeleteBroadcastCloudflareVideos,
        {
            requestImmediately: false
        }
    );

    const deleteSingleBroadcast = useCallback(
        async (broadcast: Broadcast, refetch?: boolean) => {
            try {
                await deleteBroadcast([broadcast.Id]);
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: "messages:video-delete-success"
                    })
                );
                if (refetch) {
                    await refetchVideos();
                }
            } catch (e) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:videos-deleted", { count: 1 })
                    })
                );
            }
        },
        [deleteBroadcast, dispatch, refetchVideos, t]
    );

    const deleteManyBroadcasts = useCallback(
        async (broadcastDetailsProps: BroadcastDetailsProps[]) => {
            const promisesArray = broadcastDetailsProps.map((broadcastItem) =>
                deleteBroadcast([broadcastItem?.broadcast?.Id])
            );

            return Promise.allSettled(promisesArray).then(async (results) => {
                const { fulfilled, rejected } =
                    getResultsFromPromiseAllSettled<Broadcast>(results);

                if (rejected?.length) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t("errors:videos-deleted", {
                                count: rejected.length
                            })
                        })
                    );
                }

                if (fulfilled?.length) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: t("messages:videos-deleted-success", {
                                count: fulfilled.length
                            })
                        })
                    );

                    await refetchVideos();
                }
            });
        },
        [deleteBroadcast, dispatch, refetchVideos, t]
    );

    const closeAttentionModal = useCallback(() => {
        dispatch(closeCurrentModal());
    }, [dispatch]);

    /** A callback to be run once the user agrees to continue with deleting the video
     * from within the confirmation modal
     */
    const onAttentionModalContinue = useCallback(
        async (callback: (...args: any) => any) => {
            callback();
            closeAttentionModal();
        },
        [closeAttentionModal]
    );

    const handleDispatchAttentionModal = useCallback(
        (
            hasEntitlements: boolean = false,
            multiDelete: boolean = false,
            broadcast: Broadcast = null,
            broadcastDetailsProps: BroadcastDetailsProps[] = null
        ) => {
            const deleteConfirmationText = hasEntitlements
                ? t("video-library:delete-confirmation-entitlements")
                : t("video-library:delete-confirmation");
            dispatch(
                setActiveModal({
                    id: Modals.AttentionModal,
                    type: Modals.AttentionModal,
                    component: (
                        <AttentionModal
                            isOpen={true}
                            setIsOpen={closeAttentionModal}
                            handleCancel={closeAttentionModal}
                            hasContinueButton={true}
                            handleContinue={() =>
                                multiDelete
                                    ? onAttentionModalContinue(() =>
                                          deleteManyBroadcasts(
                                              broadcastDetailsProps
                                          )
                                      )
                                    : onAttentionModalContinue(() =>
                                          deleteSingleBroadcast(broadcast, true)
                                      )
                            }
                            continueText={t("buttons:delete")}
                        >
                            <>
                                <div className={styles["delete-confirmation"]}>
                                    {deleteConfirmationText}
                                </div>
                            </>
                        </AttentionModal>
                    )
                })
            );
        },
        [
            t,
            dispatch,
            closeAttentionModal,
            deleteManyBroadcasts,
            onAttentionModalContinue,
            deleteSingleBroadcast
        ]
    );

    /**
     * First checks broadcast to see if it can be deleted
     * without jeapordizing any existing purchases from the Creator's customers on the broadcast
     *
     * If it is safe, it calls the deleteSingleBroadcast function on the broadcast
     * If not safe, opens the attention modal to get user confirmation from Creator before deleting
     */
    const handleDelete = useCallback(
        async (
            broadcast: Broadcast,
            entitlements: CreatorProductEntitlement[]
        ) => {
            if (!entitlements.length) {
                handleDispatchAttentionModal(false, false, broadcast, null);
            } else {
                handleDispatchAttentionModal(true, false, broadcast, null);
            }
        },
        [handleDispatchAttentionModal]
    );

    /**
     * First checks the list of broadcasts to see if they can be deleted
     * without jeapordizing any existing purchases from the Creator's customers on the broadcast
     *
     * If it is safe, it calls the deleteManyBroadcasts function on the broadcasts
     * If not safe, opens the attention modal to get user confirmation from Creator before deleting
     */
    const handleDeleteMany = useCallback(
        async (broadcastDetailsProps: BroadcastDetailsProps[]) => {
            if (!broadcastDetailsProps?.length) return;
            let showDefaultDeleteConfirmation = true;
            for (let i = 0; i < broadcastDetailsProps.length; i++) {
                const { entitlements } = broadcastDetailsProps[i] ?? {};
                if (entitlements.length) {
                    showDefaultDeleteConfirmation = false;
                    break;
                }
            }

            if (showDefaultDeleteConfirmation) {
                handleDispatchAttentionModal(
                    false,
                    true,
                    null,
                    broadcastDetailsProps
                );
            } else {
                handleDispatchAttentionModal(
                    true,
                    true,
                    null,
                    broadcastDetailsProps
                );
            }
        },
        [handleDispatchAttentionModal]
    );

    const handleDownloadMany = useCallback(
        async (broadcastDetailsProps: BroadcastDetailsProps[]) => {
            handleDownloads(
                broadcastDetailsProps
                    .filter((bdp) => !!bdp.video)
                    .map((bdp) => {
                        return {
                            broadcastId: bdp.broadcast.Id,
                            videoId: bdp.video.uid,
                            title: bdp.broadcast.Title
                        } as DownloadProps;
                    })
            );
        },
        [handleDownloads]
    );

    const broadcastListItemComponentItems: ComponentItem<VideoPlayerCloudflareResponse>[] =
        useMemo(() => {
            if (!allBroadcasts.length) return [];

            return allBroadcasts.map((broadcast) => {
                const entitlements = [
                    ...broadcast.playlistBroadcastEntitlements,
                    ...broadcast.playerEntitlements
                ];

                return {
                    id: broadcast.broadcast.Id,
                    component: (
                        <BroadcastDetails
                            broadcast={broadcast.broadcast}
                            video={broadcast.videos?.[0]}
                            metrics={broadcast.entitySummary}
                            badges
                            players={broadcast.players}
                            entitlements={entitlements}
                            showPricing
                            showEdit
                            showDownload
                            allowVideoPlaybackOnThumbnailClick
                            location="video-library"
                            handleDeleteBroadcast={() => {
                                handleDelete(broadcast.broadcast, entitlements);
                            }}
                            onBroadcastUpdate={() => {
                                refetchVideos();
                            }}
                            disabled={broadcast.videos?.some((v) => !v)}
                        />
                    ),
                    baseObject: broadcast
                };
            });
        }, [allBroadcasts, handleDelete, refetchVideos]);

    const { GenericMultiSelectComponent } = useGenericMultiSelect<
        VideoPlayerCloudflareResponse,
        BroadcastDetailsProps
    >({
        isMultiple: true,
        checkBoxLocation: "left",
        allowItemClick: false,
        items: broadcastListItemComponentItems,
        previouslySelectedIds: useMemo(() => [], []), // passing just an array causes an infinite loop as it treats an empty array as a new object
        actionsBarOptions: {
            showActions: true,
            actions: [
                {
                    text: t("buttons:download"),
                    onClick: handleDownloadMany,
                    buttonType: "outline",
                    MobileIcon: DownloadIcon
                },
                {
                    text: t("buttons:delete"),
                    onClick: handleDeleteMany,
                    buttonType: "outline",
                    shouldDeselectOnClick: false,
                    MobileIcon: TrashIcon
                }
            ]
        },
        searchSortOptions: {
            showSearchSort: true,
            implementationType: "broadcast-search-sort",
            location: "video-library"
        }
    });

    return (
        <div className={styles["video-library-page"]}>
            {!userHasNoSubscription && (
                <div className={styles["upload-drag-and-drop-container"]}>
                    <VideoUploadModalEntryPoint
                        sessionId={videoUploadSessionId}
                        setSessionId={setVideoUploadSessionId}
                        key={uploadModalKey}
                        allowMultipleUploads
                        onUploadComplete={() =>
                            refetchVideos(null, { hideLoading: true })
                        }
                        isVideoLibrary={true}
                        showVideoStorageBar={hasVideos}
                    />
                </div>
            )}
            {hasVideos ? (
                <>{GenericMultiSelectComponent}</>
            ) : (
                !loading && (
                    <p>
                        {t("video-library:no-videos")}{" "}
                        <Link
                            onClick={() =>
                                openExternalUrlInNewTab(
                                    "https://itunes.apple.com/us/app/switcher-studio-pro/id908386221"
                                )
                            }
                        >
                            {t("video-library:download-app")}
                        </Link>
                    </p>
                )
            )}
        </div>
    );
};

import React from "react";
import { Entitlement } from "@switcherstudio/switcher-api-client";
import styles from "./PlanGrids.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "hooks/useNavigate";

interface EntitlementGridProps {
    entitlements: Entitlement[] | undefined;
    displayDateFn: (date?: string) => string | undefined;
    headerButtonFn: () => void;
}

export const NewEntitlementGrid: React.FC<EntitlementGridProps> = ({
    entitlements,
    headerButtonFn
}) => {
    const { t } = useTranslation();
    const { navigate } = useNavigate();

    const addTime = () => {
        return navigate(`/subscription/subscribe?hideSubscriptions=true`);
    };

    const isSubscriptionEntitlement = (e) =>
        e?.discriminator?.includes("Subscription");

    return (
        <>
            {entitlements && (
                <>
                    <div className={`${styles["plan-grid"]}`}>
                        {entitlements?.map((entitlement, idx) => {
                            return (
                                <div key={idx} className={styles["plan-card"]}>
                                    <div
                                        className={
                                            styles["plan-price-container"]
                                        }
                                    >
                                        <h5>
                                            {entitlement.LatestProduct.Name}
                                        </h5>
                                        <h6>
                                            {isSubscriptionEntitlement(
                                                entitlement
                                            )
                                                ? "Subscription"
                                                : t("subscription:pre-paid")}
                                        </h6>
                                    </div>

                                    <p>
                                        {entitlement.LatestProduct.Description}
                                    </p>
                                    {/* if type of entitlement is PurchaseEntitlement */}

                                    <div className={styles["action-buttons"]}>
                                        {isSubscriptionEntitlement(
                                            entitlement
                                        ) ? (
                                            ""
                                        ) : (
                                            // link to shopify to manage this account
                                            // <button
                                            //   type="button"
                                            //   className="btn btn-outline-primary mb-2 mr-2"
                                            // >
                                            //   Manage Account
                                            // </button>
                                            <>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={headerButtonFn}
                                                >
                                                    {t("buttons:change-plan")}
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary"
                                                    onClick={addTime}
                                                >
                                                    {t("subscription:add-time")}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
};

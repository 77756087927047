import { useEffect } from "react";
import { useCurrentRoute } from "hooks/useCurrentRoute";

export const useMatchPathCallback = (match: RegExp, callback: () => void) => {
    const { url } = useCurrentRoute();

    useEffect(() => {
        if (match.test(url.pathname)) {
            callback();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match, url]);
};

import { Data } from "navi-router";
import { Route as NaviRoute } from "navi";
import { useCurrentRoute as useNaviCurrentRoute } from "react-navi";
import { RouteMetaData } from "router/types";
import { useLocation, Location } from "react-router";
import { useTranslation } from "react-i18next";
import { isGuid } from "helpers/strings";

const useReactRouterDom = import.meta.env.VITE_USE_REACT_ROUTER_DOM === "true";

/**
 * An intermediate hook for the transition to react-router. When react-router is in place we can remove navi form this hook.
 */
export const useCurrentRoute = () => {
    const { t } = useTranslation("page-titles");

    const route: NaviRoute<Data> | null = (
        !useReactRouterDom ? useNaviCurrentRoute : (): null => null
    )();

    const location: Location<RouteMetaData> = (
        useReactRouterDom ? useLocation : (): null => null
    )();

    if (useReactRouterDom) {
        return {
            url: location,
            title: t(
                location.pathname
                    .split("/")
                    .reverse()
                    .find((p) => !isGuid(p)) || "home"
            ),
            state: {
                subheaderLink: undefined
            },
            data: {
                backNavigation: undefined,
                backNavigationText: undefined,
                badgeConfig: undefined,
                upgradeConfig: undefined
            }
        };
    } else {
        return route;
    }
};

// Shorten a string to less than maxLen characters without truncating words.
export function shorten(str: string, maxLen: number, separator = undefined) {
    if (!str || str?.length <= maxLen) return str;
    if (separator)
        return str.slice(0, str.lastIndexOf(separator, maxLen)) + "...";
    return str.slice(0, maxLen) + "...";
}

export function isGuid(node: string) {
    return node.match(
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
    );
}

export const isNullOrWhitespace = (str: string) => !str || !str.trim();

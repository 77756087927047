import { useSwitcherClient } from "hooks/useSwitcherClient";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { CreatePlayerCard } from "./CreatePlayerCard";
import { VideoPlayerCard } from "./VideoPlayerCard";
import styles from "./index.module.scss";
import { CreateOrUpdateSwitcherPlayerModal } from "components/modal/CreateOrUpdateSwitcherPlayerModal";
import { usePageHeader } from "hooks/usePageHeader";
import { useClaimCheck } from "hooks/useClaimCheck";
import classNames from "classnames/bind";
import { Banner } from "components/banners/Banner";
import { usePageBanner } from "hooks/usePageBanner";
import { useHasPlanRole } from "hooks/useHasPlanRole";
import { setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { ContactSalesModal } from "components/modal/ContactSalesModal";
import { useNavigate } from "hooks/useNavigate";
import { VideoPlayersResponse } from "@switcherstudio/switcher-api-client";
const cx = classNames.bind(styles);

export const PlayersPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const hasVideoPlayerAccess = useClaimCheck("videoplayer");
    const videoPlayerDisabled = useClaimCheck("videoplayer.disabled");
    const isStudioUser = useHasPlanRole("Studio");
    const { navigate } = useNavigate();

    usePageHeader({
        customButtons: hasVideoPlayerAccess && !videoPlayerDisabled && (
            <button
                className="btn btn-primary"
                onClick={() => setModalOpen(true)}
            >
                {t("players:create-player")}
            </button>
        )
    });
    const userState = useSelector((s: RootState) => s.user);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { loading, data: videoPlayersResponse } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_GetVideoPlayers,
        {
            requestImmediately: true,
            args: [userState?.userInfo?.ProjectId, true, true]
        }
    );

    const { updatePageBanner } = usePageBanner();

    /** Updated page banner with video player disabled banner */
    useEffect(() => {
        if (videoPlayerDisabled) {
            updatePageBanner(
                <Banner
                    header={t("players:messages:players-disabled-header")}
                    subheader={t("players:messages:players-disabled-subheader")}
                    buttonProps={{
                        type: "btn-outline-primary",
                        label: t("buttons:contact-sales"),
                        onClick: () => {
                            dispatch(
                                setActiveModal({
                                    id: Modals.ContactSalesModal,
                                    type: Modals.ContactSalesModal,
                                    component: <ContactSalesModal />
                                })
                            );
                        },
                        className: "upgrade-button"
                    }}
                    cssClass="switcher-player-studio-promo"
                />
            );
        } else if (isStudioUser) {
            updatePageBanner(
                <Banner
                    header={t("promotion:studio-switcher-player:demo")}
                    subheader={t("promotion:studio-switcher-player:upgrade")}
                    buttonProps={{
                        type: "btn-outline-primary",
                        label: t("buttons:contact-sales"),
                        onClick: () => {
                            dispatch(
                                setActiveModal({
                                    id: Modals.ContactSalesModal,
                                    type: Modals.ContactSalesModal,
                                    component: <ContactSalesModal />
                                })
                            );
                        },
                        className: "upgrade-button"
                    }}
                    cssClass="switcher-player-studio-promo"
                />
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoPlayerDisabled, isStudioUser, t]);

    if (loading) return null;

    return (
        <div
            className={cx("players-container", {
                "player-disabled": videoPlayerDisabled
            })}
        >
            {videoPlayersResponse?.map((vpr) => (
                <VideoPlayerCard {...vpr} key={vpr.VideoPlayer.Id} />
            ))}
            {hasVideoPlayerAccess && !videoPlayerDisabled && (
                <CreatePlayerCard onClick={() => setModalOpen(true)} />
            )}

            <CreateOrUpdateSwitcherPlayerModal
                isOpen={modalOpen}
                setIsOpen={setModalOpen}
                type="create"
                onSubmit={(vpr: VideoPlayersResponse) => {
                    navigate(`/switcher-player/${vpr?.VideoPlayers?.[0]?.Id}`);
                }}
            />
        </div>
    );
};

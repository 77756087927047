import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { Link } from "react-navi";
import { client } from "api/client";
import {
    Broadcast,
    ProjectBroadcastDefault,
    BroadcastStatus
} from "@switcherstudio/switcher-api-client";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import styles from "./SimulcastExistingBroadcast.module.scss";
import { SimulcastExistingTable } from "./SimulcastExistingTable";
import rollbar from "helpers/rollbar";
import { openConfirmation } from "store/confirmation/slice";
import dayjs from "dayjs";
import { useSimulcastFork } from "hooks/useSimulcastFork";

export const SimulcastExistingBroadcasts: React.FC = () => {
    // display existing streams
    // show currently selected stream - if any.
    // user should be able to click an existing stream to 'set as default'
    const user = useSelector((state: RootState) => state.user);
    const { simulcastType, t } = useSimulcastFork();
    const dispatch = useDispatch<AppDispatch>();
    const [scheduledBroacasts, setScheduledBroadcasts] = useState<Broadcast[]>(
        []
    );
    const [liveNowBroadcasts, setLiveNowBroadcasts] = useState<Broadcast[]>([]);
    const [defaultBroadcast, setDefaultBroadcast] =
        useState<ProjectBroadcastDefault>();
    const projectId = user?.userInfo?.ProjectId;

    const parseStreams = (_existingStreams) => {
        const scheduledBcasts = [];
        const liveNowBcasts = [];

        _existingStreams.forEach((s) => {
            if (dayjs(s.StartsAt).isAfter(dayjs().subtract(30, "minute"))) {
                scheduledBcasts.push(s);
            } else if (s.StartsAt === null) {
                liveNowBcasts.push(s);
            }
        });

        return {
            scheduledBcasts,
            liveNowBcasts
        };
    };

    const getBroadcastInfo = useCallback(async () => {
        if (!projectId) return;
        try {
            const _existingStreams =
                await client.projects_GetProjectBroadcastsByFilter(
                    projectId,
                    "simulcast",
                    BroadcastStatus._1
                );
            const _defaultBroadcast =
                await client.projects_GetDefaultBroadcast(projectId);
            const { scheduledBcasts, liveNowBcasts } =
                parseStreams(_existingStreams);

            setScheduledBroadcasts(scheduledBcasts);
            setLiveNowBroadcasts(liveNowBcasts);
            setDefaultBroadcast(_defaultBroadcast);
        } catch (err) {
            rollbar.error("Error getting simulcast broadcast info", err);
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: err?.message
                })
            );
        }
    }, [dispatch, projectId]);

    useEffect(() => {
        getBroadcastInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setNewDefault = async (bcastId) => {
        await client.projects_PostDefaultBroadcast(projectId, bcastId);
        const _defaultBroadcast =
            await client.projects_GetDefaultBroadcast(projectId);
        setDefaultBroadcast(_defaultBroadcast);

        dispatch(
            addNotification({
                type: NotificationType.Success,
                message: `${simulcastType}:default-broadcast-selected`
            })
        );
    };

    const triggerDelete = useCallback(
        async (broadcastId) => {
            dispatch(
                openConfirmation({
                    message: `messages:delete-${simulcastType}-confirmation`,
                    onSuccess: async () => {
                        try {
                            await client.broadcasts_DeleteBroadcast(
                                broadcastId
                            );

                            getBroadcastInfo();

                            dispatch(
                                addNotification({
                                    type: NotificationType.Success,
                                    message: `messages:delete-${simulcastType}-success`
                                })
                            );
                        } catch (err) {
                            rollbar.error("Error deleting multistream", err);
                            dispatch(
                                addNotification({
                                    type: NotificationType.Danger,
                                    message: `errors:delete-${simulcastType}-error`,
                                    messageOptions: { rawError: err?.message }
                                })
                            );
                        }
                    }
                })
            );
        },
        [dispatch, getBroadcastInfo, simulcastType]
    );

    return (
        <div className={styles["container"]}>
            <Link
                className={`btn btn-primary ${styles["create-simulcast-btn"]}`}
                href="/platforms/simulcast/destinations"
            >
                {t("create-new")}
            </Link>
            <div>
                <h2>{t("existing-streams")}</h2>
                {!!liveNowBroadcasts?.length || !!scheduledBroacasts?.length ? (
                    <p>{t("default-stream-select")}</p>
                ) : (
                    <p>
                        {t("empty-streams")}{" "}
                        <Link href="/platforms/simulcast/destinations">
                            {t("create-new")}
                        </Link>{" "}
                        {t("to-get-started")}
                    </p>
                )}

                {/* LIVE NOW EVENTS */}

                {!!liveNowBroadcasts?.length && (
                    <>
                        <h4>{t("live-now-streams")}</h4>
                        <SimulcastExistingTable
                            existingStreams={liveNowBroadcasts}
                            defaultBroadcastId={defaultBroadcast?.BroadcastId}
                            onChange={setNewDefault}
                            triggerDelete={triggerDelete}
                        />
                    </>
                )}

                {/* UPCOMING EVENTS */}
                {!!scheduledBroacasts?.length && (
                    <>
                        <h4>{t("scheduled-streams")}</h4>
                        <SimulcastExistingTable
                            existingStreams={scheduledBroacasts}
                            defaultBroadcastId={defaultBroadcast?.BroadcastId}
                            onChange={setNewDefault}
                            triggerDelete={triggerDelete}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

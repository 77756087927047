import React, { useMemo } from "react";
// import commonStyles from "./CommonStyles.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-navi";
import { Widget } from "./Widget";
import VideoLibraryIcon from "assets/icons/video-library.svg?react";
import { ProgressBar } from "components/widgets/progress-bar/ProgressBar";
import { useClaimCheck } from "hooks/useClaimCheck";
import { CloudRecordingUsageResponse } from "@switcherstudio/switcher-api-client";

export interface VideoLibraryWidgetProps {
    loading: boolean;
    cloudUsage: CloudRecordingUsageResponse;
}

export const VideoLibraryWidget = ({
    loading,
    cloudUsage
}: VideoLibraryWidgetProps) => {
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");

    const cloudUsagePercentageAsDecimal =
        cloudUsage?.TotalRecordings / cloudUsage?.MaxRecordings;

    const variant = useMemo(() => {
        if (cloudUsagePercentageAsDecimal >= 1) {
            return "thin-video-storage-max";
        }
        if (cloudUsagePercentageAsDecimal >= 0.8) {
            return "thin-video-storage-medium";
        }
        return "thin-video-storage";
    }, [cloudUsagePercentageAsDecimal]);

    return (
        <Widget
            loading={loading}
            icon={<VideoLibraryIcon />}
            title={t("widgets:video-library-title").toUpperCase()}
            details={[
                {
                    title: t("widgets:videos-uploaded"),
                    children: (
                        <ProgressBar
                            currentValue={cloudUsage?.TotalRecordings}
                            maxValue={cloudUsage?.MaxRecordings}
                            variant={variant}
                        />
                    )
                }
            ]}
            footerSubtitle={
                (cloudUsage?.TotalRecordings ?? 0) > 0
                    ? hasCatalogClaim
                        ? t("widgets:upload-catalog")
                        : t("widgets:upload-CTA")
                    : t("widget:upload-get-started")
            }
            button={
                <Link className="btn" href={`/video-library`}>
                    {t("widgets:upload-video")}
                </Link>
            }
        ></Widget>
    );
};

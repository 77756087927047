import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { Link } from "react-navi";
import { useTranslation } from "react-i18next";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import {
    Entitlement,
    SilverSunnStripeSubscription
} from "@switcherstudio/switcher-api-client";
import { StripeStatus } from "views/page-content/platforms/types";
import { isBusinessPlan } from "helpers/stripe";
import { useHasPlanRole } from "hooks/useHasPlanRole";

export const UpgradePlanAlert = ({
    entitlements,
    subscriptions
}: {
    entitlements?: Entitlement[];
    subscriptions?: SilverSunnStripeSubscription[];
}) => {
    const { userInfo } = useSelector((s: RootState) => s.user);
    const hasStudioPlan = useHasPlanRole("Studio");
    const hasNoActiveSubscription = useMemo(
        () => !subscriptions.find((s) => s.Status === StripeStatus.Active),
        [subscriptions]
    );
    const hasOneTimePlan = useMemo(
        () =>
            hasNoActiveSubscription &&
            entitlements?.some(
                (entitlement) =>
                    entitlement.discriminator === "PurchaseEntitlement"
            ),
        [entitlements, hasNoActiveSubscription]
    );
    const { t } = useTranslation();
    const { data: prices, loading } = useSwitcherClient(
        (client) => client.userStripePrices_GetStripePrices,
        { args: [userInfo.UserId], requestImmediately: true }
    );

    const businessPlanDescription = useMemo(() => {
        if (prices) {
            const business = prices.find((price) => isBusinessPlan(price));
            return business.Product.Description;
        }
    }, [prices]);

    return (
        (hasStudioPlan || hasOneTimePlan) &&
        !loading && (
            <div className="alert alert-info" role="alert">
                <strong>{`${t("buttons:upgrade-business")}: `}</strong>

                {businessPlanDescription}

                <Link href="/subscription/subscribe">
                    {` ${t("buttons:upgrade-now")}`}
                </Link>
            </div>
        )
    );
};

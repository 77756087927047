import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { client } from "api/client";
import { AppDispatch } from "store/store";
import { Link } from "react-navi";
import { useTranslation } from "react-i18next";
import { DestinationSelector } from "components/destination-selector/DestinationSelector";
import { setLoading } from "store/loading/slice";
import rollbar from "helpers/rollbar";
import { updateGeneralForm } from "store/platforms/slice";
import { DestinationDescriptor } from "../../types";
import { useNavigate } from "hooks/useNavigate";

interface SimulcastRtmpDestinationSelectorProps {
    disableAll?: boolean;
}

export const SimulcastRtmpDestinationSelector: React.FC<
    SimulcastRtmpDestinationSelectorProps
> = ({ disableAll }) => {
    const { navigate } = useNavigate();

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { generalForm } = useSelector(
        (state: RootState) => state.platforms.general
    );
    const [destinations, setDestinations] = useState([]);
    const onClick = async (destination: { id: string }) => {
        if (
            generalForm.selectedSimulcastDestinations.some(
                (d) => d.id === destination["switcher-stream-setting-id"]
            )
        ) {
            return;
        }
        const descrip: DestinationDescriptor = {
            name: destination["channel-name"],
            id: destination["switcher-stream-setting-id"],
            platform: "custom"
        };
        dispatch(
            updateGeneralForm({
                selectedSimulcastDestinations: [
                    ...generalForm.selectedSimulcastDestinations,
                    descrip
                ]
            })
        );
        // appendParamArray("destination[]", JSON.stringify(descrip));
        const path = `/platforms/simulcast/destinations`;
        navigate(path);
    };

    useEffect(() => {
        async function getRtmpInfo() {
            try {
                dispatch(setLoading(1));
                const customChannelRes =
                    await client.switcherStreamSettings_GetAllSwitcherStudioSettings();
                const rtmpChannels = customChannelRes.filter(
                    (a) => a["user-editable"]
                );

                setDestinations(rtmpChannels);
                dispatch(setLoading(-1));
            } catch (e) {
                dispatch(setLoading(-1));
                rollbar.error(
                    "Error getting and setting simulcast rtmp destinations",
                    e
                );
            }
        }

        getRtmpInfo();
    }, [dispatch, navigate]);

    return (
        <div>
            <DestinationSelector
                destinations={destinations}
                imgSrcKey={""}
                destinationNameKey="channel-name"
                onDestinationClick={onClick}
                isSimulcast={true}
                disableAll={disableAll}
            />
            <Link
                className={`btn btn-primary mt-4`}
                href="/platforms/simulcast/destinations/custom/connect"
            >
                {t("platforms:custom-rtmp-setup")}
            </Link>
        </div>
    );
};

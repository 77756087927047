/**
 * Checks a string for true or false values. If string is neither, then returns undefined
 * @param value to be checked
 */
export const parseBool = (value: string): boolean | undefined => {
    if (value === "true") {
        return true;
    } else if (value === "false") {
        return false;
    } else {
        return undefined;
    }
};

export const exists = (...values: any[]) => !values.some((v) => !v);

export const existsWithValues = (array: any[]) => !!array?.length;

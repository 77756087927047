import React, { SetStateAction, Dispatch } from "react";
import { ModalBase } from "components/modal/ModalBase";
import styles from "./SimulcastModal.module.scss";
import Checkmark from "assets/icons/checkmark-thin.svg?react";
import X from "assets/icons/close.svg?react";
import FacebookIcon from "assets/icons/facebook.svg?react";
import YouTubeIcon from "assets/icons/youtube.svg?react";
import TwitchIcon from "assets/icons/twitch.svg?react";
import SwitcherPlayerIcon from "assets/icons/switcher-player.svg?react";
import { PlatformId } from "../../../types";
import { CompleteRequest } from "../SimulcastForm";
import { useTranslation } from "react-i18next";
import { useSimulcastFork } from "hooks/useSimulcastFork";

interface SimulcastModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    successButton?: string;
    dismissButton?: string;
    onSuccess?: (...args: any) => any;
    requests?: CompleteRequest[];
    message?: string;
    isSuccess?: boolean;
}

const getKeyFromValue = function (value: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(PlatformId).find(([key, val]) => val === value)?.[0];
};

export const SimulcastModal: React.FC<SimulcastModalProps> = ({
    isOpen,
    setIsOpen,
    successButton,
    onSuccess,
    requests,
    message,
    isSuccess
}) => {
    const { t } = useTranslation();
    const { simulcastType } = useSimulcastFork();

    const getIcon = (platform: PlatformId | "custom") => {
        if (platform === PlatformId.Facebook) {
            return <FacebookIcon />;
        } else if (platform === PlatformId.Youtube) {
            return <YouTubeIcon />;
        } else if (platform === PlatformId.Twitch) {
            return <TwitchIcon />;
        } else if (platform === PlatformId.VideoPlayer) {
            return <SwitcherPlayerIcon />;
        } else {
            return "";
        }
    };

    return (
        <ModalBase
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            successButton={successButton}
            onSuccess={onSuccess}
            preventDismiss={true}
        >
            <div className={styles["container"]}>
                <div
                    className={`styles["message"] alert ${
                        isSuccess ? "alert-success" : "alert-danger"
                    }`}
                >
                    {message}
                </div>

                {requests
                    .filter((r) => r.status === "rejected")
                    .map((r, i) => (
                        <div key={i} className={styles["request-container"]}>
                            <div
                                className={`${styles["simulcast-request"]} ${styles["failed"]}`}
                            >
                                <div>
                                    <span className={styles["dest-name"]}>
                                        {getIcon(r.destination.platform)}
                                    </span>
                                    <span>
                                        <b>{`${r.destination.name.trim()} `}</b>
                                    </span>
                                </div>{" "}
                                <span className={styles["break"]}></span>
                                <div className={styles["status"]}>
                                    <span className={styles["icon-container"]}>
                                        <X />
                                    </span>
                                    <strong>{`Failed`}</strong>
                                </div>
                            </div>
                            <div className={styles["reason"]}>
                                <p>
                                    <strong>{`Failed `}</strong>
                                    <span>
                                        {t("errors:from-platform", {
                                            platform:
                                                getKeyFromValue(
                                                    r.destination.platform
                                                ) || r.destination.name
                                        })}
                                    </span>
                                    <span>
                                        {!!r?.reason?.message ? ": " : " "}
                                    </span>
                                    <br />
                                    {r.destination.platform !== "custom"
                                        ? r?.reason?.message
                                        : t(
                                              `errors:${simulcastType}-rtmp-error`
                                          )}
                                </p>
                            </div>
                        </div>
                    ))}

                {requests
                    .filter((r) => r.status === "fulfilled")
                    .map((r, i) => (
                        <div key={i} className={styles["request-container"]}>
                            <div
                                className={`${styles["simulcast-request"]} ${styles["successful"]}`}
                            >
                                <div className={styles["dest-name"]}>
                                    {getIcon(r.destination.platform)}

                                    <b>{`${r.destination.name.trim()} `}</b>
                                </div>{" "}
                                <span className={styles["break"]}></span>
                                <div className={styles["status"]}>
                                    <span className={styles["icon-container"]}>
                                        <Checkmark />
                                    </span>
                                    <strong>{`Success`}</strong>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </ModalBase>
    );
};

import dayjs, { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import esLocale from "dayjs/locale/es";
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

export const format = (
    date: string | number | Date | Dayjs,
    format: string
) => {
    return dayjs(date).format(format);
};

export const minutesToHours = (minutes: number) => {
    return Math.floor(minutes / 60);
};

export const minutesToSeconds = (minutes: number) => {
    return minutes * 60;
};

export const compareMinutes = function (date, now, minuteOffset) {
    if (now.getMinutes() === date.getMinutes()) return false;

    const diff = now.getTime() - date.getTime();
    const diffMinutes = Math.abs(Math.floor(diff / 1000 / 60));

    return diffMinutes < minuteOffset;
};

export const areMatchingDates = function (firstDate: Date, secondDate: Date) {
    return (
        firstDate.getFullYear() === secondDate.getFullYear() &&
        firstDate.getMonth() === secondDate.getMonth() &&
        firstDate.getDate() === secondDate.getDate()
    );
};

export const formatTimeDuration = (
    seconds: number,
    format: string = "HH:mm:ss"
): string => {
    return dayjs.duration(seconds, "seconds").format(format).toString();
};

/** Return true if the given date is within one month from today.
 * If no "day" value is given, default to "01". */
export function expiresSoon(year?: string, month?: string, day?: string) {
    if (!year || !month || year === "0" || month === "0") {
        return false;
    }

    const expireMonth = dayjs(`${year}-${month}- ${day || "01"}`);
    const today = dayjs();
    const diff = expireMonth.diff(today, "day");
    return diff <= 30 && diff >= -31;
}

export const displayDate = (
    date?: string | Date,
    locale?: string,
    format?: string
) => {
    if (!date) {
        return "";
    }
    // eslint-disable-next-line
    const locales = {
        // locales don't work if we don't do this. yes really
        es: esLocale
    };
    if (locale)
        return dayjs(date)
            .locale(locale)
            .format(format ?? "ddd, MMM D, YYYY");
    return dayjs(date).format(format ?? "ddd, MMM D, YYYY");
};

/**
 * Determines if a given date is in the past.
 *
 * @param {string} date - The date to check, in string format.
 * @returns {boolean} - True if the date is in the past, false otherwise.
 */
export const isInPast = (date: string): boolean => {
    if (date) {
        return dayjs.utc(date).isBefore(dayjs.utc());
    }
    return false;
};

/**
 * Determines if a given date is in the future.
 *
 * @param {string} date - The date to check, in string format.
 * @returns {boolean} - True if the date is in the future, false otherwise.
 */
export const isInFuture = (date: string): boolean => {
    if (date) {
        return dayjs.utc(date).isAfter(dayjs.utc());
    }
    return false;
};

/**
 * Determines if a given date is the same as the current day.
 *
 * @param {string} date - The date to check, in string format.
 * @returns {boolean} - True if the date is the same as the current day, false otherwise.
 */
export const isDayOf = (date: string): boolean => {
    if (date) {
        return dayjs().startOf("day").isSame(dayjs(date).startOf("day"));
    }
};

/**
 * Converts a given date to the local time zone and formats it.
 *
 * @param {string} date - The date to convert, in string format.
 * @param {string} format - The format to use for the output date string.
 * @returns {string} - The date string in the local time zone and the specified format.
 */
export const localizeTime = (date: string, format: string): string => {
    if (date) {
        return dayjs(date)
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format(format);
    }
};

/**
 * gets the numeric value for the last month, taking December into account
 * @param Date the date object to get the last month of
 * @returns number
 */
export function getLastMonth(date: Date) {
    return date.getUTCMonth() ? date.getUTCMonth() - 1 : 11;
}

export const differenceInCalendarDays = (
    dateLeft: Date | number,
    dateRight: Date | number,
    /**include last day in difference */
    inclusive = false
): number => {
    return dayjs(dateLeft).diff(dayjs(dateRight), "day") + (inclusive ? 1 : 0);
};

export const getMaxScheduleDate = (platformId: string) => {
    const today = dayjs();

    return (function () {
        switch (platformId) {
            case "facebook":
                return today.add(7, "day");
            case "youtube":
                return today.add(1, "year");
            case "simulcast":
                return today.add(7, "day");
            default:
                return today.add(1, "year");
        }
    })().toDate();
};

export interface SortByDateOptions {
    descending: boolean;
}

export const sortByDate = (
    a: string | number | Date | dayjs.Dayjs,
    b: string | number | Date | dayjs.Dayjs,
    { descending }: SortByDateOptions = { descending: false }
) => {
    if (descending) {
        return dayjs(a).isBefore(dayjs(b)) ? 1 : -1;
    } else {
        return dayjs(a).isAfter(dayjs(b)) ? 1 : -1;
    }
};

export const formatMonthDateDigits = (digits: string) => {
    if (Number(digits) < 10) return `0${digits}`;
    return digits;
};

/** Creates a Date object at UTC time */
export const getUTCDate = (date?: string | number | Date): Date => {
    if (!date) {
        date = new Date();
    }
    return dayjs(date)
        .add(new Date(date).getTimezoneOffset(), "minutes")
        .toDate(); // Update this with the Temporal API, when it's ready. This is so dumb.
};

/**
 * Sets a Date object to the start of it's given day
 * @param date Date to extract from
 * @returns Date object with a time of 00:00
 */
export const setToStartOfDay = (date: Date | string) => {
    if (typeof date === "string") {
        date = new Date(date);
    }
    date.setHours(0, 0, 0, 0);
    return date;
};

/**
 * Sets a Date object to the end of it's given day
 * @param date Date to extract from
 * @returns Date object with a time of 23:59
 */
export const setToEndOfDay = (date: Date | string) => {
    if (typeof date === "string") {
        date = new Date(date);
    }
    date.setHours(23, 59, 59, 999);
    return date;
};

export interface AuthorizePageProps {
    credentials: AuthCredentials;
    code: string;
    error?: boolean;
    shop?: string;
}

export interface AuthCredentials {
    providerId?: ProviderId;
    userId?: string;
    userToken?: string;
    type?: string;
    finalUrl: string;
}

export interface AuthorizationState {
    state: AuthorizationStateType;
}

type AuthorizationStateType = "pending" | "success" | "error";

export enum ProviderId {
    Facebook = "facebook",
    Twitch = "twitch",
    Youtube = "youtube",
    Shopify = "shopify",
    StripeConnect = "stripe-connect"
}

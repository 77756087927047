import React, { useState, useCallback, useRef } from "react";
import { StripeInvoiceBindingModel } from "@switcherstudio/switcher-api-client";
import FilterIcon from "assets/icons/filter.svg?react";
import DownIcon from "assets/icons/down.svg?react";
import styles from "./SubscriptionPage.module.scss";
import { useTranslation } from "react-i18next";
import useClickOutside from "hooks/useClickOutside";

import { TimeFrameFilterDropdown } from "./TimeFrameFilterDropdown";
import dayjs from "dayjs";

interface InvoiceTableProps {
    invoices: StripeInvoiceBindingModel[] | undefined;
    displayDateFn: (date?: string) => string | undefined;
    displayCostFn: (amount?: number) => string | undefined;
    startDate: Date;
    endDate: Date;
    setStartDate: (date: Date) => void;
    setEndDate: (date: Date) => void;
    timeFrameOptions: string[];
}

export const InvoiceTable: React.FC<InvoiceTableProps> = ({
    invoices,
    displayDateFn,
    displayCostFn,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    timeFrameOptions
}) => {
    const { t } = useTranslation();
    const timeFrameDropdownRef = useRef(null);
    const statusDropdownRef = useRef(null);
    useClickOutside(timeFrameDropdownRef, closeTimeFrameDropdown);
    useClickOutside(statusDropdownRef, closeStatusDropdown);
    const [showTimeFrameFilterDropdown, setShowTimeFrameFilterDropdown] =
        useState<boolean>(false);
    const [showStatusFilterDropdown, setShowStatusFilterDropdown] =
        useState<boolean>(false);
    const [selectedTimeFrame, setSelectedTimeFrame] = useState<number>(0);

    const handleSetSelectedTimeFrame = useCallback(
        (timeFrame: string) => {
            const currentDate = new Date();

            const timeFrameIndex = timeFrameOptions.findIndex(
                (value: string) => value === timeFrame
            );
            setSelectedTimeFrame(timeFrameIndex);

            switch (timeFrameIndex) {
                case 0: // Last 12 Months
                    setStartDate(dayjs().subtract(1, "years").toDate());
                    setEndDate(currentDate);
                    break;

                case 1: // Year to Date
                    setStartDate(dayjs(currentDate).startOf("year").toDate());
                    setEndDate(currentDate);
                    break;

                case 2: // Previous Year
                    const lastYear = currentDate.setFullYear(
                        currentDate.getFullYear() - 1
                    );
                    setStartDate(dayjs(lastYear).startOf("year").toDate());
                    setEndDate(dayjs(lastYear).endOf("year").toDate());
                    break;
            }
        },
        [setSelectedTimeFrame, timeFrameOptions, setStartDate, setEndDate]
    );

    const handleShowTimeFrameFilterDropdownClick = useCallback(() => {
        if (showStatusFilterDropdown) {
            setShowStatusFilterDropdown(false);
        }
        if (!showTimeFrameFilterDropdown) {
            setShowTimeFrameFilterDropdown(true);
        }
    }, [showTimeFrameFilterDropdown, showStatusFilterDropdown]);

    function closeTimeFrameDropdown() {
        if (showTimeFrameFilterDropdown) {
            setShowTimeFrameFilterDropdown(false);
        }
    }

    function closeStatusDropdown() {
        if (showStatusFilterDropdown) {
            setShowStatusFilterDropdown(false);
        }
    }

    function invoiceIsCredit(invoice: StripeInvoiceBindingModel) {
        const displayedPrice =
            invoice.applied_balance < 0 ? invoice.amount_due : invoice.total;

        return displayedPrice >= 0 ? false : true;
    }

    const shouldShowInvoice = useCallback(
        (invoice: StripeInvoiceBindingModel): boolean => {
            const invoiceDate = new Date(invoice.date);
            return startDate <= invoiceDate && invoiceDate <= endDate;
        },
        [startDate, endDate]
    );

    return (
        <div className={styles["invoice-table"]}>
            {invoices && (
                <>
                    {!invoices || invoices.length === 0 ? (
                        <div className="alert alert-info" role="alert">
                            {t("messages:no-invoices")}
                        </div>
                    ) : null}
                    <div className={styles["invoice-header"]}>
                        <h4>{t("subscription:invoice-history")}</h4>
                        <span>
                            <strong>
                                <FilterIcon />
                                {t("buttons:filter-by")}:
                            </strong>
                            <button
                                className="btn btn-primary"
                                onMouseDown={
                                    handleShowTimeFrameFilterDropdownClick
                                }
                                onFocus={() =>
                                    setShowTimeFrameFilterDropdown(true)
                                }
                                // onBlur={() => setShowTimeFrameFilterDropdown(false)} - there should be some way to move on and hide dropdown
                            >
                                {t("subscription:date")}{" "}
                                <DownIcon className={styles["down-icon"]} />
                            </button>
                        </span>
                    </div>
                    <div ref={timeFrameDropdownRef}>
                        {showTimeFrameFilterDropdown && (
                            <TimeFrameFilterDropdown
                                selectedTimeFrame={selectedTimeFrame}
                                setSelectedTimeFrame={
                                    handleSetSelectedTimeFrame
                                }
                                startDate={startDate}
                                timeFrameOptions={timeFrameOptions}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                closeDropdown={closeTimeFrameDropdown}
                            />
                        )}
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped table-sm">
                            <thead>
                                <tr>
                                    <th>{t("subscription:invoice-date")}</th>
                                    <th>{t("subscription:total")}</th>
                                    <th>{t("subscription:status")}</th>
                                    <th>{t("misc:action")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoices?.map((invoice, idx) => {
                                    return (
                                        shouldShowInvoice(invoice) && (
                                            <tr key={idx}>
                                                <td>
                                                    {displayDateFn(
                                                        invoice.date
                                                    )}
                                                </td>
                                                <td>
                                                    {displayCostFn(
                                                        invoice.applied_balance <
                                                            0
                                                            ? invoice.amount_due
                                                            : invoice.total
                                                    )}
                                                </td>
                                                <td>
                                                    {invoiceIsCredit(invoice)
                                                        ? t(
                                                              "subscription:credit"
                                                          )
                                                        : t(
                                                              "subscription:paid"
                                                          )}
                                                </td>
                                                <td>
                                                    {invoice.paid && (
                                                        <a
                                                            className={`${styles["table-action"]}`}
                                                            href={
                                                                invoice.hosted_invoice_url
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {t("messages:view")}
                                                        </a>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
};

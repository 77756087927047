import { client as switcherClient } from "api/client";
import { trackEvent } from "helpers/analyticsHelpers";
import { userHasClaim } from "helpers/userHelper";
import { BlockBlobClient, AnonymousCredential } from "@azure/storage-blob";
import {
    FacebookDestination,
    FacebookForm,
    GeneralPlatformForm,
    FacebookProfile
} from "store/platforms/types";
import store from "store/store";
import fb, { parseRtmpString } from "./facebook-helper";
import { switcherSdk } from "utils/switcher-sdk";
import { mapBodyToForm } from "helpers/utils";
import { ScheduledOptions } from "views/page-content/platforms/platform/forms/types";

// TODO: make dynamic?
const dashRelayUrl = "https://dash-relay.switcher-studio.workers.dev";

async function uploadToTempStorage(file: File, url: string) {
    try {
        const blobServiceClient = new BlockBlobClient(
            url,
            new AnonymousCredential()
        );
        await blobServiceClient.uploadBrowserData(file);
    } catch (e) {
        console.error(e);
    }
}

export const facebook = {
    accessToken: {
        get: (id) =>
            fb({
                edge: id,
                fields: {
                    fields: "access_token"
                },
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            })
    },
    contentTags: {
        get: (searchTerm) =>
            fb({
                edge: "search",
                fields: {
                    q: searchTerm,
                    type: "adinterest"
                },
                enableLoading: false
            })
    },
    crossPostOptions: {
        get: (id) =>
            fb({
                edge: `${id}/crosspost_whitelisted_pages`,
                fields: {
                    fields: "allows_live_crossposts,name",
                    limit: 100
                }
            })
    },
    destinations: {
        get: (options) =>
            fb({
                edge: `me/${options.edge}`,
                fields: {
                    fields: options.fields
                        ? options.fields
                        : "picture.type(large),id,name,administrator",
                    limit: options.limit ? options.limit : 100
                },
                fbApiVersion: options.edge === "accounts" ? "v16.0" : undefined
            })
    },
    pages: {
        search: (searchTerm) =>
            fb({
                edge: "pages/search",
                fields: {
                    q: searchTerm,
                    fields: "id,name,verification_status,location,link,is_unclaimed,is_eligible_for_branded_content"
                }
            })
    },
    permissions: {
        get: () =>
            fb({
                edge: "me/permissions"
            })
    },
    stream: {
        create: (edge, body) =>
            fb({
                edge: `${edge}/live_videos`,
                method: "POST",
                body,
                fields: {
                    fields: "permalink_url,stream_url,secure_stream_url,dash_ingest_url"
                }
            }),
        delete: (vidId) =>
            fb({
                edge: vidId.toString(),
                method: "DELETE"
            }),
        getOptimal: (postOptionItemId, videoId, edgeType, token) =>
            fb({
                headers: {
                    "X-SS-Facebook-Edge-Type":
                        edgeType === "accounts" ? "1" : null,
                    "X-SS-Facebook-Edge-Id":
                        edgeType === "accounts" ? postOptionItemId : null
                },
                edge: videoId,
                fields: {
                    target_token: token,
                    fields: "secure_stream_url"
                }
            }),
        getScheduledForTimeline: (edge) =>
            fb({
                edge,
                fields: {
                    fields: `live_videos.limit(100).source(owner).broadcast_status(["LIVE","SCHEDULED_UNPUBLISHED"]){ broadcast_start_time,description,title,id,stream_url,status,secure_stream_url,permalink_url,dash_ingest_url }`
                }
            }),
        getScheduledForPages: (edge) =>
            fb({
                edge,
                fields: {
                    fields: `is_eligible_for_branded_content,verification_status,fan_count,category,live_videos.limit(100).source(owner).broadcast_status(["LIVE","SCHEDULED_UNPUBLISHED"]){ broadcast_start_time,description,title,id,stream_url,status,secure_stream_url,from,permalink_url,dash_ingest_url }`
                }
            }),
        link: (id, body, access_token) =>
            fb({
                edge: id,
                method: "POST",
                body,
                fields: {
                    fields: { access_token }
                },
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            }),
        update: (streamId, fields, body) =>
            fb({
                edge: streamId,
                body,
                fields,
                method: "POST"
            })
    },
    userProfileInfo: {
        get: () =>
            fb({
                edge: "me",
                fields: {
                    fields: "id,name,picture.type(large)"
                }
            })
    },
    helpers: {
        handleCreation: async (
            dest: FacebookProfile,
            form: FacebookForm,
            broadcastId?: string,
            isSimulcast?: boolean
        ) => {
            const platforms = store.getState().platforms;
            const { general } = platforms;
            const selectedDestination = dest;
            const edge = selectedDestination.id;
            const liveOrScheduled =
                general.formState === ScheduledOptions.Later
                    ? "createScheduledStream"
                    : "goLive";

            const response = await facebook.helpers[liveOrScheduled](
                edge,
                form,
                general.generalForm
            );

            if (!response || response.error) {
                const { error } = response;

                if (!!error.message) {
                    throw { message: error.message };
                } else {
                    throw { message: "errors:facebook-creation-error" };
                }
            } else {
                const hasBrandedContent =
                    form.brandedContentSelection.length > 0 &&
                    userHasClaim("fbbrandedcontent") &&
                    form.enableBrandedContent;

                const hasCommentModerationSettings =
                    form.enableModeration &&
                    form.selectedCommentModerationOptions.length > 0;

                if (hasBrandedContent || hasCommentModerationSettings) {
                    const selectedPageAccessToken =
                        await facebook.accessToken.get(selectedDestination.id);

                    const body = {} as any;

                    if (hasCommentModerationSettings) {
                        body["live_comment_moderation_setting"] =
                            JSON.stringify(
                                form.selectedCommentModerationOptions.map(
                                    (o) => o.id
                                )
                            );
                    }

                    if (hasBrandedContent) {
                        body["sponsor_id"] = form.brandedContentSelection;
                    }

                    const formBody = mapBodyToForm(body);

                    const updateResponse = await facebook.stream.update(
                        response.id,
                        { access_token: selectedPageAccessToken.access_token },
                        formBody
                    );

                    if (updateResponse.error) {
                        throw updateResponse.error;
                    }
                }

                const switcherResult =
                    await facebook.helpers.putOrPostFacebookSwitcherStreamSettings(
                        response,
                        selectedDestination,
                        true,
                        broadcastId,
                        isSimulcast
                    );

                return switcherResult;
            }
        },
        updateStream: async (
            stream,
            generalForm: GeneralPlatformForm,
            selectedDestination: FacebookDestination
        ) => {
            const body = {
                title: generalForm.title,
                description: !!generalForm.description
                    ? generalForm.description
                    : ""
                //This is causing issues with Facebook API v12 since these streams are not technically scheduled, they appear as "LIVE"
                //planned_start_time: Math.floor(generalForm.scheduledStartTime.valueOf() / 1000)
            };

            const formBody = mapBodyToForm(body);
            await facebook.stream.update(
                stream.id.toString(),
                undefined,
                formBody
            );
            const result =
                await facebook.helpers.putOrPostFacebookSwitcherStreamSettings(
                    stream,
                    selectedDestination,
                    true
                );
            return result;
        },
        putOrPostFacebookSwitcherStreamSettings: async (
            stream,
            selectedDestination: FacebookDestination,
            isNewStream?: boolean,
            broadcastId?: string,
            isSimulcast?: boolean
        ) => {
            const { platforms, user } = store.getState();
            const { facebookForms } = platforms.facebook;
            const facebookForm = facebookForms[selectedDestination.id];
            const { generalForm, formState } = platforms.general;

            const streamResult = parseRtmpString(stream);
            const streamUrl = streamResult.streamUrl;
            const streamKey = streamResult.streamKey;

            //   if (this.fbSpeedTestSuccess()) {
            //     streamUrl = await this.getOptimalStreamUrl(stream.id);
            // }
            if (
                isNewStream &&
                formState !== ScheduledOptions.Existing &&
                facebookForm.selectedCrosspostingOptions.length > 0
            )
                trackEvent("Used Crossposting");

            const relay = new URL(dashRelayUrl);
            relay.searchParams.set("facebook", btoa(stream.dash_ingest_url));
            relay.searchParams.set("session", user.userInfo.UserId);
            const relayUrl = relay.toString();

            const newSetting = isNewStream
                ? { ...generalForm.selectedQuality.setting }
                : {
                      ...platforms.general.availableQualities[
                          platforms.general.availableQualities.length - 1
                      ]?.setting
                  };

            newSetting["url"] = userHasClaim("fbaltingest")
                ? relayUrl
                : streamUrl;
            if (isNewStream) {
                newSetting["permalink-url"] =
                    `https://www.facebook.com${stream.permalink_url}`;
                newSetting["facebook-edge-id"] = selectedDestination.id;
                newSetting["meta"] = JSON.stringify({
                    contentTags: facebookForm.selectedTags.map((ct) => ct.name),
                    usedCrossposting:
                        facebookForm.selectedCrosspostingOptions.length > 0
                });

                switch (selectedDestination.edge) {
                    case "me":
                        newSetting["facebook-edge"] = "Me";
                        break;
                    case "accounts":
                        newSetting["facebook-edge"] = "Page";
                        break;
                }
            }
            newSetting["rtmp-stream"] = userHasClaim("fbaltingest")
                ? ""
                : streamKey;
            newSetting["facebook-video-id"] = stream.id;
            newSetting["channel-name"] = selectedDestination.isTestStream
                ? "Private Test Stream"
                : generalForm.title || generalForm.description || "Stream";
            newSetting["name"] = "Facebook";
            newSetting["broadcastId"] = broadcastId;

            const result = isSimulcast
                ? await switcherClient.facebook_PostFacebookSwitcherStreamSetting(
                      newSetting
                  )
                : await switcherClient.facebook_PutFacebookSwitcherStreamSetting(
                      newSetting
                  );

            if (
                switcherSdk.clientId !== "Generic" &&
                switcherSdk.api.versionMajor >= 2 &&
                !broadcastId
            ) {
                switcherSdk.terminateBCProfileCreation(result);
            }

            return result;
        },
        goLive: async (
            edge,
            form: FacebookForm,
            genForm: GeneralPlatformForm
        ) => {
            const body = {
                crossposting_actions: JSON.stringify(
                    form.selectedCrosspostingOptions.map((c) => {
                        return {
                            page_id: c.id,
                            action: "enable_crossposting_and_create_post"
                        };
                    })
                ),
                title: genForm.title,
                description: !!genForm.description ? genForm.description : "",
                status: "LIVE_NOW",
                privacy: JSON.stringify(form.privacy),
                stop_on_delete_stream: true,
                content_tags: JSON.stringify(form.selectedTags.map((t) => t.id))
            };

            const formBody = mapBodyToForm(body);

            return facebook.stream.create(edge, formBody);
        },
        createScheduledStream: async (
            edge,
            form: FacebookForm,
            genForm: GeneralPlatformForm
        ) => {
            const tempImgStore =
                await switcherClient.switcherTempStorage_PostTempAsset();
            await uploadToTempStorage(
                genForm.scheduledImageFile,
                tempImgStore.writeUrl
            );

            const body = {
                crossposting_actions: JSON.stringify(
                    form.selectedCrosspostingOptions.map((c) => {
                        return {
                            page_id: c.id,
                            action: "enable_crossposting_and_create_post"
                        };
                    })
                ),
                title: genForm.title,
                description: !!genForm.description ? genForm.description : "",
                privacy: JSON.stringify(form.privacy),
                stop_on_delete_stream: true,
                content_tags: JSON.stringify(
                    form.selectedTags.map((t) => t.id)
                ),
                event_params: JSON.stringify({
                    start_time: Math.floor(
                        genForm.scheduledStartTime.valueOf() / 1000
                    ),
                    cover: tempImgStore.readUrl
                })
            };

            // if (!!genForm.scheduledImageFile) body["schedule_custom_profile_image"] = genForm.scheduledImageFile;

            const formBody = mapBodyToForm(body);

            return facebook.stream.create(edge, formBody);
        },
        getScheduledStreamsByType: async (
            destinationId: string | number,
            edge
        ) => {
            switch (edge) {
                case "me":
                    return facebook.stream.getScheduledForTimeline(
                        destinationId
                    );
                case "accounts":
                    return facebook.stream.getScheduledForPages(destinationId);
                default:
                    return facebook.stream.getScheduledForTimeline("me");
            }
        },
        getDestinationById: async (id: string): Promise<FacebookProfile> => {
            return await fb({
                edge: id,
                fields: { fields: "picture.type(large),id,name" }
            });
        },
        getDestinationsByType: async (options) => {
            if (options.edge !== "me" && options.edge !== "events") {
                const destinations = await facebook.destinations.get(options);

                // when default stream, original code then checks to see if this is the currently-selected item. if it's not we do another request for the relevant image to display
                // maybe this goes on the form page with this new setup

                destinations.data = destinations.data.map((d) => {
                    return {
                        ...d,
                        edge: options.edge
                    };
                });

                return destinations;
            } else if (options.edge === "me") {
                return {
                    data: [
                        {
                            name: "My timeline",
                            id: "me",
                            picture: {
                                data: {
                                    url: options.userImage
                                }
                            },
                            edge: "me"
                        },
                        {
                            name: "Private test stream",
                            id: "me",
                            isTestStream: true,
                            picture: {
                                data: {
                                    url: options.userImage
                                }
                            },
                            edge: "me"
                        }
                    ]
                };
            }
        }
    }
};

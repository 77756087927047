import React, { useEffect } from "react";
import { useCurrentRoute } from "react-navi";
import {
    ShopifyLinkArgs,
    ShopifyParams
} from "views/page-content/platforms/types";

interface ConnectShopifyContainerProps {
    updateExtraLinkArgs: (args: any) => void;
}
export const ConnectShopifyContainer: React.FC<
    ConnectShopifyContainerProps
> = ({ updateExtraLinkArgs }: ConnectShopifyContainerProps) => {
    const { url } = useCurrentRoute();
    const shopifyParams: ShopifyParams = url.query as unknown as ShopifyParams;
    useEffect(() => {
        const shopifyLinkArgs: ShopifyLinkArgs = {
            shop: shopifyParams.shop
        };
        updateExtraLinkArgs(shopifyLinkArgs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shopifyParams]);

    return <></>;
};

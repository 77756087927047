import styles from "./index.module.scss";
import { AnchorHTMLAttributes, DetailedHTMLProps } from "react";

export interface LinkProps {}

/**
 * A styled wrapper for react-navi Link component
 */
export const Link = (
    props: DetailedHTMLProps<
        AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
    >
) => (
    <a {...props} className={styles["link"]}>
        {props.children}
    </a>
);

import React from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "assets/icons/add-no-circle.svg?react";
import styles from "./index.module.scss";

export const CreatePlayerCard = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();

    return (
        <button className={styles["create-player-card"]} onClick={onClick}>
            <AddIcon />
            <h6>{t("players:create-player")}</h6>
        </button>
    );
};

import React, { useCallback } from "react";
import styles from "./AccountDeletion.module.scss";
import { useNavigate } from "hooks/useNavigate";
import { useTranslation } from "react-i18next";
import { UserState } from "store/user/types";
import { format } from "helpers/time";

export interface AccountDeletionDisclaimerViewProps {
    toSurvey: () => void;
    user: UserState;
}

export const AccountDeletionDisclaimerView: React.FC<
    AccountDeletionDisclaimerViewProps
> = ({ toSurvey, user }) => {
    const { navigate } = useNavigate();

    const { t } = useTranslation();

    const toProfilePage = useCallback(() => {
        navigate("profile");
    }, [navigate]);

    const activeUntil = format(
        new Date(user.userInfo.ActiveUntil),
        "MM/DD/YYYY"
    );

    const subStatus = user.userInfo.Status;

    return (
        <>
            <div>
                {!(subStatus === "ended") && (
                    <div>
                        <h4>{t("delete-account:leaving")}</h4>
                        <p>
                            {t("delete-account:subscription-paid-until", {
                                activeUntil
                            })}
                        </p>
                    </div>
                )}

                <h4>{t("delete-account:reminder")}</h4>
                <p>{t("delete-account:what-happens-next-preface")}</p>
                <ul>
                    <li>{t("delete-account:what-happens-next:saved-info")}</li>
                    <li>
                        {t(
                            "delete-account:what-happens-next:multimedia-and-assets"
                        )}
                    </li>
                    <li>
                        {t("delete-account:what-happens-next:past-streams")}
                    </li>
                </ul>
            </div>

            <button
                className={`btn btn-grey ${styles["btn"]}`}
                onClick={toProfilePage}
            >
                {t("buttons:cancel").toUpperCase()}
            </button>

            <button className={`btn btn-primary`} onClick={toSurvey}>
                {t("buttons:continue").toUpperCase()}
            </button>
        </>
    );
};

import React, { useCallback, useState } from "react";
import styles from "./index.module.scss";
import { BroadcastsHashItem } from "components/modal/AddToPlaylistModal";
import { BroadcastDetails } from "../../../../../components/entity-details/BroadcastDetails";
import { StyledCheckboxOrRadio } from "components/inputs/checkbox/StyledCheckboxOrRadio";
import { BroadcastsSearchSortBar } from "components/inputs/search-sort-bar/BroadcastsSearchSortBar";
import { useTranslation } from "react-i18next";

interface BroadcastsMultiSelectProps {
    allowMultiple: boolean;
    broadcasts: BroadcastsHashItem[];
    selectedBroadcastId: string;
    handleSelect: (broadcastId: string) => void;
    setVisibleBroadcastIds?: (ids: string[]) => void;
    setAnyVisibleSelected?: (anyVisibleSelected: boolean) => void;
}

export const BroadcastsMultiSelect = ({
    allowMultiple,
    broadcasts,
    selectedBroadcastId,
    handleSelect,
    setVisibleBroadcastIds,
    setAnyVisibleSelected
}: BroadcastsMultiSelectProps) => {
    const [sortedBroadcasts, setSortedBroadcasts] = useState<
        BroadcastsHashItem[]
    >([]);
    const { t } = useTranslation();

    const handleBroadcastSort = useCallback(
        (broadcasts: BroadcastsHashItem[]) => {
            setSortedBroadcasts(broadcasts as BroadcastsHashItem[]);
            setVisibleBroadcastIds?.(
                (broadcasts as BroadcastsHashItem[]).map(
                    ({ broadcast }) => broadcast?.Id
                )
            );
            setAnyVisibleSelected?.(
                (broadcasts as BroadcastsHashItem[]).some(
                    ({ selected, disabled }) => selected && !disabled
                )
            );
        },
        [setSortedBroadcasts, setVisibleBroadcastIds, setAnyVisibleSelected]
    );

    if (!broadcasts?.length) {
        return <></>;
    }

    return (
        <div className={styles["broadcasts-container"]}>
            <BroadcastsSearchSortBar
                broadcasts={broadcasts}
                handleSort={(broadcasts) =>
                    handleBroadcastSort(broadcasts as BroadcastsHashItem[])
                }
            />
            {!!sortedBroadcasts.length ? (
                sortedBroadcasts?.map(
                    (
                        {
                            videoResponse: video,
                            broadcast,
                            metrics,
                            selected,
                            disabled
                        },
                        i
                    ) => {
                        const previouslySelected = selected && disabled;
                        return (
                            <div
                                className={`${
                                    styles["broadcast-select-card"]
                                } ${
                                    previouslySelected
                                        ? styles["previously-selected"]
                                        : ""
                                }`}
                                key={i}
                                onClick={() => handleSelect(broadcast?.Id)}
                            >
                                <BroadcastDetails
                                    video={video}
                                    broadcast={broadcast}
                                    badges={true}
                                    metrics={metrics}
                                />
                                <StyledCheckboxOrRadio
                                    type={allowMultiple ? "checkbox" : "radio"}
                                    checked={
                                        !!selectedBroadcastId
                                            ? selectedBroadcastId ===
                                              broadcast?.Id
                                            : selected
                                    }
                                    disabled={disabled}
                                />
                            </div>
                        );
                    }
                )
            ) : (
                <p className={styles["empty"]}>
                    {t("video-library:no-videos-match")}
                </p>
            )}
        </div>
    );
};

import {
    PlatformActions,
    PlatformId,
    PlatformAction,
    ShopifyLinkArgs
} from "../views/page-content/platforms/types";
import {
    getTwitchIngestServers,
    getTwitchUserAndChannel
} from "store/platforms/thunks";
import { useCallback } from "react";
import { switcherSdk as switcher } from "utils/switcher-sdk";
import { client } from "api/client";
import { useCurrentRoute } from "hooks/useCurrentRoute";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { openConfirmation } from "store/confirmation/slice";
import store, { AppDispatch } from "store/store";
import { facebook as fb } from "api/facebook/facebook-client";
import { youtube as yt } from "api/youtube/youtube-client";
import rollbar from "helpers/rollbar";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useNavigate } from "hooks/useNavigate";

const REDIRECT_URI = import.meta.env.VITE_REDIRECT_URI;

export function useAllPlatformActions() {
    const { navigate } = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((s: RootState) => s.user);
    const isVLH = useClaimCheck("vlh");
    const isLegacySimulcast = useClaimCheck("legacysimulcast");
    const onlySimulcastManage = isVLH && !isLegacySimulcast;

    // Connecting platforms as part of a multistream will
    // have different routing afterwards
    const { url } = useCurrentRoute();
    const isSimulcastRoute = url.pathname.includes("simulcast");

    async function deleteStreamSettings(platformId: string) {
        try {
            await client.externalProviderSwitcherStreamSettings_DeleteExternalProviderSwitcherStreamSetting(
                platformId
            );
        } catch (err) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: "errors:disconnect-account-error"
                })
            );
        }
    }

    async function getPlatform(platformId: string): Promise<StreamingProvider> {
        const response = await client.streamingProviders_GetStreamingProvider(
            platformId
        );

        // Workaround -- GetStreamingProvider (above) falls through to
        // GetStreamingProviders if the platform id is not a guid, and
        // the platform does not have its own dedicated controller action
        // (churchstreamingtv, streamspot). Call GetStreamingProviders again
        // with browse = true, and pick out the provider we want.
        if ((response as any).StreamingProviders?.length) {
            const res = await client.streamingProviders_GetStreamingProviders(
                true
            );
            const platform = res.StreamingProviders.find(
                (s) => s.Id === platformId
            );
            return platform;
        } else {
            return response;
        }
    }

    const actions: PlatformActions = {
        /** TODO: re-evaluate redirectUris/finalUris, especially for orgs.
         * For now, just use the /platforms path for the redirect.
         */
        [PlatformId.Facebook]: {
            async link() {
                const userToken = await client.account_GetUserToken();
                const isolatedReturnUrl =
                    switcher.api.versionMajor >= 2
                        ? switcher.getIsolatedWebViewReturnUrl()
                        : null;

                const finalUrl = isSimulcastRoute
                    ? "/platforms/simulcast/destinations/facebook/add"
                    : onlySimulcastManage
                    ? "/platforms"
                    : "/platforms/facebook";

                const state = {
                    providerId: "facebook",
                    userId: switcher.auth.userId || user.ticket?.userId,
                    userToken: userToken,
                    finalUrl: isolatedReturnUrl || finalUrl
                };

                // Use the mobile FB site if rendered in a webview.
                const subDomain = switcher.api.versionMajor >= 2 ? "m" : "www";
                const scopes = [
                    "pages_messaging",
                    "pages_show_list",
                    "pages_read_user_content",
                    "publish_video",
                    "pages_read_engagement",
                    "pages_manage_posts",
                    "pages_manage_metadata",
                    "business_management"
                ];

                const authURL = new URL(
                    `https://${subDomain}.facebook.com/dialog/oauth`
                );

                authURL.searchParams.append("state", JSON.stringify(state));
                authURL.searchParams.append("default_audience", "everyone");
                authURL.searchParams.append(
                    "client_id",
                    import.meta.env.VITE_FACEBOOK_CLIENTID
                );
                authURL.searchParams.append("scope", scopes.join(","));
                authURL.searchParams.append("redirect_uri", REDIRECT_URI);

                if (switcher.api.versionMajor >= 2) {
                    switcher.openIsolatedWebView(
                        authURL.href,
                        null,
                        null,
                        () => {
                            switcher.closeIsolatedWebView(null, () => {
                                navigate(finalUrl);
                            });
                        }
                    );
                } else {
                    window.location.replace(authURL.href);
                }
            },
            unlink: useCallback(async () => {
                try {
                    await client.facebook_DeleteCredentials();
                    const streamDestinations =
                        await client.streamDestinations_GetStreamDestinations(
                            user.userInfo?.UserId
                        );

                    const defaultStreamDestination = streamDestinations.find(
                        (sd) => sd.IsDefault
                    );
                    if (defaultStreamDestination) {
                        await client.streamDestinations_DeleteStreamDestination(
                            defaultStreamDestination.Id,
                            user.userInfo?.UserId
                        );
                    }
                } catch (e) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "errors:disconnect-account-error"
                        })
                    );
                }
            }, [dispatch, user.userInfo]),
            init: useCallback(async (destinationInfo) => {
                const { destinationId, destinationType } = destinationInfo;
                const {
                    platforms: { facebook }
                } = store.getState();
                const { selectedDestination } = facebook;
                const getExistingStreams = async () => {
                    // this needs to change soon.. need better defaults.
                    if (
                        destinationId === "-1" ||
                        selectedDestination.id === "-1"
                    ) {
                        return [];
                    }
                    const res = await fb.helpers.getScheduledStreamsByType(
                        destinationId || selectedDestination.id,
                        destinationType || selectedDestination.edge
                    );
                    if (res.hasOwnProperty("live_videos")) {
                        return res.live_videos.data.filter(
                            (v) => v.broadcast_start_time
                        );
                    } else {
                        return [];
                    }
                };
                const existingStreams = await getExistingStreams();
                const initialState = {
                    imgSrc: selectedDestination?.picture?.data?.url,
                    name: selectedDestination?.name,
                    existingStreams,
                    hasAccess: true
                };

                return initialState;
            }, []),
            getPlatform: useCallback(() => getPlatform(PlatformId.Facebook), [])
        },
        [PlatformId.Youtube]: {
            async link() {
                const userToken = await client.account_GetUserToken();
                const redirectUrl = encodeURIComponent(REDIRECT_URI); //TODO: url
                const isolatedReturnUrl =
                    switcher.api.versionMajor >= 2
                        ? switcher.getIsolatedWebViewReturnUrl()
                        : "";

                const finalUrl = isSimulcastRoute
                    ? "/platforms/simulcast/destinations/youtube/add"
                    : onlySimulcastManage
                    ? "/platforms"
                    : "/platforms/youtube";

                const state = {
                    providerId: "youtube",
                    userId: switcher.auth.userId || user.ticket?.userId,
                    userToken: userToken,
                    finalUrl: isolatedReturnUrl || finalUrl
                };

                const authURL =
                    "https://accounts.google.com/o/oauth2/auth" +
                    "?returnUrl=" +
                    encodeURIComponent(isolatedReturnUrl) +
                    "&response_type=code&client_id=" +
                    import.meta.env.VITE_YOUTUBE_CLIENT_ID +
                    "&redirect_uri=" +
                    redirectUrl +
                    "&state=" +
                    encodeURIComponent(JSON.stringify(state)) +
                    "&scope=" +
                    "https://www.googleapis.com/auth/youtube&access_type=offline&include_granted_scopes=true&approval_prompt=force";

                if (switcher.api.versionMajor >= 2) {
                    switcher.openIsolatedWebView(
                        authURL,
                        null,
                        null,
                        function () {
                            switcher.closeIsolatedWebView(null, () => {
                                navigate(finalUrl);
                            });
                        }
                    );
                } else {
                    window.location.replace(authURL);
                }
            },
            unlink: useCallback(async () => {
                try {
                    await client.youTube_DeleteCredentials();
                } catch (e) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "errors:disconnect-account-error"
                        })
                    );
                }
            }, [dispatch]),
            init: useCallback(async (destinationInfo) => {
                const { destinationId } = destinationInfo;
                const getExistingStreams = async () => {
                    const existingScheduled = await yt.liveBroadcasts.list({
                        maxResults: 500,
                        part: "id,snippet,contentDetails,status",
                        broadcastType: "event",
                        broadcastStatus: "upcoming"
                    });
                    return existingScheduled.items.filter(
                        (i) => !!i.snippet.scheduledStartTime
                    );
                };
                const listArgs = {
                    part: "id,snippet,contentDetails",
                    ...(destinationId ? { id: destinationId } : { mine: true })
                };
                const { items } = await yt.channels.list(listArgs);
                const channelInfo = items[0];

                return {
                    name: channelInfo?.snippet?.title,
                    imgSrc: channelInfo?.snippet?.thumbnails?.default?.url,
                    existingStreams: await getExistingStreams(),
                    hasAccess: true
                };
            }, []),
            getPlatform: useCallback(() => getPlatform(PlatformId.Youtube), [])
        },
        [PlatformId.Shopify]: {
            link: useCallback(
                async ({ finalReturnUrl }: ShopifyLinkArgs) => {
                    const userToken = await client.account_GetUserToken();
                    const isolatedReturnUrl =
                        switcher.api.versionMajor >= 2
                            ? switcher.getIsolatedWebViewReturnUrl()
                            : null;

                    const state = {
                        providerId: PlatformId.Shopify,
                        userId: switcher.auth.userId || user.ticket?.userId,
                        userToken: userToken,
                        finalUrl:
                            isolatedReturnUrl ??
                            finalReturnUrl ??
                            `/platforms/${PlatformId.Shopify}`
                    };

                    const authParams = new URLSearchParams();
                    authParams.append(
                        "client_id",
                        import.meta.env.VITE_SHOPIFY_CLIENTID
                    );
                    const scopes = [
                        "unauthenticated_read_checkouts",
                        "unauthenticated_write_checkouts",
                        "unauthenticated_read_customers",
                        "unauthenticated_write_customers",
                        "unauthenticated_read_product_listings",
                        "unauthenticated_read_product_tags",
                        "unauthenticated_read_selling_plans",
                        "unauthenticated_read_product_inventory",
                        "read_orders",
                        "read_products"
                    ];
                    authParams.append("redirect_uri", REDIRECT_URI);
                    authParams.append("scope", scopes.join(","));
                    authParams.append("state", JSON.stringify(state));

                    const authUrl = `https://accounts.shopify.com/store-login?redirect=${encodeURIComponent(
                        `oauth/authorize?${authParams.toString()}`
                    )}`;

                    if (switcher.api.versionMajor >= 2) {
                        switcher.openIsolatedWebView(
                            authUrl,
                            null,
                            null,
                            () => {
                                switcher.closeIsolatedWebView(null, () => {
                                    navigate(
                                        `/platforms/${PlatformId.Shopify}`
                                    );
                                });
                            }
                        );
                    } else {
                        window.location.replace(authUrl);
                    }
                },
                [navigate, user.ticket]
            ),
            unlink: useCallback(async () => {
                try {
                    await client.shopify_DeleteCredentials();
                } catch (e) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "errors:disconnect-account-error"
                        })
                    );
                }
            }, [dispatch]),
            getPlatform: useCallback(async () => {
                let credentials = null;
                try {
                    credentials = await client.shopify_GetCredentials();
                } catch (e) {}

                return {
                    Id: "shopify",
                    LogoUrl:
                        "https://switcherstudio.blob.core.windows.net/staticcontent/images/providers/shopify.png",
                    IsLinked: credentials?.length > 0
                } as StreamingProvider;
            }, [])
        } as PlatformAction,
        [PlatformId.Twitch]: {
            async link() {
                const userToken = await client.account_GetUserToken();
                const isolatedReturnUrl =
                    switcher.api.versionMajor >= 2
                        ? switcher.getIsolatedWebViewReturnUrl()
                        : null;

                const finalUrl = isSimulcastRoute
                    ? "/platforms/simulcast/destinations/twitch/add"
                    : onlySimulcastManage
                    ? "/platforms"
                    : "/platforms/twitch";

                const state = {
                    providerId: "twitch",
                    userId: switcher.auth.userId || user.ticket?.userId,
                    userToken: userToken,
                    finalUrl: isolatedReturnUrl || finalUrl
                };
                const perms = encodeURIComponent(
                    [
                        "user_read",
                        "channel_read",
                        "channel_editor",
                        "user:read:email",
                        "channel:read:editors",
                        "channel:read:stream_key",
                        "channel:manage:broadcast",
                        "channel:manage:videos",
                        "channel:moderate",
                        "chat:read",
                        "chat:edit",
                        "whispers:read",
                        "whispers:edit",
                        "channel:read:subscriptions"
                    ].join(" ")
                );
                const authRedirect = encodeURIComponent(REDIRECT_URI);
                const authURL =
                    "https://id.twitch.tv/oauth2/authorize?" +
                    "client_id=" +
                    import.meta.env.VITE_TWITCH_CLIENT_ID +
                    "&redirect_uri=" +
                    authRedirect +
                    "&response_type=code" +
                    "&scope=" +
                    perms +
                    "&state=" +
                    encodeURIComponent(JSON.stringify(state));

                if (switcher.api.versionMajor >= 2) {
                    switcher.openIsolatedWebView(authURL, null, null, () => {
                        switcher.closeIsolatedWebView(null, () => {
                            navigate(finalUrl);
                        });
                    });
                } else {
                    window.location.replace(authURL);
                }
            },
            unlink: useCallback(async () => {
                try {
                    await client.twitch_DeleteCredentials();
                } catch (e) {
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: "errors:disconnect-account-error"
                        })
                    );
                }
            }, [dispatch]),
            init: useCallback(async () => {
                await dispatch(getTwitchIngestServers());
                const info = await dispatch(getTwitchUserAndChannel()).unwrap();

                return {
                    name: info.user.display_name,
                    imgSrc: info.user.profile_image_url,
                    hasAccess: true
                };
            }, [dispatch]),
            getPlatform: useCallback(() => getPlatform(PlatformId.Twitch), [])
        } as PlatformAction,
        [PlatformId.VideoPlayer]: {
            init: useCallback(async () => {
                return {
                    hasAccess: true
                };
            }, []),
            getPlatform: useCallback(
                () => getPlatform(PlatformId.VideoPlayer),
                []
            )
        } as PlatformAction,
        [PlatformId.StripeConnect]: {
            async link() {
                try {
                    window.location.replace(
                        (
                            await client.stripeConnect_GetAccountLink(
                                user.userInfo.UserId,
                                REDIRECT_URI
                            )
                        ).url
                    );
                } catch (e) {
                    rollbar.error(
                        "Error getting stripe connect account link",
                        e
                    );
                }
            }
        },
        [PlatformId.MsStream]: {
            async unlink() {
                return deleteStreamSettings(PlatformId.MsStream);
            },
            getPlatform: useCallback(() => getPlatform(PlatformId.MsStream), [])
        },
        [PlatformId.Caast]: {
            async unlink() {
                return deleteStreamSettings(PlatformId.Caast);
            },
            getPlatform: useCallback(() => getPlatform(PlatformId.Caast), [])
        },
        [PlatformId.Instafeed]: {
            async unlink() {
                return deleteStreamSettings(PlatformId.Instafeed);
            },
            getPlatform: useCallback(
                () => getPlatform(PlatformId.Instafeed),
                []
            )
        },
        [PlatformId.AmazonLive]: {
            async unlink() {
                return deleteStreamSettings(PlatformId.AmazonLive);
            },
            getPlatform: useCallback(
                () => getPlatform(PlatformId.AmazonLive),
                []
            )
        },
        [PlatformId.AzureMediaServices]: {
            async unlink() {
                return deleteStreamSettings(PlatformId.AzureMediaServices);
            },
            getPlatform: () => getPlatform(PlatformId.AzureMediaServices)
        },
        [PlatformId.SwitchboardLive]: {
            async link() {
                try {
                    await client.switchboard_GenerateJWT();
                    navigate("/platforms/switchboard");
                } catch (e) {
                    rollbar.error("Error generating switchboard JWT", e);
                }
            },
            async unlink() {
                // delete credentials & remove stream settings.
                await client.switchboard_DeleteCredentials();
            },
            getPlatform: useCallback(
                () => getPlatform(PlatformId.SwitchboardLive),
                []
            )
        },
        [PlatformId.ChurchStreamingTv]: {
            async unlink() {
                return deleteStreamSettings(PlatformId.ChurchStreamingTv);
            },
            getPlatform: useCallback(
                () => getPlatform(PlatformId.ChurchStreamingTv),
                []
            )
        }
    };

    // Disconnect account - after confirmation, will unlink and then update local data
    const disconnect = async (
        platformId: PlatformId,
        providers: StreamingProvider[],
        setProviders: (providers: StreamingProvider[]) => void
    ) => {
        dispatch(
            openConfirmation({
                message: "platforms:confirm-account-disconnect",
                onSuccess: async () => {
                    await actions[platformId]?.unlink();
                    setProviders(
                        providers.map((p) =>
                            p.Id === platformId ? { ...p, IsLinked: false } : p
                        )
                    );
                }
            })
        );
    };

    // Should the user be permitted to manage this platform, i.e. create broadcasts directly to it?
    const canManage = useCallback(
        (platformId: PlatformId): boolean =>
            !onlySimulcastManage || platformId === PlatformId.Simulcast,
        [onlySimulcastManage]
    );

    return { actions, disconnect, canManage };
}

export function usePlatformActions(platformId: PlatformId) {
    const { actions } = useAllPlatformActions();

    return {
        link: actions[platformId]?.link,
        unlink: actions[platformId]?.unlink,
        init: actions[platformId]?.init,
        getPlatform: actions[platformId]?.getPlatform
    } as PlatformAction;
}

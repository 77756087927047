import React from "react";
import { useNavigate } from "hooks/useNavigate";
import ChevronLeft from "assets/icons/chevron-left.svg?react";
import CheckmarkIcon from "assets/icons/checkmark.svg?react";
import styles from "./PaymentSuccessPage.module.scss";
import { useTranslation } from "react-i18next";

export const PaymentSuccessPage: React.FC = () => {
    const { t } = useTranslation();
    const { navigate } = useNavigate();

    const back = () => {
        navigate("/", { replace: true });
    };

    return (
        <>
            <div className="row">
                <div className="col-xl-10">
                    <div className={styles["form-card"]}>
                        <span className={styles["icon"]}>
                            <CheckmarkIcon />
                        </span>
                        <div className={styles["form-card-body"]}>
                            <p className="lead">
                                {t("payment-success-page:thank-you")}{" "}
                                <strong className={styles["highlight"]}>
                                    {t("payment-success-page:payment-success")}
                                </strong>
                            </p>
                            <p>{t("payment-success-page:success-message")}</p>
                            <button
                                type="button"
                                className={`btn btn-primary ${styles["return-home-button"]}`}
                                onClick={back}
                            >
                                <ChevronLeft className="mr-2" />{" "}
                                {t("payment-success-page:return-home")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

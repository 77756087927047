import { PlanType } from ".";
import StudioPlanIcon from "assets/icons/studio-plan.svg?react";
import BusinessPlanIcon from "assets/icons/business-plan.svg?react";
import MerchantPlanIcon from "assets/icons/merchant-plan.svg?react";
import EnterprisePlanIcon from "assets/icons/enterprise-plan.svg?react";
import PassIcon from "assets/icons/pass.svg?react";
import styles from "../index.module.scss";

export interface PlanIconProps {
    planType: PlanType;
}

export const PlanIcon: React.FC<PlanIconProps> = ({ planType }) => {
    switch (planType) {
        case "studio":
            return <StudioPlanIcon />;
        case "business":
            return <BusinessPlanIcon className={styles["business-icon"]} />;
        case "merchant":
            return <MerchantPlanIcon />;
        case "enterprise":
            return <EnterprisePlanIcon />;
        case "pass":
            return <PassIcon />;
    }
};

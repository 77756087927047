import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Link } from "react-navi";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { NewSubscriptionGrid } from "./NewSubscriptionGrid";
import { InvoiceTable } from "./InvoiceTable";
import { client } from "api/client";
import {
    SilverSunnStripeSubscription,
    StripePrice
} from "@switcherstudio/switcher-api-client";
import { AppDispatch } from "store/store";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { displayDate } from "helpers/time";
import { useTranslation } from "react-i18next";
import rollbar from "helpers/rollbar";
import { trackEvent } from "helpers/analyticsHelpers";
import {
    mustBeSubscriptionOwner,
    useRedirectIfDisallowed
} from "hooks/useRedirectIfDisallowed";
import { NewEntitlementGrid } from "./NewEntitlementGrid";
import { StripeStatus } from "../platforms/types";
import { useEntitlements } from "hooks/useEntitlements";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { NextPaymentOn } from "./NextPaymentOn";
import styles from "./SubscriptionPage.module.scss";
import { UpgradePlanAlert } from "components/alerts/UpgradePlanAlert";
import { existsWithValues } from "helpers/booleans";
import { useHasPlanRole } from "hooks/useHasPlanRole";
import dayjs from "dayjs";
import { EmptyState } from "components/empty-state/EmptyState";
import { useSupportSystem } from "hooks/useSupportSystem";
import { useNavigate } from "hooks/useNavigate";

export interface SubscriptionWithPrice {
    subscription?: SilverSunnStripeSubscription;
    price?: StripePrice;
}

export const NewSubscriptionPage: React.FC = () => {
    useRedirectIfDisallowed(mustBeSubscriptionOwner);

    const { t, i18n } = useTranslation();
    const { isSupportAvailable, supportSystemCommand } = useSupportSystem();
    const { navigate } = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const { userInfo } = useSelector((state: RootState) => state.user);
    const [hasActiveSub, setHasActiveSub] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date>(
        dayjs().subtract(1, "years").toDate()
    );
    const [endDate, setEndDate] = useState<Date>(new Date());
    const { entitlements } = useEntitlements(userInfo.UserId);
    const [detailedSubscriptions, setDetailedSubscriptions] = useState<
        SubscriptionWithPrice[]
    >([]);
    const hasStudioPlan = useHasPlanRole("Studio");

    const { data: invoices } = useSwitcherClient(
        (client) => client.stripe_GetInvoices,
        {
            requestImmediately: true,
            args: [startDate.toUTCString(), endDate.toUTCString()]
        }
    );

    const {
        data: stripePrice,
        loading: stripePriceLoading,
        dispatchApiRequest: getStripePrice
    } = useSwitcherClient((client) => client.userStripePrices_GetStripePrice);

    const { data: customer, loading: stripeCustomerLoading } =
        useSwitcherClient((client) => client.stripe_GetCustomer, {
            requestImmediately: true,
            onError: (e) => {
                rollbar.error(
                    "Error getting user stripe customer subscription info",
                    e
                );
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:subscription-info-error"
                    })
                );
            },
            onSuccess: (data) => {
                const isLapsedSub =
                    userInfo?.Roles?.includes("Lapsed") &&
                    userInfo?.Status !== StripeStatus.Incomplete;
                const isTrialSub = userInfo?.Roles?.includes("Trial");
                const isShopifyCustomer =
                    userInfo?.ActiveProduct?.discriminator?.includes(
                        "ShopifyProduct"
                    );

                if (
                    !data ||
                    ((isLapsedSub || isTrialSub) &&
                        data.StripeCreditCards?.length === 0 &&
                        !isShopifyCustomer)
                ) {
                    navigate("/subscription/subscribe");
                } else {
                    data.StripeCreditCards?.sort((a, b) =>
                        a.Default === b.Default ? 0 : a.Default ? -1 : 1
                    );
                    const activeSub = data?.StripeSubscriptions.find(
                        (s) =>
                            s.Status === StripeStatus.Active ||
                            s.Status === StripeStatus.Trialing
                    );
                    setHasActiveSub(!!activeSub);

                    if (!!activeSub) {
                        getStripePrice([userInfo.UserId, activeSub.PlanId]);
                    }
                }
            }
        });

    const loading = useMemo(
        () => stripePriceLoading || stripeCustomerLoading,
        [stripeCustomerLoading, stripePriceLoading]
    );

    const isAppleSub = useMemo(() => {
        return (
            userInfo.ActiveProduct?.discriminator === "AppleProduct" &&
            userInfo.IsRecurring === true
        );
    }, [userInfo]);

    // combined subscriptions, products and price
    useEffect(() => {
        if (!!customer && !!stripePrice) {
            const detailedSubs: SubscriptionWithPrice[] =
                customer.StripeSubscriptions.map((sub) => {
                    const matchesPrice = stripePrice.Id === sub.PlanId;
                    return {
                        subscription: sub,
                        price: matchesPrice && stripePrice
                    };
                });
            setDetailedSubscriptions(detailedSubs);
        }
    }, [customer, stripePrice]);

    const onEntitlementPrimaryBtnClick = useCallback(() => {
        navigate("/subscription/subscribe");
    }, [navigate]);

    const onSubPrimaryBtnClick = useCallback(
        async (sub: SilverSunnStripeSubscription) => {
            if (
                sub.Status === StripeStatus.PastDue ||
                sub.Status === StripeStatus.Incomplete
            ) {
                // take user directly to fix billing
                const plan = await client.userStripePrices_GetStripePrice(
                    userInfo.UserId,
                    sub.PlanId
                );
                navigate(`/subscription/subscribe/${plan.Id}`);
            } else {
                // go to subscription selection page
                navigate("/subscription/subscribe");
            }
        },
        [navigate, userInfo.UserId]
    );

    const displayCost = useCallback(
        (amount?: number, showPeriodText?: boolean) => {
            let periodText = "";
            if (!!stripePrice && hasActiveSub) {
                periodText =
                    stripePrice.RecurringInterval === "year"
                        ? t("subscription:per-year")
                        : t("subscription:per-month");
            }
            return `${((amount || 0) / 100).toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
            })}${showPeriodText ? ` ${periodText}` : ""}`;
        },
        [stripePrice, hasActiveSub, t]
    );

    const _displayDate = (date?: string) => {
        return displayDate(date, i18n.language);
    };

    const handleSetStartDate = useCallback(
        (date: Date) => {
            setStartDate(date);
        },
        [setStartDate]
    );

    const handleSetEndDate = useCallback(
        (date: Date) => {
            setEndDate(date);
        },
        [setEndDate]
    );

    if (loading) return <></>;

    return (
        <>
            <div className="row">
                <div className="col">
                    <UpgradePlanAlert
                        subscriptions={customer?.StripeSubscriptions}
                        entitlements={entitlements}
                    />
                    {!isAppleSub && <h4>{t("subscription:current-plan")}</h4>}
                    <div className={styles["current-plan-row"]}>
                        {isAppleSub ? (
                            <EmptyState>
                                {
                                    <>
                                        <h6 className={styles["plan-notice"]}>
                                            {t(
                                                "subscription:apple-subscription"
                                            )}{" "}
                                            <button
                                                className={
                                                    styles["support-button"]
                                                }
                                                onClick={() => {
                                                    if (isSupportAvailable) {
                                                        trackEvent(
                                                            "Opened Support Widget",
                                                            {
                                                                source: "sidebar"
                                                            }
                                                        );
                                                        supportSystemCommand(
                                                            "show"
                                                        );
                                                    } else {
                                                        window.open(
                                                            "mailto:support@switcherstudio.com"
                                                        );
                                                    }
                                                }}
                                            >
                                                {t(
                                                    "subscription:apple-sub-link-text"
                                                )}
                                            </button>
                                        </h6>
                                    </>
                                }
                            </EmptyState>
                        ) : !hasActiveSub &&
                          entitlements?.length &&
                          !isAppleSub ? (
                            <NewEntitlementGrid
                                entitlements={entitlements}
                                displayDateFn={_displayDate}
                                headerButtonFn={onEntitlementPrimaryBtnClick}
                            />
                        ) : (
                            <>
                                {hasActiveSub && !isAppleSub ? (
                                    <NewSubscriptionGrid
                                        subscriptions={detailedSubscriptions}
                                        displayDateFn={_displayDate}
                                        displayCostFn={displayCost}
                                        headerButtonFn={onSubPrimaryBtnClick}
                                        isStudioRole={hasStudioPlan}
                                    />
                                ) : (
                                    <div
                                        className="alert alert-info"
                                        role="alert"
                                    >
                                        {t("messages:no-subscription")} <br />
                                        <Link href="/subscription/subscribe">
                                            {t("subscription:choose-plan")}
                                        </Link>
                                    </div>
                                )}
                            </>
                        )}

                        {(hasActiveSub || existsWithValues(entitlements)) &&
                            !isAppleSub && (
                                <NextPaymentOn
                                    activeEntitlement={
                                        !hasActiveSub && entitlements?.[0]
                                    }
                                    activePlan={
                                        hasActiveSub && detailedSubscriptions[0]
                                    }
                                />
                            )}
                    </div>

                    {!isAppleSub && (
                        <InvoiceTable
                            invoices={invoices}
                            displayCostFn={displayCost}
                            displayDateFn={_displayDate}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={handleSetStartDate}
                            setEndDate={handleSetEndDate}
                            timeFrameOptions={t(
                                "subscription:time-frame-options",
                                {
                                    returnObjects: true
                                }
                            )}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

import { SetupIntent } from "@stripe/stripe-js";
import { useCallback, useEffect } from "react";
import { client } from "api/client";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useMakeSubscription } from "./useMakeSubscription";
import partnerstack from "helpers/partnerstack";
import rollbar from "helpers/rollbar";
import { identify } from "store/user/slice";
import { AppDispatch } from "store/store";
import { removeQueryParams } from "helpers/url";
import { useRefreshToken } from "./useRefreshToken";
import { IUserInfo } from "store/user/types";
import { getPredictedPlanValue, trackEvent } from "helpers/analyticsHelpers";
import { configureRollbar } from "store/rollbar/slice";
import { getToken, getUserInfo } from "store/user/thunks";
import { setLoading } from "store/loading/slice";
import {
    RegisterBindingModel,
    StripePrice
} from "@switcherstudio/switcher-api-client";
import { StripePriceAnonymous } from "@switcherstudio/switcher-api-client";
import { useMakePass } from "./usMakePass";

export interface OnboardingSetupIntentBody extends RegisterBindingModel {
    client_id?: string;
    ResellerInventoryItemId?: string;
    Tag?: string;
    Subscribe?: boolean;
    Plan?: StripePrice;
    Industry?: string;
    IndustryCustom?: string;
    EcommercePlatform?: string;
    EcommercePlatformCustom?: string;
    EmailOptIn?: boolean;
}
export interface UseOnboardingHandleSetupIntentArgs {
    /** The Setup Intent object to process. If no Setup Intent is provided, processing the Setup Intent will not process until the callback function is called. */
    setupIntent?: SetupIntent;
    redirectStatus: string | undefined;
    clientId: string;
    plan: StripePriceAnonymous;
    resellerInventoryItemId: string | undefined;
    isTrialing: boolean;
    body?: OnboardingSetupIntentBody;
    onSuccess?: () => void;
}

export const useOnboardingSetupIntent = ({
    setupIntent,
    redirectStatus,
    clientId,
    plan,
    resellerInventoryItemId,
    isTrialing,
    body,
    onSuccess
}: UseOnboardingHandleSetupIntentArgs) => {
    const dispatch = useDispatch<AppDispatch>();
    const { getNewRefreshToken } = useRefreshToken({ lazyLoad: true });

    const makeSubscription = useMakeSubscription({
        clientId,
        planId: plan?.Id,
        resellerInventoryItemId,
        isTrialing
    });

    const makePass = useMakePass({
        planId: plan?.Id,
        resellerInventoryItemId
    });

    const isValidIntent = useCallback(
        async (setupIntent: SetupIntent) => {
            if (setupIntent?.status !== "succeeded") {
                switch (setupIntent?.status) {
                    case "processing":
                        rollbar.warning(
                            "Invalid setupIntent status. 'processing'"
                        );
                        break;
                    case "requires_payment_method":
                        rollbar.warning(
                            "Invalid setupIntent status. 'requires_payment_method'"
                        );
                        break;
                    default:
                        rollbar.warning("Invalid setupIntent status.");
                }

                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:payment-method-error"
                    })
                );
                return false;
            }

            return true;
        },
        [dispatch]
    );

    const handleCreateUser = useCallback(
        async (body: OnboardingSetupIntentBody) => {
            await client.account_Register(body);

            await dispatch(
                getToken({ username: body.Email, password: body.Password })
            );
            const userInfo = await dispatch(getUserInfo()).unwrap();
            dispatch(
                configureRollbar({
                    UserId: userInfo.UserId
                })
            );

            dispatch(
                identify({
                    defaultToSubscribe: !!body.Plan,
                    Industry: body.Industry,
                    "Industry Detail Custom":
                        body.Industry === "Other" ? body.IndustryCustom : "",
                    "Ecommerce Platform":
                        body.Industry === "Retail & Ecommerce"
                            ? body.EcommercePlatform
                            : "",
                    "Ecommerce Platform Custom":
                        body.EcommercePlatform === "Other, please specify"
                            ? body.EcommercePlatformCustom
                            : ""
                })
            );

            try {
                partnerstack.registerCustomer({
                    name: `${body.FirstName} ${body.LastName}`,
                    email: body.Email,
                    customer_key: userInfo.UserId
                });
            } catch (e) {
                if (e.message === partnerstack.notInitializedError)
                    rollbar.warning(
                        "Account created without PartnerStack registration - " +
                            userInfo.UserId
                    );
            }

            trackEvent("Email confirmation survey completed", {
                primaryTag: body.Industry,
                customResponse: body.IndustryCustom
            });

            try {
                // google analytics report conversion
                const predictedValue = await getPredictedPlanValue(body?.Plan);

                // legacy tracking call - deprecated
                trackEvent("Created Account", {
                    source: clientId,
                    currency: "USD",
                    predicted_ltv: predictedValue,
                    value: predictedValue,
                    priceId: body.Plan?.Id
                });

                trackEvent("Account Created", {
                    source: clientId,
                    currency: "USD",
                    predicted_ltv: predictedValue,
                    value: predictedValue,
                    priceId: body.Plan?.Id,
                    emailOptIn: body.EmailOptIn
                });
            } catch (e) {
                rollbar.warning("Error getting predicted plan value", e);
            }

            // We can only do this after the user has been created
            // This is executed when dts is false
            if (!body?.Plan) {
                const customer = await client.stripe_GetCustomer();
                const subscriptionId =
                    customer?.StripeSubscriptions[0]
                        ?.SilverSunnStripeSubscriptionId;

                // sets trial to end at period end by default, also removes sub at that time
                await client.stripe_DeleteSubscription(subscriptionId);
            }

            return userInfo;
        },
        [dispatch, clientId]
    );

    const handleSetupIntent = useCallback(
        async (setupIntent: SetupIntent, userInfo: IUserInfo) => {
            try {
                if (typeof setupIntent.payment_method === "string") {
                    await client.userPaymentMethods_AttachPaymentMethod(
                        userInfo.UserId,
                        setupIntent.payment_method
                    );
                } else {
                    await client.userPaymentMethods_AttachPaymentMethod(
                        userInfo.UserId,
                        setupIntent.payment_method.id
                    );
                }
                if (plan.IsRecurring) {
                    await makeSubscription(userInfo.UserId);
                } else {
                    await makePass(userInfo.UserId);
                }

                // Successful sub, track plan on user
                dispatch(
                    identify({
                        plan
                    })
                );

                try {
                    partnerstack.registerCustomer({
                        name: `${userInfo.FirstName} ${userInfo.LastName}`,
                        email: userInfo.Email,
                        customer_key: userInfo.UserId
                    });
                } catch (e) {
                    if (e.message === partnerstack.notInitializedError)
                        rollbar.warning(
                            "Account created without PartnerStack registration - " +
                                userInfo.UserId
                        );
                }

                removeQueryParams();
                getNewRefreshToken();
            } catch (e) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: e.message
                    })
                );

                // Throw to prevent further parent procesisng
                throw e;
            }
        },
        [dispatch, getNewRefreshToken, makePass, makeSubscription, plan]
    );

    useEffect(() => {
        const process = async () => {
            if (redirectStatus !== "succeeded") {
                return;
            }

            if (setupIntent && body) {
                dispatch(setLoading({ loading: 1, isFullscreen: true }));
                if (!(await isValidIntent(setupIntent))) {
                    dispatch(setLoading({ loading: -1, isFullscreen: false }));
                    return;
                }
                const userInfo = await handleCreateUser(body);
                if (body.Plan) {
                    await handleSetupIntent(setupIntent, userInfo);
                }
                dispatch(setLoading({ loading: -1, isFullscreen: false }));
                onSuccess && onSuccess();
            }
        };

        process();
        // ONLY RUN THIS ONCE
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { isValidIntent, handleSetupIntent, handleCreateUser };
};

import React, { useMemo, useCallback } from "react";
import styles from "../index.module.scss";
import {
    StripePrice,
    ResellerInventoryItem,
    StripePriceAnonymous
} from "@switcherstudio/switcher-api-client";
import { PlanPeriodOptions } from "components/cards/SelectPlanCardsContainer";
import { useTranslation } from "react-i18next";
import {
    calculateDiscountedAmount,
    couponAppliesToPlan,
    formatPlanName
} from "helpers/stripe";
import { PriceHeader } from "./PriceHeader";
import { Card } from "../../Card";
import { PlanIcon } from "./PlanIcon";
import { CardBreak } from "components/breaks/CardBreak";
import { FeaturesList } from "./FeaturesList";
import TransactionIcon from "assets/icons/transaction.svg?react";
import { TooltipBaseV2 } from "components/tooltips/TooltipBaseV2";
import { useDispatch } from "react-redux";
import { setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { ContactSalesModal } from "components/modal/ContactSalesModal";

export type PlanType =
    | "studio"
    | "business"
    | "merchant"
    | "enterprise"
    | "pass";

/** Props for the SelectPlanCard component */
interface SelectPlanCardProps {
    plan: StripePrice | StripePriceAnonymous | undefined;
    planPeriod: PlanPeriodOptions;
    onSelectPlanButtonClicked: (
        plan: StripePrice | StripePriceAnonymous
    ) => void;
    /** Wraps the plan card in the "best value" CSS flare */
    showBestValueFlag?: boolean;
    /** Wraps the plan card in the "current plan" CSS flare*/
    showCurrentPlanFlag?: boolean;
    isCurrentPlan?: boolean;
    /** Object that contains coupon details */
    resellerInventoryItem?: ResellerInventoryItem;
    showFeatures?: boolean;
}

export const SelectPlanCard: React.FC<SelectPlanCardProps> = ({
    plan,
    planPeriod,
    onSelectPlanButtonClicked = () => {},
    showBestValueFlag = false,
    showCurrentPlanFlag = false,
    isCurrentPlan = false,
    resellerInventoryItem,
    showFeatures = false
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation("getting-started");

    const planType = useMemo<PlanType>(() => {
        const name = plan?.Name.toLocaleLowerCase();
        if (name?.match(/enterprise/)) return "enterprise";
        if (!plan?.RecurringInterval) return "pass";
        if (name?.match(/studio/)) return "studio";
        if (name?.match(/business/)) return "business";
        if (name?.match(/merchant/)) return "merchant";
    }, [plan]);

    const isStudioBusinessMerchant = useMemo<boolean>(() => {
        return (
            planType === "studio" ||
            planType === "business" ||
            planType === "merchant"
        );
    }, [planType]);

    const isCurrentPlanFlag = useMemo<boolean>(
        () => showCurrentPlanFlag && isCurrentPlan,
        [showCurrentPlanFlag, isCurrentPlan]
    );

    const isBestValueFlag = useMemo<boolean>(
        () =>
            showBestValueFlag && !isCurrentPlanFlag && planType === "business",
        [showBestValueFlag, isCurrentPlanFlag, planType]
    );

    const buttonText = useMemo<string>(() => {
        switch (planType) {
            case "pass":
                return t("buttons:7-day-pass");
            case "enterprise":
                return t("buttons:contact-sales");
            default:
                return t("buttons:select-plan");
        }
    }, [planType, t]);

    const handlePlanSelect = useCallback(() => {
        if (!isCurrentPlan) {
            if (onSelectPlanButtonClicked) onSelectPlanButtonClicked(plan);
        }
    }, [onSelectPlanButtonClicked, plan, isCurrentPlan]);

    const onEnterpriseSelect = useCallback(() => {
        dispatch(
            setActiveModal({
                id: Modals.ContactSalesModal,
                type: Modals.ContactSalesModal,
                component: <ContactSalesModal />
            })
        );
    }, [dispatch]);

    const originalAmount = useMemo<number>(() => plan?.Amount, [plan]);

    const finalAmount = useMemo<number>(() => {
        if (couponAppliesToPlan(plan, resellerInventoryItem)) {
            return calculateDiscountedAmount(
                plan?.Amount,
                resellerInventoryItem?.ResellerInventory?.Coupon
            );
        } else {
            return plan?.Amount;
        }
    }, [plan, resellerInventoryItem]);

    const hasDiscount = useMemo<boolean>(
        () => originalAmount !== finalAmount,
        [finalAmount, originalAmount]
    );

    return (
        <Card
            onClick={handlePlanSelect}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
                event.keyCode === 13 && handlePlanSelect()
            }
            className={`
        ${styles["card"]}
        ${isCurrentPlanFlag ? styles["current-plan"] : ""}
        ${isBestValueFlag ? styles["best-value"] : ""}
        `}
            tabIndex={0}
        >
            <style>
                {`:root {
                      --current-plan-flair-text: "${t(
                          "subscription:current-plan"
                      )}";
                      --best-value-plan-flair-text: "${t(
                          "subscription:video-monetization"
                      )}";
                  }`}
            </style>
            <div className={`text-center ${styles["card-content"]}`}>
                <span>
                    <span>
                        <PlanIcon planType={planType} />
                        <h5>{formatPlanName(plan?.Name, true)}</h5>
                    </span>

                    <PriceHeader
                        plan={plan}
                        planPeriod={planPeriod}
                        planType={planType}
                        hasDiscount={hasDiscount}
                        resellerInventoryItem={resellerInventoryItem}
                        isCurrentPlan={isCurrentPlan}
                    />
                </span>

                <div
                    className={
                        isStudioBusinessMerchant && showFeatures
                            ? styles["plan-info"]
                            : styles["width-100"]
                    }
                >
                    <div className={`${styles["p-with-tooltip"]}`}>
                        <p className={`${styles["paragraph"]}`}>
                            {t(`subscription:paragraph-${planType}`)}
                        </p>
                        <TooltipBaseV2
                            body={t(`subscription:99`)}
                            show={planType === "business"}
                            type="info"
                            variant={
                                isCurrentPlan ? "default" : "high-contrast"
                            }
                        />
                    </div>

                    <CardBreak />

                    {planType === "business" && showFeatures && (
                        <>
                            <div
                                className={`text-center ${styles["card-content"]}`}
                            >
                                <span className={styles["transaction-span"]}>
                                    <p className={styles["transaction"]}>
                                        <TransactionIcon />
                                        <span
                                            className={`${styles["transaction-fee"]}`}
                                        >
                                            {t(`subscription:ninety-nine-cent`)}
                                        </span>
                                    </p>
                                </span>
                            </div>
                            <CardBreak />
                        </>
                    )}

                    {showFeatures && (
                        <>
                            <FeaturesList
                                planType={planType}
                                amount={finalAmount}
                            />
                        </>
                    )}
                </div>

                {showFeatures && <CardBreak />}

                <div className={styles["select-plan-button-container"]}>
                    <button
                        className={`btn ${styles[`${planType}-button`]}`}
                        onClick={
                            planType === "enterprise"
                                ? onEnterpriseSelect
                                : handlePlanSelect
                        }
                        disabled={isCurrentPlan}
                    >
                        {isCurrentPlan
                            ? t("subscription:current-plan")
                            : buttonText}
                    </button>
                </div>
            </div>
        </Card>
    );
};

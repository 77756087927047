import React, { useCallback } from "react";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { TextInput } from "components/inputs/text-input/TextInput";
import { useTranslation } from "react-i18next";
import { useForm, Errors } from "hooks/useForm";
import { client } from "api/client";
import { useNavigate } from "hooks/useNavigate";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { PlatformId } from "../../types";
import styles from "./ConnectChurchStreamingTvContainer.module.scss";

interface ConnectChurchStreamingTvContainerProps {
    platform: StreamingProvider;
}

interface PlatformLoginValues {
    username: string;
    password: string;
}

export const ConnectChurchStreamingTvContainer: React.FC<
    ConnectChurchStreamingTvContainerProps
> = ({ platform }) => {
    const { isInBrowser, switcherSdk } = useSwitcherSdk(true);
    const { navigate } = useNavigate();

    const { t } = useTranslation();

    const submit = useCallback(
        async (values: PlatformLoginValues) => {
            if (platform.Id === PlatformId.ChurchStreamingTv) {
                await client.churchStreamingTv_PostLinkAccount({
                    Username: values.username,
                    Password: values.password
                });
            }

            if (isInBrowser) {
                navigate("/platforms");
            } else if (
                switcherSdk.clientId !== "Generic" &&
                switcherSdk.api.versionMajor >= 2
            ) {
                const settings =
                    await client.switcherStreamSettings_GetSwitcherStreamSetting(
                        platform.Id
                    );
                switcherSdk.terminateBCProfileCreation(settings);
            }
        },
        [isInBrowser, navigate, platform.Id, switcherSdk]
    );

    const validate = useCallback(
        (values: PlatformLoginValues) => {
            let errors: Errors = {};

            if (!values.username) {
                errors.username = t("errors:username-required-error");
            }

            if (!values.password) {
                errors.password = t("errors:password-required-error");
            }

            return errors;
        },
        [t]
    );

    const { values, errors, isSubmitting, handleChange, handleSubmit } =
        useForm<PlatformLoginValues>(
            {
                username: "",
                password: ""
            },
            submit,
            validate,
            true
        );
    return (
        <div className={styles["form-card"]}>
            <div className={styles["form-card-body"]}>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={handleSubmit}>
                            <TextInput
                                id="username"
                                type="email"
                                label={t("login:enter-username")}
                                value={values.username}
                                error={errors.username}
                                onChange={handleChange}
                            />
                            <TextInput
                                id="password"
                                type="password"
                                label={t("login:enter-password")}
                                value={values.password}
                                error={errors.password}
                                onChange={handleChange}
                            />
                            {errors.api && (
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                >
                                    {t("errors:incorrect-login")}
                                </div>
                            )}
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                            >
                                {t("page-titles:login").toUpperCase()}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { useCallback } from "react";
import { SilverSunnStripeCreditCard } from "@switcherstudio/switcher-api-client";
import { RadioCardsContainer, RadioCard } from "components/radio-cards";
import { CardElement } from "@stripe/react-stripe-js";
import { CARD_ELEMENT_OPTIONS } from "components/forms/payment-form/PaymentForm";
import styles from "./index.module.scss";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { getCreditCardIcon } from "helpers/creditCardHelpers";
import { formatMonthDateDigits } from "helpers/time";
import { useTranslation } from "react-i18next";
import { useNavigate } from "hooks/useNavigate";
import { PrimarySecondaryButtonsGroup } from "components/buttons/PrimarySecondaryButtonsGroup";

interface RadioPaymentFormProps {
    availablePaymentMethods: SilverSunnStripeCreditCard[];
    selectedPaymentMethodId: string;
    setSelectedPaymentMethodId: (id: string) => void;
    buttonText: string;
    onChange: (event: StripeCardElementChangeEvent) => void;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
    error: string;
    busy: boolean;
}

export const RadioPaymentForm = ({
    availablePaymentMethods,
    selectedPaymentMethodId,
    setSelectedPaymentMethodId,
    onSubmit,
    buttonText,
    onChange,
    error,
    busy
}: RadioPaymentFormProps) => {
    const { t } = useTranslation();
    const { navigate } = useNavigate();

    const handleCancelChanges = useCallback(() => {
        navigate("/subscription");
    }, [navigate]);

    return (
        <form onSubmit={onSubmit}>
            <RadioCardsContainer>
                {!!availablePaymentMethods?.length &&
                    availablePaymentMethods.map((pm) => (
                        <RadioCard
                            id={pm.SilverSunnStripeCreditCardId}
                            key={pm.SilverSunnStripeCreditCardId}
                            name="payment-methods"
                            content={
                                <div
                                    className={
                                        styles["payment-method-container"]
                                    }
                                >
                                    {getCreditCardIcon(pm.Brand)}
                                    <div className={styles["brand-name"]}>
                                        {`${
                                            pm.Brand === "Other" ? "" : pm.Brand
                                        }`}
                                    </div>
                                    <div className={styles["last-four"]}>
                                        {`*${pm.LastFour}`}
                                    </div>
                                    <div className={styles["expiration-date"]}>
                                        {`Exp: ${formatMonthDateDigits(
                                            pm.ExpirationMonth
                                        )}/${pm.ExpirationYear?.slice(2)}`}
                                    </div>
                                </div>
                            }
                            onSelect={() =>
                                setSelectedPaymentMethodId(
                                    pm.SilverSunnStripeCreditCardId
                                )
                            }
                            selectedCardId={selectedPaymentMethodId}
                        />
                    ))}
                <RadioCard
                    id="new-payment-method"
                    name="payment-methods"
                    content={t("subscription:use-new-payment")}
                    expandedContent={
                        <div className={styles["stripe-payment-element"]}>
                            <CardElement
                                id="card-element"
                                options={CARD_ELEMENT_OPTIONS}
                                onChange={onChange}
                            />
                            {error && (
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                    style={{
                                        marginTop: "1rem",
                                        marginBottom: 0
                                    }}
                                >
                                    {error}
                                </div>
                            )}
                        </div>
                    }
                    onSelect={() =>
                        setSelectedPaymentMethodId("new-payment-method")
                    }
                    selectedCardId={selectedPaymentMethodId}
                />
            </RadioCardsContainer>
            <PrimarySecondaryButtonsGroup
                primaryButtonText={buttonText}
                primaryButtonType="submit"
                secondaryButtonText={t("subscription:cancel-changes")}
                onSecondaryButtonClick={handleCancelChanges}
                buttonsDisabled={busy}
            />
        </form>
    );
};

import React, { PropsWithChildren } from "react";
import styles from "./BaseLayout.module.scss";
import { NotFoundBoundary, View } from "react-navi";
import { NotificationContainer } from "components/notification/NotificationContainer";
import Loading from "components/loading/Loading";

import { FlagContainer } from "components/flag-container/FlagContainer";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";

// Import the logos and set Logo (and background styles) based on the environment variable
import OldLogo from "assets/icons/switcher-vertical_old.svg?url";
import NewLogo from "assets/icons/switcher-vertical_new.svg?url";
import { Outlet } from "react-router";

const useReactRouterDom = import.meta.env.VITE_USE_REACT_ROUTER_DOM === "true";
const useNewLogo = import.meta.env.VITE_USE_NEW_LOGO === "true";

const Logo = useNewLogo ? NewLogo : OldLogo;

const backgroundStyles = useNewLogo
    ? styles["background-new"]
    : styles.background;

interface BaseLayoutProps extends PropsWithChildren {
    hideFlags?: boolean;
}

export const BaseLayout: React.FC<BaseLayoutProps> = ({
    hideFlags = false
}) => {
    useSwitcherSdk(true);

    return (
        <>
            <span className={backgroundStyles}></span>
            <section className={styles["app-container"]}>
                <NotFoundBoundary render={() => <h1>Not Found</h1>}>
                    <div className={`${styles.container} pt-4`}>
                        <Loading />
                        <div className={styles.row}>
                            <div className="col-sm-10">
                                <div className="form-group text-center">
                                    <img
                                        className={styles.logo}
                                        src={Logo}
                                        alt="Switcher Studio Logo"
                                    />
                                </div>
                            </div>
                        </div>
                        <NotificationContainer />
                        <div className={styles.row}>
                            <div className="col-sm-10">
                                {useReactRouterDom ? <Outlet /> : <View />}
                            </div>
                        </div>
                    </div>
                    {!hideFlags && <FlagContainer />}
                </NotFoundBoundary>
            </section>
        </>
    );
};

import React, { useEffect, useState, useCallback, useMemo } from "react";
import { VideoPlayerWidgetProps } from "./types";
import commonStyles from "./CommonStyles.module.scss";
import SwitcherPlayerIcon from "assets/icons/switcher-player.svg?react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { Link } from "react-navi";
import { useGetPromotionalClaim } from "hooks/useGetPromotionalClaim";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { Select } from "components/inputs/select/Select";
import { Widget } from "./Widget";
import { TooltipBaseV2 } from "components/tooltips/TooltipBaseV2";
import { useEmbedCode } from "hooks/useEmbedCode";

export const VideoPlayerWidget: React.FC<VideoPlayerWidgetProps> = ({
    enabled
}: VideoPlayerWidgetProps) => {
    const { t } = useTranslation();

    const { userInfo } = useSelector((state: RootState) => state.user);
    const projectId = userInfo?.ProjectId;
    const [isEnabled, setIsEnabled] = useState(enabled);
    const hasStudioPlan = userInfo?.PlanName?.toLowerCase().includes("studio");

    const { loading, data: videoPlayersResponse } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_GetVideoPlayers,
        {
            requestImmediately: true,
            args: [projectId]
        }
    );
    const { hasPromotionalClaim: hasSwitcherPlayerStudioAccessPromo } =
        useGetPromotionalClaim("SwitcherPlayerStudioAccess");

    const [selectedPlayerId, setSelectedPlayerId] = useState<
        string | undefined
    >();

    const onDropdownChange = useCallback((playerId) => {
        setSelectedPlayerId(playerId);
    }, []);

    // Sets the default player id to be the first in the list of video players
    useEffect(() => {
        setSelectedPlayerId(videoPlayersResponse?.[0]?.VideoPlayer?.Id);
    }, [videoPlayersResponse]);

    const { copyEmbedCode: copy } = useEmbedCode(
        "collection",
        selectedPlayerId
    );

    useEffect(() => {
        setIsEnabled(enabled);
    }, [enabled]);

    const buttonText = useMemo(() => {
        if (hasSwitcherPlayerStudioAccessPromo) {
            return t("misc:try-it-out");
        }
        return isEnabled
            ? t("widgets:view-video-player").toUpperCase()
            : t("widgets:preview-video-player").toUpperCase();
    }, [t, hasSwitcherPlayerStudioAccessPromo, isEnabled]);

    return (
        <Widget
            loading={loading}
            icon={<SwitcherPlayerIcon />}
            title={
                hasStudioPlan
                    ? t("widgets:video-player-widget-title2")
                    : t("widgets:video-player-widget-title")
            }
            details={[
                {
                    title: t("widgets:select-a-player"),
                    children: (
                        <Select
                            id="player-select"
                            parentClassName={`${commonStyles["video-actions-dropdown-button"]}`}
                            options={videoPlayersResponse?.map((vp) => {
                                return {
                                    value: vp.VideoPlayer.Id,
                                    text: vp.VideoPlayer.Name
                                        ? vp.VideoPlayer.Name
                                        : t("widgets:untitled-player")
                                };
                            })}
                            selected={selectedPlayerId}
                            onChange={(e) => onDropdownChange(e.target.value)}
                        />
                    )
                },
                {
                    infoText: t("widgets:video-player-embed-code"),
                    buttonText: t("widgets:copy"),
                    onClick: copy
                }
            ]}
            footerSubtitle={
                isEnabled ? (
                    t("widgets:video-player-message")
                ) : (
                    <div className={commonStyles["no-subscription-footer"]}>
                        <span>
                            {t("widgets:upgrade-account-message-front")}&nbsp;
                            <Link href="/subscription/subscribe">
                                Switcher Business
                            </Link>
                            &nbsp;{t("widgets:upgrade-account-message-back")}
                        </span>
                        <TooltipBaseV2
                            body={t("widgets:player-tooltip-body")}
                            show
                            type="info"
                            variant="dark"
                            hide={200}
                        />
                    </div>
                )
            }
            button={
                <Link
                    className="btn"
                    href={`/switcher-player/${selectedPlayerId}`}
                >
                    {buttonText}
                </Link>
            }
        ></Widget>
    );
};

import { useState, useEffect } from "react";

export interface UseDebounceOptions<T> {
    onUpdate?: (value: T) => void;
}

export function useDebounce<T>(
    value: T,
    delay: number,
    { onUpdate }: UseDebounceOptions<T> = {}
) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
            onUpdate?.(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, delay]);

    return debouncedValue;
}

import { useSwitcherClient } from "hooks/useSwitcherClient";
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState
} from "react";
import { ModalBase } from "../ModalBase";
import styles from "./index.module.scss";
import CloseIcon from "assets/icons/close.svg?react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import rollbar from "helpers/rollbar";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { exists } from "helpers/booleans";
import {
    PlayerDetailsResponse,
    VideoPlayer,
    VideoPlayerAspectRatio,
    VideoPlayersResponse
} from "@switcherstudio/switcher-api-client";
import { postEvents } from "store/events/thunks";
import { AppDispatch } from "store/store";
import { useClaimCheck } from "hooks/useClaimCheck";

export interface CreateOrUpdateSwitcherPlayerModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    type: "create" | "update";
    onSubmit: (vpr?: VideoPlayersResponse) => void;
    videoPlayer?: VideoPlayer | PlayerDetailsResponse;
}

export const CreateOrUpdateSwitcherPlayerModal = ({
    isOpen,
    setIsOpen,
    type,
    onSubmit,
    videoPlayer
}: CreateOrUpdateSwitcherPlayerModalProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const [name, setName] = useState<string>();
    const [aspectRatio, setAspectRatio] = useState<VideoPlayerAspectRatio>();
    const [description, setDescription] = useState<string>();
    const userState = useSelector((s: RootState) => s.user);
    const enableCatalog = useClaimCheck("catalog");

    useEffect(() => {
        if (!!videoPlayer) {
            setName(videoPlayer?.Name);
            setDescription(videoPlayer?.Description ?? "");
            // takes care of typing differences
            setAspectRatio(
                (videoPlayer?.AspectRatio as any as VideoPlayerAspectRatio) ??
                    VideoPlayerAspectRatio.SixteenByNine
            );
        }
    }, [videoPlayer]);

    const { data: currentVideoPlayerProfile } = useSwitcherClient(
        (client) =>
            client.videoPlayerProfile_GetVideoPlayerProfileByVideoPlayerId,
        {
            requestImmediately: exists(videoPlayer?.Id),
            args: [videoPlayer?.Id]
        }
    );

    const { loading: postLoading, dispatchApiRequest: postVideoPlayers } =
        useSwitcherClient(
            (client) => client.projectsVideoPlayer_PostVideoPlayers
        );

    const { loading: putLoading, dispatchApiRequest: updateVideoPlayer } =
        useSwitcherClient(
            (client) => client.projectsVideoPlayer_PutVideoPlayer
        );

    const { t } = useTranslation();

    const handleSubmit = useCallback(async () => {
        if (!name?.length) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: enableCatalog
                        ? t("collections:errors:name-length")
                        : t("players:errors:name-length")
                })
            );
            return;
        }

        if (type === "create") {
            postVideoPlayers([
                {
                    VideoPlayers: [
                        {
                            ProjectId: userState?.userInfo?.ProjectId,
                            Name: name,
                            Description: description,
                            VideoPlayerPlaylists: [
                                {
                                    Title: "Default Playlist",
                                    IsDefault: true,
                                    VideoPlayerId: null
                                }
                            ],
                            AspectRatio: aspectRatio
                        }
                    ]
                },
                userState?.userInfo?.ProjectId
            ])
                .then((e: VideoPlayersResponse) => {
                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: enableCatalog
                                ? t(
                                      "collections-page:collection-create-success"
                                  )
                                : t("players:messages:player-create-success")
                        })
                    );
                    dispatch(postEvents({ "created-player": true }));
                    onSubmit && onSubmit(e);
                })
                .catch((e) => {
                    rollbar.error("Error creating video players", e);
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: enableCatalog
                                ? t("collections-page:collection-create-error")
                                : t("players:errors:player-create-error")
                        })
                    );
                })
                .finally(() => {
                    setIsOpen(false);
                });
        } else {
            updateVideoPlayer([
                videoPlayer?.Id,
                {
                    VideoPlayer: {
                        ...currentVideoPlayerProfile?.VideoPlayer,
                        ...(exists(description) && {
                            Description: description
                        }),
                        Name: name
                    }
                },
                userState?.userInfo?.ProjectId
            ])
                .then(() => {
                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: enableCatalog
                                ? t(
                                      "collections-page:collection-update-success"
                                  )
                                : t("players:messages:player-update-success")
                        })
                    );
                    onSubmit && onSubmit();
                })
                .catch((e) => {
                    rollbar.error("Error updating video player", e);
                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: enableCatalog
                                ? t("collections-page:collection-update-error")
                                : t("players:errors:player-update-error")
                        })
                    );
                })
                .finally(() => {
                    setIsOpen(false);
                });
        }
    }, [
        postVideoPlayers,
        updateVideoPlayer,
        type,
        dispatch,
        name,
        t,
        setIsOpen,
        description,
        userState,
        videoPlayer?.Id,
        onSubmit,
        currentVideoPlayerProfile,
        aspectRatio,
        enableCatalog
    ]);

    return (
        <ModalBase
            preventDismiss={postLoading || putLoading}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            stylesOverride={{
                padding: "0",
                height: "auto",
                maxWidth: "700px"
            }}
        >
            <>
                <div className={styles["header"]}>
                    {type === "create"
                        ? enableCatalog
                            ? t("collections-page:create-collection")
                            : t("players:create-player")
                        : t("buttons:edit-details")}

                    <button
                        className={styles["close-btn"]}
                        onClick={() => setIsOpen(false)}
                    >
                        <CloseIcon />
                    </button>
                </div>

                <div className={styles["content"]}>
                    <div className={styles["label-row"]}>
                        <label htmlFor="name">{t("switcher-cloud:name")}</label>

                        <small>{`${name?.length ?? 0} / 50`}</small>
                    </div>
                    <input
                        id="name"
                        type="text"
                        className={`${styles["name-input"]} form-control`}
                        value={name}
                        maxLength={50}
                        onChange={(e) => {
                            setName(e?.currentTarget?.value);
                        }}
                    />

                    <div className={styles["label-row"]}>
                        <label htmlFor="description">
                            {t("video-upload:description")}
                        </label>

                        <small>{`${description?.length ?? 0} / 500`}</small>
                    </div>
                    <textarea
                        id="description"
                        className={`${styles["description-input"]} form-control`}
                        value={description}
                        maxLength={500}
                        onChange={(e) =>
                            setDescription(e?.currentTarget?.value)
                        }
                    />
                </div>

                <div className={styles["footer"]}>
                    <button
                        className="btn btn-text"
                        onClick={() => setIsOpen(false)}
                    >
                        {t("buttons:cancel")}
                    </button>

                    <button className="btn btn-primary" onClick={handleSubmit}>
                        {type === "create"
                            ? t("buttons:create")
                            : t("buttons:save")}
                    </button>
                </div>
            </>
        </ModalBase>
    );
};

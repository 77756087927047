import React from "react";
import ErrorIcon from "assets/icons/error-filled.svg?react";
import styles from "../CreateOrUpdateSwitcherPlayerModal/index.module.scss";
import mpstyles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { GatedContentPassFormProps } from ".";
import { Errors } from "hooks/useForm";
import { formatNumericalInputToTwoDecimals } from "helpers/numbers";

export const OneTimePassField = ({
    values,
    values: { oneTimePrice },
    errors,
    onChange
}: {
    values: GatedContentPassFormProps;
    errors: Errors;
    onChange: (values: GatedContentPassFormProps) => void;
}) => {
    const { t } = useTranslation();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e?.currentTarget?.value;
        const oldValue = oneTimePrice;

        const formattedValue = formatNumericalInputToTwoDecimals(
            newValue,
            oldValue
        );

        onChange({
            ...values,
            oneTimePrice: formattedValue
        });
    };

    return (
        <>
            <div className={`${mpstyles["pricing-form-expanded-content"]}`}>
                <div className={styles["label-row"]}>
                    <label htmlFor="price">{"Price"}</label>
                </div>
                <div className={mpstyles["price-row"]}>
                    <span className={mpstyles["price-currency"]}>US$</span>
                    <input
                        id="price"
                        type="number"
                        min={import.meta.env.VITE_MINIMUM_ONE_TIME_PRICE}
                        className={`form-control ${mpstyles["price-input"]}`}
                        value={oneTimePrice}
                        placeholder={t("gated-content-modal:price-placeholder")}
                        onChange={handleChange}
                    />
                </div>
                {errors.oneTimePrice && (
                    <div className={mpstyles["sub-error"]}>
                        <ErrorIcon /> {errors.oneTimePrice}
                    </div>
                )}
            </div>
        </>
    );
};

import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { RootState } from "store/reducers";
import { useTranslation } from "react-i18next";
import commonStyles from "components/widgets/CommonStyles.module.scss";
import moduleStyles from "./VideoPlayerPage.module.scss";
import EmbedIcon from "assets/icons/embed.svg?react";
import SwitcherShareIcon from "assets/icons/share.svg?react";
import ChevronRight from "assets/icons/chevron-right.svg?react";
import DefaultDisplayIcon from "assets/icons/display.svg?react";
import PlayerAppearance from "assets/icons/brandkit.svg?react";
import Links from "assets/icons/platforms.svg?react";
import VoDIcon from "assets/icons/vod.svg?react";
import GatedIcon from "assets/icons/gated.svg?react";
import { useCallback } from "react";
import { Banner } from "components/banners/Banner";
import InfoIcon from "assets/icons/info.svg?react";
import { Link } from "react-navi";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { tryGetProp } from "utils/js-utils";
import {
    VideoPlayer,
    VideoPlayerAspectRatio,
    VideoPlayerResponse
} from "@switcherstudio/switcher-api-client";
import { exists } from "helpers/booleans";
import { usePageHeader } from "hooks/usePageHeader";
import { Dropdown, DropdownButton } from "react-bootstrap";
import ThreeDotsIcon from "assets/icons/more-vertical.svg?react";
import styles from "./VideoPlayerPage.module.scss";
import { CreateOrUpdateSwitcherPlayerModal } from "components/modal/CreateOrUpdateSwitcherPlayerModal";
import { DeleteModal } from "../../../components/modal/DeleteModal";
import { Toggle } from "components/inputs/toggle/Toggle";
import { useClaimCheck } from "hooks/useClaimCheck";
import { postEvents } from "store/events/thunks";
import { useRedirectIfDisallowed } from "hooks/useRedirectIfDisallowed";
import { EditCustomSlugModal } from "components/modal/EditCustomSlugModal";
import { Modals } from "store/modal/types";
import { setActiveModal } from "store/modal/slice";
import { InlineButton } from "components/buttons/InlineButton";
import { EmbedPreview } from "components/embed-preview";
import { useNavigate } from "hooks/useNavigate";

const iconColor = {
    backgroundColor: "#0C4F60"
};

export const VideoPlayerPage: React.FC = (props) => {
    const videoPlayerDisabled = useClaimCheck("videoplayer.disabled");
    useRedirectIfDisallowed(() => !videoPlayerDisabled, "/switcher-players");

    const { t } = useTranslation("video-player-page");
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.user);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [deletePlayerModalOpen, setDeletePlayerModalOpen] =
        useState<boolean>(false);

    const [clipboardExists] = useState(!!navigator.clipboard);
    const [showCompatEmbed, setShowCompatEmbed] = useState(false);
    const [videoPlayerId] = useState(tryGetProp(props, "videoPlayerId"));

    const hasVideoPlayerAccess = useClaimCheck("videoplayer");
    const hasGatedContentAccess = useClaimCheck("gatedcontent");

    const [videoPlayer, setVideoPlayer] = useState<VideoPlayer>();
    const [videoPlayerInfo, setVideoPlayerInfo] =
        useState<VideoPlayerResponse>();
    const { navigate } = useNavigate();

    const [hasMultiplePlayers, setHasMultiplePlayers] =
        useState<boolean>(false);

    // clear switcher player app from embed.js
    useEffect(() => {
        return () => {
            window.switcherPlayerApp = undefined;
        };
    }, []);

    const { dispatchApiRequest: getVideoPlayerInfo } = useSwitcherClient(
        (client) => client.videoPlayers_GetVideoPlayer,
        {
            requestImmediately: false
        }
    );

    const { dispatchApiRequest: refetchVideoPlayers, loading } =
        useSwitcherClient(
            (client) => client.projectsVideoPlayer_GetVideoPlayers,
            {
                requestImmediately: true,
                args: [user?.userInfo?.ProjectId],
                onSuccess: async (videoPlayersResponse) => {
                    const _videoPlayer = exists(videoPlayerId)
                        ? videoPlayersResponse?.find(
                              (vp) => vp.VideoPlayer.Id === videoPlayerId
                          )?.VideoPlayer
                        : videoPlayersResponse?.[0].VideoPlayer;

                    setHasMultiplePlayers(videoPlayersResponse?.length > 1);
                    setVideoPlayer(_videoPlayer);

                    let _videoPlayerInfo: VideoPlayerResponse;
                    if (hasVideoPlayerAccess) {
                        _videoPlayerInfo = await getVideoPlayerInfo([
                            _videoPlayer.Id
                        ]);
                        _videoPlayerInfo.EmbedCode =
                            _videoPlayerInfo.EmbedCode.replace(/\s\s+/g, " ");
                    } else {
                        _videoPlayerInfo = {
                            ProjectId: "0",
                            ProjectName: "DEMO",
                            MicrositeUrl:
                                import.meta.env.VITE_SWITCHER_PLAYER_URL +
                                "/watch?p=" +
                                _videoPlayer.Id,
                            EmbedCode:
                                '<div class="dff402f7-5be0-4890-b831-95c5b63ddb42" data-hostname="' +
                                import.meta.env.VITE_SWITCHER_PLAYER_URL +
                                '" data-path="/embed" data-videoplayerid=' +
                                _videoPlayer.Id +
                                ' data-location="iframe"></div><script src = "' +
                                import.meta.env.VITE_SWITCHER_PLAYER_URL +
                                '/embed.js"></script>'
                        };
                    }
                    setVideoPlayerInfo(_videoPlayerInfo);
                    if (_videoPlayer?.Name !== videoPlayer?.Name) {
                        updatePageHeader({
                            title: `"${_videoPlayer?.Name}" ${t(
                                "video-player-page:page-title"
                            )}`
                        });
                    }
                }
            }
        );

    const { dispatchApiRequest: updateVideoPlayer } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_PutVideoPlayer,
        {
            onSuccess: async () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t(`collection-page:custom-slug-update-success`)
                    })
                );
                await refetchVideoPlayers();
            },
            onError: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t(`collection-page:custom-slug-update-error`)
                    })
                );
            }
        }
    );

    const compatModeAvailable = useMemo(() => {
        return !(
            videoPlayerInfo?.VideoPlayerEntitlements?.ProductEntitlements
                ?.length > 0 ||
            videoPlayerInfo?.BroadcastEntitlements?.ProductEntitlements
                ?.length > 0
        );
    }, [videoPlayerInfo]);

    const playerName = useMemo(() => {
        if (!loading) {
            return videoPlayer?.Name
                ? `"${videoPlayer?.Name}" ${t("video-player-page:page-title")}`
                : `"${t("players:untitled-player")}" ${t(
                      "video-player-page:page-title"
                  )}`;
        }
    }, [t, videoPlayer?.Name, loading]);

    const { dispatchApiRequest: deletePlayer } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_DeleteVideoPlayer
    );

    const handleDeleteSuccess = useCallback(() => {
        deletePlayer([videoPlayer?.Id, user?.userInfo?.ProjectId])
            .then(() => {
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t("players:messages:delete-success")
                    })
                );

                navigate("/switcher-players");
            })
            .catch(() => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("players:errors:delete-error")
                    })
                );
            })
            .finally(() => setDeletePlayerModalOpen(false));
    }, [deletePlayer, videoPlayer, user, dispatch, t, navigate]);

    const { updatePageHeader } = usePageHeader({
        title: playerName ?? "",
        backNavigation: "/switcher-players",
        backNavigationText: t("video-player-page:back-navigation-text"),
        shouldHeaderWrap: false,
        customButtons: (
            <DropdownButton
                drop="down"
                title={<ThreeDotsIcon />}
                className={styles["dropdown-button"]}
                rootCloseEvent={"mousedown"}
            >
                <Dropdown.Item
                    key={"edit"}
                    className={`${styles["dropdown-item"]} ${
                        !hasMultiplePlayers && styles["bottom"]
                    }`}
                    onClick={() => setModalOpen(true)}
                >
                    {t("buttons:edit-details")}
                </Dropdown.Item>
                {hasMultiplePlayers && (
                    <Dropdown.Item
                        key="can"
                        className={`${styles["dropdown-item"]} ${styles["delete"]} ${styles["bottom"]}`}
                        onClick={() => setDeletePlayerModalOpen(true)}
                    >
                        {t("buttons:delete")}
                    </Dropdown.Item>
                )}
            </DropdownButton>
        )
    });

    const compatEmbed = useMemo(
        () =>
            `<iframe width="560" height="315" allowfullscreen="" frameborder="0" scrolling="auto" src="${
                import.meta.env.VITE_SWITCHER_PLAYER_URL
            }/embed?p=${videoPlayer?.Id}"></iframe>`,
        [videoPlayer]
    );

    const copyLink = useCallback(async () => {
        if (clipboardExists) {
            await navigator.clipboard.writeText(videoPlayerInfo?.MicrositeUrl);

            dispatch(
                addNotification({
                    type: NotificationType.Info,
                    message: "messages:copy-share-link"
                })
            );
        }
    }, [dispatch, videoPlayerInfo, clipboardExists]);

    const copyEmbedCode = useCallback(async () => {
        if (clipboardExists) {
            await navigator.clipboard.writeText(
                showCompatEmbed ? compatEmbed : videoPlayerInfo?.EmbedCode
            );

            dispatch(postEvents({ "copied-code": true }));
            dispatch(
                addNotification({
                    type: NotificationType.Info,
                    message: "messages:copy-embed-code"
                })
            );
        }
    }, [
        dispatch,
        videoPlayerInfo,
        clipboardExists,
        showCompatEmbed,
        compatEmbed
    ]);

    const handleUpdateCustomSlug = useCallback(
        (customSlug: string) => {
            updateVideoPlayer([
                videoPlayer?.Id,
                {
                    VideoPlayer: {
                        ...videoPlayer,
                        CustomSlug: customSlug
                    }
                },
                user?.userInfo?.ProjectId
            ]);
        },
        [updateVideoPlayer, user, videoPlayer]
    );

    const openEditLinkModal = useCallback(() => {
        dispatch(
            setActiveModal({
                id: Modals.EditBroadcastModal,
                type: Modals.EditBroadcastModal,
                component: (
                    <EditCustomSlugModal
                        currentSlug={videoPlayerInfo?.VideoPlayer.CustomSlug}
                        onSave={handleUpdateCustomSlug}
                    />
                )
            })
        );
    }, [dispatch, handleUpdateCustomSlug, videoPlayerInfo]);

    return (
        videoPlayerInfo && (
            <>
                <div className="row">
                    <div className="col-lg-8">
                        <EmbedPreview
                            embedString={videoPlayerInfo?.EmbedCode}
                            isVertical={
                                videoPlayer?.AspectRatio ===
                                VideoPlayerAspectRatio.NineBySixteen
                            }
                            playerId={videoPlayer?.Id}
                            footer={
                                <VideoPlayerPageEmbedFooter
                                    hasVideoPlayerAccess={hasVideoPlayerAccess}
                                    hasGatedContentAccess={
                                        hasGatedContentAccess
                                    }
                                    videoPlayer={videoPlayer}
                                    t={t}
                                />
                            }
                        />
                    </div>
                    <div className="col-lg-4">
                        <div>
                            <div className={`${moduleStyles["widget"]}`}>
                                <div className={moduleStyles["title"]}>
                                    <div
                                        className={commonStyles["icon"]}
                                        style={iconColor}
                                    >
                                        <EmbedIcon />
                                    </div>
                                    <div
                                        className={`${commonStyles["title-text"]} ${moduleStyles["title-color"]}`}
                                    >
                                        {t("video-player-page:embed-title")}
                                    </div>
                                </div>

                                <p
                                    className={`text-small ${moduleStyles["text-color"]}`}
                                >
                                    {t("video-player-page:embed-subhead")}
                                </p>

                                <div
                                    className={`${
                                        moduleStyles["embed-field-banner"]
                                    } ${
                                        showCompatEmbed
                                            ? moduleStyles["active"]
                                            : ""
                                    }`}
                                >
                                    <Banner
                                        header={t(
                                            "video-player-page:compat-tooltip-body"
                                        )}
                                        cssClass="widget-warning"
                                        icon={<InfoIcon />}
                                    />
                                </div>
                                <div>
                                    <Toggle
                                        on={showCompatEmbed}
                                        onToggle={() => {
                                            setShowCompatEmbed(
                                                !showCompatEmbed
                                            );
                                        }}
                                        label={
                                            compatModeAvailable
                                                ? t(
                                                      "video-player-page:compat-mode-title"
                                                  )
                                                : t(
                                                      "video-player-page:compat-mode-title-disabled"
                                                  )
                                        }
                                        className={`${moduleStyles["toggle"]}`}
                                        reverseLayout
                                        disabled={!compatModeAvailable}
                                    />
                                </div>
                                <div className={moduleStyles["footer"]}>
                                    <div
                                        className={`${moduleStyles["button-container"]} ${moduleStyles["button-container-embed"]}`}
                                    >
                                        <button
                                            className={`btn btn-primary`}
                                            onClick={copyEmbedCode}
                                            title={
                                                showCompatEmbed
                                                    ? compatEmbed
                                                    : videoPlayerInfo?.EmbedCode
                                            }
                                        >
                                            {t(
                                                "video-player-page:embed-action-copy"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`${moduleStyles["widget"]}`}>
                            <div className={moduleStyles["title"]}>
                                <div
                                    className={commonStyles["icon"]}
                                    style={iconColor}
                                >
                                    <SwitcherShareIcon />
                                </div>
                                <div
                                    className={`${commonStyles["title-text"]} ${moduleStyles["title-color"]}`}
                                >
                                    {t("video-player-page:share-title")}
                                </div>
                            </div>

                            <p
                                className={`text-small ${moduleStyles["text-color"]}`}
                            >
                                {t("video-player-page:share-subhead")}
                            </p>

                            <div
                                className={`${moduleStyles["embed-field-wrapper"]}`}
                            >
                                <span
                                    className={moduleStyles["embed-field"]}
                                    role="textbox"
                                    aria-readonly
                                >
                                    {videoPlayerInfo?.MicrositeUrl}
                                </span>
                                <InlineButton onClick={openEditLinkModal}>
                                    {t("edit-custom-slug:button")}
                                </InlineButton>
                            </div>

                            <div className={moduleStyles["footer"]}>
                                <div
                                    className={moduleStyles["button-container"]}
                                >
                                    <button
                                        className={`btn btn-primary`}
                                        onClick={copyLink}
                                        title={videoPlayerInfo?.MicrositeUrl}
                                    >
                                        {t(
                                            "video-player-page:share-action-copy"
                                        )}
                                    </button>
                                    <a
                                        className={`btn btn-secondary`}
                                        href={videoPlayerInfo?.MicrositeUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {t(
                                            "video-player-page:share-action-open"
                                        )}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CreateOrUpdateSwitcherPlayerModal
                    isOpen={modalOpen}
                    setIsOpen={setModalOpen}
                    type="update"
                    onSubmit={() => {
                        refetchVideoPlayers();
                    }}
                    videoPlayer={videoPlayer}
                />
                <DeleteModal
                    isOpen={deletePlayerModalOpen}
                    setIsOpen={setDeletePlayerModalOpen}
                    onSubmit={handleDeleteSuccess}
                    titleText={t("players:messages:delete-player")}
                    subMsg={t("players:messages:no-back")}
                />
            </>
        )
    );
};

const VideoPlayerPageEmbedFooter = ({
    hasVideoPlayerAccess,
    hasGatedContentAccess,
    videoPlayer,
    t
}: {
    hasVideoPlayerAccess: boolean;
    hasGatedContentAccess: boolean;
    videoPlayer: VideoPlayer;
    t: any;
}) => {
    return (
        <>
            {hasVideoPlayerAccess && (
                <>
                    <Link
                        className={`${moduleStyles["link-container"]}`}
                        href={`/switcher-player/${videoPlayer?.Id}/playlist`}
                    >
                        <div
                            className={`${commonStyles["icon"]} ${moduleStyles["link-container-icon"]}`}
                        >
                            <VoDIcon />
                        </div>
                        <div className={moduleStyles["link-container-text"]}>
                            <h6>{t("video-player-page:playlist")}</h6>
                            <p>{t("video-player-page:playlist-description")}</p>
                        </div>
                        <ChevronRight
                            className={moduleStyles["link-container-arrow"]}
                        />
                    </Link>
                    {hasGatedContentAccess && (
                        <Link
                            className={moduleStyles["link-container"]}
                            href={`/switcher-player/${videoPlayer?.Id}/gated-content`}
                        >
                            <div
                                className={`${commonStyles["icon"]} ${moduleStyles["link-container-icon"]}`}
                            >
                                <GatedIcon />
                            </div>
                            <div
                                className={moduleStyles["link-container-text"]}
                            >
                                <h6>{t("video-player-page:gated-content")}</h6>
                                <p>
                                    {t(
                                        "video-player-page:gated-content-description"
                                    )}
                                </p>
                            </div>
                            <ChevronRight
                                className={moduleStyles["link-container-arrow"]}
                            />
                        </Link>
                    )}
                    <Link
                        className={moduleStyles["link-container"]}
                        href={`/switcher-player/${videoPlayer?.Id}/player-status`}
                    >
                        <div
                            className={`${commonStyles["icon"]} ${moduleStyles["link-container-icon"]}`}
                        >
                            <DefaultDisplayIcon />
                        </div>
                        <div className={moduleStyles["link-container-text"]}>
                            <h6>{t("video-player-page:player-status")}</h6>
                            <p>
                                {t(
                                    "video-player-page:player-status-description"
                                )}
                            </p>
                        </div>
                        <ChevronRight
                            className={moduleStyles["link-container-arrow"]}
                        />
                    </Link>

                    <Link
                        className={moduleStyles["link-container"]}
                        href={`/switcher-player/${videoPlayer?.Id}/interactive-links`}
                    >
                        <div
                            className={`${commonStyles["icon"]} ${moduleStyles["link-container-icon"]}`}
                        >
                            <Links />
                        </div>
                        <div className={moduleStyles["link-container-text"]}>
                            <h6>{t("video-player-page:interactive-links")}</h6>
                            <p>
                                {t(
                                    "video-player-page:interactive-links-description"
                                )}
                            </p>
                        </div>
                        <ChevronRight
                            className={moduleStyles["link-container-arrow"]}
                        />
                    </Link>
                </>
            )}
            <Link
                className={`${moduleStyles["link-container"]}`}
                href={`/switcher-player/${videoPlayer?.Id}/appearance`}
            >
                <div
                    className={`${commonStyles["icon"]} ${moduleStyles["link-container-icon"]}`}
                >
                    <PlayerAppearance />
                </div>
                <div className={moduleStyles["link-container-text"]}>
                    <h6>{t("video-player-page:appearance")}</h6>
                    <p>{t("video-player-page:appearance-description")}</p>
                </div>
                <ChevronRight
                    className={moduleStyles["link-container-arrow"]}
                />
            </Link>
        </>
    );
};

import React, { useEffect, useState } from "react";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useNavigate } from "hooks/useNavigate";
import { DestinationSelector } from "components/destination-selector/DestinationSelector";
import { youtube } from "api/youtube/youtube-client";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { setLoading } from "../../../../../store/loading/slice";
import rollbar from "helpers/rollbar";
import { usePlatformActions } from "hooks/usePlatformActions";
import { PlatformId } from "../../types";
import { useTranslation } from "react-i18next";

interface YouTubeContainerProps {
    platform: StreamingProvider;
}

export const YouTubeContainer: React.FC<YouTubeContainerProps> = ({
    platform
}: YouTubeContainerProps) => {
    const { navigate } = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const [destinations, setDestinations] = useState([]);
    const { unlink } = usePlatformActions(PlatformId.Youtube);
    const { t } = useTranslation("platforms");

    const onClick = async (destination: { id: string }) => {
        navigate(
            `/platforms/stream/${platform.Id}?destinationId=${destination.id}`
        );
    };

    useEffect(() => {
        async function getChannelInfo() {
            try {
                dispatch(setLoading(1));
                const { items } = await youtube.channels.list({
                    mine: true,
                    part: "id,snippet,contentDetails"
                });
                setDestinations(items);
                dispatch(setLoading(-1));
            } catch (e) {
                dispatch(setLoading(-1));
                rollbar.error(
                    "Error getting and setting youtube destinations",
                    e
                );
                await unlink();
                navigate(`/platforms/connect/${PlatformId.Youtube}`);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message:
                            e?.message ||
                            t("token-expired", { platformName: platform.Name })
                    })
                );
            }
        }

        getChannelInfo();
    }, [dispatch, navigate, unlink, t, platform.Name]);

    return (
        <DestinationSelector
            destinations={destinations}
            imgSrcKey={"snippet.thumbnails.default.url"}
            destinationNameKey="snippet.title"
            onDestinationClick={onClick}
        />
    );
};

import { useEffect } from "react";

export const useOnLocationChange = (callback: () => void) => {
    /*
        This custom hook can be used, independent of the implemented
        router, to trigger a callback when the location changes. Because
        different routers use different techniques for local navigation,
        we can't rely on the popstate event alone. Ideally, in the future,
        we will just add an event listener to the window.navigation object;
        unfortunately, that is still an experimental feature in Firefox:
        
        https://developer.mozilla.org/en-US/docs/Web/API/Navigation_API
        
        Until this has been implemented across all major browsers, the
        approach below (overwriting the pushState and replaceState methods
        on the global history object), is used for browser compatibility.
    */

    useEffect(() => {
        let oldPushState = history.pushState;
        history.pushState = function pushState() {
            let ret = oldPushState.apply(this, arguments as any);
            window.dispatchEvent(new Event("pushstate"));
            window.dispatchEvent(new Event("locationchange"));
            return ret;
        };

        let oldReplaceState = history.replaceState;
        history.replaceState = function replaceState() {
            let ret = oldReplaceState.apply(this, arguments as any);
            window.dispatchEvent(new Event("replacestate"));
            window.dispatchEvent(new Event("locationchange"));
            return ret;
        };

        window.addEventListener("popstate", () => {
            window.dispatchEvent(new Event("locationchange"));
        });

        window.addEventListener("locationchange", () => {
            callback();
        });
    }, [callback]);
};

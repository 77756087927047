import React, { useState, useCallback } from "react";
import { CatalogWidgetProps } from "./types";
import CatalogIcon from "assets/icons/vod.svg?react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { RootState } from "store/reducers";
import { Link } from "react-navi";
import { AppDispatch } from "store/store";
import { Widget } from "./Widget";
import { postEvents } from "store/events/thunks";
import { useEmbedCode } from "hooks/useEmbedCode";

export const CatalogWidget: React.FC<CatalogWidgetProps> = ({
    enabled,
    loading,
    catalogId,
    shareLink
}: CatalogWidgetProps) => {
    const { t } = useTranslation();

    const { userInfo } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch<AppDispatch>();
    const [clipboardExists] = useState(!!navigator.clipboard);
    const hasStudioPlan = userInfo?.PlanName?.toLowerCase().includes("studio");
    const { copyEmbedCode } = useEmbedCode("catalog", catalogId);

    const copyShareLink = useCallback(async () => {
        if (clipboardExists) {
            await navigator.clipboard.writeText(shareLink);
        }
        dispatch(postEvents({ "copied-code": true }));
        dispatch(
            addNotification({
                type: NotificationType.Info,
                message: "messages:copy-share-link"
            })
        );
    }, [clipboardExists, dispatch, shareLink]);

    if (!enabled) return;

    return (
        <Widget
            loading={loading}
            icon={<CatalogIcon />}
            title={
                hasStudioPlan
                    ? t("widgets:catalog-widget-title2")
                    : t("widgets:catalog-widget-title")
            }
            details={[
                {
                    infoText: t("widgets:video-player-embed-code"),
                    buttonText: t("widgets:copy"),
                    onClick: copyEmbedCode
                },
                {
                    infoText: t("widgets:video-player-share-link"),
                    buttonText: t("widgets:copy"),
                    onClick: copyShareLink
                }
            ]}
            footerSubtitle={t("widgets:catalog-message")}
            button={
                <Link className="btn" href={`/catalog`}>
                    {t("widgets:view-catalog").toUpperCase()}
                </Link>
            }
        ></Widget>
    );
};

import { useCallback, useMemo, useState } from "react";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useClaimCheck } from "hooks/useClaimCheck";
import { CatalogResponse } from "@switcherstudio/switcher-api-client";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useEmbedCode } from "./useEmbedCode";

export interface UseCatalogDataProp {
    projectId: string;
    onSuccess?: (data: CatalogResponse) => void;
    hideLoading?: boolean;
    onCustomSlugUpdateSuccess?: (data: CatalogResponse) => void;
    onCustomSlugUpdateError?: (data: string) => void;
}

export const useCatalogData = ({
    projectId,
    onSuccess,
    hideLoading,
    onCustomSlugUpdateSuccess,
    onCustomSlugUpdateError
}: UseCatalogDataProp) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");
    const [catalogData, setCatalogData] = useState<CatalogResponse | null>(
        null
    );

    const { embedCode } = useEmbedCode("catalog", catalogData?.Details?.Id);

    // Fetch the catalog data
    const { loading: catalogFetchLoading, dispatchApiRequest: fetchCatalog } =
        useSwitcherClient((client) => client.catalog_GetCatalogByProjectId, {
            requestImmediately: true,
            args: [projectId],
            onSuccess: (data) => {
                setCatalogData(data);
                onSuccess?.(data);
            },
            hideLoading
        });

    const handleDefaultSlugUpdateSuccess = useCallback(() => {
        dispatch(
            addNotification({
                type: NotificationType.Success,
                message: t(`messages:custom-slug-update-success`)
            })
        );
    }, [dispatch, t]);

    const handleDefaultSlugUpdateError = useCallback(() => {
        dispatch(
            addNotification({
                type: NotificationType.Danger,
                message: t(`messages:custom-slug-update-error`)
            })
        );
    }, [dispatch, t]);

    const {
        dispatchApiRequest: catalogUpdateCustomSlug,
        loading: updateSlugLoading
    } = useSwitcherClient((client) => client.catalog_PutCatalogSlug, {
        requestImmediately: false,
        hideLoading,
        onSuccess: async (data) => {
            setCatalogData(data);

            !!onCustomSlugUpdateSuccess
                ? onCustomSlugUpdateSuccess(data)
                : handleDefaultSlugUpdateSuccess();
        },
        onError: (data) =>
            !!onCustomSlugUpdateError
                ? onCustomSlugUpdateError?.(data)
                : handleDefaultSlugUpdateError()
    });

    // Generate the share link using the catalog id
    const shareLink = useMemo(
        () =>
            !!catalogData?.Details?.CustomSlug
                ? `${import.meta.env.VITE_SWITCHER_PLAYER_URL}/${
                      catalogData?.Details?.CustomSlug
                  }`
                : `${import.meta.env.VITE_SWITCHER_PLAYER_URL}/watch?c=${
                      catalogData?.Details?.Id
                  }`,
        [catalogData?.Details?.Id, catalogData?.Details?.CustomSlug]
    );

    return {
        hasCatalogClaim,
        loading: catalogFetchLoading || updateSlugLoading,
        catalogData,
        embedCode,
        shareLink,
        fetchCatalog,
        catalogUpdateCustomSlug
    };
};

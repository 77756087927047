import React from "react";
import { useNavigate } from "hooks/useNavigate";
import { AssetDetailsCard } from "../AssetDetailsCard";
import { useRawFileDetails } from "./useRawFileDetails";
import { AssetCardList } from "../AssetCardList";
import { Asset } from "@switcherstudio/switcher-api-client";
import styles from "./RawFileDetailsPage.module.scss";
import { useTranslation } from "react-i18next";

interface RawFileDetailsPageProps {
    assetId: string;
}

export const RawFileDetailsPage: React.FC<RawFileDetailsPageProps> = ({
    assetId
}) => {
    const { t } = useTranslation();
    const { navigate } = useNavigate();

    const { rawFile, assetsUsingRawFile, disable, remove } =
        useRawFileDetails(assetId);

    const goToAsset = (asset: Asset) => {
        navigate(`/switcher-cloud/asset/${asset.Id}`);
    };

    return (
        <>
            {rawFile && (
                <>
                    <div className="row">
                        <div className="col">
                            <AssetDetailsCard
                                asset={rawFile}
                                headerText={t(
                                    "switcher-cloud:raw-file-details"
                                )}
                            />
                        </div>
                    </div>
                    {assetsUsingRawFile.length > 0 && (
                        <div className="row">
                            <div className="col">
                                <h4>{t("switcher-cloud:assets")}</h4>
                                <AssetCardList
                                    assets={assetsUsingRawFile}
                                    onClick={goToAsset}
                                    size="sm"
                                />
                            </div>
                        </div>
                    )}
                    <div className="col-lg-8 pl-0">
                        <div className={styles["delete-actions"]}>
                            <button
                                type="button"
                                className="btn btn-danger mb-3 mr-2"
                                onClick={remove}
                                disabled={disable}
                            >
                                {t("switcher-cloud:delete")}
                            </button>
                            <br></br>
                            {assetsUsingRawFile.length > 0 && (
                                <small>
                                    {disable
                                        ? t("switcher-cloud:raw-asset-used")
                                        : t(
                                              "switcher-cloud:assets-will-be-removed"
                                          )}
                                </small>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

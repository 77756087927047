import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomHeaderState } from "./types";

const initialState = {
    backNavigation: null,
    backNavigationText: null,
    showBreadcrumbs: false,
    breadcrumbLabels: [],
    title: null,
    subTitle: null,
    customButtons: null,
    inlineCustomButton: null,
    autoSave: null
} as CustomHeaderState;

export const header = createSlice({
    name: "header",
    initialState: initialState,
    reducers: {
        updateHeader: (
            state,
            { payload }: PayloadAction<CustomHeaderState>
        ) => {
            return {
                ...state,
                ...payload
            };
        },
        resetHeader: () => initialState
    }
});
export const { updateHeader, resetHeader } = header.actions;

export default header.reducer;

import React, { Dispatch, SetStateAction } from "react";
import mpstyles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { CheckboxInput } from "components/inputs/checkbox-input";
import { GatedContentPassFormProps, PricingModalTypes } from ".";
import { Errors } from "hooks/useForm";
import { formatNumericalInputToTwoDecimals } from "helpers/numbers";
import { Toggle } from "components/inputs/toggle/Toggle";
import { Button } from "components/buttons/Button";
import { useClaimCheck } from "hooks/useClaimCheck";

export const RecurringPassField = ({
    values,
    values: {
        recurringAnnualPrice,
        recurringAnnualPriceSelected,
        recurringMonthlyPrice,
        recurringMonthlyPriceSelected,
        recurringApplySubscriptionUpdatesNewOnly
    },
    errors,
    onChange,
    setPlayerSelectModalIsOpen,
    buttonText,
    buttonActive,
    loading = false,
    type,
    variant,
    submitDisabled,
    onSubmit
}: {
    values: GatedContentPassFormProps;
    errors: Errors;
    onChange: (values: GatedContentPassFormProps) => void;
    setPlayerSelectModalIsOpen?: Dispatch<SetStateAction<boolean>>;
    buttonText?: string;
    buttonActive?: boolean;
    loading?: boolean;
    type: PricingModalTypes;
    variant?: "catalog";
    submitDisabled?: boolean;
    onSubmit?: () => void;
}) => {
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputId = e?.currentTarget?.id;
        const newValue = e?.currentTarget?.value;
        const oldValue =
            inputId === "monthly-price-input"
                ? recurringMonthlyPrice
                : recurringAnnualPrice;

        const formattedValue = formatNumericalInputToTwoDecimals(
            newValue,
            oldValue
        );

        onChange({
            ...values,
            ...(inputId === "monthly-price-input" && {
                recurringMonthlyPrice: formattedValue
            }),
            ...(inputId === "annual-price-input" && {
                recurringAnnualPrice: formattedValue
            })
        });
    };

    const handleToggle = (applyUpdateToNewOnly: boolean) => {
        onChange({
            ...values,
            recurringApplySubscriptionUpdatesNewOnly: applyUpdateToNewOnly
        });
    };

    return (
        <>
            <div
                className={`${mpstyles["pricing-form-expanded-content"]} ${variant ? mpstyles[variant] : ""}`}
            >
                <div className={mpstyles["recurring-pass-prices-container"]}>
                    <CheckboxInput
                        checkBoxId="monthly-price-checkbox"
                        inputId="monthly-price-input"
                        inputName={
                            variant === "catalog"
                                ? t("misc:monthly-catalog-price")
                                : t("misc:monthly-price")
                        }
                        checked={recurringMonthlyPriceSelected}
                        onCheckboxChange={() => {
                            onChange({
                                ...values,
                                recurringMonthlyPriceSelected:
                                    !recurringMonthlyPriceSelected
                            });
                        }}
                        onInputChange={handleChange}
                        inputType="number"
                        min={import.meta.env.VITE_MINIMUM_MONTHLY_PRICE}
                        placeholder={t("gated-content-modal:price-placeholder")}
                        value={recurringMonthlyPrice}
                        error={errors.recurringMonthlyPrice}
                        disabled={
                            values?.recurringMonthlyPriceSelected === false
                        }
                    />
                    <CheckboxInput
                        checkBoxId="annual-price-checkbox"
                        inputId="annual-price-input"
                        inputName={
                            variant === "catalog"
                                ? t("misc:annual-catalog-price")
                                : t("misc:annual-price")
                        }
                        checked={recurringAnnualPriceSelected}
                        onCheckboxChange={() =>
                            onChange({
                                ...values,
                                recurringAnnualPriceSelected:
                                    !recurringAnnualPriceSelected
                            })
                        }
                        onInputChange={handleChange}
                        inputType="number"
                        min={import.meta.env.VITE_MINIMUM_ANNUAL_PRICE}
                        placeholder={t("gated-content-modal:price-placeholder")}
                        value={recurringAnnualPrice}
                        error={errors.recurringAnnualPrice}
                        disabled={
                            values?.recurringAnnualPriceSelected === false
                        }
                    />
                </div>
                <hr />
                {setPlayerSelectModalIsOpen && buttonText && (
                    <div className={mpstyles["add-to-players-container"]}>
                        <p>
                            {hasCatalogClaim
                                ? t("buttons:add-to-collections")
                                : t("buttons:add-to-playlist")}
                        </p>
                        <Button
                            type="badge"
                            disabled={loading}
                            isActive={buttonActive}
                            onClick={() => setPlayerSelectModalIsOpen(true)}
                        >
                            {buttonText}
                        </Button>
                    </div>
                )}
                {(variant === "catalog" ||
                    type === PricingModalTypes.Update) && (
                    <>
                        <Toggle
                            label={t(
                                "gated-content:subscription-update:update-to-new-only"
                            )}
                            on={recurringApplySubscriptionUpdatesNewOnly}
                            onToggle={() =>
                                handleToggle(
                                    !recurringApplySubscriptionUpdatesNewOnly
                                )
                            }
                            className={mpstyles["toggle"]}
                            reverseLayout={true}
                        />
                        {!recurringApplySubscriptionUpdatesNewOnly && (
                            <div
                                className={
                                    mpstyles[
                                        "recurring-update-subscribers-warning"
                                    ]
                                }
                            >
                                {t(
                                    "gated-content:subscription-update:update-to-new-only-warning"
                                )}
                            </div>
                        )}
                    </>
                )}
                {variant === "catalog" && (
                    <div className={mpstyles["save-btn-container"]}>
                        <Button
                            type="primary"
                            isSubmit
                            onClick={onSubmit}
                            disabled={submitDisabled}
                        >
                            {t("buttons:save-changes")}
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};

import React, { useEffect, useState, useCallback } from "react";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { usePlatformActions } from "hooks/usePlatformActions";
import { PlatformId } from "../types";
import { ConnectPlatformContainer } from "./ConnectPlatformContainer";
import styles from "./ConnectPlatformPage.module.scss";
import { PlatformImage } from "components/image/PlatformImage";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openConfirmation } from "store/confirmation/slice";
import { AppDispatch } from "store/store";
import { useParams } from "react-router-dom";

interface ConnectPlatformPageProps {
    platformId?: PlatformId;
}

export const ConnectPlatformPage: React.FC<ConnectPlatformPageProps> = ({
    platformId: propPlatformId
}: ConnectPlatformPageProps) => {
    const { platformId: paramPlatformId } = useParams<{
        platformId: PlatformId;
    }>();

    const platformId = propPlatformId || paramPlatformId;

    const { t } = useTranslation("platforms");
    const isMountedRef = useIsMountedRef();
    const [platform, setPlatform] = useState<StreamingProvider>();
    const [extraLinkArgs, setExtraLinkArgs] = useState<object>();
    const { link, unlink, getPlatform } = usePlatformActions(platformId);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        async function run() {
            const res = await getPlatform();
            setPlatform(res);
        }

        if (isMountedRef.current) {
            run();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMountedRef]);

    const disconnect = useCallback(async () => {
        dispatch(
            openConfirmation({
                message: "platforms:confirm-account-disconnect",
                onSuccess: async () => {
                    await unlink();
                    const res = await getPlatform();
                    setPlatform(res);
                }
            })
        );
    }, [unlink, getPlatform, dispatch]);

    const updateExtraLinkArgs = useCallback((args: object) => {
        setExtraLinkArgs((a) => {
            return { ...a, ...args };
        });
    }, []);

    return (
        <>
            {platform && (
                <div className="row justify-content-center">
                    <div className="col-lg-8 d-flex flex-column align-items-center">
                        <div className={styles["platform-image-container"]}>
                            <PlatformImage
                                platformId={platform?.Id || ""}
                                logoUrl={platform?.LogoUrl}
                            />
                        </div>
                        {platform?.IsLinked ? (
                            <button
                                className="btn btn-primary"
                                onClick={async () => {
                                    await disconnect();
                                }}
                            >
                                {t("disconnect-account-button")}
                            </button>
                        ) : (
                            <>
                                <ConnectPlatformContainer
                                    platform={platform}
                                    updateExtraLinkArgs={updateExtraLinkArgs}
                                />
                                {typeof link === "function" && (
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => link(extraLinkArgs)}
                                    >
                                        {t("connect-account-button")}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

import React from "react";
import styles from "./index.module.scss";

interface TextInputProps {
    label?: string;
    type: string;
    id: string;
    className?: string;
    placeholder?: string;
    value: string | number | string[] | undefined;
    error?: string;
    help?: string;
    horizontal?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    maxLength?: number;
    min?: number;
    leftNode?: React.ReactNode;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextInput: React.FC<TextInputProps> = ({
    label,
    type,
    id,
    className,
    placeholder,
    value,
    error,
    help,
    horizontal,
    readonly,
    disabled = false,
    maxLength,
    min,
    leftNode = <></>,
    onChange
}: TextInputProps) => {
    return (
        <>
            <div
                className={`form-group ${horizontal ? "row" : ""} ${
                    styles[className]
                }`}
            >
                {label && (
                    <label
                        className={`${horizontal ? "col-xl-2 col-form-label" : ""} ${styles["label"]}`}
                        htmlFor={id}
                    >
                        {label}
                    </label>
                )}
                <div
                    className={`${horizontal ? "col-xl-10" : ""} ${styles["search-bar"]}`}
                >
                    <span className={`form-control ${styles["input-wrapper"]}`}>
                        {leftNode}
                        <input
                            type={type}
                            id={id}
                            placeholder={placeholder}
                            value={value || ""}
                            onChange={onChange}
                            disabled={disabled}
                            readOnly={readonly}
                            maxLength={maxLength}
                            min={min}
                        />
                    </span>
                    <small
                        id={`${id}-help`}
                        aria-describedby={id}
                        className={`form-text ${
                            error ? "text-danger" : "text-muted"
                        }`}
                    >
                        {error ?? help}
                    </small>
                </div>
            </div>
        </>
    );
};

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-navi";

export const useBeforeUnload = (
    shouldRun: boolean,
    handler: () => void,
    confirmOnExit: boolean = false
): void => {
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (shouldRun) {
                if (confirmOnExit) {
                    event.preventDefault();
                    event.returnValue = "";
                } else {
                    handler && handler();
                }
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        const unblock =
            shouldRun && confirmOnExit
                ? history.block(t("message:confirm-before-unload"))
                : history.block(() => {
                      handleBeforeUnload;
                  });

        return () => {
            unblock();
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [shouldRun, handler, history, confirmOnExit, t]);
};

import React, {
    useEffect,
    useState,
    useCallback,
    useMemo,
    useRef
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { RootState } from "store/reducers";
import { useTranslation } from "react-i18next";
import rollbar from "helpers/rollbar";
import styles from "./VideoPlayerSettings.module.scss";
import { ColorPicker } from "components/inputs/colorpicker/ColorPicker";
import {
    VideoPlayerAspectRatio,
    VideoPlayerProfile,
    VideoPlayerResponse
} from "@switcherstudio/switcher-api-client";
import { Errors, useForm } from "hooks/useForm";
import { tryGetProp } from "utils/js-utils";
import { Toggle } from "components/inputs/toggle/Toggle";
import { usePageHeader } from "hooks/usePageHeader";
import { Trans } from "react-i18next";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { Select } from "components/inputs/select/Select";
import { StyledCheckboxOrRadio } from "components/inputs/checkbox/StyledCheckboxOrRadio";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { Button } from "components/buttons/Button";
import { EmbedPreview } from "components/embed-preview";
import { defaultInteractiveTabMap, idleStateMap } from "helpers/playerApiMaps";

declare global {
    interface Window {
        switcherPlayerApp: any;
    }
}

export const AppearancePage: React.FC = (props) => {
    const { t } = useTranslation("video-player-page");
    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.user);

    const projectId = user?.userInfo?.ProjectId;
    const [embedPreviewKey, setEmbedPreviewKey] = useState<number>(0);
    const [localPlayerResponse, setLocalPlayerResponse] =
        useState<VideoPlayerResponse>(null);
    const [initPlayerResponse, setInitPlayerResponse] =
        useState<VideoPlayerResponse>(null);
    const [hasPendingChanges, setHasPendingChanges] = useState<boolean>(false);
    const isMountedRef = useIsMountedRef();
    const formRef = useRef<HTMLFormElement>(null);

    const { loading: getVideoPlayersLoading } = useSwitcherClient(
        (client) => client.videoPlayers_GetVideoPlayer,
        {
            requestImmediately: true,
            args: [tryGetProp(props, "videoPlayerId")],
            onSuccess: (data) => {
                setLocalPlayerResponse(data);
                setInitPlayerResponse(data);
            }
        }
    );

    const {
        loading: updateVideoPlayerLoading,
        dispatchApiRequest: updateVideoPlayer
    } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_PutVideoPlayer
    );

    const loading = useMemo(
        () => getVideoPlayersLoading || updateVideoPlayerLoading,
        [getVideoPlayersLoading, updateVideoPlayerLoading]
    );

    const playerName = useMemo(() => {
        if (!!localPlayerResponse) {
            return localPlayerResponse?.VideoPlayer?.Name
                ? `"${localPlayerResponse?.VideoPlayer?.Name}" ${t(
                      "customization-page:customization"
                  )}`
                : `"${t("players:untitled-player")}" ${t("customization-page:customization")}`;
        }
    }, [t, localPlayerResponse]);

    const submitAction = useCallback(async () => {
        try {
            /** Save video player edits */
            await updateVideoPlayer([
                localPlayerResponse?.VideoPlayer?.Id,
                {
                    VideoPlayer: {
                        ...localPlayerResponse?.VideoPlayer
                    }
                },
                projectId
            ]);

            setInitPlayerResponse(localPlayerResponse);

            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "messages:video-profile-success"
                })
            );
        } catch (e) {
            rollbar.error("Error updating video player appearance", e);

            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: e.message
                })
            );
        }

        setEmbedPreviewKey((prev) => prev + 1);
    }, [dispatch, updateVideoPlayer, localPlayerResponse, projectId]);

    const handleVideoPlayerSettingsChange = (key: string, value: any) => {
        let updatedProfile = { ...localPlayerResponse };
        updatedProfile.VideoPlayer = {
            ...updatedProfile.VideoPlayer,
            [key]: value === "" ? null : value
        };

        setLocalPlayerResponse(updatedProfile);
        customHandleChange(updatedProfile);
    };

    const {
        handleSubmit,
        /** only responsible for setting values internally */
        customHandleChange,
        values,
        errors
    } = useForm<VideoPlayerProfile>(
        localPlayerResponse,
        submitAction,
        (values) => {
            let errors: Errors = {};
            // eslint-disable-next-line
            const hexRegExp = /^#([0-9a-f]{6})$/i;
            if (
                values?.VideoPlayer?.PrimaryColor &&
                !hexRegExp.test(values?.VideoPlayer?.PrimaryColor)
            ) {
                //(errors.VideoPlayerSettings ??= {}).PrimaryColor = t("errors:color-error");
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.PrimaryColor =
                    t("errors:color-error");
            }
            if (
                values?.VideoPlayer?.SecondaryColor &&
                !hexRegExp.test(values?.VideoPlayer?.SecondaryColor)
            ) {
                //(errors.VideoPlayerSettings ??= {}).SecondaryColor = t("errors:color-error");
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.SecondaryColor =
                    t("errors:color-error");
            }
            if (
                values?.VideoPlayer?.DonateButtonColor &&
                !hexRegExp.test(values?.VideoPlayer?.DonateButtonColor)
            ) {
                //(errors.VideoPlayerSettings ??= {}).DonateButtonColor = t("errors:color-error");
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.DonateButtonColor =
                    t("errors:color-error");
            }
            if (
                values?.VideoPlayer?.DonateButtonTextColor &&
                !hexRegExp.test(values?.VideoPlayer?.DonateButtonTextColor)
            ) {
                //(errors.VideoPlayerSettings ??= {}).DonateButtonTextColor = t("errors:color-error");
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.DonateButtonTextColor =
                    t("errors:color-error");
            }
            if (
                values?.VideoPlayer?.LogoBackgroundColor &&
                !hexRegExp.test(values?.VideoPlayer?.LogoBackgroundColor)
            ) {
                //(errors.VideoPlayerSettings ??= {}).LogoBackgroundColor = t("errors:color-error");
                if (!errors.VideoPlayerSettings)
                    errors.VideoPlayerSettings = {};
                errors.VideoPlayerSettings.LogoBackgroundColor =
                    t("errors:color-error");
            }

            return errors;
        },
        false
    );

    useEffect(() => {
        setHasPendingChanges(
            JSON.stringify(localPlayerResponse) !==
                JSON.stringify(initPlayerResponse)
        );
    }, [initPlayerResponse, localPlayerResponse, setHasPendingChanges]);

    /** Only allow users to set these tabs */
    const defaultInteractiveTabOptions = [
        { value: 0, text: t("customization-page:details") },
        { value: 4, text: t("customization-page:playlist") },
        { value: 2, text: t("customization-page:shop") }
    ];

    /** Trigger form submission from outside form */
    const handleSaveButtonClick = () => {
        if (formRef.current) {
            handleSubmit();
        }
    };

    const handleRevertButtonClick = () => {
        if (formRef.current) {
            setLocalPlayerResponse(initPlayerResponse);
        }
    };

    usePageHeader({
        title: playerName ?? "",
        subTitle: (
            <Trans i18nKey={t("customization-page:appearance-subtitle")} />
        )
    });

    /** Update form values as playerProfile is set/present */
    useEffect(() => {
        customHandleChange(localPlayerResponse);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localPlayerResponse]);

    /** Send the updated video profile values to the player embed.
     * We are accessing a method written in the player project and
     * exposed by penpal. We are calling that method with the new values
     * and updating the player preview with those values. */
    useEffect(() => {
        try {
            if (isMountedRef.current) {
                const profileUpdate = {
                    ...localPlayerResponse?.VideoPlayer,
                    PrimaryColor:
                        localPlayerResponse?.VideoPlayer?.PrimaryColor?.toLocaleUpperCase() ??
                        "#000000",
                    SecondaryColor:
                        localPlayerResponse?.VideoPlayer?.SecondaryColor?.toLocaleUpperCase() ??
                        "#FFFFFF",
                    LogoBackgroundColor:
                        localPlayerResponse?.VideoPlayer?.LogoBackgroundColor?.toLocaleUpperCase() ??
                        "#000000",
                    PlayerControlsColor:
                        localPlayerResponse?.VideoPlayer?.PlayerControlsColor?.toLocaleUpperCase() ??
                        "#FFFFFF",
                    DefaultInteractiveTab:
                        defaultInteractiveTabMap[
                            localPlayerResponse?.VideoPlayer
                                ?.DefaultInteractiveTab
                        ],
                    IdleState:
                        idleStateMap[
                            localPlayerResponse?.VideoPlayer?.IdleState
                        ]
                };

                // vp expects camelCase keys
                const mappedProfileUpdate = Object.keys(profileUpdate).reduce(
                    (acc, key) => {
                        const newKey =
                            key.charAt(0).toLowerCase() + key.slice(1);
                        acc[newKey] = profileUpdate[key];
                        return acc;
                    },
                    {}
                );

                window.switcherPlayerApp?.playerCollection[
                    localPlayerResponse?.VideoPlayer?.Id
                ]?.mainConnection?.setCollectionDetails(mappedProfileUpdate);
                window.switcherPlayerApp?.playerCollection[
                    localPlayerResponse?.VideoPlayer?.Id
                ]?.auxConnection?.setCollectionDetails(mappedProfileUpdate);
            }
        } catch (e) {}
    }, [localPlayerResponse?.VideoPlayer, isMountedRef]);

    useBeforeUnload(hasPendingChanges, null, true);

    if (localPlayerResponse !== null) {
        return (
            <>
                <div className="row">
                    <div className="col-lg-4">
                        <form
                            onSubmit={handleSubmit}
                            onKeyDown={(e) =>
                                e.key === "Enter" ? handleSubmit(e) : null
                            }
                            ref={formRef}
                        >
                            <div className={styles["form-content"]}>
                                <h5 className={styles["sub-header"]}>
                                    {t("customization-page:playback-behavior")}
                                </h5>
                                <hr />
                                <Toggle
                                    on={
                                        values?.VideoPlayer
                                            ?.AutoOpenInteractiveMode
                                    }
                                    onToggle={() =>
                                        handleVideoPlayerSettingsChange(
                                            "AutoOpenInteractiveMode",
                                            !values?.VideoPlayer
                                                ?.AutoOpenInteractiveMode
                                        )
                                    }
                                    label={t(
                                        "customization-page:interactive-mode"
                                    )}
                                    className={styles["sub-sub-header"]}
                                    reverseLayout
                                />
                                <Select
                                    id="default-interactive"
                                    onChange={(e) => {
                                        handleVideoPlayerSettingsChange(
                                            "DefaultInteractiveTab",
                                            parseInt(e.target.value)
                                        );
                                    }}
                                    options={defaultInteractiveTabOptions}
                                    selected={
                                        localPlayerResponse?.VideoPlayer
                                            ?.DefaultInteractiveTab
                                    }
                                    label={t(
                                        "customization-page:default-interactive-tab"
                                    )}
                                />
                                <h5 className={styles["sub-header"]}>
                                    {t(
                                        "customization-page:content-orientation"
                                    )}
                                </h5>
                                <h6 className={styles["sub-sub-header"]}>
                                    {t(
                                        "customization-page:content-orientation-sub"
                                    )}
                                </h6>
                                <hr />
                                <StyledCheckboxOrRadio
                                    checked={
                                        values?.VideoPlayer?.AspectRatio ===
                                        VideoPlayerAspectRatio.SixteenByNine
                                    }
                                    type="radio"
                                    id="horiz"
                                    label={t(
                                        "customization-page:orientation-horizontal"
                                    )}
                                    onChange={() =>
                                        handleVideoPlayerSettingsChange(
                                            "AspectRatio",
                                            VideoPlayerAspectRatio.SixteenByNine
                                        )
                                    }
                                />
                                <StyledCheckboxOrRadio
                                    checked={
                                        values?.VideoPlayer?.AspectRatio ===
                                        VideoPlayerAspectRatio.NineBySixteen
                                    }
                                    type="radio"
                                    id="vert"
                                    label={t(
                                        "customization-page:orientation-vertical"
                                    )}
                                    onChange={() =>
                                        handleVideoPlayerSettingsChange(
                                            "AspectRatio",
                                            VideoPlayerAspectRatio.NineBySixteen
                                        )
                                    }
                                />

                                <ColorPicker
                                    id="primaryColor"
                                    label={t(
                                        "customization-page:btn-bkgds-links"
                                    )}
                                    value={
                                        values?.VideoPlayer?.PrimaryColor?.toLocaleUpperCase() ??
                                        "#000000"
                                    }
                                    onChange={(e) => {
                                        handleVideoPlayerSettingsChange(
                                            "PrimaryColor",
                                            e.target.value
                                        );
                                    }}
                                    error={
                                        errors?.VideoPlayerSettings
                                            ?.PrimaryColor
                                    }
                                    allowText
                                    variation={false}
                                />

                                <ColorPicker
                                    id="secondaryColor"
                                    label={t("customization-page:btn-text")}
                                    value={
                                        values?.VideoPlayer?.SecondaryColor?.toLocaleUpperCase() ??
                                        "#FFFFFF"
                                    }
                                    onChange={(e) => {
                                        handleVideoPlayerSettingsChange(
                                            "SecondaryColor",
                                            e.target.value
                                        );
                                    }}
                                    error={
                                        errors?.VideoPlayerSettings
                                            ?.SecondaryColor
                                    }
                                    allowText
                                    variation={false}
                                />

                                <ColorPicker
                                    id="logoBackgroundColor"
                                    label={t(
                                        "video-player-page:logo-background-color"
                                    )}
                                    value={
                                        values?.VideoPlayer?.LogoBackgroundColor?.toLocaleUpperCase() ??
                                        "#000000"
                                    }
                                    onChange={(e) => {
                                        handleVideoPlayerSettingsChange(
                                            "LogoBackgroundColor",
                                            e.target.value
                                        );
                                    }}
                                    error={
                                        errors?.VideoPlayerSettings
                                            ?.LogoBackgroundColor
                                    }
                                    allowText
                                    variation={false}
                                />

                                <ColorPicker
                                    id="playerControlsColor"
                                    label={t(
                                        "customization-page:player-controls-color"
                                    )}
                                    value={
                                        values?.VideoPlayer?.PlayerControlsColor?.toLocaleUpperCase() ??
                                        "#FFFFFF"
                                    }
                                    onChange={(e) => {
                                        handleVideoPlayerSettingsChange(
                                            "PlayerControlsColor",
                                            e.target.value
                                        );
                                    }}
                                    error={
                                        errors?.VideoPlayerSettings
                                            ?.PlayerControlsColor
                                    }
                                    allowText
                                    variation={false}
                                />
                            </div>
                        </form>
                    </div>

                    <div className={`col-lg-8 ${styles["sticky-container"]}`}>
                        <div className={`${styles["button-container"]}`}>
                            <Button
                                onClick={handleRevertButtonClick}
                                disabled={loading || !hasPendingChanges}
                                type="outline"
                            >
                                {t("buttons:revert").toUpperCase()}
                            </Button>
                            <Button
                                onClick={handleSaveButtonClick}
                                disabled={loading || !hasPendingChanges}
                                type="primary"
                            >
                                {t("buttons:save-changes").toUpperCase()}
                            </Button>
                        </div>
                        <EmbedPreview
                            isVertical={
                                localPlayerResponse?.VideoPlayer
                                    ?.AspectRatio ===
                                VideoPlayerAspectRatio.NineBySixteen
                            }
                            embedString={localPlayerResponse?.EmbedCode}
                            playerId={localPlayerResponse?.VideoPlayer?.Id}
                            key={embedPreviewKey}
                        />
                    </div>
                </div>
            </>
        );
    }
};

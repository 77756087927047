import styles from "./index.module.scss";
import commonStyles from "components/widgets/CommonStyles.module.scss";
import ChevronRight from "assets/icons/chevron-right.svg?react";
import { Link } from "react-navi";

export interface LinkListItem {
    Title: string;
    Description?: string;
    Icon: React.ReactNode;
    NavigateTo: string;
    OnClick?: (e: React.MouseEvent) => void;
    Child?: React.ReactNode;
}

export interface LinkListProps {
    actions: LinkListItem[];
}

export const LinkList: React.FC<LinkListProps> = ({
    actions
}: LinkListProps) => {
    if (!actions) return;

    return (
        <>
            {actions.map((action, index) =>
                !!action.NavigateTo ? (
                    <Link
                        className={styles["link-container"]}
                        href={action.NavigateTo}
                        key={index}
                    >
                        <div className={styles["link-container-heading"]}>
                            <div
                                className={`${commonStyles["icon"]} ${styles["link-container-icon"]}`}
                            >
                                {action.Icon}
                            </div>
                            <h6>{action.Title}</h6>
                        </div>
                        <ChevronRight
                            className={styles["link-container-arrow"]}
                        />
                    </Link>
                ) : (
                    <div
                        className={`${styles["link-container"]} ${styles["with-child-data"]} ${!action.OnClick ? styles["no-navigation"] : ""}`}
                        key={index}
                        onClick={action.OnClick}
                    >
                        <div className={styles["link-container-heading"]}>
                            <div
                                className={`${commonStyles["icon"]} ${styles["link-container-icon"]}`}
                            >
                                {action.Icon}
                            </div>
                            <h6>{action.Title}</h6>
                        </div>
                        <>
                            {action.Description && (
                                <div className={styles["link-container-text"]}>
                                    <p>{action.Description}</p>
                                </div>
                            )}
                            {action.Child}
                        </>
                        {action.OnClick && (
                            <ChevronRight
                                className={styles["link-container-arrow"]}
                            />
                        )}
                    </div>
                )
            )}
        </>
    );
};

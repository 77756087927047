import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useNavigate } from "hooks/useNavigate";
import { Checkbox } from "components/inputs/checkbox/Checkbox";
import { AssetDetailsCard } from "../AssetDetailsCard";
import { useAssetDetails } from "./useAssetDetails";
import { AssetCardList } from "../AssetCardList";
import { Asset } from "@switcherstudio/switcher-api-client";
import styles from "./AssetDetailsPage.module.scss";
import { useTranslation } from "react-i18next";

interface AssetDetailsPageProps {
    assetId: string;
}

export const AssetDetailsPage: React.FC<AssetDetailsPageProps> = ({
    assetId
}) => {
    const { navigate } = useNavigate();

    const { loading } = useSelector((s: RootState) => s.loading);
    const { t } = useTranslation("switcher-cloud");
    const {
        asset,
        rawFiles,
        otherAssets,
        deleteRawFiles,
        disableDeleteRawFiles,
        remove,
        toggleDeleteRawFiles
    } = useAssetDetails(assetId);

    const goToAsset = (asset: Asset) => {
        navigate(`/switcher-cloud/asset/${asset.Id}`);
    };

    const goToRawFile = (asset: Asset) => {
        navigate(`/switcher-cloud/raw-file/${asset.Id}`);
    };

    return (
        <>
            {asset && (
                <>
                    <div className="row">
                        <div className="col">
                            <AssetDetailsCard
                                asset={asset}
                                headerText={t("asset-details")}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {rawFiles.length > 0 && (
                                <>
                                    <h5>{t("raw-files-used")}</h5>
                                    <AssetCardList
                                        assets={rawFiles}
                                        onClick={goToRawFile}
                                        size="sm"
                                    />
                                </>
                            )}
                            {otherAssets.length > 0 && (
                                <>
                                    <h5>Assets Sharing Raw Files</h5>
                                    <AssetCardList
                                        assets={otherAssets}
                                        onClick={goToAsset}
                                        size="sm"
                                    />
                                </>
                            )}
                        </div>
                    </div>{" "}
                    <div className="col-lg-8 pl-0">
                        <div className={styles["delete-actions"]}>
                            <button
                                type="button"
                                className="btn btn-danger mb-3 mr-2"
                                onClick={remove}
                                disabled={loading > 0}
                            >
                                {t("delete")}
                            </button>
                            <Checkbox
                                label={`Delete related raw files${
                                    otherAssets.length > 0 ? " and assets" : ""
                                }`}
                                id="delete-raw-files"
                                checked={deleteRawFiles}
                                onChange={toggleDeleteRawFiles}
                                small={
                                    disableDeleteRawFiles
                                        ? "* Some assets are using other raw file(s). Delete other assets before deleting the raw file(s)."
                                        : ""
                                }
                                disabled={disableDeleteRawFiles}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

import { UppyFile } from "@uppy/core";

import { useMemo } from "react";
import { shorten } from "helpers/strings";
import { useClaimCheck } from "hooks/useClaimCheck";
import {
    BroadcastStatus,
    BroadcastWebLink,
    Category,
    CreatorProduct
} from "@switcherstudio/switcher-api-client";
import { BroadcastDetailsForm } from "components/forms/BroadcastDetailsForm";
import { useVideoBlobData } from "hooks/useVideoBlobData";
import { AspectRatio } from "views/page-content/cloud/upload/types";

export interface UploadOptionsStepProps {
    file: UppyFile | null;
    title: string;
    setTitle: (title: string) => void;
    description: string;
    setDescription: (description: string) => void;
    addToPlayers: string[];
    setAddToPlayers: (addToPlayers: string[]) => void;
    links: BroadcastWebLink[];
    setAddLinks: (links: BroadcastWebLink[]) => void;
    selectedProduct: CreatorProduct | undefined;
    setSelectedProduct: (selectedProduct: CreatorProduct) => void;
    /** The switcher player id this form is locked to if uploading directly to player */
    lockedToPlayer?: string;
    thumbnailFile: File;
    setThumbnailFile: (thumbnailFile: File) => void;
    thumbnailImageURL: string;
    setThumbnailImageURL: (thumbnailImageURL: string) => void;
    showThumbnailUploader: boolean;
    selectedCategories?: Category[];
    setSelectedCategories?: (selectedCategories: Category[]) => void;
    showCategories: boolean;
    showCategoryDropdownWhenEmpty?: boolean;
    broadcastStatus: BroadcastStatus;
    setBroadcastStatus: (status: BroadcastStatus) => void;
    showInCatalog: boolean;
    setShowInCatalog: (showInCatalog: boolean) => void;
    startsAt: string;
    setStartsAt: (date: string) => void;
}

export const UploadOptionsStep: React.FC<UploadOptionsStepProps> = ({
    file,
    title,
    setTitle,
    description,
    setDescription,
    addToPlayers,
    setAddToPlayers,
    links,
    setAddLinks,
    lockedToPlayer,
    thumbnailFile,
    setThumbnailFile,
    thumbnailImageURL,
    setThumbnailImageURL,
    showThumbnailUploader,
    selectedCategories,
    setSelectedCategories,
    showCategories,
    showCategoryDropdownWhenEmpty,
    broadcastStatus,
    setBroadcastStatus,
    showInCatalog,
    setShowInCatalog,
    startsAt,
    setStartsAt
}) => {
    // const [linkSelectModalIsOpen, setLinkSelectModalIsOpen] =
    //     useState<boolean>(false);
    const videoPlayerDisabled = useClaimCheck("videoplayer.disabled");
    const selectedPlayerIds = useMemo(
        () => (lockedToPlayer ? [lockedToPlayer] : addToPlayers),
        [lockedToPlayer, addToPlayers]
    );

    const { videoWidth, videoHeight } = useVideoBlobData(file.data);

    return (
        <>
            <BroadcastDetailsForm
                title={title}
                setTitle={setTitle}
                description={description}
                setDescription={setDescription}
                thumbnailFile={thumbnailFile}
                setThumbnailFile={setThumbnailFile}
                thumbnailImageURL={thumbnailImageURL}
                setThumbnailImageURL={setThumbnailImageURL}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                showThumbnailUploader={showThumbnailUploader}
                showCategories={showCategories}
                showCategoryDropdownWhenEmpty={showCategoryDropdownWhenEmpty}
                broadcastStatus={broadcastStatus}
                setBroadcastStatus={setBroadcastStatus}
                showInCatalog={showInCatalog}
                setShowInCatalog={setShowInCatalog}
                startsAt={startsAt}
                players={selectedPlayerIds}
                links={links}
                onLinkSave={setAddLinks}
                setAddToPlayers={setAddToPlayers}
                setStartsAt={setStartsAt}
                orientation={
                    videoWidth >= videoHeight
                        ? AspectRatio.horizontal
                        : AspectRatio.vertical
                }
                disablePlayerSelect={lockedToPlayer && videoPlayerDisabled}
                filename={shorten(file.name, 20)}
                filesize={`${Math.round(file.size / 1000 / 1000)} MB`}
            />
        </>
    );
};

// basically exactly the same as existing 'facebook container' but with differnt on click and routing
// remove logic for redirecting if account is not linked. What to do instead TBD.

import React, { useCallback, useEffect, useState } from "react";
import { facebook as fbClient } from "api/facebook/facebook-client";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import {
    getFacebookUserProfile,
    selectFacebookDestination
} from "store/platforms/thunks";
import { RootState } from "store/reducers";
import { useNavigate } from "hooks/useNavigate";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { DestinationSelector } from "components/destination-selector/DestinationSelector";
import { FacebookProfile } from "store/platforms/types";
import { PlatformId } from "views/page-content/platforms/types";
import rollbar from "helpers/rollbar";
import { usePlatformActions } from "hooks/usePlatformActions";
// import { useTranslation } from "react-i18next";
import fbFetch from "api/facebook/facebook-helper";
// import { client } from "api/client";
import { DestinationDescriptor } from "views/page-content/platforms/platform/simulcast/types";
import { updateGeneralForm, resetFacebookForm } from "store/platforms/slice";

interface FacebookContainerProps {
    destinationType?: string;
    disableAll?: boolean;
}

const pills = ["My Timeline", "Page"];

export const SimulcastFacebookDestinationSelector: React.FC<
    FacebookContainerProps
> = ({ destinationType, disableAll }: FacebookContainerProps) => {
    // const { t } = useTranslation();
    const isMounted = useIsMountedRef();
    const dispatch = useDispatch<AppDispatch>();
    const { navigate } = useNavigate();

    const [destinations, setDestinations] = useState(undefined);
    const [isPaginated, setIspaginated] = useState(false);
    const [nextPage, setNextPage] = useState(undefined);
    // const [defaultDestination, setDefaultDestination] = useState<
    //   FacebookProfile
    // >();
    const { userInfo } = useSelector((s: RootState) => s.user);
    const { profileInfo } = useSelector(
        (state: RootState) => state.platforms.facebook
    );
    const { generalForm } = useSelector(
        (state: RootState) => state.platforms.general
    );
    const { unlink } = usePlatformActions(PlatformId.Facebook);

    const getMore = useCallback(async () => {
        const res = await fbFetch(nextPage);
        setDestinations([...destinations, ...res.data]);
        if (res?.getNextPage) {
            setNextPage(res.getNextPage);
            return;
        }
        setIspaginated(false);
    }, [nextPage, destinations]);

    const handlePillSelect = useCallback(
        async (newPill: string) => {
            setDestinations(undefined);

            let options = { edge: "me" };

            switch (newPill) {
                case "My Timeline":
                    options.edge = "me";
                    options["userImage"] = profileInfo?.picture?.data?.url;
                    break;
                case "Page":
                    options.edge = "accounts";
                    break;
                default:
                    break;
            }

            try {
                const results = await fbClient.helpers.getDestinationsByType(
                    options
                );
                if (!!results) setDestinations(results.data);
                if (results?.getNextPage) {
                    setIspaginated(true);
                    setNextPage(results.getNextPage);
                } else {
                    setIspaginated(false);
                }
            } catch (e) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:facebook-load-error"
                    })
                );
            }
        },
        [dispatch, profileInfo]
    );

    useEffect(() => {
        async function init() {
            try {
                await dispatch(getFacebookUserProfile());
                // Do we want to support default dest when selectinng simulcast destinations?

                // const streamDestinations = await client.streamDestinations_GetStreamDestinations(
                //   userInfo?.UserId
                // );
                // const defaultStreamDestination = streamDestinations?.find(
                //   (sd) => sd.IsDefault
                // );

                // if (defaultStreamDestination) {
                // const {
                //   StreamDestinationId,
                //   StreamDestinationType,
                //   Name,
                // } = defaultStreamDestination;
                // const destination = await fbClient.helpers.getDestinationById(
                //   StreamDestinationId
                // );
                // setDefaultDestination({
                //   ...destination,
                //   edge: StreamDestinationType,
                //   name: Name,
                //   isTestStream:
                //     StreamDestinationType === "me" && Name === "Private test stream",
                // });
                // }
            } catch (e) {
                rollbar.error("Error getting facebook user profile", e, {
                    platformId: PlatformId.Facebook
                });

                // DO WE NEED TO SUPPORT MULTIPLE ASPECT RATIOS FOR SIMULCASTING?

                // const needsUnlink = e.message.includes("The session has been invalidated");

                // don't do this when choosing simulcast dests.
                // if (needsUnlink) {
                //   await unlink();
                //   navigate(`/platforms/connect/${PlatformId.Facebook}`);
                // }

                // dispatch(
                //   addNotification({
                //     type: NotificationType.Danger,
                //     message: needsUnlink ? e.message : "errors:facebook-load-error"
                //   })
                // );
            }
        }

        if (isMounted.current) init();
    }, [dispatch, isMounted, unlink, navigate, userInfo]);

    useEffect(() => {
        let initialPill;

        switch (destinationType) {
            case "accounts":
                initialPill = pills[1];
                break;
            default:
                initialPill = pills[0];
                break;
        }

        handlePillSelect(initialPill);
    }, [handlePillSelect, destinationType]);

    function newStreamClick(destination: FacebookProfile) {
        if (
            generalForm.selectedSimulcastDestinations.some(
                (d) => d.id === destination.id
            )
        ) {
            return;
        }
        const destinationDescriptor: DestinationDescriptor = {
            name: destination.name,
            id: destination.id,
            type: destination.edge,
            platform: PlatformId.Facebook,
            isTestStream: destination.isTestStream
        };
        // appendParamArray("destination[]", JSON.stringify(destinationDescriptor));

        dispatch(selectFacebookDestination(destination));
        dispatch(resetFacebookForm(destination.id));
        dispatch(
            updateGeneralForm({
                selectedSimulcastDestinations: [
                    ...generalForm.selectedSimulcastDestinations,
                    destinationDescriptor
                ]
            })
        );
        const path = `/platforms/simulcast/destinations`;
        navigate(path);
    }

    return (
        <DestinationSelector
            destinations={destinations}
            pills={pills}
            imgSrcKey="picture.data.url"
            destinationNameKey="name"
            onDestinationClick={newStreamClick}
            onPillSelect={handlePillSelect}
            isPaginated={isPaginated}
            loadMore={getMore}
            isSimulcast={true}
            disableAll={disableAll}
        />
    );
};

import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-navi";

import { GatedContentStatus } from "hooks/useStripeAccountInfo";
import { TooltipBaseV2, TooltipProps } from "../TooltipBaseV2";
import { useGetStripeConnectLink } from "hooks/useGetStripeConnectLink";

interface GatedContentDisabledTooltipProps extends Partial<TooltipProps> {
    gatedContentStatus: GatedContentStatus;
    override?: boolean;
    overrideKey?: string;
    children?: JSX.Element;
}

export const GatedContentDisabledTooltip = ({
    gatedContentStatus,
    children,
    style,
    labelOrientation,
    override,
    overrideKey
}: GatedContentDisabledTooltipProps) => {
    const { link, target } = useGetStripeConnectLink();

    return (
        <TooltipBaseV2
            type="info"
            show={override || gatedContentStatus !== GatedContentStatus.READY}
            body={
                <Trans
                    i18nKey={
                        override
                            ? overrideKey
                            : gatedContentStatus ===
                                GatedContentStatus.STRIPE_ACCOUNT_UNVERIFIED
                              ? "video-player-settings:verify-stripe"
                              : "video-player-settings:connect-stripe"
                    }
                    components={{
                        link1: (
                            <Link
                                href={link}
                                target={target}
                                style={{
                                    color: "#138F99",
                                    textDecoration: "none"
                                }}
                                title="Gated Content - Stripe Connect Account"
                            />
                        )
                    }}
                />
            }
            style={style}
            labelOrientation={labelOrientation}
        >
            {children}
        </TooltipBaseV2>
    );
};

import React, { useState, useEffect, useCallback } from "react";
import styles from "./index.module.scss";
import AddCouponIcon from "assets/icons/add.svg?react";
import { useTranslation } from "react-i18next";
import { CouponPrompt } from "./CouponPrompt";

interface CouponInputProps {
    label?: string;
    type?: string;
    id?: string;
    couponCode: string;
    addCoupon?: (value: string) => void;
    removeCoupon?: () => void;
    location?: "login" | "sign-up";
}

export const CouponInput: React.FC<CouponInputProps> = ({
    label,
    type = "coupon",
    id = "coupon",
    couponCode,
    addCoupon,
    removeCoupon,
    location = "sign-up"
}: CouponInputProps) => {
    const { t } = useTranslation();
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [showCouponInput, setShowCouponInput] = useState<boolean>(false);
    const [value, setValue] = useState<string>(couponCode || "");

    const onIconClick = useCallback(() => {
        if (couponCode) {
            removeCoupon();
            setIsFocused(false);
        } else if (!isFocused) {
            setIsFocused(true);
        } else if (value === undefined || value === "") {
            setIsFocused(false);
        } else {
            setIsFocused(true);
        }
    }, [couponCode, isFocused, removeCoupon, value]);

    const showAndFocus = useCallback(() => {
        setShowCouponInput(true);
        setIsFocused(true);
    }, []);

    useEffect(() => {
        setValue(couponCode);
        if (couponCode) {
            showAndFocus();
        }
    }, [couponCode, showAndFocus]);

    if (!showCouponInput) {
        return <CouponPrompt onClick={showAndFocus} />;
    }

    return (
        <>
            <div
                className={`form-group mb-4 ${styles[`${location}-input`]} ${
                    styles["coupon-input"]
                }`}
            >
                <div
                    className={`${styles["input-container"]} ${
                        !isFocused || couponCode ? styles["not-focused"] : ""
                    }`}
                >
                    {!couponCode && (
                        <label
                            className={`${styles["floating-label"]} ${
                                isFocused ? styles["has-focus"] : ""
                            }`}
                            htmlFor={id}
                        >
                            {label}
                        </label>
                    )}

                    <AddCouponIcon
                        className={`${styles["coupon-icon"]} ${
                            isFocused ? styles["remove-icon"] : ""
                        }`}
                        onClick={onIconClick}
                    />

                    <input
                        type={type}
                        className="form-control"
                        id={id}
                        value={value || ""}
                        onChange={(e) => setValue(e.target.value)}
                        onFocus={() => setIsFocused(true)}
                        readOnly={!!couponCode}
                    />
                    {!couponCode && isFocused && (
                        <button
                            type="button"
                            className={`btn ${
                                location === "login" ? "btn-white mb-2" : ""
                            } md-btn`}
                            disabled={value === "" || value === undefined}
                            onClick={() => addCoupon(value)}
                        >
                            {t("buttons:redeem").toUpperCase()}
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

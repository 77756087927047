import React, { useState, useEffect } from "react";
import { useNavigate } from "hooks/useNavigate";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { client } from "api/client";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { PaymentForm } from "components/forms/payment-form/PaymentForm";
import ChevronLeft from "assets/icons/chevron-left.svg?react";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import rollbar from "helpers/rollbar";
import { useTranslation } from "react-i18next";
import { RootState } from "store/reducers";
import { SubscriptionFormWrapper } from "../SubscriptionFormWrapper";
import commonStyles from "../SubscriptionCommonStyles.module.scss";
import { trackEvent } from "helpers/analyticsHelpers";
import { AppDispatch } from "store/store";

export const NewPaymentMethodPage: React.FC = () => {
    const { t } = useTranslation();
    const { navigate } = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const { userInfo } = useSelector((s: RootState) => s.user);
    const stripe = useStripe();
    const elements = useElements();
    const isMounted = useIsMountedRef();

    const [customerId, setCustomerId] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        async function run() {
            if (!customerId) {
                const customer = await client.stripe_GetCustomer();

                if (isMounted.current) {
                    if (customer.SilverSunnStripeCustomerId) {
                        setCustomerId(customer.SilverSunnStripeCustomerId);
                    } else {
                        // TODO: handle this error -- navigate back/present error?
                        // No customerId == stripe payment will not succeed
                    }
                }
            }
        }
        run();
    }, [customerId, isMounted]);

    const back = () => {
        navigate("/subscription");
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);

        if (!card) {
            return;
        }

        setIsSubmitting(true);
        setError("");

        try {
            // create payment method
            const paymentMethod = await stripe.createPaymentMethod({
                type: "card", // TODO: support other types
                card: card
            });

            if (paymentMethod.error?.message) {
                setError(paymentMethod.error.message);
            } else if (!paymentMethod.paymentMethod) {
                setError(t("errors:payment-add-error"));
            } else {
                // attach payment method to customer
                await client.userPaymentMethods_AttachPaymentMethod(
                    userInfo.UserId,
                    paymentMethod.paymentMethod.id
                );

                trackEvent("Added Payment Info", null, {
                    integrations: { Intercom: false, HelpScout: false }
                });

                navigate("/subscription");
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: "messages:payment-add-success"
                    })
                );
            }

            setIsSubmitting(false);
        } catch (e) {
            rollbar.error("Error adding payment method", e);
            setError(t("errors:payment-add-error"));
            setIsSubmitting(false);
        }
    };

    const onChange = (event: StripeCardElementChangeEvent) => {
        if (event.error) {
            setError(event.error.message);
        } else {
            setError("");
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <p>
                        <button
                            type="button"
                            className={`btn btn-outline-primary ${commonStyles["header-btn"]}`}
                            onClick={back}
                        >
                            <ChevronLeft />{" "}
                            {t("cancel-subscription:subscriptions")}
                        </button>
                    </p>
                    <SubscriptionFormWrapper>
                        <PaymentForm
                            label={t("subscription:credit-or-debit-card")}
                            buttonText={t("page-titles:add-payment")}
                            onChange={onChange}
                            onSubmit={onSubmit}
                            error={error}
                            busy={isSubmitting}
                        ></PaymentForm>
                    </SubscriptionFormWrapper>
                </div>
            </div>
        </>
    );
};

import React, { useState, useEffect, useCallback } from "react";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { ConfirmOneTimePageProps, Amounts } from "../types";
import { useNavigate } from "hooks/useNavigate";
import { client } from "api/client";
import {
    StripeInvoiceBindingModel,
    StripeInvoiceRequest,
    SilverSunnStripeCreditCard
} from "@switcherstudio/switcher-api-client";
import { NewAmountTable } from "./NewAmountTable";
import {
    StripeCardElementChangeEvent,
    PaymentRequest,
    PaymentRequestPaymentMethodEvent
} from "@stripe/stripe-js";
import {
    CardElement,
    PaymentRequestButtonElement
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import rollbar from "helpers/rollbar";
import styles from "./ConfirmSubscriptionPage.module.scss";
import { useStripeHandlers } from "hooks/useStripeHandlers";
import { useTranslation } from "react-i18next";
import { getUserInfo, refreshToken } from "store/user/thunks";
import { AppDispatch } from "store/store";
import { setLoading } from "store/loading/slice";
import { useGetLastClaimedCoupon } from "hooks/useGetLastClaimedCoupon";
import { trackConversion, trackEvent } from "helpers/analyticsHelpers";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { RadioPaymentForm } from "components/forms/radio-payment-form";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { PlanSummary } from "./PlanSummary";
import { PrimarySecondaryButtonsGroup } from "components/buttons/PrimarySecondaryButtonsGroup";

export const NewConfirmOneTimePage: React.FC<ConfirmOneTimePageProps> = ({
    plan,
    pricing
}: ConfirmOneTimePageProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { userInfo } = useSelector((s: RootState) => s.user);
    const isMounted = useIsMountedRef();
    const { navigate } = useNavigate();

    const { stripe, elements } = useStripeHandlers();
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>(null);
    const [walletName, setWalletName] = useState<string>("");
    const [showPaymentRequestBtn, setShowPaymentRequestBtn] =
        useState<boolean>(false);
    const [amounts, setAmounts] = useState<Amounts>({
        total: plan.Amount,
        due: plan.Amount,
        proration: 0,
        discount: 0
    });
    const [isPaymentMethodRequired, setIsPaymentMethodRequired] =
        useState<boolean>(true);
    const [availablePaymentMethods, setAvailablePaymentMethods] = useState<
        SilverSunnStripeCreditCard[]
    >([]);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] =
        useState<string>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [paymentError, setPaymentError] = useState<string>("");
    const [currentSubscriptionId, setCurrentSubscriptionId] =
        useState<string>("");
    const [isTrialing, setIsTrialing] = useState<boolean>(false);
    const [invoice, setInvoice] = useState<StripeInvoiceBindingModel>();
    const { claimedCoupon, removeClaimedCoupon } = useGetLastClaimedCoupon();

    const createDraftInvoice = useCallback(
        async ({
            userId,
            invoiceReq
        }: {
            userId: string;
            invoiceReq: StripeInvoiceRequest;
        }) => {
            try {
                if (isMounted.current) {
                    const draftInvoice =
                        await client.stripeInvoices_PostInvoice(
                            userId,
                            invoiceReq
                        );
                    setInvoice(draftInvoice);
                    setAmounts({
                        total: plan.Amount,
                        due: draftInvoice.amount_due,
                        proration: draftInvoice.starting_balance,
                        discount: draftInvoice.total_discount
                    });
                }
            } catch (e) {
                console.error(e);
            }
        },
        [isMounted, plan.Amount]
    );

    useSwitcherClient((client) => client.stripe_GetCustomer, {
        requestImmediately: true,
        onSuccessLoading: true,
        onSuccess: async (customer) => {
            if (customer.SilverSunnStripeCustomerId) {
                const defaultPaymentMethod = customer.StripeCreditCards.find(
                    (cc) => cc.Default && !cc.Expired
                );
                const hasDefaultPaymentMethod = !!defaultPaymentMethod;
                const availablePaymentMethods =
                    customer.StripeCreditCards?.filter((cc) => !cc.Expired).map(
                        (cc) => {
                            return {
                                ...cc,
                                optionLabel: `**** ${cc.LastFour} - Exp: ${cc.ExpirationMonth} / ${cc.ExpirationYear}`
                            };
                        }
                    );
                setShowPaymentRequestBtn(!hasDefaultPaymentMethod);
                setAvailablePaymentMethods(availablePaymentMethods);
                setSelectedPaymentMethodId(
                    defaultPaymentMethod?.SilverSunnStripeCreditCardId
                );
                try {
                    if (claimedCoupon || claimedCoupon === null) {
                        await createDraftInvoice({
                            userId: userInfo.UserId,
                            invoiceReq: {
                                InvoiceItems: [{ Price: plan.Id, Quantity: 1 }],
                                ResellerInventoryItemId:
                                    claimedCoupon?.ResellerInventoryItem.Id
                            }
                        });
                    }
                } catch (e) {
                    rollbar.error(
                        "Error creating one time payment draft invoice",
                        e
                    );
                }

                // Use the first licensed subscription
                const licensedSubscription = customer.StripeSubscriptions?.find(
                    (s) => s.UsageType === "licensed"
                );
                if (licensedSubscription?.SilverSunnStripeSubscriptionId) {
                    setCurrentSubscriptionId(
                        licensedSubscription.SilverSunnStripeSubscriptionId
                    );
                    if (licensedSubscription.PlanName === "Trial") {
                        setIsTrialing(true);
                    }
                }
            }
        }
    });

    useEffect(() => {
        if (claimedCoupon) {
            setIsPaymentMethodRequired(
                claimedCoupon.ResellerInventoryItem.ResellerInventory
                    .IsPaymentMethodRequired
            );
        } else {
            setIsPaymentMethodRequired(true);
        }
    }, [claimedCoupon, t]);

    const onPaymentChange = (event: StripeCardElementChangeEvent) => {
        if (event.error) {
            setPaymentError(event.error.message);
        } else {
            setPaymentError("");
        }
    };

    const changePlan = useCallback(async () => {
        // if there is a claimed coupon for this plan,
        // release it before navigating to /subscribe
        if (claimedCoupon?.ToPlan) {
            await removeClaimedCoupon();
        }

        navigate("/subscription/subscribe");
    }, [navigate, claimedCoupon, removeClaimedCoupon]);

    // // get or create payment method id
    const getPaymentMethodId = useCallback(async () => {
        if (selectedPaymentMethodId !== "new-payment-method") {
            // use current default payment method for confirmation
            return selectedPaymentMethodId;
        } else {
            // do nothing in case stripe/elements have not loaded yet
            if (!stripe || !elements) {
                return;
            }

            const card = elements.getElement(CardElement);
            if (!card) {
                return;
            }

            setPaymentError("");

            // create payment method
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card", // TODO: support other types? Apple Pay, etc
                card: card
            });

            if (error) {
                throw error;
            }

            // attach new payment method to existing customer
            await client.userPaymentMethods_AttachPaymentMethod(
                userInfo.UserId,
                paymentMethod.id
            );

            trackEvent("Added Payment Info", null, {
                integrations: { Intercom: false, HelpScout: false }
            });

            return paymentMethod.id;
        }
    }, [userInfo.UserId, elements, stripe, selectedPaymentMethodId]);

    /** Method for collecting payment and completing the order.
     * This method is shared by the submit button and the
     * `paymentmethod` listener on the PaymentRequestButton.
     * On the submit button, the `PaymentRequestPaymentMethodEvent`
     * will be undefined.
     */
    const collectPayment = useCallback(
        async (prEvent?: PaymentRequestPaymentMethodEvent) => {
            dispatch(setLoading(1));
            setIsSubmitting(true);

            let paymentMethodId;

            try {
                if (isPaymentMethodRequired) {
                    if (prEvent?.paymentMethod.id) {
                        paymentMethodId = prEvent?.paymentMethod.id;
                        // attach Apple Pay payment method to existing customer
                        await client.userPaymentMethods_AttachPaymentMethod(
                            userInfo.UserId,
                            paymentMethodId
                        );

                        trackEvent(
                            `Added Payment Info with ${prEvent.walletName}`,
                            null,
                            {
                                integrations: {
                                    Intercom: false,
                                    HelpScout: false
                                }
                            }
                        );
                    } else {
                        paymentMethodId = await getPaymentMethodId();
                    }
                }

                if (!invoice) {
                    throw new Error(t("errors:invoice-failure"));
                }

                const finalizedInvoice =
                    await client.stripeInvoices_FinalizeInvoice(
                        userInfo.UserId,
                        invoice.id
                    );

                if (isPaymentMethodRequired && !finalizedInvoice.paid) {
                    // If using a PaymentRequest, we must set handleActions to false, so that we can
                    // close the PaymentRequest overlay and allow the user to interact with potential
                    // modals for SCA/3DS, etc.
                    const paymentConfirmationResponse =
                        await stripe.confirmCardPayment(
                            finalizedInvoice.payment_intent_secret,
                            {},
                            {
                                handleActions: prEvent ? false : true
                            }
                        );

                    if (paymentConfirmationResponse.error) {
                        throw paymentConfirmationResponse.error;
                    }

                    if (
                        prEvent &&
                        paymentConfirmationResponse?.paymentIntent?.status ===
                            "requires_action"
                    ) {
                        prEvent.complete("success");
                        const reconfirmationResponse =
                            await stripe.confirmCardPayment(
                                paymentConfirmationResponse.paymentIntent
                                    .client_secret
                            );

                        if (reconfirmationResponse?.error) {
                            throw reconfirmationResponse.error;
                        }
                    }
                }

                await client.purchaseEntitlements_PostUserPurchaseEntitlement(
                    userInfo.UserId,
                    finalizedInvoice.id
                );

                if (isTrialing) {
                    await client.stripe_DeleteSubscription(
                        currentSubscriptionId,
                        false
                    );
                }

                trackConversion(false, "SilverSunnDashboard", plan);

                prEvent?.complete("success");

                try {
                    await dispatch(refreshToken());
                    await dispatch(getUserInfo());
                } catch (e) {
                    rollbar.error(
                        "Error getting user account info on subscription success",
                        e
                    );

                    dispatch(
                        addNotification({
                            type: NotificationType.Danger,
                            message: t("errors:user-info-load-error")
                        })
                    );
                }

                navigate("/subscription/success");
            } catch (e) {
                if (paymentMethodId) {
                    if (paymentMethodId === "new-payment-method" || prEvent) {
                        try {
                            if (availablePaymentMethods.length) {
                                const originalDefault =
                                    availablePaymentMethods.find(
                                        (c) => c.Default && !c.Expired
                                    );
                                if (originalDefault) {
                                    await client.userPaymentMethods_SetPrimaryPaymentMethod(
                                        userInfo.UserId,
                                        originalDefault.SilverSunnStripeCreditCardId
                                    );
                                }
                            }

                            await client.userPaymentMethods_DeletePaymentMethod(
                                userInfo.UserId,
                                paymentMethodId
                            );
                        } catch {
                            /** eat DeletePaymenMethod request errors */
                        }
                    }
                }

                await createDraftInvoice({
                    userId: userInfo.UserId,
                    invoiceReq: {
                        InvoiceItems: [{ Price: plan.Id, Quantity: 1 }],
                        ResellerInventoryItemId:
                            claimedCoupon?.ResellerInventoryItem.Id
                    }
                });

                prEvent?.complete("fail");
                rollbar.error("Error finalizing one time payment invoice", e);
                setPaymentError(e.message || e.error.decline_code);
            } finally {
                dispatch(setLoading(-1));
                setIsSubmitting(false);
            }
        },
        [
            availablePaymentMethods,
            dispatch,
            stripe,
            currentSubscriptionId,
            createDraftInvoice,
            plan,
            claimedCoupon,
            isTrialing,
            isPaymentMethodRequired,
            getPaymentMethodId,
            navigate,
            userInfo,
            invoice,
            t
        ]
    );

    const onSubmit = useCallback(
        async (event?: React.FormEvent<HTMLFormElement>) => {
            event?.preventDefault();

            collectPayment();
        },
        [collectPayment]
    );

    /**
     * Creates a PaymentRequest object. If the user has a wallet available,
     * we will set the PaymentRequest, and make a PaymentRequestButton visible.
     */
    useEffect(() => {
        if (stripe) {
            if (!paymentRequest) {
                const pr = stripe.paymentRequest({
                    country: "US",
                    currency: "usd",
                    total: {
                        label: "Total",
                        amount: amounts.due
                    },
                    requestPayerName: true,
                    requestPayerEmail: true,
                    disableWallets: ["browserCard"]
                });

                // Check the availability of the Payment Request API.
                pr.canMakePayment().then((result) => {
                    if (result) {
                        // Payment Request Button click handler
                        setPaymentRequest(pr);

                        if (result.googlePay) {
                            setWalletName("Google Pay");
                        }

                        if (result.applePay) {
                            setWalletName("Apple Pay");
                        }
                    }
                });
            } else {
                paymentRequest.update({
                    total: {
                        label: "Total",
                        amount: amounts.due
                    }
                });
            }
        }
    }, [stripe, amounts, paymentRequest]);

    /**
     * Adds/updates a `paymentmethod` listener for the payment request button.
     */
    useEffect(() => {
        if (paymentRequest) {
            paymentRequest.on("paymentmethod", collectPayment);
        }

        return () => {
            paymentRequest?.off("paymentmethod");
        };
    }, [collectPayment, paymentRequest]);

    const cancelChanges = useCallback(() => {
        navigate("/subscription");
    }, [navigate]);

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className={styles["almost-official-container"]}>
                        <p className="lead">
                            {t("subscription:almost-official-1", { pricing })}{" "}
                            <strong>
                                {t("subscription:almost-official-2", {
                                    title: plan.Product.Name
                                })}
                            </strong>{" "}
                            {t("subscription:almost-official-3")}
                        </p>

                        <p>
                            <small>
                                {t("subscription:one-time-fine-text")}
                            </small>
                        </p>
                    </div>

                    <div
                        className={
                            styles["payment-method-and-summary-container"]
                        }
                    >
                        <div className={styles["payment-summary-container"]}>
                            <h5>{t("subscription:payment-summary-header")}</h5>
                            <PlanSummary
                                plan={plan}
                                onChangePlan={changePlan}
                            />
                            <NewAmountTable
                                amounts={amounts}
                                pricing={pricing}
                            />

                            {/** Putting the subscribe/cancel button here if no payment is required since the payment method div will be empty */}
                            {!isPaymentMethodRequired && (
                                <PrimarySecondaryButtonsGroup
                                    primaryButtonText={t(
                                        "subscription:subscribe"
                                    )}
                                    primaryButtonType="button"
                                    onPrimaryButtonClick={onSubmit}
                                    secondaryButtonText={t(
                                        "subscription:cancel-changes"
                                    )}
                                    onSecondaryButtonClick={cancelChanges}
                                />
                            )}
                        </div>

                        <div className={styles["payment-method-container"]}>
                            {isPaymentMethodRequired &&
                            paymentRequest &&
                            showPaymentRequestBtn ? (
                                <>
                                    <PaymentRequestButtonElement
                                        options={{ paymentRequest }}
                                    />
                                    {paymentError && (
                                        <div
                                            className="alert alert-danger"
                                            role="alert"
                                        >
                                            {paymentError}
                                        </div>
                                    )}
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-block btn-link mt-3"
                                        onClick={() =>
                                            setShowPaymentRequestBtn((p) => !p)
                                        }
                                    >
                                        {t("subscription:or-enter-manually")}
                                    </button>
                                </>
                            ) : (
                                <>
                                    {isPaymentMethodRequired && (
                                        <h5>
                                            {t("subscription:payment-method")}
                                        </h5>
                                    )}
                                    {isPaymentMethodRequired && (
                                        <>
                                            <RadioPaymentForm
                                                buttonText={t(
                                                    isPaymentMethodRequired
                                                        ? "subscription:one-time-purchase"
                                                        : "subscription:one-time-free"
                                                )}
                                                onSubmit={onSubmit}
                                                onChange={onPaymentChange}
                                                error={paymentError}
                                                busy={isSubmitting}
                                                availablePaymentMethods={
                                                    availablePaymentMethods
                                                }
                                                selectedPaymentMethodId={
                                                    selectedPaymentMethodId
                                                }
                                                setSelectedPaymentMethodId={
                                                    setSelectedPaymentMethodId
                                                }
                                            />
                                            {paymentRequest && (
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-block btn-link mt-3"
                                                    onClick={() =>
                                                        setShowPaymentRequestBtn(
                                                            (p) => !p
                                                        )
                                                    }
                                                >
                                                    {t(
                                                        "subscription:or-use-wallet",
                                                        { walletName }
                                                    )}
                                                </button>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

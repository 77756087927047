import { runVwoVariations, trackABEvent } from "helpers/abHelper";
import { useCallback } from "react";
import { useNavigate } from "./useNavigate";

export const useLandingPageRedirectABTest = () => {
    const { navigate } = useNavigate();

    /** only for new users and users directed to dashboard, not users directed to download app
    will split traffic of all qualifying users 50/50 to either home page or video library page as
    the landing page for the users */
    const redirectToLandingPageWithABTest = useCallback(() => {
        const variations: {
            variationId: string;
            callback: (...args: any) => any;
        }[] = [
            {
                variationId: "video-library-landing-page",
                callback: () => {
                    navigate("/video-library");
                }
            }
        ];

        runVwoVariations("9", variations, () => navigate("/home"));
    }, [navigate]);

    /**
     *the conversion event being tracked is video uploads
     */
    const trackUploadForABTest = useCallback(() => {
        trackABEvent("videoUploaded");
    }, []);

    return {
        redirectToLandingPageWithABTest,
        trackUploadForABTest
    };
};

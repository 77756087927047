import React from "react";

import { ResellerInventory } from "@switcherstudio/switcher-api-client";

import { useTranslation } from "react-i18next";
import { useNavigate } from "hooks/useNavigate";

import { displayDate, isInPast } from "helpers/time";

interface InventoryCardProps {
    inventory: ResellerInventory;
}

export const InventoryCard: React.FC<InventoryCardProps> = ({ inventory }) => {
    const { t, i18n } = useTranslation();
    const { navigate } = useNavigate();

    const view = () => {
        navigate(`manage-inventories/${inventory.Id}`);
    };

    return (
        <div
            className={`card card-body mb-2 ${
                isInPast(inventory?.ValidUntil)
                    ? "border-danger text-danger"
                    : ""
            }`}
        >
            {inventory?.Price ? (
                <h5 className="card-title">{inventory?.Price.Name}</h5>
            ) : (
                <h5 className="card-title">
                    {t("manage-inventories:any-plan")}
                </h5>
            )}
            <h6 className="text-muted">{inventory?.Description}</h6>
            {inventory?.ValidUntil ? (
                <p className="card-text lead mb-2">
                    <strong>
                        {!isInPast(inventory?.ValidUntil)
                            ? t("misc:expires")
                            : t("misc:expired")}
                    </strong>
                    {": "}
                    {displayDate(inventory?.ValidUntil, i18n.language)}
                </p>
            ) : (
                <p className="card-text lead mb-2">
                    <strong>{t("misc:non-expiring")}</strong>
                </p>
            )}
            {inventory?.IsUnlimited ? (
                <p>
                    <strong>{inventory?.ResellerInventoryRedeemedCount}</strong>
                    {` ${t("manage-inventories:redeemed").toLowerCase()}`}
                </p>
            ) : (
                <p>
                    <strong>{inventory?.ResellerInventoryRedeemedCount}</strong>
                    {` ${t("manage-inventories:out-of")} `}
                    <strong>{inventory?.ResellerInventoryTotalCount}</strong>
                    {` ${t("manage-inventories:redeemed").toLowerCase()}`}
                </p>
            )}
            <button className="btn btn-outline-primary" onClick={view}>
                {t("buttons:view")}
            </button>
        </div>
    );
};

import React from "react";
import styles from "./LoadingAnimation.module.scss";
import SwitcherLogo from "./loading.svg?react";
import { Spinner } from "components/spinners/Spinner";

interface LoadingAnimationProps {
    size?: string;
}
const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ size }) => {
    // Check feature flag to determine which loading animation to use
    const useNewLogo = import.meta.env.VITE_USE_NEW_LOGO === "true";
    let classes = `loader ${styles["ss-loader"]} ${styles[size] || ""} ${useNewLogo ? ` ${styles["ringLoader"]}` : ""}`;

    return (
        <div className={classes}>
            {useNewLogo ? <Spinner /> : <SwitcherLogo />}
        </div>
    );
};

export default LoadingAnimation;

import React, { useMemo } from "react";
import { useNavigate } from "hooks/useNavigate";
import { useTranslation } from "react-i18next";
import { exists } from "helpers/booleans";

export interface UpgradeButtonProps {
    needsUpgrade?: boolean;
    isLapsedOrPaused?: boolean;
    alternateText?: string;
    alternateRoute?: string;
}

export const UpgradeButton: React.FC<UpgradeButtonProps> = ({
    needsUpgrade = false,
    isLapsedOrPaused = false,
    alternateText,
    alternateRoute
}: UpgradeButtonProps) => {
    const { t } = useTranslation("buttons");
    const { navigate } = useNavigate();

    const buttonText = useMemo<string>(() => {
        if (!needsUpgrade && alternateText) {
            return alternateText;
        }
        return t("upgrade-account").toUpperCase();
    }, [alternateText, needsUpgrade, t]);

    const buttonRoute = useMemo<string>(() => {
        if (!needsUpgrade && alternateRoute) {
            return alternateRoute;
        }
        return "/subscription/subscribe";
    }, [alternateRoute, needsUpgrade]);

    return (
        <button
            className="btn btn-primary"
            type="button"
            disabled={
                (!needsUpgrade || isLapsedOrPaused) && !exists(alternateRoute)
            }
            onClick={() => navigate(buttonRoute)}
        >
            {buttonText}
        </button>
    );
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { AppDispatch } from "store/store";
import {
    ActionsBarOptions,
    ComponentItem,
    GenericMultiSelect,
    ItemsHash,
    SearchSortOptions
} from ".";

interface UseGenericMultiSelectProps<T, Y> {
    items: ComponentItem<T>[];
    previouslySelectedIds: string[];
    /**
     * Run on submit, only used when the submit button lives outside of the GenericMultiSelect component.
     *
     * Do not use in conjunction with the actions bar options
     * */
    onSubmit?: (ids: string[]) => void;
    showNone?: boolean;
    allowUnselect?: boolean;
    isMultiple?: boolean;
    allowItemClick?: boolean;
    checkBoxLocation?: "left" | "right";
    actionsBarOptions?: ActionsBarOptions<Y>;
    searchSortOptions?: SearchSortOptions;
    allowNoSelection?: boolean;
    loading?: boolean;
}

export function useGenericMultiSelect<T, Y>({
    isMultiple,
    allowItemClick = true,
    previouslySelectedIds,
    allowUnselect = false,
    items,
    onSubmit,
    allowNoSelection = false,
    showNone = false,
    checkBoxLocation = "right",
    actionsBarOptions,
    searchSortOptions,
    loading = false
}: UseGenericMultiSelectProps<T, Y>) {
    const [itemsHash, setItemsHash] = useState<ItemsHash<T>>();
    const [isCleared, setIsCleared] = useState<boolean>(false);
    const [selectedId, setSelectedId] = useState<string>();
    const [numberSelected, setNumberSelected] = useState<number>(0);
    const [visibleBroadcasts, setVisibleBroadcasts] = useState<string[]>([]);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    /** Takes the initial data source "items" and transforms it to the ItemsHash type */
    const transformAndSetData = useCallback(
        (data: ComponentItem<T>[]) => {
            const localItems = data?.reduce((hash, i) => {
                return {
                    ...hash,
                    [i.id]: {
                        item: i,
                        selected:
                            !isCleared && previouslySelectedIds.includes(i.id),
                        disabled:
                            !allowUnselect &&
                            previouslySelectedIds.includes(i.id)
                    }
                };
            }, {} as ItemsHash<T>);

            setItemsHash(localItems);
        },
        [previouslySelectedIds, allowUnselect, isCleared]
    );

    /** Resets the form of the multi select. Can be used when reopening modals with multiselect if not already conditionally rendered */
    const resetMultiSelect = useCallback(() => {
        setIsCleared(false);
        setSelectedId(
            allowUnselect && !isMultiple ? previouslySelectedIds[0] : undefined
        );
        setNumberSelected(allowUnselect ? previouslySelectedIds.length : 0);
        transformAndSetData(items);
    }, [
        allowUnselect,
        isMultiple,
        items,
        previouslySelectedIds,
        transformAndSetData
    ]);

    /** If any items in the list are selected */
    const anySelected = useMemo(() => numberSelected > 0, [numberSelected]);

    /** Handle retransforming and setting ItemsHash any time the seed data changes */
    useEffect(() => {
        transformAndSetData(items);
    }, [transformAndSetData, items]);

    const handleSelect = useCallback(
        (id: string) => {
            if (isMultiple) {
                if (itemsHash[id]?.selected) {
                    setNumberSelected(numberSelected - 1);
                } else {
                    setNumberSelected(numberSelected + 1);
                }

                setItemsHash({
                    ...itemsHash,
                    [id]: {
                        ...itemsHash[id],
                        selected: !itemsHash[id]?.selected
                    }
                });
                return;
            } else {
                if (numberSelected < 1) setNumberSelected(1);
                if (selectedId === id) {
                    setSelectedId(undefined);
                } else {
                    setSelectedId(id);
                }
            }
        },
        [isMultiple, itemsHash, numberSelected, selectedId]
    );

    /** Handles selecting all items in hash, or deselecting if any are selected */
    const handleSelectOrDeselectAll = useCallback(
        (visibleElements: string[] = []) => {
            if (visibleElements.length === 0) {
                visibleElements = Object.keys(itemsHash);
            }

            const itemsHashEntries = Object.entries(itemsHash);

            // If there are selected items that are not visible, they should not be considered in the count
            const anyVisibleSelected = itemsHashEntries.some(
                ([id, item]) => item.selected && visibleElements.includes(id)
            );

            const updatedHash = itemsHashEntries.reduce((hash, [id]) => {
                // If the item is not visible, it should not be selected
                if (!visibleElements.includes(id)) {
                    return {
                        ...hash,
                        [id]: {
                            ...itemsHash[id],
                            selected: false
                        }
                    };
                }

                return {
                    ...hash,
                    [id]: {
                        ...itemsHash[id],
                        selected: !anyVisibleSelected
                    }
                };
            }, {} as ItemsHash<T>);

            setNumberSelected(
                anyVisibleSelected
                    ? 0
                    : Object.values(updatedHash).filter((i) => i.selected)
                          .length
            );
            setItemsHash(updatedHash);
        },
        [setItemsHash, itemsHash]
    );

    /** Handles submit when the submit button is outside of the <GenericMultiSelect /> component
     *
     * If using the actions bar option for the multi select component, avoid using this
     */
    const handleSubmit = useCallback(() => {
        if (!allowNoSelection && numberSelected === 0) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("errors:no-items-selected")
                })
            );
            return;
        }

        if (isMultiple) {
            onSubmit(
                Object.entries(itemsHash)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .filter(([_, item]) => item?.selected && !item?.disabled)
                    .map(([id]) => id)
            );
        } else {
            onSubmit([selectedId]);
        }
    }, [
        allowNoSelection,
        numberSelected,
        isMultiple,
        dispatch,
        t,
        onSubmit,
        itemsHash,
        selectedId
    ]);

    const GenericMultiSelectComponent = useMemo(
        () => (
            <GenericMultiSelect<T, Y>
                itemsHash={itemsHash}
                selectedId={selectedId}
                handleSelect={handleSelect}
                handleSelectAll={() =>
                    handleSelectOrDeselectAll(visibleBroadcasts)
                }
                allowUnselect={allowUnselect}
                allowItemClick={allowItemClick}
                isMultiple={isMultiple}
                showNone={showNone}
                checkBoxLocation={checkBoxLocation}
                actionsBarOptions={actionsBarOptions}
                searchSortOptions={searchSortOptions}
                loading={loading}
                setVisibleBroadcasts={setVisibleBroadcasts}
            />
        ),
        [
            itemsHash,
            selectedId,
            handleSelect,
            handleSelectOrDeselectAll,
            allowUnselect,
            isMultiple,
            showNone,
            actionsBarOptions,
            searchSortOptions,
            checkBoxLocation,
            allowItemClick,
            loading,
            visibleBroadcasts
        ]
    );
    return {
        GenericMultiSelectComponent,
        resetMultiSelect,
        //itemsHash,
        //selectedId,
        //handleSelect,
        handleSelectOrDeselectAll,
        anySelected,
        handleSubmit
    };
}

import React, { PropsWithChildren, useEffect, useState } from "react";
import { Link } from "react-navi";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import {
    accountSettingsRoutes,
    creationToolsRoutes,
    helpRoutes,
    sidebarRoutes,
    videoCMSRoutes
} from "navi-router";
import { NaviPageContent as PageContent } from "./page-content/NaviPageContent";
import HamburgerIcon from "assets/icons/hamburger.svg?react";
import Loading from "components/loading/Loading";
import styles from "./DashboardLayout.module.scss";
import {
    mustBeLoggedIn,
    useRedirectIfDisallowed
} from "hooks/useRedirectIfDisallowed";
import { ConfirmationModal } from "components/modal/ConfirmationModal";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { useRefreshToken } from "hooks/useRefreshToken";
import { useCurrentRoute } from "react-navi";
import { SubscriptionStatusBanner } from "components/banners/custom-banners/SubscriptionStatusBanner";
import { PromoModal } from "components/modal/PromoModal";
import { useSupportSystem } from "hooks/useSupportSystem";
import { ModalManager } from "components/modal/ModalManager";

// Import the logos and set Logo based on the environment variable
import OldLogo from "assets/icons/switcher-horizontal_old.svg?url";
import NewLogo from "assets/icons/switcher-horizontal_new.svg?url";
import { NaviSidebar } from "./sidebar/NaviSidebar";

const Logo = import.meta.env.VITE_USE_NEW_LOGO === "true" ? NewLogo : OldLogo;

export const NaviDashboardLayout: React.FC<PropsWithChildren> = () => {
    const { isInBrowser } = useSwitcherSdk();
    let isDisallowed = true;
    const { url } = useCurrentRoute();

    isDisallowed = useRedirectIfDisallowed(
        mustBeLoggedIn,
        "/login?redirect=" + encodeURIComponent(url.pathname + url.search)
    );
    isDisallowed = !useRefreshToken().isAuthenticated;

    useSupportSystem(isInBrowser && !isDisallowed);

    const isUserReady =
        useSelector((state: RootState) => state.user)?.userInfo?.UserId != null;
    const {
        confirmationOpen,
        htmlMessage,
        message,
        cancelText,
        confirmText,
        onSuccess
    } = useSelector((state: RootState) => state.confirmation);
    const [showSidebar, setShowSidebar] = useState<boolean>(false);
    const toggleSidebar = () => {
        setShowSidebar((prev) => !prev);
    };

    const pageSpecificBanner = useSelector(
        (s: RootState) => s.page?.activeBanner
    );

    useEffect(() => {
        const handleResize = () => {
            //setting the sidebar to false when the window is resized larger than mobile breakpoint
            if (window.innerWidth >= 1024) {
                setShowSidebar(false);
            }
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            {isDisallowed ? null : (
                <div className="container-fluid">
                    <ConfirmationModal
                        isOpen={confirmationOpen}
                        htmlMessage={htmlMessage}
                        message={message}
                        cancelText={cancelText}
                        confirmText={confirmText}
                        onSuccess={onSuccess}
                    />
                    {isInBrowser && <PromoModal />}
                    <Loading isLoading={!isUserReady} offset />

                    {isUserReady && (
                        <div className={styles["main-layout"]}>
                            {isInBrowser && (
                                <>
                                    <NaviSidebar
                                        videoCMSRoutes={videoCMSRoutes}
                                        accountSettingsRoutes={
                                            accountSettingsRoutes
                                        }
                                        creationToolsRoutes={
                                            creationToolsRoutes
                                        }
                                        helpRoutes={helpRoutes}
                                        sidebarRoutes={sidebarRoutes}
                                        activeRoute={
                                            url?.pathname
                                                ?.split("/")
                                                .filter((x) => x)[0]
                                        }
                                        show={showSidebar}
                                        onToggle={toggleSidebar}
                                    />

                                    <div className={styles["mobile-header"]}>
                                        <HamburgerIcon
                                            onClick={toggleSidebar}
                                        />
                                        <Link href="/home">
                                            <img
                                                className={styles.logo}
                                                src={Logo}
                                                alt="Switcher Logo"
                                            />
                                        </Link>
                                    </div>
                                </>
                            )}
                            <div className="col-sm">
                                {isInBrowser && (pageSpecificBanner ?? <></>)}
                                {isInBrowser && <SubscriptionStatusBanner />}
                                <ModalManager />
                                <div className={styles["page-content"]}>
                                    <PageContent showPageHeader={isInBrowser} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

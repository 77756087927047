import { useNavigation } from "react-navi";
import { useCallback } from "react";
import { useNavigate as useRouterNavigate } from "react-router";
import { NavigateOptionsWithoutURL } from "navi/dist/types/Navigation";
import { NavigateOptions as RouterNavigateOptions } from "react-router-dom";

const useReactRouterDom = import.meta.env.VITE_USE_REACT_ROUTER_DOM === "true";

export interface UseLinkOptions {
    // default page to navigate to
    href?: string;
    // a function to be called and awaited before navigation
    onBeforeNavigate?: () => Promise<void>;
}

export interface NavigateOptions
    extends Partial<NavigateOptionsWithoutURL & RouterNavigateOptions> {
    fragment?: string;
    searchParams?: { [key: string]: string };
}

/**
 * A wrapper for react-navi navigate method with the option to pass a function to be called before the navigation occurs.
 */
export const useNavigate = () => {
    const navigation = useNavigation();

    const routerNavigate = (
        useReactRouterDom ? useRouterNavigate : () => () => {}
    )();

    const navigate = useCallback(
        async (
            href = undefined,
            { fragment, searchParams }: NavigateOptions = {
                fragment: undefined,
                searchParams: undefined
            },
            navigateOptions: NavigateOptions = {}
        ) => {
            if (href) {
                // remove trailing slash
                if (href && href !== "/") {
                    href = href.replace(/\/$/, "");
                }

                if (searchParams) {
                    href += "?";
                    for (const param of Object.keys(searchParams)) {
                        href += `${param}=${searchParams[param]}`;
                    }
                }
                href += fragment ? `#${fragment}` : "";

                if (useReactRouterDom) {
                    routerNavigate(href, navigateOptions);
                } else {
                    navigation.navigate(href, navigateOptions);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return { navigate };
};

import React, { useEffect, useState } from "react";
import { StreamingProvider } from "@switcherstudio/switcher-api-client";
import { useNavigate } from "hooks/useNavigate";
import { DestinationSelector } from "components/destination-selector/DestinationSelector";
import { getTwitchUserAndChannel } from "store/platforms/thunks";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { usePlatformActions } from "hooks/usePlatformActions";
import { PlatformId } from "../../types";
import { useTranslation } from "react-i18next";

interface TwitchContainerProps {
    platform: StreamingProvider;
}

export const TwitchContainer: React.FC<TwitchContainerProps> = ({
    platform
}: TwitchContainerProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { navigate } = useNavigate();

    const [destinations, setDestinations] = useState([]);
    const { user } = useSelector((s: RootState) => s.platforms.twitch);
    const { unlink } = usePlatformActions(PlatformId.Twitch);
    const { t } = useTranslation("platforms");

    useEffect(() => {
        async function getChannelInfo() {
            try {
                await dispatch(getTwitchUserAndChannel());
            } catch (e) {
                await unlink();
                navigate(`/platforms/connect/${platform.Id}`);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("token-expired", {
                            platformName: platform.Name
                        })
                    })
                );
            }
        }

        getChannelInfo();
    }, [dispatch, navigate, platform.Id, unlink, t, platform.Name]);

    useEffect(() => {
        setDestinations([user]);
    }, [user]);

    const onClick = async () => {
        navigate(`/platforms/stream/${platform.Id}`);
    };

    return (
        <>
            <DestinationSelector
                destinations={destinations}
                imgSrcKey="profile_image_url"
                destinationNameKey="display_name"
                onDestinationClick={onClick}
            />
        </>
    );
};

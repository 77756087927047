import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateHeader, resetHeader } from "store/header/slice";
import { CustomHeaderState } from "store/header/types";

export const usePageHeader = (headerProps?: CustomHeaderState) => {
    const dispatch = useDispatch();
    const updatePageHeader = useCallback(
        (args: CustomHeaderState) => {
            dispatch(updateHeader({ ...headerProps, ...args }));
        },
        [dispatch, headerProps]
    );

    useEffect(() => {
        if (headerProps) {
            updatePageHeader(headerProps);
        }
        // reset headers on page dismount
        return () => dispatch(resetHeader()) as unknown as void;
    }, [dispatch, headerProps, updatePageHeader]);

    return { updatePageHeader };
};

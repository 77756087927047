import { Card } from "../Card";
import { Link } from "react-navi";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import StripeConnectLogo from "assets/icons/stripe-connect-logo.svg?react";
import { GatedContentStatus } from "hooks/useStripeAccountInfo";
import { useMemo } from "react";
import { StripeAccountsDetails } from "@switcherstudio/switcher-api-client";

export interface IsSetupStateProps {
    link: string;
    target: string;
    details: StripeAccountsDetails;
    gatedContentStatus: GatedContentStatus;
}

export const IsSetupState: React.FC<IsSetupStateProps> = ({
    link,
    target,
    details,
    gatedContentStatus
}) => {
    const { t } = useTranslation();

    const statusText = useMemo(() => {
        if (
            gatedContentStatus === GatedContentStatus.STRIPE_ACCOUNT_UNVERIFIED
        ) {
            return t("video-player-settings:verify-stripe-no-link");
        }
        return null;
    }, [gatedContentStatus, t]);

    return (
        <Card className={styles["container"]}>
            <StripeConnectLogo />
            <div>
                <h5 className={styles["card-header"]}>
                    {details?.Name ?? t("gated-content:stripe-connect-account")}
                </h5>
                <p className={styles["email"]}>{details?.Email}</p>
                {statusText && (
                    <Card position="foreground">
                        <p className={styles["text"]}>{statusText}</p>
                    </Card>
                )}
                <Link href={link} target={target} className={styles["link"]}>
                    {t("gated-content:manage-stripe-account")}
                </Link>
            </div>
        </Card>
    );
};

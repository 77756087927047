import {
    Entitlement,
    SilverSunnStripeSubscription
} from "@switcherstudio/switcher-api-client";
import { PaymentMethodsModal } from "components/modal/PaymentMethodsModal";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SubscriptionPage.module.scss";
import { format } from "date-fns";
import { SubscriptionWithPrice } from "./NewSubscriptionPage";
import { es, enUS } from "date-fns/locale";

export const NextPaymentOn = ({
    activeEntitlement,
    activePlan: { subscription: activeSubscriptionPlan } = {
        subscription: undefined,
        price: undefined
    }
}: {
    activeEntitlement: Entitlement;
    activePlan?: SubscriptionWithPrice;
}) => {
    const { t, i18n } = useTranslation();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { data: stripeCustomer } = useSwitcherClient(
        (client) => client.stripe_GetCustomer,
        { requestImmediately: true }
    );
    const defaultCreditCard = useMemo(
        () =>
            stripeCustomer?.StripeCreditCards?.find((c) => c.Default === true),
        [stripeCustomer]
    );
    const nextPaymentString = useMemo(() => {
        if (!activeSubscriptionPlan) {
            return activeEntitlement?.ExpiresAt
                ? format(
                      new Date(activeEntitlement.ExpiresAt),
                      "MMMM dd, yyyy",
                      { locale: i18n.language === "es" ? es : enUS }
                  )
                : "";
        }

        if (!!activeSubscriptionPlan) {
            return `${format(
                new Date(activeSubscriptionPlan.ActiveUntil),
                "MMMM dd, yyyy",
                { locale: i18n.language === "es" ? es : enUS }
            )} ${t("misc:using")} ${defaultCreditCard?.Brand} ${t(
                "misc:ending-in"
            )} ${defaultCreditCard?.LastFour}`;
        }
        return "";
    }, [activeEntitlement, activeSubscriptionPlan, defaultCreditCard, t, i18n]);

    const displayRenewalText = (subscription: SilverSunnStripeSubscription) => {
        if (!activeSubscriptionPlan) {
            return t("subscription:active-until");
        }
        if (subscription.CancelAtPeriodEnd || !!subscription.CanceledAt) {
            return `${t("subscription:cancellation")}: `;
        }

        if (subscription.TrialUntil === subscription.ActiveUntil) {
            return `${t("subscription:trial-ends")}: `;
        }

        return `${t("subscription:next-payment")}: `;
    };

    return (
        <div className={styles["next-payment-on"]}>
            <h5>{displayRenewalText(activeSubscriptionPlan)}</h5>

            <p>{nextPaymentString}</p>

            {!!activeSubscriptionPlan && (
                <div className={styles["button-container"]}>
                    <button
                        className="btn btn-outline-primary"
                        onClick={() => setModalOpen(true)}
                    >
                        {t("subscription:edit-payment-method")}
                    </button>
                </div>
            )}
            <PaymentMethodsModal isOpen={modalOpen} setIsOpen={setModalOpen} />
        </div>
    );
};

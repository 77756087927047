import React from "react";
import { CloudAssetsProps } from "./types";
import { ProgressBar } from "components/widgets/progress-bar/ProgressBar";
import CloudIcon from "assets/icons/cloud.svg?react";
import { EnabledIndicator } from "components/widgets/EnabledIndicator";
import { UpgradeButton } from "components/buttons/upgrade-button/UpgradeButton";
import { useTranslation } from "react-i18next";
import { useHasAccess } from "hooks/useHasAccess";
import { Widget } from "./Widget";

export const CloudAssetsWidget: React.FC<CloudAssetsProps> = ({
    enabled,
    assets
}: CloudAssetsProps) => {
    const { t } = useTranslation("widgets");
    const isLapsedOrPaused = useHasAccess(
        (userInfo) =>
            userInfo?.Roles.includes("Paused") ||
            userInfo?.Roles.includes("Lapsed")
    );

    return (
        <Widget
            icon={<CloudIcon />}
            title="CLOUD ASSETS"
            details={[
                {
                    title: t("widgets:your-plan"),
                    children: <EnabledIndicator enabled={enabled} />
                },
                {
                    title: t("widgets:cloud-assets-used"),
                    children: (
                        <ProgressBar
                            maxValue={assets.MaxAssets}
                            currentValue={assets.TotalAssets}
                        />
                    )
                }
            ]}
            footerSubtitle={
                enabled ? t("store-cloud-assets") : t("upgrade-account-message")
            }
            button={
                <UpgradeButton
                    needsUpgrade={enabled === false}
                    isLapsedOrPaused={isLapsedOrPaused}
                    alternateText={t("manage-assets").toUpperCase()}
                    alternateRoute="/switcher-cloud"
                />
            }
        />
    );
};

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import { CARD_ELEMENT_OPTIONS } from "components/forms/payment-form/PaymentForm";
import { trackEvent } from "helpers/analyticsHelpers";
import rollbar from "helpers/rollbar";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { RootState } from "store/reducers";
import styles from "./index.module.scss";
import { useSwitcherClient } from "hooks/useSwitcherClient";

export const AddNewPaymentMethod = ({
    onSubmit
}: {
    onSubmit: (id: string) => void;
}) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const { userInfo } = useSelector((s: RootState) => s.user);

    const handleAddPaymentMethodError = useCallback(
        (e: any) => {
            rollbar.error("Error adding payment method", e);
            setError(t("errors:payment-add-error"));
            setIsSubmitting(false);
        },
        [t]
    );

    const handleAddPaymentMethodSuccess = useCallback(
        (_, newDefaultCardId) => {
            trackEvent("Added Payment Info", null, {
                integrations: { Intercom: false }
            });
            onSubmit(newDefaultCardId);

            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "messages:payment-add-success"
                })
            );
            setIsSubmitting(false);
            setIsExpanded(false);
        },
        [dispatch, onSubmit]
    );

    const { dispatchApiRequest: dispatchAttachPaymentMethod } =
        useSwitcherClient(
            (client) => client.userPaymentMethods_AttachPaymentMethod,
            {
                onError: handleAddPaymentMethodError,
                onSuccess: handleAddPaymentMethodSuccess,
                transformResponseData: ({
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    originalArgs: [_, paymentMethodId]
                }) => {
                    return paymentMethodId;
                }
            }
        );

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (!stripe || !elements) {
                return;
            }

            const card = elements.getElement(CardElement);

            if (!card) {
                return;
            }

            setIsSubmitting(true);
            setError("");

            try {
                // create payment method
                const paymentMethod = await stripe.createPaymentMethod({
                    type: "card", // TODO: support other types
                    card: card
                });

                if (paymentMethod.error?.message) {
                    setError(paymentMethod.error.message);
                    setIsSubmitting(false);
                    return;
                }

                if (!paymentMethod.paymentMethod) {
                    setError(t("errors:payment-add-error"));
                    setIsSubmitting(false);
                    return;
                }

                // attach payment method to customer
                dispatchAttachPaymentMethod([
                    userInfo.UserId,
                    paymentMethod.paymentMethod.id
                ]);
            } catch (e) {
                handleAddPaymentMethodError(e);
            }
        },
        [
            dispatchAttachPaymentMethod,
            handleAddPaymentMethodError,
            elements,
            stripe,
            t,
            userInfo.UserId
        ]
    );

    const onChange = useCallback((event: StripeCardElementChangeEvent) => {
        if (event.error) {
            setError(event.error.message);
        } else {
            setError("");
        }
    }, []);

    return (
        <div className={styles["add-new-payment-container"]}>
            <div className={styles["card-details"]}>
                {t("subscription:add-new-payment-method")}
            </div>

            <div className={styles["action-buttons"]}>
                {!isExpanded && (
                    <button
                        className="btn btn-primary"
                        onClick={() => setIsExpanded(true)}
                    >
                        {`+ ${t("subscription:payment-method")}`}
                    </button>
                )}
            </div>

            {isExpanded && (
                <form
                    className={styles["expanded-content"]}
                    onSubmit={handleSubmit}
                >
                    <div className={styles["stripe-payment-element"]}>
                        <CardElement
                            id="card-element"
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={onChange}
                        />
                    </div>
                    {error && (
                        <div
                            className="alert alert-danger"
                            role="alert"
                            style={{ marginTop: "1rem", marginBottom: 0 }}
                        >
                            {error}
                        </div>
                    )}

                    <div className={styles["action-buttons"]}>
                        <button
                            className="btn btn-link"
                            onClick={() => setIsExpanded(false)}
                        >
                            {t("button:cancel")}
                        </button>

                        <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {t("page-titles:add-payment")}
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

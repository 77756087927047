import { Detail, DetailProps } from "components/widgets/Detail";
import { PropsWithChildren } from "react";
import styles from "./index.module.scss";

export interface WidgetProps extends PropsWithChildren {
    /** If set to true, the widget will not render, allowing any needed data to be fetched */
    loading?: boolean;
    /** Sets icon for widget */
    icon: JSX.Element;
    /** Sets title for widget */
    title: string;
    /** Sets lines of widget details */
    details: DetailProps[];
    /** Sets subtitle in footer */
    footerSubtitle?: string | JSX.Element;
    /** Sets CTA on widget */
    button?: JSX.Element;
}

export const Widget: React.FC<WidgetProps> = ({
    loading,
    icon,
    title,
    children,
    details,
    footerSubtitle,
    button
}) => {
    if (loading) return <Widget icon={icon} title={title} details={[]} />;

    return (
        <div className={styles["widget"]}>
            <div className={styles["title"]}>
                <div className={styles["icon"]}>{icon}</div>
                <div className={styles["title-text"]}>{title}</div>
            </div>

            <hr />

            <div className={styles["details"]}>
                {details.map(
                    (
                        { title, infoText, children, buttonText, onClick },
                        key
                    ) => {
                        return (
                            <Detail
                                key={key}
                                title={title}
                                infoText={infoText}
                                buttonText={buttonText}
                                onClick={onClick}
                            >
                                {children}
                            </Detail>
                        );
                    }
                )}
            </div>

            {children}

            <div className={styles["footer"]}>
                <div className={styles["subtitle"]}>{footerSubtitle}</div>
                <div className={styles["button-container"]}>{button}</div>
            </div>
        </div>
    );
};

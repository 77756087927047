import { exists } from "./booleans";

export const isEmptyOrUndefined = (obj: object) => {
    if (!obj) return true;

    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }

    return true;
};

export const hasValues = (obj: object): boolean =>
    Object.values(obj).some((p) => exists(p));

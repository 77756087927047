import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { GenericMultiSelectModal } from "../GenericMultiSelectModal";
import { useSwitcherClient } from "../../../hooks/useSwitcherClient";
import { PlayerDetails } from "../../entity-details/PlayerDetails";
import { addNotification } from "../../../store/notification/slice";
import { NotificationType } from "../../../store/notification/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../../store/store";
import { ComponentItem } from "components/generic-multiselect";
import { VideoPlayerResponse } from "@switcherstudio/switcher-api-client";
import { useClaimCheck } from "hooks/useClaimCheck";

interface PlayerSelectModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    previouslySelectedIds: string[] | undefined; //send empty array if not applicable
    handleSelect: (ids: string[]) => void; //callback function to be called on modal's onSubmit. returns list of selected ids
    /** Adds any playlist that contains this broadcast id to the "previouslySelectedIds" array */
    broadcastToFilter?: string;
    allowUnselect?: boolean;
    allowNoSelection?: boolean;
    returnPlaylistIds?: boolean;
    /** Applies specific styles */
    isDuringPassCreation?: boolean;
    players?: VideoPlayerResponse[];
    preventDismiss?: boolean;
    submitHandlesClose?: boolean;
    emptyStateMessage?: JSX.Element;
}

export const PlayerSelectModal = ({
    isOpen,
    setIsOpen,
    previouslySelectedIds = [],
    handleSelect,
    broadcastToFilter,
    allowUnselect = false,
    allowNoSelection = false,
    returnPlaylistIds = false,
    isDuringPassCreation = false,
    players,
    preventDismiss = false,
    submitHandlesClose = true,
    emptyStateMessage
}: PlayerSelectModalProps) => {
    const { userInfo } = useSelector((state: RootState) => state.user);
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const hasCatalogClaim = useClaimCheck("catalog");

    const {
        dispatchApiRequest: getVideoPlayers,
        data: fetchedPlayers,
        loading
    } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_GetVideoPlayers,
        {
            requestImmediately: false,
            args: [userInfo?.ProjectId, true],
            hideLoading: true,
            onError: () => {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("errors:player-retrieval-error")
                    })
                );
            }
        }
    );

    // if players from prop, return that, if not call API
    const _players = useMemo(
        () => players ?? fetchedPlayers,
        [players, fetchedPlayers]
    );

    // load players after open
    useEffect(() => {
        if (players || fetchedPlayers) return;
        if (isOpen) {
            getVideoPlayers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, fetchedPlayers, players]);

    const _previouslySelectedIds = useMemo<string[]>(() => {
        let playlistIds: string[] = previouslySelectedIds ?? [];

        if (_players && broadcastToFilter) {
            const filteredIds = _players
                .filter((p) =>
                    p?.VideoPlayer?.VideoPlayerPlaylists?.[0]?.VideoPlayerPlaylistBroadcasts?.some(
                        (b) => b?.BroadcastId === broadcastToFilter
                    )
                )
                .map((p) =>
                    returnPlaylistIds
                        ? p?.VideoPlayer?.VideoPlayerPlaylists?.[0]?.Id
                        : p?.VideoPlayer?.Id
                );

            playlistIds = [...filteredIds, ...playlistIds];
        }

        return playlistIds.filter(
            (id) => id && id !== "00000000-0000-0000-0000-000000000000"
        );
    }, [broadcastToFilter, _players, previouslySelectedIds, returnPlaylistIds]);

    const selectItems = useMemo(() => {
        if (_players) {
            return _players
                .filter((vp) => !vp.VideoPlayer.IsLibraryPlayer)
                .map((vp) => {
                    return {
                        id: returnPlaylistIds
                            ? vp.VideoPlayer?.VideoPlayerPlaylists?.[0]?.Id
                            : vp.VideoPlayer?.Id,
                        component: <PlayerDetails item={vp} />
                    } as ComponentItem<VideoPlayerResponse>;
                });
        }
        return null;
    }, [_players, returnPlaylistIds]);

    return (
        <GenericMultiSelectModal
            isMultiple={true}
            onSubmit={handleSelect}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            previouslySelectedIds={_previouslySelectedIds}
            allowUnselect={allowUnselect}
            allowNoSelection={allowNoSelection}
            titleText={
                hasCatalogClaim
                    ? t("players-select-modal:select-collection")
                    : t("players-select-modal:select-player")
            }
            buttonText={
                isDuringPassCreation
                    ? hasCatalogClaim
                        ? t("buttons:add-to-collections")
                        : t("buttons:add-to-players")
                    : t("buttons:save")
            }
            items={selectItems}
            preventDismiss={preventDismiss}
            submitHandlesClose={submitHandlesClose}
            loading={loading}
            emptyStateMessage={emptyStateMessage}
        />
    );
};

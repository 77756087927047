import ShoppingBagIcon from "assets/icons/shopping-bag.svg?react";
import { EnabledIndicator } from "components/widgets/EnabledIndicator";
import { useTranslation } from "react-i18next";
import { ShopifyExternalAuth } from "@switcherstudio/switcher-api-client";
import { usePlatformActions } from "hooks/usePlatformActions";
import { PlatformId } from "views/page-content/platforms/types";
import { Widget } from "./Widget";

export const ShopifyLinkedWidget = ({
    enabled,
    storeCredentials
}: {
    enabled: boolean;
    storeCredentials: ShopifyExternalAuth[];
}) => {
    const { t } = useTranslation("widgets");

    const { link } = usePlatformActions(PlatformId.Shopify);

    return (
        <Widget
            icon={<ShoppingBagIcon />}
            title={t("widgets:shopify-widget-title").toUpperCase()}
            details={[
                {
                    title: t("widgets:link-status"),
                    children: (
                        <EnabledIndicator
                            enabled={enabled}
                            enabledText={t("connected")}
                            disabledText={t("disconnected")}
                        />
                    )
                },
                {
                    title: t("widgets:store-name"),
                    infoText: enabled ? storeCredentials[0].Name : "-"
                }
            ]}
            footerSubtitle={
                enabled
                    ? t("widgets:shopify-store-linked")
                    : t("widgets:shopify-store-not-linked")
            }
            button={
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() =>
                        enabled
                            ? window.open(
                                  `https://admin.shopify.com/store/${storeCredentials[0].Name}/apps/${import.meta.env.VITE_SHOPIFY_APP_NAME}`
                              )
                            : link({ finalReturnUrl: "/home" })
                    }
                >
                    {enabled
                        ? t("widgets:manage-connection").toUpperCase()
                        : t("widgets:link-shopify-store").toUpperCase()}
                </button>
            }
        />
    );
};

import { useEffect, useMemo } from "react";
import { Tabs } from "components/tabs/Tabs";
import { ViewsTab } from "./ViewsTab";
import { RevenueTab } from "./RevenueTab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import {
    updateAnalyticsActiveTab,
    updateAnalyticsCustomEndDate,
    updateAnalyticsCustomStartDate,
    updateAnalyticsDateRangeSelection
} from "store/view/slice";
import { AnalyticsDateRangeOptions, AnalyticsTabs } from "store/view/types";
import { usePageHeader } from "hooks/usePageHeader";
import { DateRangeSelector } from "components/inputs/select/DateRangeSelector";
import { useGetStripeConnectLink } from "hooks/useGetStripeConnectLink";
import {
    GatedContentStatus,
    useStripeAccountInfo
} from "hooks/useStripeAccountInfo";
import { useTranslation } from "react-i18next";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import dayjs from "dayjs";
import { MetricsRequestSummaryInterval } from "@switcherstudio/switcher-api-client";
import { getUTCDate, setToEndOfDay, setToStartOfDay } from "helpers/time";

export const AnalyticsPage: React.FC = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { link: stripeLink } = useGetStripeConnectLink();
    const { gatedContentStatus } = useStripeAccountInfo();

    const {
        analyticsActiveTab,
        analyticsDateRangeSelection,
        analyticsCustomDateRangeSelection = { start: null, end: null }
    } = useSelector((state: RootState) => state.view);

    const { start = null, end = null } = useMemo(() => {
        switch (analyticsDateRangeSelection) {
            case AnalyticsDateRangeOptions.SevenDays:
                return {
                    start: dayjs(setToStartOfDay(getUTCDate()))
                        .subtract(7, "day")
                        .toISOString(),
                    end: null
                };
            case AnalyticsDateRangeOptions.ThirtyDays:
                return {
                    start: dayjs(setToStartOfDay(getUTCDate()))
                        .subtract(30, "day")
                        .toISOString(),
                    end: null
                };
            case AnalyticsDateRangeOptions.MonthToDate:
                return {
                    start: dayjs(setToStartOfDay(getUTCDate()))
                        .startOf("month")
                        .toISOString(),
                    end: null
                };
            case AnalyticsDateRangeOptions.YearToDate:
                return {
                    start: dayjs(setToStartOfDay(getUTCDate()))
                        .startOf("year")
                        .toISOString(),
                    end: null
                };
            case AnalyticsDateRangeOptions.Custom:
                return {
                    start:
                        analyticsCustomDateRangeSelection.start ??
                        dayjs(getUTCDate()).subtract(30, "day").toISOString(),
                    end:
                        analyticsCustomDateRangeSelection.end ??
                        setToEndOfDay(getUTCDate()).toISOString()
                };
        }
    }, [analyticsCustomDateRangeSelection, analyticsDateRangeSelection]);

    const { data: userMetrics, loading } = useSwitcherClient(
        (client) => client.videoAnalytics_GetMetrics,
        {
            requestImmediately: true,
            args: [
                {
                    StartDate: start,
                    EndDate: end,
                    SummaryInterval: MetricsRequestSummaryInterval.Day,
                    PopulateDateRange: true
                }
            ],
            hideLoading: true
        }
    );

    const { data: revenueMetrics, loading: revenueLoading } = useSwitcherClient(
        (client) => client.revenueAnalytics_GetRevenue,
        {
            requestImmediately: true,
            args: [
                {
                    StartDate: start,
                    EndDate: end,
                    SummaryInterval: MetricsRequestSummaryInterval.Day,
                    PopulateDateRange: true
                }
            ],
            hideLoading: true
        }
    );

    useEffect(() => {
        if (gatedContentStatus !== GatedContentStatus.READY) {
            dispatch(updateAnalyticsActiveTab(AnalyticsTabs.Views));
        }
    }, [dispatch, gatedContentStatus]);

    usePageHeader({
        customButtons: (
            <DateRangeSelector<AnalyticsDateRangeOptions>
                id="analytics-date-range"
                label="Range"
                selection={analyticsDateRangeSelection}
                options={Object.values(AnalyticsDateRangeOptions).map(
                    (value) => ({
                        value,
                        text: t(`analytics-page:date-range-options:${value}`)
                    })
                )}
                onChange={(e) =>
                    dispatch(
                        updateAnalyticsDateRangeSelection(
                            e.target.value as AnalyticsDateRangeOptions
                        )
                    )
                }
                customStartDate={start}
                placeholderStartDate={start}
                onStartDateChange={(date) =>
                    dispatch(updateAnalyticsCustomStartDate(date))
                }
                customEndDate={end}
                placeholderEndDate={end}
                onEndDateChange={(date) =>
                    dispatch(updateAnalyticsCustomEndDate(date))
                }
                datePickerProps={{
                    showTimeSelect: false,
                    locale: i18n.language
                }}
            />
        )
    });

    return (
        <Tabs<AnalyticsTabs>
            tabs={[
                {
                    id: AnalyticsTabs.Views,
                    label: "Views",
                    component: (
                        <ViewsTab
                            metricsLoading={loading}
                            metrics={userMetrics}
                        />
                    )
                },
                {
                    id: AnalyticsTabs.Revenue,
                    label: "Revenue",
                    component: (
                        <RevenueTab
                            stripeLink={stripeLink}
                            metrics={revenueMetrics}
                            metricsLoading={revenueLoading}
                        />
                    )
                }
            ]}
            activeTab={analyticsActiveTab}
            setActiveTab={(id) => dispatch(updateAnalyticsActiveTab(id))}
            hideTabs={gatedContentStatus !== GatedContentStatus.READY}
        />
    );
};

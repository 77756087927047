import React, { useEffect } from "react";
import { useNavigate } from "hooks/useNavigate";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { PlatformId } from "views/page-content/platforms/types";
import { DestinationDescriptor } from "../../types";
import { updateGeneralForm } from "store/platforms/slice";

export const SimulcastPlayerDestinationSelector: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { navigate } = useNavigate();

    const {
        generalForm: { selectedSimulcastDestinations }
    } = useSelector((state: RootState) => state.platforms.general);

    useEffect(() => {
        if (
            !selectedSimulcastDestinations.some(
                (d) => d.platform === PlatformId.VideoPlayer
            )
        ) {
            const destinationDescriptor: DestinationDescriptor = {
                name: "Player Page",
                id: "videoplayer",
                platform: PlatformId.VideoPlayer
            };

            dispatch(
                updateGeneralForm({
                    selectedSimulcastDestinations: [
                        ...selectedSimulcastDestinations,
                        destinationDescriptor
                    ]
                })
            );

            dispatch(
                updateGeneralForm({
                    enableRecording: true
                })
            );
        }

        navigate(`/platforms/simulcast/destinations`);
    }, [dispatch, navigate, selectedSimulcastDestinations]);

    return <></>;
};

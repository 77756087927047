import React, { useCallback } from "react";
import { SilverSunnStripeSubscription } from "@switcherstudio/switcher-api-client";
import styles from "./PlanGrids.module.scss";
import { Link } from "react-navi";
import { useTranslation } from "react-i18next";
import { StripeStatus } from "../platforms/types";
import rollbar from "helpers/rollbar";
import { client } from "api/client";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useHasAccess } from "hooks/useHasAccess";
import { openConfirmation } from "store/confirmation/slice";
import { trackEvent } from "helpers/analyticsHelpers";
import { SubscriptionWithPrice } from "./NewSubscriptionPage";
import { calculateDiscountedAmount } from "helpers/stripe";
import { useNavigate } from "hooks/useNavigate";
interface SubscriptionGridProps {
    subscriptions: SubscriptionWithPrice[] | undefined;
    displayDateFn: (date?: string) => string | undefined;
    displayCostFn: (
        amount?: number,
        showPeriodText?: boolean
    ) => string | undefined;
    headerButtonFn: (sub: SilverSunnStripeSubscription) => void;
    isStudioRole: boolean;
}

export const NewSubscriptionGrid: React.FC<SubscriptionGridProps> = ({
    subscriptions,
    displayCostFn,
    headerButtonFn,
    /** Currently the role is only found on the userInfo object rather than on each subscription
     * so it must be passed down.
     */
    isStudioRole
}) => {
    const dispatch = useDispatch();
    const { navigate } = useNavigate();

    const isPaused = useHasAccess((userInfo) =>
        userInfo.Roles.includes("Paused")
    );
    const { t } = useTranslation();

    const displayDiscount = (sub: SilverSunnStripeSubscription) => {
        const amountOff = (sub.AmountOff || 0) / 100;

        if (sub.PercentOff !== null) {
            return ` (${t("misc:discount", { amount: sub.PercentOff + "%" })})`;
        } else if (amountOff > 0) {
            return ` (${t("misc:discount", { amount: "$" + amountOff })})`;
        }

        return "";
    };

    const cancelSub = useCallback(
        async (subscriptionId: string) => {
            try {
                await client.stripe_DeleteSubscription(subscriptionId);
                navigate("/subscription/subscribe");
            } catch (e) {
                rollbar.error("Error canceling user subscription", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: e?.message
                    })
                );
            }
        },
        [dispatch, navigate]
    );

    const trySave = useCallback(
        async (subscriptionId: string, planId: string) => {
            try {
                const profitwellResponse = await profitwell(
                    "init_cancellation_flow",
                    { subscription_id: subscriptionId }
                );
                if (profitwellResponse) {
                    trackEvent("ProfitWell Retain Attempt", {
                        profitWellResponse: profitwellResponse,
                        subscriptionId,
                        planId
                    });
                }

                if (!profitwellResponse) {
                    await dispatch(
                        openConfirmation({
                            message: t("messages:cancel-confirmation"),
                            onSuccess: () => cancelSub(subscriptionId)
                        })
                    );
                } else if (
                    profitwellResponse.status === "retained" ||
                    profitwellResponse.status === "aborted"
                ) {
                    return;
                } else {
                    await cancelSub(subscriptionId);
                }
            } catch (e) {
                await dispatch(
                    openConfirmation({
                        message: t("messages:cancel-confirmation"),
                        onSuccess: () => cancelSub(subscriptionId)
                    })
                );
            }
        },
        [dispatch, cancelSub, t]
    );

    const getHeaderButtonText = useCallback(
        ({ subscription }: SubscriptionWithPrice) => {
            if (
                subscription.Status === StripeStatus.PastDue ||
                subscription.Status === StripeStatus.Incomplete
            ) {
                return t("buttons:fix-billing");
            }
            return isStudioRole
                ? t("buttons:upgrade-plan")
                : t("buttons:change-plan");
        },
        [t, isStudioRole]
    );

    return (
        <>
            {!!subscriptions?.length && (
                <>
                    <div className={`${styles["plan-grid"]}`}>
                        {subscriptions?.map(({ subscription, price }, idx) => {
                            return (
                                <div key={idx} className={styles["plan-card"]}>
                                    <div
                                        className={
                                            styles["plan-price-container"]
                                        }
                                    >
                                        <h5>{subscription.PlanName}</h5>
                                        <h6>
                                            {displayCostFn(
                                                calculateDiscountedAmount(
                                                    subscription?.Amount,
                                                    subscription
                                                ),
                                                true
                                            )}
                                            {displayDiscount(subscription)}
                                        </h6>
                                    </div>

                                    {price?.Product?.Description && (
                                        <p>{price?.Product?.Description}</p>
                                    )}

                                    {!price?.Product.Description &&
                                        !!price.Product?.Features?.length &&
                                        price.Product.Features.map(
                                            (feature, index) => (
                                                <p key={index}>{feature}</p>
                                            )
                                        )}

                                    <div className={styles["action-buttons"]}>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() =>
                                                headerButtonFn(subscription)
                                            }
                                            disabled={isPaused}
                                        >
                                            {getHeaderButtonText({
                                                subscription,
                                                price
                                            })}
                                        </button>
                                        {subscription.CancelAtPeriodEnd ||
                                        !!subscription.CanceledAt ? (
                                            <Link
                                                className="btn btn-outline-success"
                                                href={`/subscription/renew?s=${subscription.SilverSunnStripeSubscriptionId}`}
                                            >
                                                {t("buttons:renew")}
                                            </Link>
                                        ) : (
                                            <button
                                                type="button"
                                                className={`btn btn-link ${styles["cancel-button"]}`}
                                                onClick={() =>
                                                    trySave(
                                                        subscription.SilverSunnStripeSubscriptionId,
                                                        subscription.PlanId
                                                    )
                                                }
                                            >
                                                {t("subscription:cancel-plan")}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
};

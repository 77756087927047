import {
    BroadcastsHash,
    VideoMultiSelectModalProps
} from "../AddToPlaylistModal";
import { VideoPlayerCloudflareResponse } from "@switcherstudio/switcher-api-client";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { ModalBase } from "components/modal/ModalBase";
import styles from "./index.module.scss";
import CloseIcon from "assets/icons/close.svg?react";
import { BroadcastsMultiSelect } from "views/page-content/video-player/video-player-settings/BroadcastsMultiSelect";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useDispatch } from "react-redux";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "store/store";
import { exists } from "helpers/booleans";
import { VideoUploadModalEntryPoint } from "components/video-upload/VideoUploadModalEntryPoint";
import { sortByDate } from "helpers/time";
import { v4 as uuidv4 } from "uuid";
import { useClaimCheck } from "hooks/useClaimCheck";

export interface UploadOrChooseVideoModalProps
    extends VideoMultiSelectModalProps {
    playerId: string;
    onVideoUpload?: (broadcastId: string) => void;
    modalTitle: string;
    /** The button to the left of the rightmost button */
    rightButtonOneText?: string;
    /** The rightmost button */
    rightButtonTwoText?: string;
    uploadModalKey?: number;
}

export const UploadOrChooseVideoModal = ({
    playerId,
    isMultiple,
    allowAdditional = true,
    onSelect,
    isOpen,
    setIsOpen,
    previouslySelectedBroadcastIds,
    videosFromProps,
    modalTitle,
    rightButtonOneText,
    rightButtonTwoText,
    uploadModalKey,
    onVideoUpload
}: UploadOrChooseVideoModalProps) => {
    const [videoUploadSessionId, setVideoUploadSessionId] = useState<string>(
        uuidv4()
    );
    const [broadcastsHash, setBroadcastsHash] = useState<BroadcastsHash>();
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [selectedBroadcastId, setSelectedBroadcastId] = useState<string>();
    const [numberSelected, setNumberSelected] = useState<number>(0);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const hasCatalogClaim = useClaimCheck("catalog");

    useEffect(() => {
        if (isMultiple) return;

        setHasChanges(
            previouslySelectedBroadcastIds?.[0]?.toString() !==
                selectedBroadcastId?.toString()
        );
    }, [isMultiple, selectedBroadcastId, previouslySelectedBroadcastIds]);

    const transformAndSetVideoData = useCallback(
        (data: VideoPlayerCloudflareResponse[]) => {
            const hashedBroadcasts = data?.reduce((hash, videoResponse) => {
                return {
                    ...hash,
                    [videoResponse.broadcast?.Id]: {
                        broadcast: videoResponse.broadcast,
                        videoResponse: videoResponse?.videos[0],
                        metrics: videoResponse?.entitySummary,
                        subsequentVideoResponses:
                            videoResponse?.videos.slice(1),
                        selected: previouslySelectedBroadcastIds.includes(
                            videoResponse?.broadcast?.Id
                        ),
                        disabled: false
                    }
                };
            }, {} as BroadcastsHash);

            setBroadcastsHash(hashedBroadcasts);
        },
        [setBroadcastsHash, previouslySelectedBroadcastIds]
    );

    useSwitcherClient((client) => client.cloudRecordings_GetVideosForUserV2, {
        // if videos passed in as props, avoid fetching a second time
        requestImmediately: !videosFromProps,
        fetchPolicy: "cache-and-network",
        onSuccess: (data) => {
            transformAndSetVideoData(data);
        },
        onError: () => {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("errors:recording-retrieval-error")
                })
            );
        }
    });

    useEffect(() => {
        if (!!videosFromProps) {
            transformAndSetVideoData(videosFromProps);
        }
    }, [videosFromProps, transformAndSetVideoData]);

    const handleSelect = useCallback(
        (broadcastId: string) => {
            if (isMultiple) {
                if (broadcastsHash[broadcastId]?.selected) {
                    setNumberSelected(numberSelected - 1);
                } else {
                    setNumberSelected(numberSelected + 1);
                }

                setBroadcastsHash({
                    ...broadcastsHash,
                    [broadcastId]: {
                        ...broadcastsHash[broadcastId],
                        selected: !broadcastsHash[broadcastId]?.selected
                    }
                });
                return;
            } else {
                if (numberSelected < 1) setNumberSelected(1);
                setSelectedBroadcastId(broadcastId);
            }
        },
        [
            setBroadcastsHash,
            broadcastsHash,
            isMultiple,
            setSelectedBroadcastId,
            numberSelected,
            setNumberSelected
        ]
    );

    const handleSelectAll = useCallback(() => {
        const updatedBroadcastHash = Object.entries(broadcastsHash).reduce(
            (hash, [broadcastId]) => ({
                ...hash,
                [broadcastId]: {
                    ...broadcastsHash[broadcastId],
                    selected: broadcastsHash[broadcastId]?.disabled
                        ? true
                        : !allSelected
                }
            }),
            {} as BroadcastsHash
        );
        setBroadcastsHash(updatedBroadcastHash);
        setNumberSelected(
            !allSelected ? Object.values(broadcastsHash).length : 0
        );
        setAllSelected(!allSelected);
    }, [setBroadcastsHash, broadcastsHash, allSelected, setAllSelected]);

    const handleSubmit = () => {
        if (numberSelected === 0) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("errors:no-broadcasts-selected")
                })
            );
            return;
        }

        if (isMultiple) {
            onSelect(
                Object.entries(broadcastsHash)
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .filter(([_, video]) => video?.selected && !video?.disabled)
                    .map(([broadcastId]) => broadcastId)
            );
        } else {
            onSelect([selectedBroadcastId]);
        }

        setIsOpen(false);
    };

    const sortedBroadcasts = useMemo(
        () =>
            !!broadcastsHash
                ? Object.values(broadcastsHash).sort((a, b) =>
                      sortByDate(
                          a.broadcast?.CreatedAt,
                          b.broadcast?.CreatedAt,
                          { descending: true }
                      )
                  )
                : [],
        [broadcastsHash]
    );

    return (
        exists(broadcastsHash) && (
            <ModalBase
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                stylesOverride={{
                    padding: "0",
                    maxHeight: "unset",
                    maxWidth: "700px"
                }}
            >
                <div className={styles["multi-select-container"]}>
                    <div className={styles["header"]}>
                        <h5>
                            {modalTitle
                                ? modalTitle
                                : t("video-player-page:add-videos")}
                        </h5>
                        <button
                            type="button"
                            className="btn"
                            onClick={() => setIsOpen(false)}
                        >
                            <CloseIcon />
                        </button>
                    </div>

                    <hr />
                    <div className={styles["content"]}>
                        <div className={styles["upload-container"]}>
                            <VideoUploadModalEntryPoint
                                key={uploadModalKey}
                                lockedToPlayer={playerId}
                                allowMultipleUploads={isMultiple}
                                allowAdditionalUploads={allowAdditional}
                                onUploadComplete={onVideoUpload}
                                sessionId={videoUploadSessionId}
                                setSessionId={setVideoUploadSessionId}
                            />
                        </div>

                        {sortedBroadcasts?.length > 0 && (
                            <>
                                <div className={styles["separator"]}>
                                    <hr />
                                    <small>
                                        {t(
                                            "video-player-page:add-videos-prompt"
                                        )}
                                    </small>
                                    <hr />
                                </div>

                                <div
                                    className={styles["video-select-container"]}
                                >
                                    <BroadcastsMultiSelect
                                        allowMultiple={isMultiple}
                                        broadcasts={sortedBroadcasts}
                                        selectedBroadcastId={
                                            selectedBroadcastId
                                        }
                                        handleSelect={handleSelect}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <hr />

                    <div className={styles["footer"]}>
                        <button
                            type="button"
                            className="btn"
                            onClick={() => setIsOpen(false)}
                        >
                            {t("buttons:cancel")}
                        </button>
                        {sortedBroadcasts?.length > 0 && isMultiple && (
                            <button
                                type="button"
                                className="btn"
                                onClick={handleSelectAll}
                            >
                                {!!rightButtonOneText
                                    ? rightButtonOneText
                                    : t("buttons:select-all")}
                            </button>
                        )}
                        {sortedBroadcasts?.length > 0 && (
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                                disabled={numberSelected === 0 || !hasChanges}
                            >
                                {!!rightButtonTwoText
                                    ? t(rightButtonTwoText)
                                    : hasCatalogClaim
                                    ? t("video-player-page:add-to-collection")
                                    : t("video-player-page:add-to-playlist")}
                            </button>
                        )}
                    </div>
                </div>
            </ModalBase>
        )
    );
};

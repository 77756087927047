import React, { useState, useCallback } from "react";
import { TextInput } from "components/inputs/text-input/TextInput";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { CouponResponse } from "@switcherstudio/switcher-api-client";
import { IUserInfo } from "store/user/types";
import { CouponApplied } from "../../alerts/CouponApplied";

export interface CouponFormProps {
    onClick?: (coupon: string) => void;
    removeCoupon?: () => void;
    applyClaimedCoupon?: (couponResponse: CouponResponse) => void;
    label?: string;
    description?: string;
    claimedCoupon?: CouponResponse;
    userInfo: IUserInfo;
    className?: any;
    discount?: number;
    readOnly?: boolean;
}

export const CouponForm: React.FC<CouponFormProps> = ({
    className,
    label,
    claimedCoupon,
    readOnly,
    onClick,
    removeCoupon
}: CouponFormProps) => {
    const { t } = useTranslation();
    const [couponInput, setCouponInput] = useState<string>("");
    const onCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCouponInput(e.target.value);
    };

    const handleRemoveCoupon = useCallback(() => {
        removeCoupon();
        setCouponInput("");
    }, [removeCoupon]);

    if (claimedCoupon) {
        return (
            <CouponApplied
                coupon={claimedCoupon}
                onRemove={handleRemoveCoupon}
                hideRemoveButton={readOnly}
            />
        );
    }

    return (
        <div
            className={`${styles["coupon-form"]} ${className ? className : ""}`}
        >
            <>
                <div className={`${styles["text-wrapper"]}`}>
                    <TextInput
                        type="text"
                        className={"coupon-input"}
                        id="coupon-code"
                        placeholder={t("coupon-form:coupon-code")}
                        value={couponInput}
                        disabled={readOnly}
                        onChange={onCouponChange}
                        label={label || t("coupon-form:enter-code")}
                    />
                </div>
                <button
                    type="button"
                    className={`btn btn-primary mt-6 mb-6 ${styles["apply-button"]}`}
                    onClick={() => onClick(couponInput)}
                    disabled={!couponInput}
                >
                    {t("coupon-form:apply")}
                </button>
            </>
        </div>
    );
};

import React, { FunctionComponent, SVGProps, useEffect, useMemo } from "react";
import { MatcherGenerator } from "navi";
import { Link, useNavigation, useCurrentRoute } from "react-navi";
import { useTranslation } from "react-i18next";
import { trackEvent } from "../../helpers/analyticsHelpers";

// icons
import HomeIcon from "assets/icons/dashboard.svg?react";
import CloudIcon from "assets/icons/cloud.svg?react";
import BillingIcon from "assets/icons/billing.svg?react";
import FilmStripIcon from "assets/icons/film-strip.svg?react";
import SwitcherPlayerIcon from "assets/icons/switcher-player-rev.svg?react";
import PlatformIcon from "assets/icons/platforms.svg?react";
import ProfileIcon from "assets/icons/username.svg?react";
import HelpIcon from "assets/icons/question-mark.svg?react";
import HistoryIcon from "assets/icons/history.svg?react";
import GatedIcon from "assets/icons/gated.svg?react";
import MessageIcon from "assets/icons/message.svg?react";
import AppCenterIcon from "assets/icons/appcenter.svg?react";
import CloseIcon from "assets/icons/close.svg?react";
import RemoteGuestsIcon from "assets/icons/guests.svg?react";
import LogoutIcon from "assets/icons/logout.svg?react";
import WorkspaceIcon from "assets/icons/workspace.svg?react";
import TeamIcon from "assets/icons/user-group.svg?react";
import BetaIcon from "assets/icons/beta.svg?react";
import LivestreamsIcon from "assets/icons/livestreams.svg?react";
import BrandKitIcon from "assets/icons/brandkit.svg?react";
import AnalyticsIcon from "assets/icons/analytics.svg?react";
import TutorialIcon from "assets/icons/tutorials.svg?react";
import styles from "./Sidebar.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { logout } from "store/user/slice";
import { resetNotifications } from "store/notification/slice";
import { RootState } from "store/reducers";
import { FlagContainer } from "components/flag-container/FlagContainer";
import {
    mustBeOrgAdmin,
    mustBeSubscriptionOwner
} from "hooks/useRedirectIfDisallowed";
import { useClaimCheck } from "hooks/useClaimCheck";
import { useSupportSystem } from "hooks/useSupportSystem";
import { resetEvents } from "store/events/slice";
import { resetApiCache } from "store/api/slice";
import { NavDropdown } from "components/navs/NavDropdown";
import {
    toggleSidebarDropdownSelection,
    setSidebarDropdownSelection
} from "store/view/slice";
import { BookingButton } from "components/buttons/BookingButton";

// Import the logos and set Logo based on the environment variable
import OldLogo from "assets/icons/switcher-horizontal_old.svg?url";
import NewLogo from "assets/icons/switcher-horizontal_new.svg?url";

const Logo = import.meta.env.VITE_USE_NEW_LOGO === "true" ? NewLogo : OldLogo;

interface NaviSidebarProps {
    videoCMSRoutes: { [k: string]: () => MatcherGenerator<any> };
    creationToolsRoutes: { [k: string]: () => MatcherGenerator<any> };
    helpRoutes: { [k: string]: () => MatcherGenerator<any> };
    sidebarRoutes: { [k: string]: () => MatcherGenerator<any> };
    accountSettingsRoutes: { [k: string]: () => MatcherGenerator<any> };
    activeRoute: string;
    show: boolean;
    onToggle: () => void;
}
export const NaviSidebar: React.FC<NaviSidebarProps> = ({
    videoCMSRoutes,
    creationToolsRoutes,
    helpRoutes,
    accountSettingsRoutes,
    activeRoute,
    show,
    onToggle
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigation = useNavigation();

    const user = useSelector((state: RootState) => state.user);
    const { isSupportAvailable, supportSystemCommand } = useSupportSystem();
    const { t } = useTranslation("page-titles");
    const hasVideoPlayerClaim = useClaimCheck("videoplayer");
    const enablePayPerView = useClaimCheck("gatedcontent");
    const enableCatalog = useClaimCheck("catalog");

    const navIcons: {
        [k: string]: FunctionComponent<
            SVGProps<SVGSVGElement> & { title?: string | undefined }
        >;
    } = {
        home: HomeIcon,
        "video-library": FilmStripIcon,
        "switcher-players": SwitcherPlayerIcon,
        "gated-content": GatedIcon,
        analytics: AnalyticsIcon,
        downloads: AppCenterIcon,
        platforms: PlatformIcon,
        "switcher-cloud": CloudIcon,
        "remote-guests": RemoteGuestsIcon,
        "my-livestreams": LivestreamsIcon,
        "experimental-features": BetaIcon,
        subscription: BillingIcon,
        profile: ProfileIcon,
        "brand-profile": BrandKitIcon,
        workspaces: WorkspaceIcon,
        "my-team": TeamIcon,
        help: HelpIcon,
        tutorials: TutorialIcon,
        "contact-support": MessageIcon,
        "support-messages": HistoryIcon,
        catalog: SwitcherPlayerIcon
    };

    const navTitles: { [k: string]: string } = {
        home: t("page-titles:home"),
        "video-library": t("page-titles:video-library"),
        "switcher-players": t("page-titles:switcher-players"),
        "gated-content": t("page-titles:gated-content"),
        analytics: t("page-titles:analytics"),
        downloads: t("page-titles:downloads"),
        platforms: t("page-titles:platforms"),
        "switcher-cloud": t("page-titles:switcher-cloud"),
        "remote-guests": t("page-titles:remote-guests"),
        "my-livestreams": t("page-titles:my-livestreams"),
        "experimental-features": t("page-titles:experimental-features"),
        subscription: t("page-titles:subscription"),
        profile: t("page-titles:profile"),
        workspaces: t("page-titles:workspaces"),
        "my-team": t("page-titles:my-team"),
        "brand-profile": t("page-titles:brand-profile"),
        help: t("page-titles:help-center"),
        tutorials: t("page-titles:video-tutorials"),
        "contact-support": t("page-titles:contact-support"),
        "support-messages": t("page-titles:support-messages"),
        catalog: t("page-titles:catalog")
    };

    const excludedRoutes = {
        accountSettings: !mustBeSubscriptionOwner(user)
            ? ["/subscription"]
            : [],
        creationTools: !mustBeSubscriptionOwner(user)
            ? ["/experimental-features"]
            : [],
        videoCMS: [
            ...(!enablePayPerView ? ["/gated-content"] : []),
            ...(!hasVideoPlayerClaim ? ["/switcher-players"] : []),
            ...(!hasVideoPlayerClaim ? ["/analytics"] : []),
            ...(!enableCatalog ? ["/catalog", "/catalog/collections"] : []),
            ...(enableCatalog ? ["/switcher-players"] : [])
        ],
        help: []
    };

    const {
        sidebarDropdownSelection: {
            videoCMS = null,
            accountSettings = null,
            creationTools = null,
            help = null
        }
    } = useSelector((state: RootState) => state.view);

    const route = useCurrentRoute();
    const isVideoCMSPath = Object.keys(videoCMSRoutes).some(
        (path) =>
            route.url.pathname.startsWith(path) ||
            route.url.pathname.startsWith("/switcher-player/")
    );
    const isCreationToolsPath = Object.keys(creationToolsRoutes).some((path) =>
        route.url.pathname.startsWith(path)
    );
    const ishelpRoutesPath = Object.keys(helpRoutes).some((path) =>
        route.url.pathname.startsWith(path)
    );
    const isaccountSettingsRoutesPath = Object.keys(accountSettingsRoutes).some(
        (path) => route.url.pathname.startsWith(path)
    );

    const showBookTime = useMemo<boolean>(() => {
        return (
            user?.userInfo?.Status === "trialing" &&
            user?.userInfo?.Roles?.includes("Professional")
        );
    }, [user]);

    useEffect(() => {
        if (isVideoCMSPath) {
            dispatch(
                setSidebarDropdownSelection({
                    key: "videoCMS",
                    value: isVideoCMSPath
                })
            );
        }
        if (isCreationToolsPath) {
            dispatch(
                setSidebarDropdownSelection({
                    key: "creationTools",
                    value: isCreationToolsPath
                })
            );
        }
        if (ishelpRoutesPath) {
            dispatch(
                setSidebarDropdownSelection({
                    key: "help",
                    value: ishelpRoutesPath
                })
            );
        }
        if (isaccountSettingsRoutesPath) {
            dispatch(
                setSidebarDropdownSelection({
                    key: "accountSettings",
                    value: isaccountSettingsRoutesPath
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`${styles.sidebar} ${show ? styles.active : ""}`}>
                <span className={styles.close} onClick={onToggle}>
                    <CloseIcon />
                </span>
                <div className={styles.container}>
                    <div className={styles["sidebar-top"]}>
                        <div
                            className={`${styles["desktop-logo"]} text-center`}
                        >
                            <Link href="/home">
                                <img src={Logo} alt="Switcher Logo" />
                            </Link>
                        </div>
                        <div className={styles["sidebar-dropdown-container"]}>
                            <NavDropdown
                                label={t(
                                    "sidebar-dropdown-titles:videoCMS"
                                ).toUpperCase()}
                                routes={videoCMSRoutes}
                                excludedRoutes={excludedRoutes.videoCMS}
                                navTitles={navTitles}
                                navIcons={navIcons}
                                activeRoute={activeRoute}
                                onToggle={onToggle}
                                isOpen={videoCMS}
                                toggleOpen={() => {
                                    dispatch(
                                        toggleSidebarDropdownSelection(
                                            "videoCMS"
                                        )
                                    );
                                }}
                            ></NavDropdown>
                            <NavDropdown
                                label={t(
                                    "sidebar-dropdown-titles:creationTools"
                                ).toUpperCase()}
                                routes={creationToolsRoutes}
                                excludedRoutes={excludedRoutes.creationTools}
                                navTitles={navTitles}
                                navIcons={navIcons}
                                activeRoute={activeRoute}
                                onToggle={onToggle}
                                isOpen={creationTools}
                                toggleOpen={() => {
                                    dispatch(
                                        toggleSidebarDropdownSelection(
                                            "creationTools"
                                        )
                                    );
                                }}
                            ></NavDropdown>
                            <NavDropdown
                                label={t(
                                    "sidebar-dropdown-titles:accountSettings"
                                ).toUpperCase()}
                                routes={accountSettingsRoutes}
                                excludedRoutes={excludedRoutes.accountSettings}
                                navTitles={navTitles}
                                navIcons={navIcons}
                                activeRoute={activeRoute}
                                onToggle={onToggle}
                                isOpen={accountSettings}
                                toggleOpen={() => {
                                    dispatch(
                                        toggleSidebarDropdownSelection(
                                            "accountSettings"
                                        )
                                    );
                                }}
                            >
                                <>
                                    {user.orgs.length > 0 ? (
                                        <div>
                                            <Link
                                                href="/workspaces"
                                                prefetch={false}
                                                onClick={onToggle}
                                                className={
                                                    activeRoute === "workspaces"
                                                        ? styles["active-route"]
                                                        : ""
                                                }
                                            >
                                                {<WorkspaceIcon />}
                                                {navTitles[
                                                    "workspaces"
                                                ].toUpperCase()}
                                            </Link>
                                        </div>
                                    ) : null}
                                    {mustBeOrgAdmin(user) ? (
                                        <div>
                                            <Link
                                                href="/my-team"
                                                prefetch={false}
                                                onClick={onToggle}
                                                className={
                                                    activeRoute === "my-team"
                                                        ? styles["active-route"]
                                                        : ""
                                                }
                                            >
                                                {React.createElement(
                                                    navIcons[
                                                        "my-team"
                                                    ] as React.ElementType
                                                )}
                                                {navTitles[
                                                    "my-team"
                                                ].toUpperCase()}
                                            </Link>
                                        </div>
                                    ) : null}
                                </>
                            </NavDropdown>
                            <NavDropdown
                                label={t(
                                    "sidebar-dropdown-titles:help"
                                ).toUpperCase()}
                                routes={helpRoutes}
                                excludedRoutes={excludedRoutes.help}
                                navTitles={navTitles}
                                navIcons={navIcons}
                                activeRoute={activeRoute}
                                onToggle={onToggle}
                                isOpen={help}
                                toggleOpen={() => {
                                    dispatch(
                                        toggleSidebarDropdownSelection("help")
                                    );
                                }}
                            >
                                <div className={styles["help-dropdown"]}>
                                    <>
                                        <button
                                            type="button"
                                            className="btn btn-link"
                                            onClick={() => {
                                                window.open(
                                                    "https://support.switcherstudio.com",
                                                    "_blank"
                                                );
                                            }}
                                        >
                                            {React.createElement(
                                                navIcons[
                                                    "help"
                                                ] as React.ElementType
                                            )}
                                            {navTitles["help"].toUpperCase()}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-link"
                                            onClick={() => {
                                                if (isSupportAvailable) {
                                                    trackEvent(
                                                        "Opened Support Widget",
                                                        {
                                                            source: "sidebar"
                                                        }
                                                    );
                                                    supportSystemCommand(
                                                        "show"
                                                    );
                                                } else {
                                                    window.open(
                                                        "mailto:support@switcherstudio.com"
                                                    );
                                                }
                                            }}
                                        >
                                            {React.createElement(
                                                navIcons[
                                                    "contact-support"
                                                ] as React.ElementType
                                            )}
                                            {navTitles[
                                                "contact-support"
                                            ].toUpperCase()}
                                        </button>
                                    </>
                                    {isSupportAvailable && (
                                        <>
                                            <button
                                                type="button"
                                                className="btn btn-link"
                                                onClick={() => {
                                                    supportSystemCommand(
                                                        "showMessages"
                                                    );
                                                }}
                                            >
                                                {React.createElement(
                                                    navIcons[
                                                        "support-messages"
                                                    ] as React.ElementType
                                                )}
                                                {navTitles[
                                                    "support-messages"
                                                ].toUpperCase()}
                                            </button>
                                        </>
                                    )}
                                </div>
                            </NavDropdown>
                        </div>
                        {showBookTime && <BookingButton />}
                    </div>
                    <div className={`${styles["sidebar-bottom"]}`}>
                        <div className={styles["user-info"]}>
                            <div className={styles["user-org"]}>
                                {user?.userInfo?.Email}
                            </div>
                            <div className={styles["user-plan"]}>
                                {user?.userInfo?.PlanName}
                            </div>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => {
                                    supportSystemCommand("shutdown");
                                    navigation.navigate("/login");
                                    dispatch(resetNotifications());
                                    dispatch(resetApiCache());
                                    dispatch(resetEvents());
                                    dispatch(logout());
                                }}
                            >
                                {<LogoutIcon />}{" "}
                                {t("page-titles:logout").toUpperCase()}
                            </button>
                        </div>
                        <FlagContainer />
                    </div>
                </div>
            </div>
            <div
                className={`${styles.backdrop} ${show ? styles.active : ""}`}
                onClick={onToggle}
            ></div>
        </>
    );
};
